import ButtonLink from "../../../components/ButtonLink";
import FeedbackIcon from "../../../components/icons/FeedbackIcon";
import FeedbackLargeIcon from "../../../components/icons/FeedbackLargeIcon";

export default function FeedbackCard({title,description,link}) {
  const href = link?.url ?? "#";
  const text = link?.name ?? "Send feedback";
  if (!link) {
    return (<></>)
  }
  return (
    <div className={`rounded p-4 my-4 bg-goj-tertiary-very-light-tint w-full`}>
      {title ? 
        <div className="flex align-middle">
          <div className="pt-1">
            <FeedbackIcon />
          </div>
          <div className="pl-3">
            <p><strong className="text-xl">{title}</strong></p>
          </div>
        </div> 
        : null
      }
      {description ? <div className="flex"><p>{description}</p></div> : null}
      {title ? 
        <div className="flex w-fit">
          <ButtonLink href={href} title={title} text={text} background={`goj-tertiary`} />
        </div> 
        : 
        <div className="flex justify-between">
          <div className="flex">
            <ButtonLink href={href} title={title} text={text} background={`goj-tertiary`} />
          </div>
          <div className="flex">
            <FeedbackLargeIcon />
          </div>
        </div> 
      }
    </div>
  )
}
