import { ChevronRightIcon } from '@heroicons/react/20/solid';
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';

export default function Breadcrumb(props) {
 const { pages, currentPage } = props;
 const [isCollapsed, setIsCollapsed] = useState(false);
 const [isExpanded, setIsExpanded] = useState(false); // New state to track expanded state

 useEffect(() => {
    const handleResize = () => {
      setIsCollapsed(window.innerWidth < 640);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
 }, []);

 const handleExpand = () => {
    setIsExpanded(true); // Set expanded state when "..." is clicked
 };

 // Determine what to display based on the isCollapsed and isExpanded states
 let displayedPages = pages;
 if (isCollapsed && !isExpanded && pages.length > 2) {
    displayedPages = [pages[0], { name: '...', href: '#', current: false }, pages[pages.length - 1]];
 }

 return (
    <nav className="flex-auto bg-goj-gray-light-super pt-1 pb-2" aria-label="Breadcrumb">
      <div className="container mx-auto max-w-oshMaxWidth">
        <div className="px-4 lg:px-0 lg:grid lg:grid-cols-12">
          <ol className="flex-auto items-center space-x-2 pt-2 pb-1 lg:col-start-3 lg:col-end-11">
            <li className="inline">
              <div className="inline">
                <Link to="/" className="text-goj-secondary hover:underline hover:text-goj-secondary active:text-goj-secondary visited:text-goj-secondary">
                 Home
                </Link>
              </div>
            </li>
            {displayedPages.map((page, index) => {
              if (page.name === '...') {
                return (
                 <li key={page.name} className="inline">
                    <span data-page={page.href}></span>
                    <div className="inline items-center">
                      <ChevronRightIcon className="h-4 w-4 mb-px inline" aria-hidden="true" />
                      <button onClick={handleExpand} className="text-goj-secondary hover:underline hover:text-goj-secondary active:text-goj-secondary visited:text-goj-secondary">
                        {page.name}
                      </button>
                    </div>
                 </li>
                );
              }
              return (
                <li key={page.name} className="inline">
                 <span data-page={page.href}></span>
                 <div className="inline items-center">
                    <ChevronRightIcon className="h-4 w-4 mb-px inline" aria-hidden="true" />
                    {(currentPage === page.href) ? (
                      <span href={page.href} className="ml-4 text-goj-charcoal" aria-current="page">
                        {page.name}
                      </span>
                    ) : (
                      <Link to={page.href} className="ml-4 text-goj-secondary hover:underline hover:text-goj-secondary active:text-goj-secondary visited:text-goj-secondary" aria-current={currentPage === page.href ? 'page' : undefined}>
                        {page.name}
                      </Link>
                    )}
                 </div>
                </li>
              );
            })}
          </ol>
        </div>
      </div>
    </nav>
 );
}