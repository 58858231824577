import { BrowserRouter as Router, Routes, Route, Switch, Navigate } from "react-router-dom";
import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { lazy, Suspense, useEffect, useState } from 'react';
import { reactPlugin } from "./utils/appInsightsConfig";
import { MsalProvider, useMsal } from "@azure/msal-react";
import { EventType, InteractionType } from "@azure/msal-browser";
import { b2cPolicies } from "./authConfig";
import { PageLayout } from "./components/PageLayout";
import { UmbracoContextProvider } from "./context/UmbracoContext";
import { UserContextProvider } from "./context/UserContext";
import { DashboardContextProvider } from "./context/DashboardContext";
import { TellUsOnceContextProvider } from "./features/TellUsOnce/Context/TellUsOnceContext";
import { CareRecordContextProvider } from "./features/CareRecord/Context/CareRecordContext";
import { useInactivityLogout } from './utils/useInactivityLogout'
import { SessionExpiryModal } from './components/SessionExpiryModal';
import { HandleRedirectPromiseComponent } from "./utils/authHandler";

import Home from "./pages/Home";
import Profile from "./pages/Profile";
import Service from "./pages/Service";
import TagManager from 'react-gtm-module';
import Dashboard from "./pages/Dashboard";
import ServiceInteraction from "./pages/ServiceInteraction";
import TaxonomyListing from "./pages/TaxonomyListing";
import Search from "./pages/Search";
import General from "./pages/General";
import ProfileLayout from "./pages/ProfileLayout";
import Confirmation from "./pages/Confirmation";
import YourServices from "./pages/YourServices";
import Logout from "./pages/Logout";
import ScrollToTop from "./utils/ScrollToTop";
import Notifications from "./pages/Notifications";
import Loading from "./components/Loading";
import YourChildren from "./pages/YourChildren"
import DigitalCardTypes from "./pages/DigitalCardTypes"
import AccountSettings from "./pages/AccountSettings"
import YourVehicles from "./pages/YourVehicles";
import AddVehicle from "./pages/AddVehicle";
import Appointments from "./pages/Appointments";
import RegistrationCards from "./pages/RegistrationCards";

const NotFound = lazy(() => import("./pages/NotFound"));
const Stia = lazy(() => import("./pages/Stia"));
const Ltia = lazy(() => import("./pages/Ltia"));
const ChangeDob = lazy(() => import("./pages/ChangeDob"));
const ChangePersona = lazy(() => import("./pages/ChangePersona"));
const ChangeFirstName = lazy(() => import("./pages/ChangeFirstName"));
const ChangeLastName = lazy(() => import("./pages/ChangeLastName"));
const ChangeDataSharing = lazy(() => import("./pages/ChangeDataSharing"));
const AddChild = lazy(() => import("./pages/AddChild"));
const UpdateChild = lazy(() => import("./pages/UpdateChild"));
const ChangeAddressInformation = lazy(() => import("./pages/ChangeAddressInformation"));
const ChangeContactInformation = lazy(() => import("./pages/ChangeContactInformation"));
const ChangePreferencesInformation = lazy(() => import("./pages/ChangePreferencesInformation"));
const ServiceDeliveryHistory = lazy(() => import("./pages/ServiceDeliveryHistory"));
const IncomeTaxDetails = lazy(() => import("./pages/IncomeTaxDetails"));
const CareRecord = lazy(() => import("./pages/CareRecord"));
const CareRecordEvents = lazy(() => import("./pages/CareRecordEvents"));
const CareRecordReferrals = lazy(() => import("./pages/CareRecordReferrals"));
const CareRecordConsultations = lazy(() => import("./pages/CareRecordConsultations"));
const CareRecordMedication = lazy(() => import("./pages/CareRecordMedication"));
const CareRecordProblems = lazy(() => import("./pages/CareRecordProblems"));
const CareRecordHealthStatus = lazy(() => import("./pages/CareRecordHealthStatus"));
const CareRecordInvestigations = lazy(() => import("./pages/CareRecordInvestigations"));
const CareRecordImmunisations = lazy(() => import("./pages/CareRecordImmunisations"));
const DigitalCards = lazy(() => import("./pages/DigitalCards"));
const Appointment = lazy(() => import("./pages/Appointment"));
const ChangeAppointment = lazy(() => import("./pages/ChangeAppointment"));
const CancelAppointment = lazy(() => import("./pages/CancelAppointment"));
const RegistrationCardApply = lazy(() => import("./pages/RegistrationCardApply"));

const Pages = () => {
  /**
   * useMsal is hook that returns the PublicClientApplication instance, 
   * an array of all accounts currently signed in and an inProgress value 
   * that tells you what msal is currently doing. For more, visit:
   * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/hooks.md
  */
  const { instance } = useMsal();
  const { showModal, setShowModal, logout } = useInactivityLogout();
  /**
  * Using the event API, you can register an event callback that will do something when an event is emitted. 
  * When registering an event callback in a react component you will need to make sure you do 2 things.
  * 1) The callback is registered only once
  * 2) The callback is unregistered before the component unmounts.
  * For more, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/events.md
  */
  useEffect(() => {
    const callbackId = instance.addEventCallback((event) => {
      if (event.eventType === EventType.LOGIN_FAILURE) {
        if (event.error && event.error.errorMessage.indexOf("AADB2C90118") > -1) {
          if (event.interactionType === InteractionType.Redirect) {
            instance.loginRedirect(b2cPolicies.authorities.forgotPassword);
          } else if (event.interactionType === InteractionType.Popup) {
            instance.loginPopup(b2cPolicies.authorities.forgotPassword)
              .catch(e => {
                return;
              });
          }
        }
      }

      if (event.eventType === EventType.LOGIN_SUCCESS || event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) {
        if (event?.payload) {
          /**
          * We need to reject id tokens that were not issued with the default sign-in policy.
          * "acr" claim in the token tells us what policy is used (NOTE: for new policies (v2.0), use "tfp" instead of "acr").
          * To learn more about B2C tokens, visit https://docs.microsoft.com/en-us/azure/active-directory-b2c/tokens-overview
          */
          if (event.payload.idTokenClaims["acr"] === b2cPolicies.names.forgotPassword) {
            window.alert("Password has been reset successfully. \nPlease sign-in with your new password.");
            return instance.logout();
          } else if (event.payload.idTokenClaims["acr"] === b2cPolicies.names.editProfile) {
            window.alert("Profile has been edited successfully. \nPlease sign-in again.");
            return instance.logout();
          }
        }
      }
    });

    return () => {
      if (callbackId) {
        instance.removeEventCallback(callbackId);
      }
    };
  }, []);

  return (
    <Suspense fallback={<Loading />}>
      <Routes>
        <Route path="/" element={<Home />} />

        <Route path="/search" element={<Search />} />
        <Route path="/changedateofbirth" element={<ChangeDob />} />
        <Route path="/changefirstname" element={<ChangeFirstName />} />
        <Route path="/changesurname" element={<ChangeLastName />} />
        <Route path="/changepersona" element={<ChangePersona />} />
        <Route path="/change-appointment/:id" element={<ChangeAppointment />} />
        <Route path="/cancel-appointment/:id" element={<CancelAppointment />} />

        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/dashboard/account-settings" element={<AccountSettings />} />
        <Route path="/dashboard/account-settings/data-sharing" element={<ChangeDataSharing />} />
        <Route path="/dashboard/registration-card-apply" element={<RegistrationCardApply />} />
        <Route path="/dashboard/registration-cards" element={<RegistrationCards />} />
        <Route path="/dashboard/digital-cards" element={<DigitalCardTypes />} />
        <Route path="/dashboard/personal-details" element={<Profile />} />
        <Route path="/dashboard/personal-details/confirmation" element={<Confirmation result="success" />} />
        <Route path="/dashboard/personal-details/invalid" element={<Confirmation result="invalid" />} />
        <Route path="/dashboard/personal-details/address-information" element={<ChangeAddressInformation />} />
        <Route path="/dashboard/personal-details/contact-information" element={<ChangeContactInformation />} />
        <Route path="/dashboard/personal-details/preferences-information" element={<ChangePreferencesInformation />} />
        <Route path="/dashboard/appointments" element={<Appointments />} />
        <Route path="/dashboard/notifications" element={<Notifications />} />
        <Route path="/dashboard/your-children" element={<YourChildren />} />
        <Route path="/dashboard/your-children/add-child" element={<AddChild />} />
        <Route path="/dashboard/your-children/update-child" element={<UpdateChild />} />
        <Route path="/dashboard/your-vehicles" element={<YourVehicles />} />
        <Route path="/dashboard/your-vehicles/add-vehicle" element={<AddVehicle />} />
        <Route path="/dashboard/your-services" element={<YourServices />} />
        <Route path="/dashboard/your-services/income-tax-rate" element={<IncomeTaxDetails />} />
        <Route path="/dashboard/your-services/long-term-incapacity-allowance-benefit/payments" element={<Ltia />} />
        <Route path="/dashboard/your-services/short-term-incapacity-allowance-benefit/payments" element={<Stia />} />
        <Route path="/dashboard/your-services/jersey-care-record/details" element={<CareRecord />} />
        <Route path="/dashboard/your-services/jersey-care-record/details/planned-events" element={<CareRecordEvents />} />
        <Route path="/dashboard/your-services/jersey-care-record/details/referrals" element={<CareRecordReferrals />} />
        <Route path="/dashboard/your-services/jersey-care-record/details/consultations" element={<CareRecordConsultations />} />
        <Route path="/dashboard/your-services/jersey-care-record/details/medication" element={<CareRecordMedication />} />
        <Route path="/dashboard/your-services/jersey-care-record/details/problems" element={<CareRecordProblems />} />
        <Route path="/dashboard/your-services/jersey-care-record/details/healthstatus" element={<CareRecordHealthStatus />} />
        <Route path="/dashboard/your-services/jersey-care-record/details/investigations" element={<CareRecordInvestigations />} />
        <Route path="/dashboard/your-services/jersey-care-record/details/immunisations" element={<CareRecordImmunisations />} />

        <Route path="/dashboard/:name" element={<ProfileLayout />} />
        <Route path="/dashboard/digital-cards/:name" element={<DigitalCards />} />
        <Route path="/dashboard/your-services/:service" element={<ServiceDeliveryHistory />} />
        <Route path="/dashboard/your-services/:service/:interaction" element={<ServiceInteraction />} />
        <Route path="/dashboard/your-services/:service/appointment" element={<Appointment />} />
        <Route path="/dashboard/your-services/:service/:interaction/appointment" element={<Appointment />} />

        <Route path="/services/:category" element={<TaxonomyListing taxonomy="category" />} />
        <Route path="/services/:category/:service" element={<Service taxonomy="category" />} />
        <Route path="/services/:category/:service/appointment" element={<Appointment />} />
        <Route path="/services/:category/:service/:interaction" element={<ServiceInteraction taxonomy="category" />} />
        <Route path="/services/:category/:service/:interaction/appointment" element={<Appointment />} />

        <Route path="/life-events/:category" element={<TaxonomyListing taxonomy="life-event" />} />
        <Route path="/life-events/:category/:service" element={<Service taxonomy="life-event" />} />
        <Route path="/life-events/:category/:service/appointment" element={<Appointment />} />
        <Route path="/life-events/:category/:service/:interaction" element={<ServiceInteraction taxonomy="life-event" />} />
        <Route path="/life-events/:category/:service/:interaction/appointment" element={<Appointment />} />

        <Route path="/personas/:category" element={<TaxonomyListing taxonomy="persona" />} />
        <Route path="/personas/:category/:service" element={<Service taxonomy="persona" />} />
        <Route path="/personas/:category/:service/appointment" element={<Appointment />} />
        <Route path="/personas/:category/:service/:interaction" element={<ServiceInteraction taxonomy="persona" />} />
        <Route path="/personas/:category/:service/:interaction/appointment" element={<Appointment />} />

        <Route path="/home" element={<Navigate replace to="/" />} />
        <Route path="/life-events" element={<Navigate replace to="/" state={{ taxonomy: "life-event" }} />} />
        <Route path="/services" element={<Navigate replace to="/" />} />
        <Route path="/service" element={<Navigate replace to="/" />} />

        <Route path="/home/accessibility" element={<General />} />
        <Route path="/home/contact-us" element={<General />} />
        <Route path="/home/privacy" element={<General />} />
        <Route path="/home/terms-and-conditions" element={<General />} />
        <Route path="/home/sitemap" element={<General />} />
        <Route path="/home/translate" element={<General />} />

        <Route path="/logout" element={<Logout />} />
        <Route path="*" element={<NotFound />} />

      </Routes>
      <SessionExpiryModal showModal={showModal} setShowModal={setShowModal} />
    </Suspense>
  )
}

// GTM
const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_ID_KEY
}

TagManager.initialize(tagManagerArgs)

// APP
function App({ instance }) {
  window.dataLayer.push({
    event: 'pageview'
  });

  window.dataLayer.push({
    event: 'event',
    eventProps: {
      category: 'login',
      action: 'account',
      label: 'premium',
      value: 1
    }
  });

  const [contextTellUsOnce, setContextTellUsOnce] = useState(null);
  return (
    <Router>
      <ScrollToTop />
      <MsalProvider instance={instance}>
        <UserContextProvider>
          <DashboardContextProvider>
            <UmbracoContextProvider>
              <CareRecordContextProvider>
                <TellUsOnceContextProvider value={{ contextTellUsOnce, setContextTellUsOnce }}>
                  <AppInsightsContext.Provider value={reactPlugin}>
                    <PageLayout>
                    <HandleRedirectPromiseComponent />
                      <Pages />
                    </PageLayout>
                  </AppInsightsContext.Provider>
                </TellUsOnceContextProvider>
              </CareRecordContextProvider>
            </UmbracoContextProvider>
          </DashboardContextProvider>
        </UserContextProvider>
      </MsalProvider>
    </Router>
  );
}

export default App;
