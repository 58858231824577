export default function Badge({ text, type, style }) {
  switch (type){
    case "alert":
      return (
        <>
          <span className={`inline-flex items-center rounded text-oshFilter bg-transparent border border-goj-primary px-2 py-0.5 ml-2 text-goj-primary ${style ? style : ''}`} >{text}</span>
        </>
        )
    case "info":
      return (
      <>
        <span className={`inline-flex items-center rounded text-oshFilter bg-goj-white border border-goj-secondary px-2 py-0.5 ml-2 text-goj-secondary ${style ? style : ''}`} >{text}</span>
      </>
      )
    default:
      return (
      <>
        <span className={`inline-flex items-center rounded text-oshFilter bg-transparent border border-goj-primary px-2 py-0.5 ml-2 text-goj-primary ${style ? style : ''}`} >{text}</span>
      </>
      )
  }
}