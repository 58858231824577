import { useState, useEffect, useContext } from "react";
import { useMediaQuery } from 'react-responsive';
import UmbracoContext from '../../../context/UmbracoContext';
import Loading from "../../../components/Loading";
import AZMenu from "./AZMenu";
import AZList from "./AZList";
import AZAccordion from "./AZAccordion";

function ProcessData(letter, edges) {
  let data = [];
  if (letter && edges && edges.length) {
    for (const edge of edges) {
      if(edge.node.aZTitle.length && edge.node.aZIndex.includes(letter)) {
        let obj = {
          id: edge.node.id,
          title: edge.node.aZTitle.find((str) => str.startsWith(letter)) || edge.node.aZTitle[0],
          description: edge.node.aZDescription,
          url: edge.node.link.url,
          target: edge.node.link.target
        };
        data.push(obj);
      }
    }
    data.sort((a, b) => a.title.localeCompare(b.title));
  }
  return data;
}

export default function AZContent() {
  const handleLetterClick = (letter) => {
    setLetter(letter);
  };

  const umbraco = useContext(UmbracoContext);
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const [loading, setLoading] = useState(false);
  const [letter, setLetter] = useState("A");
  const [data, setData] = useState([]);

  useEffect(() => { 
    setLoading(true);
    if (umbraco) {
      if (umbraco.azWebLinks.length) {
        setData(ProcessData(letter, umbraco.azWebLinks));
      }
      setLoading(false);
    }
  }, [letter]);

  return (
    <div className="my-2 mx-4 lg:mr-0">
      <div className="mx-auto">
        {loading ? 
          <Loading /> 
          : 
          isMobile ?
            <AZAccordion />
            :
            <>
              <AZMenu letter={letter} onLetterClick={handleLetterClick} />
              <div className="lg:grid lg:grid-cols-12">
                <div className="lg:col-start-3 lg:col-end-11">
                  <div className="pt-6 pl-4 lg:pl-0">
                    <h1>{letter}</h1>
                    {data.length ? <AZList data={data} /> : <p>There are no results for this selection</p>}
                  </div>
                </div>
              </div>
            </>
        }
      </div>
    </div>
  )
}