import { useContext, useState, useEffect, createContext } from "react";
import { callUmbracoApi, callUmbracoApiGraphQL } from "../fetch";

const cmsPreview = process.env.REACT_APP_CMS_PREVIEW;

export const UmbracoContext = createContext(null);

export function UmbracoContextProvider({ children }) {
  // config settings
  const [umbracoConfig, setUmbracoConfig] = useState([]);
  useEffect(() => {  
    if (!umbracoConfig.length > 0) {
      let url = "/content/type?contentType=serviceSettings"; 
      callUmbracoApi(url)
        .then(response => {
          setUmbracoConfig(response?._embedded?.content);
        })
        .catch((e) => {
          console.log("ERROR: ", e);
        });
    }
  }, []);

  // forms settings
  const [formSettings, setFormSettings] = useState([]);
  useEffect(() => {  
    if (!formSettings.length > 0) {
      let url = "/content/type?contentType=formSettings"; 
      callUmbracoApi(url)
        .then(response => {
          setFormSettings(response?._embedded?.content);
        })
        .catch((e) => {
          console.log("ERROR: ", e);
        });
    }
  }, []);

  // service categories dropdown
  const [serviceCategories, setServiceCategories] = useState(null);
  useEffect(() => {
    if (!serviceCategories) { console.log("CALLING SCs")
      callUmbracoApi("/content/type?contentType=taxonomyCategoryItem&page=1&pageSize=50")
        .then(response => setServiceCategories(response?._embedded?.content)) 
        .catch(error => console.log(error));
    }
  }, []);

  // profile menu
  const [menuProfile, setMenuProfile] = useState(null);
  useEffect(() => {
    if (!menuProfile) {
      callUmbracoApi("/content/type?contentType=profileMenuItem")
        .then(response => setMenuProfile(response?._embedded?.content))
        .catch(error => {console.log(error)});
    }
  }, []);

  // a-z web links
  const [azWebLinks, setAZWebLinks] = useState([]);
  useEffect(() => {
    if(!azWebLinks.length) { console.log("A-Z Web Links") 
    var isPreview = "false";
    if (cmsPreview && cmsPreview === "true") {
      isPreview = "true"
    }
    var filterQuery = {
      query: "query{allWebLink(preview:" + isPreview + "){edges{node{id aZIndex aZTitle aZDescription link {name url target}}}}}"
    };
    callUmbracoApiGraphQL(filterQuery)
      .then(response => {setAZWebLinks(response?.data?.allWebLink?.edges);})
      .catch((e) => {console.log("Error: ", e?.message);})
    }
  }, [])

  return (
    <UmbracoContext.Provider
      value={{
        umbracoConfig,
        formSettings,
        menuProfile,
        serviceCategories,
        azWebLinks
      }}
    >
      {children}
    </UmbracoContext.Provider>
  );
}

export function useUmbraco() {
  const context = useContext(UmbracoContext);
  if (context === undefined) {
    throw new Error("Context must be used within a Provider");
  }
  return context;
}

export default UmbracoContext;