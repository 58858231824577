import dayjs from 'dayjs';

export const formatTime = (timeString) => {
 // Check if timeString is null or undefined
 if (!timeString) {
    return '';
 }

 // Parse the time string into a Day.js object
 const time = dayjs(timeString, "HH:mm");

 // Determine the format based on whether the time is on the hour
 const format = time.minute() === 0 ? "h a" : "h:mm a";

 // Format the time object to the determined format
 return time.format(format);
};
