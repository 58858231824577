import { useContext } from "react";
import DashboardContext from "../../../context/DashboardContext";
import dayjs from 'dayjs';

export default function CareRecordInfo() {
  const dashboard = useContext(DashboardContext);
  const careRecords = dashboard.careRecordInfo;
  const yesterday = dayjs().subtract(1, 'day') 
  const sevenDaysAgo = dayjs().subtract(7, 'day');
  const threeMonthsAgo = dayjs().subtract(3, 'month');
  let IsAttentionCareRecord = false;
  let cardCareRecord;
  //---------
  // System must promote item to services dashboard summary view using the most recent of the dates from the list below to be used as "last update date" when comparting to other information blocks:
  //  Latest consultation date
  //  Latest or upcoming planned event
  //  Latest referral
  //  Latest medications prescribed
  //  Latest investigations 
  if (careRecords && careRecords.length) {
    let careRecord = careRecords[0];
    cardCareRecord =
    {
      id: careRecord.type + careRecord.date,
      title: careRecord.title,
      description: careRecord.description,
      link: [
        {id: "jersey-care-record-details", text: "View Jersey Care Record details", href:"/dashboard/your-services/jersey-care-record/details/"},
        {id: "jersey-care-record-history", text: "Audit history", href:"/dashboard/your-services/jersey-care-record/"}
      ],
      date: careRecord.date,
      attention: false
    };
  }

  //---------
  // System must promote care information summary to "requires action/attention" section if there are:
  //  Upcoming planned events (using event on date)
  //  Referrals in Active state that are not more than 3 months old (checked using referred on date)   
  //  Medication prescribed not more than 7 calendar days ago (checked using prescribed on date, those not having prescribed on are ignored)
  //  Investigation that is investigated not more than 7 days ago (checked using investigated on date) 
  careRecords && careRecords.length && careRecords.forEach(careRecord => {
    if (!IsAttentionCareRecord && careRecord.type === "event") {
      const eventOn = new Date(careRecord.date)
      if (dayjs(eventOn).isAfter(dayjs(yesterday))) {
        IsAttentionCareRecord = true;
        cardCareRecord =
        {
          id: careRecord.type + careRecord.date,
          title: careRecord.title,
          description: careRecord.description,
          link: [
            {id: "jersey-care-record-details", text: "View Jersey Care Record details", href:"/dashboard/your-services/jersey-care-record/details/"},
            {id: "jersey-care-record-history", text: "Audit history", href:"/dashboard/your-services/jersey-care-record/"}
          ],
          date: careRecord.date,
          attention: true
        };
      }
    }
    if (!IsAttentionCareRecord && careRecord.type === "referral") {
      const referredOn = new Date(careRecord.date)
      if (careRecord.status === "Active" && dayjs(referredOn).isAfter(dayjs(threeMonthsAgo))) {
        IsAttentionCareRecord = true;
        cardCareRecord =
        {
          id: careRecord.type + careRecord.date,
          title: careRecord.title,
          description: careRecord.description,
          link: [
            {id: "jersey-care-record-details", text: "View Jersey Care Record details", href:"/dashboard/your-services/jersey-care-record/details/"},
            {id: "jersey-care-record-history", text: "Audit history", href:"/dashboard/your-services/jersey-care-record/"}
          ],
          date: careRecord.date,
          attention: true
        };
      }
    }
    if (!IsAttentionCareRecord && careRecord.type === "medication") {
      const prescribedOn = new Date(careRecord.date)
      if (dayjs(prescribedOn).isAfter(dayjs(sevenDaysAgo))) {
        IsAttentionCareRecord = true;
        cardCareRecord =
        {
          id: careRecord.type + careRecord.date,
          title: careRecord.title,
          description: careRecord.description,
          link: [
            {id: "jersey-care-record-details", text: "View Jersey Care Record details", href:"/dashboard/your-services/jersey-care-record/details/"},
            {id: "jersey-care-record-history", text: "Audit history", href:"/dashboard/your-services/jersey-care-record/"}
          ],
          date: careRecord.date,
          attention: true
        };
      }
    }
    if (!IsAttentionCareRecord && careRecord.type === "investigation") {
      const investigatedOn = new Date(careRecord.date)
      if (dayjs(investigatedOn).isAfter(dayjs(sevenDaysAgo))) {
        IsAttentionCareRecord = true;
        cardCareRecord =
        {
          id: careRecord.type + careRecord.date,
          title: careRecord.title,
          description: careRecord.description,
          link: [
            {id: "jersey-care-record-details", text: "View Jersey Care Record details", href:"/dashboard/your-services/jersey-care-record/details/"},
            {id: "jersey-care-record-history", text: "Audit history", href:"/dashboard/your-services/jersey-care-record/"}
          ],
          date: careRecord.date,
          attention: true
        };
      }
    }
  });
  return cardCareRecord;
}