const noop = () => {};

const logger = {
 log: noop,
 warn: noop,
 info: noop,
 error: console.error.bind(console),
 dir: console.dir.bind(console),
 debugObject: (message, object) => {
   if (process.env.NODE_ENV !== 'production') {
     console.info(`%c ${message}`, 'background: #f0f9ff; color: black;', object);
   }
 },
};

if (process.env.NODE_ENV !== 'production') {
 logger.log = console.log.bind(console);
 logger.info = console.info.bind(console);
 logger.warn = console.warn.bind(console);
 logger.dir = console.dir.bind(console);
}

export default logger;
