import { useNavigate } from 'react-router-dom';
import { useState, useEffect, useContext } from 'react';
import { useMsal, useAccount } from "@azure/msal-react";
import { callApiWithToken, deleteApiParamWithToken } from '../../../fetch';
import { BrowserAuthError, InteractionRequiredAuthError } from '@azure/msal-browser';
import { protectedResources } from '../../../authConfig';
import parse from 'html-react-parser';
import Button from '../../../components/Button';
import Loading from '../../../components/Loading';
import FeedbackWidget from '../../Feedback/Components/FeedbackWidget';

function scrollToTop() {
  window.scrollTo({top: 0, left: 0, behavior: 'instant'});
}

export default function YourVehiclesList(props) {
  // msal
  const { instance, accounts, inProgress } = useMsal();
  const account = useAccount(instance.getActiveAccount() || {});

  // state
  const navigate = useNavigate();
  const [vehicles, setVehicles] = useState(null);
  const [displayDialog, setDisplayDialog] = useState(false);
  const [selectedVehicle, setSelectedVehicle] = useState(null);
  const [loading, setLoading] = useState(true);
  const [fullProfile, setFullProfile] = useState(null);
  
  // content
  const mainHeading = props?.content?.mainHeading ? props.content.mainHeading : "Your vehicles";
  const mainContent = props?.content?.mainContent ? parse(props.content.mainContent) : "";

  // status
  const [showSuccess, setShowSuccess] = useState(false)
  const [serviceError, setServiceError] = useState(false)
  const [displayError, setDisplayError] = useState(false)
  const [errorMessage, setErrorMessage] = useState({
    messageHeading: '',
    message: '',
  });

  // get profile
  function getProfile(response) {
    callApiWithToken(response.accessToken, protectedResources.apiProfileFull.endpoint)
    .then(response => {
      setLoading(false)
      setFullProfile(response)
      console.log("Profile",response)
    }).catch((error) => {
      setLoading(false);
      setServiceError(true);
      console.log("Error",error)
      setErrorMessage((prevState) => ({
        ...prevState,
        messageHeading: "Service unavailable",
        message: "We are unable to service your request at the moment, please try again later."
      }));
    });
  }

  function GetUserProfile() {
    if (account && inProgress === "none") {
      // profile
      instance.acquireTokenSilent({
        scopes: protectedResources.apiProfileFull.scopes,
        account: account
      }).then((response) => {
        getProfile(response);
        setLoading(false);
      }).catch((error) => {
        // in case if silent token acquisition fails, fallback to an interactive method
        if (error instanceof InteractionRequiredAuthError || BrowserAuthError) {
          setLoading(true);
          if (account && inProgress === "none") {
            instance.acquireTokenSilent({
              scopes: protectedResources.apiProfileFull.scopes,
              account: account
            }).then((response) => {
              getProfile(response);
              setLoading(false);
            }).catch(error => {
              setLoading(false);
              console.log(error)
              setServiceError(true);
              setErrorMessage((prevState) => ({
                ...prevState,
                messageHeading: "Service unavailable",
                message: "We are unable to service your request at the moment, please try again later."
              }));
            });
          }
        }
      });
    }
    else {
      setLoading(true);
    }
  }

  useEffect(() => {
    GetUserProfile();
  }, [account, inProgress, instance]);

  // load vehicle list
  useEffect(() => { 
    if (fullProfile) {
      setVehicles(fullProfile?.vehicles);
      setLoading(false);
    }
    else {
      setLoading(true);
    }
  }, [fullProfile])

  // events
  function handleClickAdd() {
    navigate("/dashboard/your-vehicles/add-vehicle/");
  };

  function showDialog({display,item}) {
    if (display) {
      setDisplayDialog(display);
      setSelectedVehicle(item);
    }
    else {
      setDisplayDialog(false);
      setSelectedVehicle(null);
    }
  }

  function removeVehicle(response, param) {
    deleteApiParamWithToken(response.accessToken, protectedResources.apiProfileVehicles.endpoint, param)
    .then(response => {
      console.log("DELETE RESPONSE", response)
      if (response?.statusCode === 200) {
        setDisplayError(false);
        setVehicles((current) =>
          current.filter((item) => item.vehicleNumber !== param)
        );
        scrollToTop();
        navigate("/dashboard/your-vehicles/");
      }
      else {
        console.log("DELETE ERROR", response?.errordetails?.message);
        setShowSuccess(false);
        setServiceError(true);
        setErrorMessage((prevState) => ({
          ...prevState,
          messageHeading: "An error occurred",
          message: "We are unable to process your request at the moment, please try again later."
        }));
      }
    });
  }

  function handleRemoveVehicle(param) {
    instance.acquireTokenSilent({
      scopes: protectedResources.apiProfile.scopes,
      account: account,
      loginHint: instance.getActiveAccount()?.idTokenClaims.email
    }).then((response) => {
      console.log("REMOVE PARAM",param)
      removeVehicle(response, param);
      setDisplayDialog(false);
      setSelectedVehicle(null);
    }).catch((error) => {
      // in case if silent token acquisition fails, fallback to an interactive method
      if (error instanceof InteractionRequiredAuthError || BrowserAuthError) {
        if (account && inProgress === "none") {
          instance.acquireTokenRedirect({
            scopes: protectedResources.apiProfile.scopes,
            account: account
          }).then((response) => {
            removeVehicle(response, param);
          }).catch(error => {
            console.log(error);
            setServiceError(true);
              setErrorMessage((prevState) => ({
                ...prevState,
                messageHeading: "Service unavailable",
                message: "We are unable to service your request at the moment, please try again later."
              }));
          });
        }
      }
    });
  };

  function RenderFeedbackWidget() {
    return (<FeedbackWidget feedbackTitle={props?.content?.feedbackTitle} feedbackDescription={props?.content?.feedbackDescription} feedbackLink={props?.content?.feedbackLink} />)
  }

  function RenderHeading() {
    return (
      <h1>{mainHeading ?? "Your vehicles"}</h1>
    )
  }

  function RenderMainContent() {
    return (
      <div>{mainContent ?? <></>}</div>
    )
  }

  function RenderVehicles() {
    if (!vehicles || !vehicles.length) {
      return (<p>No vehicles have been added to your profile, if you wish to add a vehicle use the add vehicle option provided.</p>)
    }
    return (
      <div className="mt-4">
        {vehicles.map((item, index) => { 
          {console.log("index",index)}
          return(
            <div key={item?.vehicleNumber} className={`${index === 0 ? "border-t" : ""} border-b border-goj-gray-light`}>
              <dl className="sm:grid sm:grid-cols-2 pt-2 pb-4 sm:space-y-2">
                <dt className="flex items-center mt-2 sm:mt-0">
                  <span>Registration number</span>
                </dt>
                <dd className="mmt-0 inline-block items-center space-y-2">
                  <span className="block lg:inline-block ml-0 lg:pl-4 lg:py-0">
                    <strong>{item?.vehicleNumber}</strong>
                  </span>
                </dd>
                <dt className="flex items-center mt-2 sm:mt-0">
                  <span>Make</span>
                </dt>
                <dd className="mt-0 inline-block items-center space-y-2">
                  <span className="block lg:inline-block ml-0 lg:pl-4 lg:py-0">
                    <strong>{item?.vehicleMake}</strong>
                  </span>
                </dd>
                <dt className="flex items-center mt-2 sm:mt-0">
                  <span>Model</span>
                </dt>
                <dd className="mt-0 inline-block items-center space-y-2">
                  <span className="block lg:inline-block ml-0 lg:pl-4 lg:py-0">
                    <strong>{item?.vehicleModel}</strong>
                  </span>
                </dd>
                <dt className="sm:py-0 mt-2 sm:mt-0">
                </dt>
                <dd className="sm:py-0 sm:mt-0 sm:pl-4">
                  <Button onClick={() => showDialog({display:true, item:item})} styles="w-full md:w-fit justify-center rounded text-oshBase font-semibold border border-goj-secondary bg-goj-white py-2 px-4 text-goj-secondary hover:text-goj-secondary-light hover:border-goj-secondary-light shadow-sm focus:outline-none focus:ring-2 focus:border-goj-focus-dark focus:ring-goj-focus-light">
                    Remove vehicle
                  </Button>
                </dd>
              </dl>
            </div>
          )})
        }
      </div>
    )
  }

  function RenderAddButton() {
    return (
      <div className="mt-6">
        <div className="">
          <div className="flex justify-end space-x-4">
            <Button onClick={() => handleClickAdd()} styles="w-full md:w-fit justify-center rounded text-oshBase font-semibold border border-transparent bg-goj-secondary py-2 px-4 text-goj-white hover:text-goj-white hover:border-goj-secondary-light shadow-sm focus:outline-none focus:ring-2 focus:border-goj-focus-dark focus:ring-goj-focus-light">
              Add vehicle
            </Button>
          </div>
        </div>
      </div>
    )
  }

  function RenderDialog() {
    return (
      <div className={displayDialog ? '' : 'hidden'}>
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="relative lg:flex lg:unset min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <div className="absolute max-w-full top-2/4 lg:max-w-unset lg:max-top-unset lg:relative transform overflow-hidden rounded-lg bg-goj-gray-light-super border border-goj-gray-light px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
              <p>Are you sure you wish to remove {selectedVehicle?.vehicleNumber}?</p>
              <div className="space-y-4 space-x-0 lg:space-y-0 lg:space-x-4">
                <button
                  type="submit"
                  className="w-full md:w-fit justify-center rounded text-oshBase font-semibold border border-goj-secondary bg-goj-secondary py-2 px-4 text-goj-white hover:text-goj-white hover:border-goj-secondary shadow-sm focus:outline-none focus:ring-2 focus:border-goj-focus-dark focus:ring-goj-focus-light"
                  onClick={() => handleRemoveVehicle(selectedVehicle?.vehicleNumber)}
                >
                  Yes
                </button>
                <button
                  type="submit"
                  className="w-full md:w-fit justify-center rounded text-oshBase font-semibold border border-goj-secondary bg-goj-white py-2 px-4 text-goj-secondary hover:text-goj-secondary-light hover:border-goj-secondary-light shadow-sm focus:outline-none focus:ring-2 focus:border-goj-focus-dark focus:ring-goj-focus-light"
                  onClick={() => showDialog({display:false})}
                >
                  No
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  if (loading || !fullProfile) { 
    return (
      <div>
        <RenderFeedbackWidget />
        <RenderHeading />
        <RenderMainContent />
        <Loading />
      </div>
    )
  }

  if (fullProfile?.authenticationSource !== "L2") {
    return (
      <div>
        <RenderFeedbackWidget />
        <RenderHeading />
        <p>If you wish to provide details of your vehicles you will need to login with a verified account, for example with JerseyMe or Yoti.</p>
      </div>
    )
  }

  return (
    <>
      <RenderFeedbackWidget />
      <RenderHeading />
      <RenderMainContent/>
      <RenderVehicles />
      <RenderAddButton />
      <RenderDialog />
    </>
  )
}