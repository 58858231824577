import { useState, useEffect, useContext, useRef } from "react";
import { useMsal, useAccount } from "@azure/msal-react";
import { InteractionRequiredAuthError, BrowserAuthError, InteractionStatus } from "@azure/msal-browser";
import { protectedResources } from "../../../authConfig";
import { callApiWithToken } from "../../../fetch";
import { Accordion, AccordionBody, AccordionHeader, AccordionItem } from "react-headless-accordion";
import { ChevronDownIcon, DevicePhoneMobileIcon, EnvelopeIcon } from "@heroicons/react/24/outline";
import { Link, useLocation } from "react-router-dom";
import dayjs from "dayjs";
import ParseNotifications from "./ParseNotifications";
import DashboardContext from "../../../context/DashboardContext";
import Loading from "../../../components/Loading";
import FeedbackWidget from "../../Feedback/Components/FeedbackWidget";
import logger from "../../../utils/logger";

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

var currentFiltersPast = []
var currentCheckedBox = null
var fromDate = null
var toDate = null
var fromDatem = null
var toDatem = null
const maxDate = "9999-12-31"

export default function DisplayNotifications(props) {
  const { instance, accounts, inProgress } = useMsal();
  const location = useLocation();
  const account = useAccount(instance.getActiveAccount());
  const data = props?.data;
  const dashboard = useContext(DashboardContext);
  const lastRead = dashboard?.notificationsLastRead;
  logger.log("LAST READ:", lastRead);
  const [notifications, setNotifications] = useState(dashboard?.notificationsRecent);
  const [continuationToken, setContinuationToken] = useState(dashboard?.notificationsContinuation);
  const [queryParams, setQueryParams] = useState("?");
  const [noRecords, setNoRecords] = useState(false);
  const [page, setPage] = useState("all");

  const ref = useRef(null);
  const refNew = useRef(null);
  const refOld = useRef(null);
  const refHeading = useRef(null);
  const refFilters = useRef(null);
  const refViewMore = useRef(null);
  const handleClickHide = () => {
    refNew.current.classList.add("hidden")
    refOld.current.classList.add("hidden")
    refHeading.current.classList.add("hidden")
    refFilters.current.classList.add("hidden")
    refViewMore.current.classList.add("hidden")
    ref.current.children[0].childNodes.forEach(item => {
      if (item.nodeName === "BUTTON" && item.classList.length) {
        item.classList.remove("show")
      }
    });
    if (window.innerWidth < 1024) {
      document.documentElement.scrollTo({
        top: 0,
        left: 0,
        behavior: "instant",
      });
    }
  };
  const handleClickShow = () => {
    refHeading.current.classList.remove("hidden")
    refFilters.current.classList.remove("hidden")
    if (notifications.filter(item => dayjs(item.notificationDate).isAfter(dayjs(lastRead))).length) {
      refNew.current.classList.remove("hidden")
    }
    if (notifications.filter(item => dayjs(item.notificationDate).isBefore(dayjs(lastRead))).length) {
      refOld.current.classList.remove("hidden")
    }
    if (continuationToken) {
      refViewMore.current.classList.remove("hidden")
    }
    ref.current.children[0].childNodes.forEach(item => {
      if (item.nodeName === "BUTTON" && item.classList.length) {
        item.classList.add("show")
      }
    });
  };
  
  const handleToggleBackLink = (e) => {
    e.preventDefault();
    if (page === "all") {
      setPage("single")
    } else {
      setPage("all")
    }
  };

  function handleViewMore() {
    setQueryParams("?searchAfter=" + continuationToken);
  }

  const [currentFilters, setCurrentFilters] = useState([]);
  const [toggleFilters, setToggleFilters] = useState(false);
  const handleToggleFiltersClick = event => {
    event.preventDefault();
    setToggleFilters(current => !current);
  }
  const [reset, setReset] = useState(false);
  const handleResetClick = event => {
    setReset(true);
    const allWithClass = Array.from(
      document.getElementsByClassName('filter-input')
    );
    allWithClass.forEach(element => {
      element.checked = false
      element.value = "";
    });
    handleFilterChange("reset", event)
  }
  const handleFilterChange = (name, event) => {
    const targetName = event.target.name
    const targetValue = event.target.value
    if (targetName.includes("filterByDays")) {
      currentCheckedBox = event.target.id
    } else if (targetName.includes("fromDate")) {
      fromDate = targetValue
    } else if (targetName.includes("toDate")) {
      toDate = targetValue
    } else if (targetName.includes("fromDatem")) {
      fromDatem = targetValue
    } else if (targetName.includes("toDatem")) {
      toDatem = targetValue
    }
    logger.log("FILTER", name)
    var newFilters = [...currentFiltersPast];
    const object = { [name]: event.target.value }
    let itemExists = false;
    newFilters.forEach(e => {
      if (e[name]) {
        itemExists = true;
        e[name] = event.target.value;
      }
    });
    if (!itemExists) {
      const index = newFilters.map(e => e[name]).indexOf(event.target.value)
      if (index === -1) {
        newFilters.push(object);
      } else {
        newFilters.splice(index, 1)
      }
    }
    else {
      if (name !== "fromDate" && name !== "toDate") {
        const index = newFilters.map(e => e[name]).indexOf(event.target.value)
        newFilters.splice(index, 1)
      }
    }
    if (newFilters.length != currentFilters.length) {
      setQueryParams("?");
    }
    if (name == "reset") {
      currentFiltersPast = []
      currentCheckedBox = null
      fromDate = null
      toDate = null
      fromDatem = null
      toDatem = null
      setCurrentFilters([]);
    } else {
      currentFiltersPast = newFilters
      setCurrentFilters(newFilters);
    }
  }
  let filter = ``;
  logger.log("CURRENT FILTERS", currentFilters)
  currentFiltersPast?.forEach(item => {
    logger.log("ITEM", item)
    if (item?.date) {
      filter += "&datefrom=" + item.date;
    } else if (item?.fromDate) {
      filter += "&datefrom=" + item.fromDate;
    } else if (item?.toDate) {
      filter += "&dateto=" + item.toDate;
    } else if (item?.status) {
      filter += "&status=" + item.status;
    }
  })

  // notifications
  useEffect(() => {
    if (account && inProgress === InteractionStatus.None && (notifications.length === 0 || queryParams.includes("searchAfter") || currentFiltersPast.length > 0 || reset)) {
      instance.acquireTokenSilent({
        scopes: protectedResources.apiNotificationEndpoint.scopes,
        extraScopesToConsent: [protectedResources.apiProfileCore.scopes],
        account: account,
        redirectUri: window.location.origin+"/auth.html",
        loginHint: instance.getActiveAccount()?.idTokenClaims.email
      }).then((response) => {
        logger.log("API CALL", protectedResources.apiNotificationEndpoint.endpoint + queryParams + filter)
        callApiWithToken(response.accessToken, protectedResources.apiNotificationEndpoint.endpoint + queryParams + filter)
          .then(response => {
            if (response) {
              logger.log("NOTIFICATIONS", response);
              if (response?.results && response?.results.length) {
                setContinuationToken(response?.metadata?.continuationToken ? response.metadata.continuationToken : null);
                const responseData = ParseNotifications(response?.results);
                if (queryParams.includes("searchAfter")) {
                  setNotifications((prevData) => [...prevData, ...responseData]);
                } else {
                  setNotifications(responseData);
                }
              }
              else {
                setNotifications([]);
                setNoRecords(true);
              }
            }
          });
      }).catch((error) => {
        // in case if silent token acquisition fails, fallback to an interactive method
        if (error instanceof InteractionRequiredAuthError || BrowserAuthError) {
          if (account && inProgress === InteractionStatus.None) {
            instance.acquireTokenRedirect({
              scopes: protectedResources.apiNotificationEndpoint.scopes,
              extraScopesToConsent: [protectedResources.apiProfileCore.scopes],
              account: account
            }).then((response) => {
              callApiWithToken(response.accessToken, protectedResources.apiNotificationEndpoint.endpoint + queryParams + filter)
                .then(response => {
                  if (response) {
                    logger.log("NOTIFICATIONS", response);
                    if (response?.results && response?.results.length) {
                      setContinuationToken(response?.metadata?.continuationToken ? response.metadata.continuationToken : null);
                      const responseData = ParseNotifications(response?.results);
                      if (queryParams.includes("searchAfter")) {
                        setNotifications((prevData) => [...prevData, ...responseData]);
                      } else {
                        setNotifications(responseData);
                      }
                    }
                    else {
                      setNotifications([]);
                      setNoRecords(true);
                    }
                  }
                });
            }).catch(error => {
              logger.log("Error: ", error);
            });
          }
        }
      });
    }
  }, [account, inProgress, instance, queryParams, reset, location, currentFilters]);

  function RenderFeedbackWidget() {
    return (<FeedbackWidget feedbackTitle={data?.feedbackTitle} feedbackDescription={data?.feedbackDescription} feedbackLink={data?.feedbackLink} />)
  }

  function RenderNotifcationsSection() {
    return (
      <>
        <main className="p-4 lg:p-0 order-2 lg:col-span-8 ">
          <RenderFeedbackWidget />
          <div className="flex justify-between lg:flex" ref={refHeading}>
            <div><h1 className="px-4 lg:px-0">Notifications</h1></div>
            <div className="m-4 lg:m-0 hidden"><Link to="/changepreferrednotification"><strong>Edit preferences</strong></Link></div>
          </div>
          <fieldset id="mobileFilters" ref={refFilters}>
            <legend className="sr-only">Filters</legend>
            <div className="flex lg:hidden mb-4 px-4">
              <button
                type="button"
                className="flex w-full lg:w-fit justify-center rounded text-oshBase font-semibold border border-goj-secondary bg-goj-white py-2 px-4 text-goj-secondary hover:text-goj-secondary-light hover:border-goj-secondary-light shadow-sm focus:outline-none focus:ring-2 focus:border-goj-focus-dark focus:ring-goj-focus-light"
                onClick={handleToggleFiltersClick}
              >
                {toggleFilters ? "Hide filters" : "Show filters"}
              </button>
            </div>
            <div className={classNames(toggleFilters ? "space-y-5 px-4" : "lg:space-y-5 hidden p-4", "mb-4")}>
              <p><strong className="text-goj-primary">Filter by date</strong></p>
              <div className="relative flex items-start">
                <div className="flex h-5 items-center">
                  <input
                    id="filterByDays30m"
                    aria-describedby="filterByDays30m"
                    type="radio"
                    name="filterByDays"
                    key="filterByDays30m"
                    value={dayjs().subtract(30, 'day').format('YYYY-MM-DD')}
                    onClick={(e) => handleFilterChange("fromDate", e)}
                    className="h-4 w-4 rounded border-goj-gray-light focus:ring-goj-focus-dark filter-input"
                    defaultChecked={currentCheckedBox === "filterByDays30m" ? true : false} />
                </div>
                <div className="ml-3">
                  <label className="text-oshFilter" htmlFor="filterByDays30m">Last 30 days</label>
                </div>
              </div>
              <div className="relative flex items-start">
                <div className="flex h-5 items-center">
                  <input
                    id="filterByDays90m"
                    aria-describedby="filterByDays90m"
                    type="radio"
                    name="filterByDays"
                    key="filterByDays90m"
                    value={dayjs().subtract(90, 'day').format('YYYY-MM-DD')}
                    onClick={(e) => handleFilterChange("fromDate", e)}
                    className="h-4 w-4 rounded border-goj-gray-light focus:ring-goj-focus-dark filter-input"
                    defaultChecked={currentCheckedBox === "filterByDays90m" ? true : false} />
                </div>
                <div className="ml-3">
                  <label className="text-oshFilter" htmlFor="filterByDays90m">Last 90 days</label>
                </div>
              </div>
              <div className="relative flex items-start">
                <div className="flex h-5 items-center">
                  <input
                    id="filterByYear1m"
                    aria-describedby="filterByYear1m"
                    type="radio"
                    name="filterByDays"
                    key="filterByYear1m"
                    value={dayjs().subtract(1, 'year').format('YYYY-MM-DD')}
                    onClick={(e) => handleFilterChange("fromDate", e)}
                    className="h-4 w-4 rounded border-goj-gray-light focus:ring-goj-focus-dark filter-input"
                    defaultChecked={currentCheckedBox === "filterByYear1m" ? true : false} />
                </div>
                <div className="ml-3">
                  <label className="text-oshFilter" htmlFor="filterByYear1m">Last year</label>
                </div>
              </div>
              <div className="relative flex items-start">
                <div className="flex h-5 items-center">
                  <input
                    id="filterByYear3m"
                    aria-describedby="filterByYear3m"
                    type="radio"
                    name="filterByDays"
                    key="filterByYear3m"
                    value={dayjs().subtract(3, 'year').format('YYYY-MM-DD')}
                    onClick={(e) => handleFilterChange("fromDate", e)}
                    className="h-4 w-4 rounded border-goj-gray-light focus:ring-goj-focus-dark filter-input"
                    defaultChecked={currentCheckedBox === "filterByYear3m" ? true : false} />
                </div>
                <div className="ml-3">
                  <label className="text-oshFilter" htmlFor="filterByYear3m">Last 3 years</label>
                </div>
              </div>
              <div className="max-w-max">
                <label className="text-oshFilter" htmlFor="fromDatem">From</label>
                <input type="date" className="block w-full rounded border-goj-gray-light shadow-sm focus:border-goj-focus-dark focus:ring-goj-focus-light max-w-max filter-input" id="fromDatem" name="fromDate" aria-describedby="fromDatem" max={toDatem ? toDatem : maxDate} value={fromDatem} onChange={(e) => handleFilterChange("fromDate", e)} />
                <label htmlFor="toDatem" className="text-oshFilter inline-flex lg:mt-4">To</label>
                <input type="date" className="block w-full rounded border-goj-gray-light shadow-sm focus:border-goj-focus-dark focus:ring-goj-focus-light max-w-max filter-input" id="toDatem" min={fromDatem ?? fromDatem} name="toDate" max={maxDate} aria-describedby="toDatem" value={toDatem} onChange={(e) => handleFilterChange("toDate", e)} />
              </div>
              <button type="button" className="flex mt-3 w-full lg:w-fit justify-center rounded text-oshBase font-semibold border border-goj-secondary bg-goj-white p-2 text-goj-secondary hover:text-goj-secondary-light hover:border-goj-secondary-light shadow-sm focus:outline-none focus:ring-2 focus:border-goj-focus-dark focus:ring-goj-focus-light" onClick={handleResetClick}>Reset</button>
            </div>
          </fieldset>
          {notifications && notifications?.length ?
            <div>
              <div id="AccordionX" className="accordion" ref={ref}>
                <Accordion transition={{ duration: "300ms", timingFunction: "cubic-bezier(0, 0, 0.2, 1)" }} alwaysOpen="true">
                  {
                    notifications.filter(item => dayjs(item.notificationDate).isAfter(dayjs(lastRead))).length > 0
                      ? <div className="px-4 mt-2 lg:px-0 text-base text-goj-secondary mb-2 lg:block" ref={refNew}><h2>New notifications</h2></div>
                      : <div className="hidden mt-2 px-4 lg:px-0 text-base text-goj-secondary mb-2" ref={refNew}><h2>New notifications</h2></div>
                  }
                  {notifications.filter(item => dayjs(item.notificationDate).isAfter(dayjs(lastRead))).map((item, index) => (
                    <AccordionItem key={item.id}>
                      {({ open }) => (
                        <>
                        {open ? 
                          <>
                            <div className="flex w-full mb-4 lg:hidden">
                              <Link
                                onClick={handleToggleBackLink}
                                styles={`justify-center rounded text-oshBase font-semibold border border-goj-secondary bg-goj-white py-2 px-4 text-goj-secondary hover:text-goj-secondary-light hover:border-goj-secondary-light shadow-sm focus:outline-none focus:ring-2 focus:borfder-goj-focus-dark focus:ring-goj-focus-light`}><strong>
                                  <span className="inline-block"><svg className="w-10 h-10 lg:ml-1 rotate-180" fill="currentColor" viewBox="0 5 15 20" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
                                  </svg></span>
                                  Back to notifcations</strong>
                              </Link>
                            </div>
                          </>
                          : "" }
                          {open ?
                            <>
                              <AccordionHeader className={`show w-full flex my-1 lg:my-0 border-b-goj-gray-light border-b lg:border-b-0 items-start lg:border-l-4 text-left px-4 pt-4 lg:px-2 lg:pt-4 ${dayjs(item.notificationDate).isAfter(dayjs(lastRead)) ? 'lg:border-l-goj-secondary lg:bg-goj-secondary/5' : 'border-l-goj-white bg-goj-white'} ${index === 0 ? " rounded-t" : ""} `}
                                onClick={handleClickShow}>
                                <div className="flex-shrink-0 lg:hidden rounded-full bg-goj-gray-light-super mr-4">
                                  <svg className="hidden lg:block w-12 h-12 text-goj-charcoal rotate-180" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
                                  </svg>
                                </div>
                                <div className={`hidden lg:flex lg:flex-shrink-0 ${dayjs(item.notificationDate).isAfter(dayjs(lastRead)) ? 'text-goj-secondary' : 'text-goj-charcoal'}`}>
                                  {item.channelType.toString().toUpperCase() === "SMS" ?
                                    <DevicePhoneMobileIcon className="w-6 h-6" />
                                    :
                                    <EnvelopeIcon className="w-6 h-6" />
                                  }
                                </div>
                                <div className="w-full lg:pl-2 lg:pr-8">
                                  <div className="flex justify-between text-goj-charcoal lg:hidden text-base">
                                    <div className="truncate">{dayjs(item.notificationDate).format('DD/MM/YYYY')}</div>
                                    <div className={`flex text-goj-charcoal`}>
                                      {item.channelType.toString().toUpperCase() === "SMS" ?
                                        <div className="flex">
                                          <div>Sent as SMS</div>
                                          <DevicePhoneMobileIcon className="w-6 h-6 ml-2" />
                                        </div>
                                        :
                                        <div className="flex">
                                          <div>Sent as Email</div>
                                          <EnvelopeIcon className="w-6 h-6 ml-2" />
                                        </div>
                                      }
                                    </div>
                                  </div>
                                  <div className="hidden lg:block truncate">{dayjs(item.notificationDate).format('dddd D MMMM YYYY')}</div>
                                  <div className="flex justify-between">
                                    <h2 style={{marginBottom: "0px"}}>{item?.senderName ? item?.senderName : "Online Services Hub"}</h2>
                                    <div className="flex-none justify-end text-base">
                                      <svg className={`w-6 h-6 lg:ml-1 text-goj-charcoal ${!open ? '' : 'rotate-90'}`} fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
                                      </svg>
                                    </div>
                                  </div>
                                  <div className="truncate mb-4">
                                    {item.channelType.toString().toUpperCase() === "SMS" ? item.contentDetails : <span style={{textWrap: "wrap"}}>{item.subjectLine}</span>}
                                  </div>
                                  <hr className={`mt-4 border-0`} />
                                </div>
                              </AccordionHeader>
                              <AccordionBody className={index === (notifications.length - 1) ? "rounded-b px-4 lg:px-0" : "px-4 lg:px-0"} >
                                <div className="lg:pl-11 py-4">
                                  <div>{item.contentDetails}</div>
                                  <hr className={`mt-4 lg:border-0 lg:mr-10 ${notifications.filter(item => dayjs(item.notificationDate).isAfter(dayjs(lastRead))).length === index + 1 ? 'lg:border lg:border-goj-gray-light' : ''}`} />
                                </div>
                              </AccordionBody>
                            </>
                            :
                            <>
                              <AccordionHeader className={`show w-full flex items-start border-l-4 text-left px-2 pt-4 ${dayjs(item.notificationDate).isAfter(dayjs(lastRead)) ? 'border-goj-secondary bg-goj-secondary/5' : 'border-goj-white bg-goj-white'} ${index === 0 ? " rounded-t" : ""} `}
                                onClick={handleClickHide}>
                                <div className="hidden flex-shrink-0 lg:hidden">
                                  <svg className="w-10 h-10 lg:ml-1 text-goj-charcoal rotate-180" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
                                  </svg>
                                </div>
                                <div className={`lg:flex lg:flex-shrink-0 ${dayjs(item.notificationDate).isAfter(dayjs(lastRead)) ? 'text-goj-secondary' : 'text-goj-charcoal'}`}>
                                  {item.channelType.toString().toUpperCase() === "SMS" ?
                                    <DevicePhoneMobileIcon className="w-6 h-6" />
                                    :
                                    <EnvelopeIcon className="w-6 h-6" />
                                  }
                                </div>
                                <div className="w-full pl-2 pr-8">
                                  <div className="hidden lg:block truncate">{dayjs(item.notificationDate).format('dddd D MMMM YYYY')}</div>
                                  <div className="block lg:hidden truncate">{dayjs(item.notificationDate).format('DD/MM/YYYY')}</div>
                                  <div className="flex justify-between">
                                    <h2 style={{marginBottom: "0px"}}>{item?.senderName ? item?.senderName : "Online Services Hub"}</h2>
                                    <div className="flex-none justify-end text-base">
                                      <svg className={`w-6 h-6 lg:ml-1 text-goj-charcoal ${!open ? '' : 'rotate-90'}`} fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
                                      </svg>
                                    </div>
                                  </div>
                                  <div className="truncate mb-4">
                                    {item.channelType.toString().toUpperCase() === "SMS" ? item.contentDetails : <span style={{textWrap: "wrap"}}>{item.subjectLine}</span>}
                                  </div>
                                  <hr className="mt-4 border border-goj-gray-light" />
                                </div>
                              </AccordionHeader>
                              <AccordionBody className={index === (notifications.length - 1) ? "rounded-b" : ""} >
                                <div className="pl-11 py-4">
                                  <p>{item.contentDetails}</p>
                                </div>
                              </AccordionBody>
                            </>
                          }
                        </>
                      )}
                    </AccordionItem>
                  ))}
                  {
                    notifications.filter(item => dayjs(item.notificationDate).isBefore(dayjs(lastRead))).length
                      ? <div className="pt-4 px-4 lg:px-0 text-base text-goj-charcoal mb-2 lg:block" ref={refOld}><h2>Old notifications</h2></div>
                      : <div className="hidden pt-4 px-4 lg:px-0 text-base text-goj-charcoal mb-2" ref={refOld}><h2>Old notifications</h2></div>
                  }
                  {notifications.filter(item => dayjs(item.notificationDate).isBefore(dayjs(lastRead))).map((item, index) => (

                    <AccordionItem key={item.id}>
                      {({ open }) => (
                        <>
                          {open ? 
                          <>
                            <div className="flex w-full mb-4 lg:hidden">
                              <Link
                                onClick={handleToggleBackLink}
                                styles={`justify-center rounded text-oshBase font-semibold border border-goj-secondary bg-goj-white py-2 px-4 text-goj-secondary hover:text-goj-secondary-light hover:border-goj-secondary-light shadow-sm focus:outline-none focus:ring-2 focus:borfder-goj-focus-dark focus:ring-goj-focus-light`}><strong>
                                  <span className="inline-block"><svg className="w-10 h-10 lg:ml-1 rotate-180" fill="currentColor" viewBox="0 5 15 20" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
                                  </svg></span>
                                  Back to notifcations</strong>
                              </Link>
                            </div>
                          </>
                          : "" }
                          {open ?
                            <>
                              <AccordionHeader className={`show w-full flex my-1 lg:my-0 border-b-goj-gray-light border-b lg:border-b-0 items-start lg:border-l-4 text-left px-4 pt-4 lg:px-2 lg:pt-4 ${dayjs(item.notificationDate).isAfter(dayjs(lastRead)) ? 'lg:border-l-goj-secondary lg:bg-goj-secondary/5' : 'border-l-goj-white bg-goj-white'} ${index === 0 ? " rounded-t" : ""}`}
                                onClick={handleClickShow}>
                                <div className="flex-shrink-0 lg:hidden rounded-full bg-goj-gray-light-super mr-4">
                                  <svg className="hidden lg:block w-12 h-12 text-goj-charcoal rotate-180" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
                                  </svg>
                                </div>
                                <div className={`hidden lg:flex lg:flex-shrink-0 ${dayjs(item.notificationDate).isAfter(dayjs(lastRead)) ? 'text-goj-secondary' : 'text-goj-charcoal'}`}>
                                  {item.channelType.toString().toUpperCase() === "SMS" ?
                                    <DevicePhoneMobileIcon className="w-6 h-6" />
                                    :
                                    <EnvelopeIcon className="w-6 h-6" />
                                  }
                                </div>
                                <div className="w-full lg:pl-2 lg:pr-8">
                                  <div className="flex justify-between text-goj-charcoal lg:hidden text-base">
                                    <div className="truncate">{dayjs(item.notificationDate).format('DD/MM/YYYY')}</div>
                                    <div className={`flex text-goj-charcoal`}>
                                      {item.channelType.toString().toUpperCase() === "SMS" ?
                                        <div className="flex">
                                          <div>Sent as SMS</div>
                                          <DevicePhoneMobileIcon className="w-6 h-6 ml-2" />
                                        </div>
                                        :
                                        <div className="flex">
                                          <div>Sent as Email</div>
                                          <EnvelopeIcon className="w-6 h-6 ml-2" />
                                        </div>
                                      }
                                    </div>
                                  </div>
                                  <div className="hidden lg:block truncate">{dayjs(item.notificationDate).format('dddd D MMMM YYYY')}</div>
                                  <div className="truncate"><h2 style={{marginBottom: "0px"}}>{item?.senderName ? item?.senderName : "Online Services Hub"}</h2></div>
                                  <div className="flex justify-between mb-4">
                                    <div className="truncate">
                                      {item.channelType.toString().toUpperCase() === "SMS" ? item.contentDetails : <span style={{textWrap: "wrap"}}>{item.subjectLine}</span>}
                                    </div>
                                    <div className="hidden flex-none justify-end text-base lg:block">
                                      <svg className={`w-6 h-6 lg:ml-1 text-goj-charcoal ${!open ? '' : 'rotate-90'}`} fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
                                      </svg>
                                    </div>
                                  </div>
                                  <hr className={`mt-4 border-0 lg:border lg:border-goj-gray-light`} />
                                </div>
                              </AccordionHeader>
                              <AccordionBody className={index === (notifications.length - 1) ? "rounded-b px-4 lg:px-0" : "px-4 lg:px-0"} >
                                <div className="lg:pl-11 py-4">
                                  <div>{item.contentDetails}</div>
                                  <hr className={`mt-4 lg:mr-10 lg:border lg:border-goj-gray-light`} />
                                </div>
                              </AccordionBody>
                            </>
                            :
                            <>
                              <AccordionHeader className={`show w-full flex items-start border-l-4 text-left px-2 pt-4 ${dayjs(item.notificationDate).isAfter(dayjs(lastRead)) ? 'border-goj-secondary bg-goj-secondary/5' : 'border-goj-white bg-goj-white'} ${index === 0 ? " rounded-t" : ""} `}
                                onClick={handleClickHide}>
                                <div className="hidden flex-shrink-0 lg:hidden">
                                  <svg className="w-10 h-10 lg:ml-1 text-goj-charcoal rotate-180" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
                                  </svg>
                                </div>
                                <div className={`lg:flex lg:flex-shrink-0 ${dayjs(item.notificationDate).isAfter(dayjs(lastRead)) ? 'text-goj-secondary' : 'text-goj-charcoal'}`}>
                                  {item.channelType.toString().toUpperCase() === "SMS" ?
                                    <DevicePhoneMobileIcon className="w-6 h-6" />
                                    :
                                    <EnvelopeIcon className="w-6 h-6" />
                                  }
                                </div>
                                <div className="w-full pl-2 pr-8">
                                  <div className="hidden lg:block truncate">{dayjs(item.notificationDate).format('dddd D MMMM YYYY')}</div>
                                  <div className="block lg:hidden truncate">{dayjs(item.notificationDate).format('DD/MM/YYYY')}</div>
                                  <div className="flex justify-between">
                                    <h2 style={{marginBottom: "0px"}}>{item?.senderName ? item?.senderName : "Online Services Hub"}</h2>
                                    <div className="flex-none justify-end text-base">
                                      <svg className={`w-6 h-6 lg:ml-1 text-goj-charcoal ${!open ? '' : 'rotate-90'}`} fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
                                      </svg>
                                    </div>
                                  </div>
                                  <div className="truncate mb-4">
                                    {item.channelType.toString().toUpperCase() === "SMS" ? item.contentDetails : <span style={{textWrap: "wrap"}}>{item.subjectLine}</span>}
                                  </div>
                                  <hr className="mt-4 border border-goj-gray-light" />
                                </div>
                              </AccordionHeader>
                              <AccordionBody className={index === (notifications.length - 1) ? "rounded-b" : ""} >
                                <div className="pl-11 py-4">
                                  <p>{item.contentDetails}</p>
                                </div>
                              </AccordionBody>
                            </>
                          }
                        </>
                      )}
                    </AccordionItem>
                  ))}
                </Accordion>
              </div>
              <div ref={refViewMore} className={classNames(continuationToken ? 'flex flex-col items-center my-4 lg:text-base px-2' : 'hidden')}>
                <button
                  type="button"
                  className="flex w-full lg:w-fit items-center justify-center rounded text-oshBase font-semibold border border-goj-secondary py-2 px-4 text-goj-secondary hover:text-goj-secondary-light hover:border-goj-secondary-light shadow-sm focus:outline-none focus:ring-1 focus:ring-goj-secondary-dark"
                  onClick={handleViewMore}
                >
                  View more <ChevronDownIcon className='w-4 h-4 ml-2' fill="white" />
                </button>
              </div>
            </div>
            :
            <>
              {noRecords
                ? currentFiltersPast.length > 0
                  ? <p className="py-4">No notifications matching your criteria. Reset or try a different filter.</p>
                  : <p className="py-4">No notifications found</p>
                : <Loading />
              }
            </>
          }
        </main>
        <div className="hidden lg:block lg:ml-4 lg:py-4 order-1 lg:order-3 lg:col-span-2">
          <fieldset id="desktopFilters">
            <legend className="sr-only">Filters</legend>
            <div className="space-y-5">
              <p><strong className="text-goj-primary text-oshFilter">Filter by date</strong></p>
              <div className="relative flex items-start">
                <div className="flex h-5 items-center">
                  <input
                    id="filterByDays30"
                    aria-describedby="filterByDays30"
                    type="radio"
                    name="filterByDays"
                    key="filterByDays30"
                    value={dayjs().subtract(30, 'day').format('YYYY-MM-DD')}
                    onClick={(e) => handleFilterChange("fromDate", e)}
                    className="h-4 w-4 rounded border-goj-gray-light focus:ring-goj-focus-dark filter-input"
                    defaultChecked={currentCheckedBox === "filterByDays30" ? true : false} />
                </div>
                <div className="ml-3">
                  <label className="text-oshFilter" htmlFor="filterByDays30">Last 30 days</label>
                </div>
              </div>
              <div className="relative flex items-start">
                <div className="flex h-5 items-center">
                  <input
                    id="filterByDays90"
                    aria-describedby="filterByDays90"
                    type="radio"
                    name="filterByDays"
                    key="filterByDays90"
                    value={dayjs().subtract(90, 'day').format('YYYY-MM-DD')}
                    onClick={(e) => handleFilterChange("fromDate", e)}
                    className="h-4 w-4 rounded border-goj-gray-light focus:ring-goj-focus-dark filter-input"
                    defaultChecked={currentCheckedBox === "filterByDays90" ? true : false} />
                </div>
                <div className="ml-3">
                  <label className="text-oshFilter" htmlFor="filterByDays90">Last 90 days</label>
                </div>
              </div>
              <div className="relative flex items-start">
                <div className="flex h-5 items-center">
                  <input
                    id="filterByYear1"
                    aria-describedby="filterByYear1"
                    type="radio"
                    name="filterByDays"
                    key="filterByYear1"
                    value={dayjs().subtract(1, 'year').format('YYYY-MM-DD')}
                    onClick={(e) => handleFilterChange("fromDate", e)}
                    className="h-4 w-4 rounded border-goj-gray-light focus:ring-goj-focus-dark filter-input"
                    defaultChecked={currentCheckedBox === "filterByYear1" ? true : false} />
                </div>
                <div className="ml-3">
                  <label className="text-oshFilter" htmlFor="filterByYear1">Last year</label>
                </div>
              </div>
              <div className="relative flex items-start">
                <div className="flex h-5 items-center">
                  <input
                    id="filterByYear3"
                    aria-describedby="filterByYear3"
                    type="radio"
                    name="filterByDays"
                    key="filterByYear3"
                    value={dayjs().subtract(3, 'year').format('YYYY-MM-DD')}
                    onClick={(e) => handleFilterChange("fromDate", e)}
                    className="h-4 w-4 rounded border-goj-gray-light focus:ring-goj-focus-dark filter-input"
                    defaultChecked={currentCheckedBox === "filterByYear3" ? true : false} />
                </div>
                <div className="ml-3">
                  <label className="text-oshFilter" htmlFor="filterByYear3">Last 3 years</label>
                </div>
              </div>
              <div className="max-w-max">
                <label className="text-oshFilter" htmlFor="fromDate">From</label>
                <input type="date" className="block w-full rounded border-goj-gray-light shadow-sm focus:border-goj-focus-dark focus:ring-goj-focus-light max-w-max filter-input" id="fromDate" name="fromDate" aria-describedby="fromDate" max={toDate ? toDate : maxDate} value={fromDate} onChange={(e) => handleFilterChange("fromDate", e)} />
                <label htmlFor="toDate" className="text-oshFilter inline-flex lg:mt-4">To</label>
                <input type="date" className="block w-full rounded border-goj-gray-light shadow-sm focus:border-goj-focus-dark focus:ring-goj-focus-light max-w-max filter-input" id="toDate" min={fromDate ?? fromDate} max={maxDate} name="toDate" aria-describedby="toDate"  value={toDate} onChange={(e) => handleFilterChange("toDate", e)} />
              </div>
              <button type="button" className="flex mt-3 w-full lg:w-fit justify-center rounded text-oshBase font-semibold border border-goj-secondary bg-goj-white p-2 text-goj-secondary hover:text-goj-secondary-light hover:border-goj-secondary-light shadow-sm focus:outline-none focus:ring-2 focus:border-goj-focus-dark focus:ring-goj-focus-light" onClick={handleResetClick}>Reset</button>
            </div>
          </fieldset>
        </div>
      </>
    )
  }

  function RenderPage() {
    return (
      <>
        {(page === "all") ? 
        <>
        <RenderNotifcationsSection />
        </> 
        :
        <>
        </>
        }
        {(page === "single") ? 
        <>
        <RenderNotifcationsSection />
        </> 
        :
        <>
        </>
        }
    </>
    )
  }

  return (
    <RenderPage />
  )
}