export default function GetCountryCode(phoneNumber) {
    //match one - +441234567890 - 2 digits
    //match two - +5091234567890 - 3 digits
    //match three - +11234567890 - 1 digit
    const cleaned = ('' + phoneNumber).replace(/\D/g, '');
    const matchOne = cleaned.match(/^(\d{2})(\d{4})(\d{6})$/);
    const matchTwo = cleaned.match(/^(\d{3})(\d{4})(\d{6})$/);
    const matchThree = cleaned.match(/^(\d{1})(\d{4})(\d{6})$/);

    if (matchOne) {
        return matchOne[1]
    } 
    else {
        if (matchTwo) {
            return matchTwo[1]
        }
        else {
            if (matchThree) {
                return matchThree[1]
            }
            else {
                return null;
            }   
        }
    }
}