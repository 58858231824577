import { Fragment, useRef, useState, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { useMsal } from "@azure/msal-react";
import CautionIcon from '../components/icons/CautionIcon';

export const SessionExpiryModal = ({ showModal, setShowModal }) => {
    const { instance } = useMsal();
    const cancelButtonRef = useRef(null);
    const [timeLeft, setTimeLeft] = useState(5 * 60); // 5 minutes in seconds

    // Logout function using MSAL
    const logout = () => {
        instance.logout(); // This will log the user out
        setShowModal(false); // Close the modal
    };

    // Timer functionality
    useEffect(() => {
        let timerId;
        if (showModal) {
            // Start the timer
            timerId = setInterval(() => {
                setTimeLeft(prevTimeLeft => {
                    if (prevTimeLeft <= 1) {
                        clearInterval(timerId); // Stop the timer when it reaches 0
                        return 0;
                    }
                    return prevTimeLeft - 1;
                });
            }, 1000); // Decrease the time left every second
        } else {
            // Reset the timer when the modal is hidden
            setTimeLeft(5 * 60); // Reset to 5 minutes
            if (timerId) clearInterval(timerId); // Clear the existing timer if any
        }

        // Clean up the timer
        return () => clearInterval(timerId);
    }, [showModal]); // Depend on showModal to start/reset the timer

    // Format the time left to display in minutes and seconds
    const formatTimeLeft = () => {
        const minutes = Math.floor(timeLeft / 60);
        const seconds = timeLeft % 60;
        return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    };

    return (
        <Transition.Root show={showModal} as={Fragment}>
            <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={() => setShowModal(false)}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                                <div>
                                    <div className="mx-auto flex items-center justify-center rounded-full">
                                        <CautionIcon fillColor='#0057B6' width="35" height="30"/>
                                    </div>
                                    <div className="mt-3 text-center sm:mt-5">
                                        <Dialog.Title as="h3" className="text-oshBase font-semibold leading-6 text-gray-900">
                                            Your session will end soon
                                        </Dialog.Title>
                                        {/* Grey line separator to go full width*/}
                                        <div className="h-px bg-gray-300 my-4 ml-[-5%] w-[112%]"></div>
                                        <div className="mt-2 text-oshBase">
                                            <p>
                                                You have been inactive on this site for 10 minutes.
                                            </p>
                                            <p>
                                                To maintain your security you will be logged out automatically.
                                            </p>
                                            <p className='font-semibold'>
                                                {formatTimeLeft()}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                                    <button
                                        type="button"
                                        className="inline-flex w-full justify-center rounded-md px-3 py-2 text-oshBase font-semibold border border-transparent bg-goj-secondary text-goj-white hover:text-goj-white hover:border-goj-secondary-light shadow-sm focus:outline-none focus:ring-2 focus:border-goj-focus-dark focus:ring-goj-focus-light sm:col-start-2 mt-4 sm:mt-0"
                                        onClick={() => setShowModal(false)}
                                    >
                                        Keep me logged in
                                    </button>
                                    <button
                                        type="button"
                                        className="w-full justify-center rounded text-oshBase font-semibold border border-goj-secondary bg-goj-white py-2 px-3 text-goj-secondary hover:text-goj-secondary-light hover:border-goj-secondary-light shadow-sm focus:outline-none focus:ring-2 focus:border-goj-focus-dark focus:ring-goj-focus-light mt-4 sm:mt-0"
                                        onClick={logout}
                                        ref={cancelButtonRef}
                                    >
                                        Log me out
                                    </button>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    );
};
