import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { MsalProvider, MsalAuthenticationTemplate } from '@azure/msal-react';
import { PublicClientApplication, InteractionType } from '@azure/msal-browser';
import { msalConfig, protectedResources } from './authConfig';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './index.css';
import LoginMessage from './components/LoginMessage';

const msalInstance = new PublicClientApplication(msalConfig);
const requireAuthentication = true;
await msalInstance.initialize();
// Define the desired scopes
const scopes = [
  ...protectedResources.apiProfileFull.scopes
];
const authRequest = {
  scopes: scopes
};
const handleRedirectPromise = async () => {
  try {
    const response = await msalInstance.handleRedirectPromise();
    if (response !== null) {
      // Check if the authentication was successful
      if (response.account) {
        // Set the active account
        msalInstance.setActiveAccount(response.account);
      }
    }
  } catch (error) {
    console.error(error);
  }
};

function PublicApp() {
  return (
    <App instance={msalInstance} />
  )
}

function AuthenticatedApp() {
  // Call handleRedirectPromise in a useEffect hook
  useEffect(() => {
    handleRedirectPromise();
  }, []);

  return (
    <MsalProvider instance={msalInstance}>
      <MsalAuthenticationTemplate
        interactionType={InteractionType.Redirect}
        authenticationRequest={authRequest}
        loading={<Loading />} // Custom loading component
        error={(error) => <Loading errorMessage={error.errorMessage} />} // Custom error component
      >
        <App instance={msalInstance} />
      </MsalAuthenticationTemplate>
    </MsalProvider>
  );
}

function Loading({ errorMessage }) {
  return (
    <div>
      {errorMessage ? (
        <>
          {console.log(`Error: ${errorMessage}`)}
          <LoginMessage
            title="Restricted access"
            message="This website is not yet released for general availability."
            errorDetails={errorMessage}
          />
        </>
      ) : (
        'Loading...'
      )}
    </div>
  );
}

function Root() {
  if (requireAuthentication) {
    return <AuthenticatedApp />;
  } else {
    return <PublicApp />;
  }
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<Root />);

reportWebVitals();
