import { useState, useEffect, Fragment, useRef } from "react";
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import { loginRequest, protectedResources } from "../authConfig";
import { Popover, Transition } from '@headlessui/react';
import { Accordion, AccordionBody, AccordionHeader, AccordionItem } from "react-headless-accordion";
import { CursorArrowRaysIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { SearchIconBlack } from './icons/SearchIcon';
import { Link, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { callUmbracoApi, callApiWithToken } from "../fetch";
import { MobileProfileMenu } from "./ProfileMenu";
import { TaxonomyMenuPopover } from "./TaxonomyMenu";
import { useInactivityLogout } from '../utils/useInactivityLogout'
import useApiCall from "../utils/useApiCall";
import Logo from './Logo';
import BurgerMenuIcon from "./icons/BurgerMenu";
import LoginButton from "./LoginButton";

function ReplaceUrl(href) {
  let url = href;
  url.includes('dashboard') ?
    url = url.replace("/profile/", "/")
    :
    url = url.replace("/profile/", "/dashboard/")

  return url;
}

export default function Navigation() {
  const searchInputRef = useRef(null);
  const { instance, accounts } = useMsal();
  const [searchParams, setSearchParams] = useSearchParams();
  const searchTerm = searchParams.get('q') || '';
  const [toggleSearchPanel, setToggleSearchPanel] = useState(true);
  const [toggleSearch, setToggleSearch] = useState(false);
  const [toggleNewNotifications, setNewNotifications] = useState();
  const [searchQuery, setSearchQuery] = useState(searchTerm);
  const { logout } = useInactivityLogout();
  const callProtectedApi = useApiCall();
  const navigate = useNavigate();
  const location = useLocation();
  let accessTokenRequest = null;
  const [scrollPosition, setScrollPosition] = useState(0);
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
    setToggleSearchPanel(position > 225);
  };
  const [accountName, setAccountName] = useState('');
  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }
  const [windowSize, setWindowSize] = useState(getWindowSize());
  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  useEffect(() => {
    if (location.pathname === "/search") {
      setToggleSearchPanel(false);
    }
    else {
      setToggleSearchPanel(true);
    }
  }, [location.pathname]);

  // Toggle show search box for top nav
  const handleToggleSearchClick = event => {
    event.preventDefault();
    setToggleSearch(current => !current);
  }

  useEffect(() => {
    if (toggleSearch && searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }, [toggleSearch]);

  // Handle search query input
  const handleSearchTopChange = event => {
    event.preventDefault();
    setSearchQuery(event.target.value)
  }

  // Handle hiding search box
  const handleHideSearch = event => {
    if (searchQuery == "") {
      setToggleSearch(current => !current);
    }
  }

  // Handle search top submit
  const handleSearchTopClick = event => {
    event.preventDefault();
    navigate("/search?q=" + searchQuery);
  }

  // Set the responsive menu accordion headers
  const menuMobile = [
    {
      id: '1',
      name: 'Services',
      icon: CursorArrowRaysIcon
    },
    {
      id: '2',
      name: 'Profile',
      icon: 'Custom'
    }
  ];

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

  const [pages, setPages] = useState([]);
  const url = "/content/type?contentType=menuItem";
  if (accounts.length > 0) {
    accessTokenRequest = {
      scopes: protectedResources.apiNotificationUnreadEndpoint.scopes,
      account: instance.getActiveAccount(),
      redirectUri: window.location.origin + "/auth.html",
      loginHint: instance.getActiveAccount()?.idTokenClaims.email
    }
  } else {
    accessTokenRequest = {
      scopes: protectedResources.apiNotificationUnreadEndpoint.scopes,
      account: instance.getActiveAccount(),
      redirectUri: window.location.origin + "/auth.html",
    }
  }

  useEffect(() => {
    // For the Umbraco API call
    callUmbracoApi(url)
      .then(response => {
        setPages(response?._embedded?.content);
      })
      .catch((e) => {
        console.log("Error: ", e?.message);
      });
    if (instance.getActiveAccount()) {
      callProtectedApi(protectedResources.apiNotificationUnreadEndpoint.endpoint, {}, protectedResources.apiNotificationUnreadEndpoint.scopes)
      .then(response => {
        setAccountName(instance.getActiveAccount()?.idTokenClaims.given_name);
        if (response.unreadNotificationsCount > 0) {
          setNewNotifications(response.unreadNotificationsCount);
        }
      })
      .catch((e) => {
        console.log("Error: ", e?.message);
      });
    } else {
      setAccountName('');
    }
  }, [accounts]);
  useEffect(() => {
    //remove unread count when going to dashboard
    if (location.pathname === '/dashboard/' || location.pathname === '/dashboard/notifications/') {
      setNewNotifications(0);
    }
  }, [location]);

  function RenderTopNavItem({ page }) {
    if (!page) return null
    if (!page?.menuLink?.url) return null
    return (
      <div key={page?._id} className={`pb-4 pt-6 border-b-4 ${location.pathname.startsWith(page.menuLink.url.substring(0, page.menuLink.url.length - 1)) ? 'border-b-goj-primary' : 'border-b-goj-white'} hover:border-b-goj-primary hover:bg-goj-white`}>
        <Link id={page?._id} className="text-goj-charcoal active:text-goj-charcoal visited:text-goj-charcoal hover:no-underline" to={`${ReplaceUrl(page?.menuLink?.url)}`}> {page?.menuLink?.name} </Link>
      </div>
    )
  }

  function RenderTopNavHomeItem({ page }) {
    return (
      <div className={`pb-4 pt-6 border-b-4 ${location.pathname === ("/") ? 'border-b-goj-primary' : 'border-b-goj-white'} hover:border-b-goj-primary hover:bg-goj-white`}>
        <Link id="osh-home-link" to="/" className="text-goj-charcoal visited:text-goj-charcoal hover:no-underline">{page?.menuLink?.name ?? "Home"}</Link>
      </div>
    )
  }

  function RenderTopNavServicesItem({ page }) {
    return (
      <Popover className={`relative group pb-4 pt-6 border-b-4 ${location.pathname.startsWith("/services") ? 'border-b-goj-primary' : 'border-b-goj-white'} hover:border-b-goj-primary hover:bg-goj-white`}>
        {({ open }) => (
          <>
            <Popover.Button
              className={classNames(
                `inline-flex items-center bg-goj-white group-hover:bg-goj-white focus:ring-goj-focus-light`
              )}
            >
              <span className="text-goj-blue-dark group-hover:bg-goj-white">{page?.menuLink?.name ?? "Services"}</span>
              <ChevronDownIcon
                className={classNames(
                  open ? 'text-goj-gray-dark' : 'text-goj-gray-mid',
                  'ml-2 h-5 w-5 group-hover:bg-goj-white'
                )}
                aria-hidden="true" />
            </Popover.Button>

            {/* Services flyout menu */}
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel style={{ maxHeight: (windowSize?.innerHeight && windowSize.innerHeight > 99 ? windowSize.innerHeight - 100 : windowSize.innerHeight) + 'px' }} className={`absolute h-auto overflow-x-hidden z-20 -ml-4 mt-5 transform px-2 sm:px-0 lg:left-1/2 lg:ml-0 lg:-translate-x-1/2 shadow-lg border-t border-goj-gray-light`}>
                <div className="rounded-lg shadow-lg ring-1 ring-goj-charcoal ring-opacity-5">
                  <div className="relative grid gap-1 bg-goj-gray-mid/95 p-4" style={{width:"max-content"}}>
                    <TaxonomyMenuPopover />
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    )
  }

  function RenderTopNavItemResponsive({ page }) {
    return (
      <div className="w-full flex items-center text-goj-white visited:text-goj-white p-4">
        <Link to="/" className="w-full text-left text-goj-white visited:text-goj-white">
          <strong className="text-2xl">{page?.menuLink?.name}</strong>
        </Link>
      </div>
    )
  }

  function RenderTopNavHomeItemResposive({ page }) {
    return (
      <div className="w-full flex items-center text-goj-white visited:text-goj-white p-4">
        <Link to="/" className="w-full text-left text-goj-white visited:text-goj-white">
          <strong className="text-2xl">{page?.menuLink?.name ?? "Home"}</strong>
        </Link>
      </div>
    )
  }

  function RenderTopNavServicesItemResponsive({ page }) {
    return (
      <AccordionItem key={page.id}>
        {({ open }) => (
          <>
            <AccordionHeader className={`${open ? '' : ''} w-full flex items-center text-goj-white visited:text-goj-white p-4`}>
              <Popover.Button
                className='w-full text-left text-goj-white visited:text-goj-white no-underline active:no-underline hover:no-underline'
                onClick={(e) => { e.preventDefault() }}
              >
                <strong className="text-2xl no-underline active:no-underline hover:no-underline">{page?.menuLink?.name ?? "Services"}</strong>
              </Popover.Button>
              <svg className={`w-6 h-6 text-goj-white ${!open ? '' : 'rotate-90'}`} fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
              </svg>
            </AccordionHeader>
            <AccordionBody className={`${open ? '' : ''}`}>
              <hr className="ml-4 mt-2 mb-0 border border-goj-gray-light w-5" />
              <div className="px-2 py-4 space-y-3">
                <TaxonomyMenuPopover />
              </div>
            </AccordionBody>
          </>
        )}
      </AccordionItem>
    )
  }

  function RenderTopNavDashboardItemResponsive({ page }) {
    return (
      <AccordionItem key={page.id}>
        {({ open }) => (
          <>
            <AccordionHeader className={`${open ? '' : ''} w-full flex items-center text-goj-white visited:text-goj-white p-4`}>
              <AuthenticatedTemplate>
                <Popover.Button
                  className='w-full text-left text-goj-white visited:text-goj-white no-underline active:no-underline hover:no-underline'
                  onClick={(e) => { e.preventDefault() }}
                >
                  <strong className="text-2xl no-underline active:no-underline hover:no-underline">{page?.menuLink?.name ?? "Your dashboard"}</strong>
                </Popover.Button>
                <svg className={`w-6 h-6 text-goj-white ${!open ? '' : 'rotate-90'}`} fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
                </svg>
              </AuthenticatedTemplate>
            </AccordionHeader>
            <AccordionBody className={`${open ? '' : ''}`}>
              <hr className="ml-4 mt-2 mb-0 border border-goj-gray-light w-5" />
              <div className="px-2 py-4 space-y-3">
                <MobileProfileMenu />
              </div>
            </AccordionBody>
          </>
        )}
      </AccordionItem>
    )
  }

  function arraySearch(array, keyword) {
    return array.find(value => {
      if (value.menuLink.url === keyword) {
        return value.menuLink.name;
      }
      return null;
    });
  };

  return (
    pages && pages.length ?
      <>
        {console.log("page", pages)}
        {console.log("find", arraySearch(pages, '/dashboard/'))}
        {console.log("isTrue", arraySearch(pages, '/dashboard/') ? "true" : "false")}
        <div className="container mx-auto max-w-oshMaxWidth min-w-oshMinWidth">
          <Popover className="grid grid-cols-12 items-center my-2 lg:my-0 ">
            <div className="bg-goj-white ml-1 lg:ml-0 col-span-2 flex items-center">
              <div className="lg:hidden mr-2 ml-2 h-8 py-auto">
                <Popover.Button>
                  <BurgerMenuIcon /> <span className="sr-only">Menu</span>
                </Popover.Button>
              </div>
              <div className="ml-1 relative">
                <Link id="logo-home-link" to="/" title="Onlines services hub">
                  <Logo />
                </Link>
              </div>
            </div>
            <div className="col-span-10">
              <div className="flex items-center w-full justify-end lg:justify-between">
                <Popover.Group as="nav" className="hidden lg:space-x-8 lg:flex">
                  {pages.map((page) => {
                    if (page.menuLink.url === '/home/') {
                      return <RenderTopNavHomeItem key={page._url} page={page} />
                    }
                    if (page.menuLink.url === '/services/') {
                      return <RenderTopNavServicesItem key={page._url} page={page} />
                    }
                    return <RenderTopNavItem key={page._url} page={page} />
                  }
                  )}
                </Popover.Group>
                <div className="justify-end flex items-center">
                  <div className={classNames(toggleSearchPanel ? 'flex' : 'hidden', 'text-goj-charcoal mr-6 mt-1')}>
                    <Link to="/" onClick={handleToggleSearchClick} className={classNames(toggleSearch ? 'hidden' : 'flex', 'hover:no-underline mb-1')}>
                      <SearchIconBlack className="w-5 h-5 stroke-goj-charcoal -mt-px" />
                      <span className="ml-1 text-goj-charcoal text-oshFilter lg:text-oshBase"></span>
                    </Link>
                    <form className={classNames(toggleSearch ? 'hidden lg:visible lg:flex' : 'hidden')} onBlur={handleHideSearch}>
                      <label htmlFor="searchtop" aria-hidden="true" className="sr-only">Search</label>
                      {toggleSearch && (<input type="text" name="searchtop" id="searchtop"
                        ref={searchInputRef}
                        className="border-goj-gray-light text-goj-charcoal focus:ring-goj-focus-light w-28 sm:w-auto"
                        placeholder="Search"
                        aria-label="Search"
                        onKeyDown={e => e.key === 'Enter' && handleSearchTopClick(e)}
                        onChange={handleSearchTopChange}
                        value={searchQuery}
                      />)}
                      <button type="button" aria-label="Search button"
                        className="border-goj-gray-light bg-goj-primary px-4 py-2 text-goj-charcoal hover:bg-goj-primary-dark focus:bg-goj-focus-dark focus:outline-none focus:ring-1 focus:ring-goj-focus-light"
                        onClick={handleSearchTopClick} >
                        <svg className="h-6 w-6 text-goj-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                          fill="currentColor" aria-hidden="true">
                          <path fillRule="evenodd"
                            d="M9 3.5a5.5 5.5 0 100 11 5.5 5.5 0 000-11zM2 9a7 7 0 1112.452 4.391l3.328 3.329a.75.75 0 11-1.06 1.06l-3.329-3.328A7 7 0 012 9z"
                            clipRule="evenodd" />
                        </svg>
                      </button>
                    </form>
                  </div>
                  <AuthenticatedTemplate>
                    <div className="flex text-goj-charcoal mr-6 relative">
                      <Link to="/dashboard/notifications/" className="hover:cursor-pointer" aria-label="notifications">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <mask id="mask0_1428_21390" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
                            <rect width="24" height="24" fill="#D9D9D9" />
                          </mask>
                          <g mask="url(#mask0_1428_21390)">
                            <path d="M4.25 18.8653V17.3653H6.25V10.1153C6.25 8.75759 6.66987 7.55728 7.5096 6.51434C8.34935 5.47139 9.42948 4.80248 10.75 4.50762V3.80762C10.75 3.45505 10.8702 3.15858 11.1106 2.91819C11.3509 2.67781 11.6474 2.55762 12 2.55762C12.3525 2.55762 12.649 2.67781 12.8894 2.91819C13.1298 3.15858 13.25 3.45505 13.25 3.80762V4.50762C14.5705 4.80248 15.6506 5.47139 16.4903 6.51434C17.3301 7.55728 17.75 8.75759 17.75 10.1153V17.3653H19.75V18.8653H4.25ZM12 21.8076C11.5077 21.8076 11.0833 21.6326 10.7269 21.2826C10.3705 20.9326 10.1923 20.505 10.1923 19.9999H13.8077C13.8077 20.505 13.6327 20.9326 13.2826 21.2826C12.9326 21.6326 12.5051 21.8076 12 21.8076ZM7.74995 17.3653H16.25V10.1153C16.25 8.93836 15.8359 7.93578 15.0077 7.10757C14.1795 6.27937 13.1769 5.86527 12 5.86527C10.823 5.86527 9.82047 6.27937 8.99227 7.10757C8.16406 7.93578 7.74995 8.93836 7.74995 10.1153V17.3653Z" fill="black" />
                          </g>
                        </svg>
                        {toggleNewNotifications >= 1 ? (
                          <span
                            style={{
                              right: '-3px',
                              top: '-7px',
                              position: 'absolute',
                              fontSize: '10px',
                              color: 'white'
                            }}
                            className="top-0 h-4 w-4 bg-goj-primary rounded-full flex items-center justify-center"
                          >
                          </span>)
                          : null
                        }
                      </Link>
                    </div>
                    <Popover className="relative mt-1">
                      {({ open }) => (
                        <>
                          <Popover.Button
                            className={classNames(
                              'group inline-flex items-center focus:ring-goj-focus-light -mt-px'
                            )}
                          >
                            <div className="flex mt-px">
                              <span className="mt-px flex">
                                <span className="mt-px flex">
                                  <span className="mt-px flex text-oshFilter lg:text-oshBase">
                                    {accountName}
                                  </span>
                                </span>
                              </span>
                            </div>
                            <ChevronDownIcon
                              className={classNames(
                                open ? 'text-goj-gray-dark' : 'text-goj-gray-mid',
                                'ml-2 h-5 w-5 group-hover:text-goj-charcoal mt-1 mr-2'
                              )}
                              aria-hidden="true" />
                          </Popover.Button>
                          <Transition
                            as={Fragment}
                            enter="transition ease-out duration-200"
                            enterFrom="opacity-0 translate-y-1"
                            enterTo="opacity-100 translate-y-0"
                            leave="transition ease-in duration-150"
                            leaveFrom="opacity-100 translate-y-0"
                            leaveTo="opacity-0 translate-y-1"
                          >

                            <Popover.Panel style={{ maxHeight: (windowSize?.innerHeight && windowSize.innerHeight > 99 ? windowSize.innerHeight - 100 : windowSize.innerHeight) + 'px' }} className="absolute z-25 h-auto w-screen -top-3 lg:top-auto lg:w-auto lg:mt-4 overflow-x-hidden right-0">
                              <div className="overflow-hidden shadow-lg ring-1 pb-[100%] lg:pb-2 ring-goj-charcoal ring-opacity-5 mt-px bg-goj-gray-mid/95 text-goj-white">
                                <div className="relative grid gap-1 mr-4 lg:mr-0 px-1 py-4 sm:p-4">
                                  <div className="flex items-center justify-start lg:hidden">
                                    <div className="ml-auto">
                                      <Popover.Button className="text-goj-white flex">
                                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                      </Popover.Button>
                                    </div>
                                  </div>
                                  <MobileProfileMenu />
                                  <hr className="ml-2 mt-2 mb-0 border border-goj-gray-light w-5" />
                                  <a
                                    className="flex items-start -mt-px p-2 visited:text-goj-white hover:text-goj-white hover:underline hover:cursor-pointer hover:bg-transparent"
                                    onClick={() => {
                                      // Call the logout function from the useInactivityLogout hook
                                      logout();
                                    }}>
                                    <div className="">
                                      <span className="text-goj-white text-oshFilter text-sm">Log out</span>
                                    </div>
                                  </a>
                                </div>
                              </div>
                            </Popover.Panel>
                          </Transition>
                        </>
                      )}
                    </Popover>
                  </AuthenticatedTemplate>
                  <UnauthenticatedTemplate>
                    <div className="mr-4 lg:mr-2">
                      <LoginButton />
                    </div>
                  </UnauthenticatedTemplate>
                </div>
              </div>
            </div>

            {/* responsive menu */}
            <Transition
              as={Fragment}
              enter="duration-500 ease-out"
              enterFrom="-left-96"
              enterTo="left-0"
              leave="duration-200 ease-in"
              leaveFrom="left-0"
              leaveTo="-left-96"
            >
              <Popover.Panel focus className="absolute w-full inset-x-0 top-0 origin-left transform lg:hidden z-50 h-screen overflow-auto overflow-x-hidden">
                <div className="divide-y-2 divide-goj-gray-mid bg-goj-gray-mid/95 shadow-lg ring-1 ring-goj-charcoal ring-opacity-5 min-h-[calc(100vh)]">
                  <div className="pt-5 pb-6">
                    <div className="flex items-center justify-start px-4">
                      <div className="-ml-1">
                        <Popover.Button className="text-goj-white flex">
                          <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                        </Popover.Button>
                      </div>
                    </div>
                    <div className="mt-6">
                      <nav className="grid gap-y-8">
                        <Accordion transition={{ duration: "300ms", timingFunction: "cubic-bezier(0, 0, 0.2, 1)" }}>
                          {pages.map((page) => {
                            if (page.menuLink.url === '/home/') {
                              return <RenderTopNavHomeItemResposive key={page._id} page={page} />
                            }
                            if (page.menuLink.url === '/services/') {
                              return <RenderTopNavServicesItemResponsive key={page._id} page={page} />
                            }
                            if (page.menuLink.url === '/dashboard/') {
                              return <RenderTopNavDashboardItemResponsive key={page._id} page={page} />
                            }
                            return <RenderTopNavItemResponsive key={page._id} page={page} />
                          }
                          )}
                        </Accordion>
                      </nav>
                    </div>
                    <AuthenticatedTemplate>
                      <hr className="ml-4 mt-2 mb-0 border border-goj-gray-light w-5" />
                      <button
                        className="mt-4"
                        onClick={() => {
                          instance.logoutRedirect({ postLogoutRedirectUri: "/" });
                        }}>
                        <div className="px-4 py-2 font-medium text-goj-white">
                          Log out
                        </div>
                      </button>
                    </AuthenticatedTemplate>
                    <UnauthenticatedTemplate>
                      <hr className="ml-4 mt-2 mb-0 border border-goj-gray-light w-5" />
                      <LoginButton textStyles="px-4 py-2 font-medium text-goj-white" />
                    </UnauthenticatedTemplate>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </Popover>
        </div>
        <Transition
          show={toggleSearch}
          enter="transition-opacity duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          afterEnter={() => {
            if (searchInputRef.current) {
              searchInputRef.current.focus();
            }
          }}
        >
          <div className={classNames(toggleSearchPanel ? 'flex' : 'hidden', 'text-goj-charcoal mr-4 mt-1')}>
            <form className={classNames(toggleSearch ? 'flex py-1 pl-3 w-full lg:hidden' : 'hidden')}>
              <div className="flex items-center">
                <Link to="/" className="text-goj-charcoal visited:text-goj-charcoal" onClick={handleToggleSearchClick}>
                  <XMarkIcon className="w-6" aria-hidden="true" />
                </Link>
              </div>
              <label htmlFor="searchtop" aria-hidden="true" className="sr-only">Search</label>
              {toggleSearch && (<input type="text" name="searchtop" id="searchtop"
                ref={searchInputRef}
                className="border-goj-gray-light text-goj-charcoal focus:ring-goj-focus-light w-full ml-2 sm:w-auto rounded-l"
                placeholder="Search"
                aria-label="Search"
                onKeyDown={e => e.key === 'Enter' && handleSearchTopClick(e)}
                onChange={handleSearchTopChange}
                value={searchQuery} />)}
              <button type="button" aria-label="Search button"
                className="border-goj-gray-light bg-goj-primary px-1 rounded-r lg:px-4 lg:py-2 text-goj-charcoal hover:bg-goj-primary-dark focus:bg-goj-focus-dark focus:outline-none focus:ring-1 focus:ring-goj-focus-light"
                onClick={handleSearchTopClick}>
                <svg className="h-6 w-8 text-goj-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                  fill="currentColor" aria-hidden="true">
                  <path fillRule="evenodd"
                    d="M9 3.5a5.5 5.5 0 100 11 5.5 5.5 0 000-11zM2 9a7 7 0 1112.452 4.391l3.328 3.329a.75.75 0 11-1.06 1.06l-3.329-3.328A7 7 0 012 9z"
                    clipRule="evenodd" />
                </svg>
              </button>
            </form>
          </div>
        </Transition>
      </>
      :
      <div className="container mx-auto max-w-oshMaxWidth">
        <div className="grid grid-cols-2 lg:grid-cols-8 lg:px-4 items-center my-2 lg:my-0">
          <div className="bg-goj-white ml-2 lg:ml-0 lg:py-2">
            <Link id="logo-home-link" to="/" title="Onlines services hub">
              <Logo />
            </Link>
          </div>
        </div>
      </div>
  )
}