import AppointmentCallToAction from '../features/Appointments/Components/AppointmentCallToAction';
import ButtonCallToAction from './ButtonCallToAction';
import ContactCallToAction from './ContactCallToAction';

function LocationContactsContent(props) {
  const parse = require('html-react-parser');
  const data = props?.contacts; //console.log("Location Contacts DATA: ", props?.contacts);  
  const webContact = [];
  const emailContact = [];
  const personContact = [];
  const phoneContact = [];
  data?.forEach(item => {
    let x = item?.contentTypeAlias;
    if (x === "webLink") {
      webContact.push({
        id: item?.sortOrder, 
        comment: item?.title ? parse(item?.title) : null,
        href: item?.link?.url, 
        title: item?.link?.name, 
        target: item?.link?.target
      })
    }
    if (x === "phoneContact") {
      phoneContact.push({
        id: item?.sortOrder,
        comment: item?.title ? parse(item?.title) : null,
        phone: item?.phoneNumber,
        info: item?.additionalInformation
      }) 
    }
    if (x === "emailContact") {
      emailContact.push({
        id: item?.sortOrder, 
        comment: item?.title ? parse(item?.title) : null,
        email: item?.emailAddress
      })
    }
    if (x === "inPersonContact") {
      personContact.push({
        id: item?.sortOrder,
        comment: item?.title ? parse(item?.title) : null,
        phone: item?.inPersonLocation?.contactPhoneNumber,
        address: item?.inPersonLocation?.address ? parse(item?.inPersonLocation?.address) : null,
        hours: item?.inPersonLocation?.workingHours ? parse(item?.inPersonLocation?.workingHours) : null
      })
    }    
  })
  //console.log("webContact: ", webContact);
  //console.log("emailContact: ", emailContact);
  //console.log("phoneContact: ", phoneContact);
  //console.log("personContact: ", personContact);

  return (
    <>
      {webContact?.length > 0 ? 
        <div className="mb-4">
        {webContact.map((item) =>
          <div key={item?.id}>
            <ButtonCallToAction href={item?.href} title={item?.title} text={item?.title} target={item?.target} caption={item.comment} />
          </div>
        )} 
        </div>
        : null
      }
      {phoneContact?.length > 0 || emailContact?.length > 0 || personContact?.length > 0 || props?.qmaticReference ?
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
        {personContact.map((item) =>
          <div key={item?.id} className="bg-goj-gray-light-super rounded p-4">
            {item.comment ? <p><strong className="text-xl">{item.comment}</strong></p> : null}
            {item?.address ? item?.address : null }
            {item?.hours ? item?.hours : null }
          </div>
        )}
        {phoneContact.map((item) =>
          <div key={item?.id} className="bg-goj-gray-light-super rounded p-4">
            <ContactCallToAction name={item.comment} phone={item?.phone} />
            <div className="mt-2">{item?.info ? <p>{item?.info}</p> : null }</div>
          </div>
        )}
        {emailContact.map((item) =>
          <div key={item?.id} className="bg-goj-gray-light-super rounded p-4">
            <ContactCallToAction name={item.comment} email={item?.email} />
          </div>
        )}
        {props?.qmaticReference ? 
          <div className="bg-goj-gray-light-super rounded p-4">
            <AppointmentCallToAction text={props?.appointmentButtonText} />
          </div> 
          : null
        }
        </div>
        : null
      }
    </>
  );
};

export default LocationContactsContent;
