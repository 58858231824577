import { lazy, Suspense, useEffect, useState, useContext } from "react";
import { MsalAuthenticationTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { loginRequest } from "../authConfig";
import { useAppInsightsContext, useTrackMetric, useTrackEvent } from "@microsoft/applicationinsights-react-js";
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { useLocation } from 'react-router-dom';
import { Helmet } from "react-helmet";
import { callUmbracoApi } from "../fetch";
import Breadcrumb from "../components/Breadcrumb";
import ProfileAside from "../components/ProfileAside";
import useBreadcrumbs from 'use-react-router-breadcrumbs';
import Loading from "../components/Loading";
import ParseHtml from "../utils/ParseHtml";
import FeedbackWidget from "../features/Feedback/Components/FeedbackWidget";
import DashboardContext from "../context/DashboardContext";
import YourServicesCards from "../components/YourServicesCards";

export default function YourServices() {
  const authRequest = { ...loginRequest };
  const location = useLocation();
  
  //context
  const dashboard = useContext(DashboardContext); //console.log("DASHBOARD",dashboard)
  
  //state
  const [loading, setLoading] = useState(true);

  // appInsights
  const appInsights = useAppInsightsContext();
  const trackComponent = useTrackMetric(appInsights, "YourServices");
  appInsights?.trackTrace({ message: 'PublishingWeb_YourServices page has been clicked by the user', severityLevel: SeverityLevel.Information });

  // breadcrumbs
  const breadcrumbs = useBreadcrumbs();
  const pages = []
  breadcrumbs.map((elem) => {
    if (elem.breadcrumb.props.children !== "Home") {
      pages.push({ name: elem.breadcrumb.props.children, href: elem.key + "/", current: false })
    }
  })

  // fetch page content from cms
  const [data, setData] = useState(null);
  useEffect(() => {
    const fetchData = async () => {
      try {
        let url = "/content/url?url=/dashboard/your-services/";
        const response = await callUmbracoApi(url);
        setData(response);
      } catch (e) {
        console.log("Error: ", e?.message);
      }
    };
    if (!data) {
      fetchData();
    }
  }, [data]);

  useEffect(() => {
    if (dashboard?.yourServicesLoaded) {
      setLoading(false);
    }
    else {
      setLoading(true)
    }
  }, [dashboard]);

  function RenderFeedbackWidget() {
    return (<FeedbackWidget feedbackTitle={data?.feedbackTitle} feedbackDescription={data?.feedbackDescription} feedbackLink={data?.feedbackLink} />)
  }

  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authRequest}
    >
      <Helmet>
        <title>{!data ? '' : data.metaTitle}</title>
        <meta name="description" content={!data ? '' : data.metaDescription} />
        <meta name="keywords" content={!data ? '' : data.metaKeywords} />
      </Helmet>
      <>
        <Breadcrumb pages={pages} currentPage={location.pathname} />
        <div className="container mx-auto max-w-oshMaxWidth">
          <div className="grid grid-cols-1 lg:grid-cols-12 mt-4">
            <ProfileAside />
            <main className="p-4 lg:p-0 order-first lg:order-last lg:col-span-8 ">
              <RenderFeedbackWidget />
              <h1>{data?.mainHeading ?? `Your services`}</h1>
              {ParseHtml(data?.mainContent)}
              {loading ? <Loading /> : <YourServicesCards />}
            </main>
          </div>
        </div>
      </>
    </MsalAuthenticationTemplate>
  )
}
