import { Link } from 'react-router-dom';

export default function ButtonLink(props) {
  const href = props.href ?? "#";
  const title = props.title ?? "Apply online";
  const text = props.text ?? "Apply online";
  const foreground = props.foreground ?? "goj-white";
  const background = props.background ?? "goj-secondary";
  const border = props.border ?? "transparent";
  const internal = props?.internal;

  if (href && href.startsWith('http')) {
    return (
      <a href={href} 
        title={title} 
        target={internal ? "_self" : "_blank"}
        rel="noopener"
        className={`w-full items-center justify-center border border-${border} text-oshBase font-semibold bg-${background} px-6 py-2 rounded shadow-sm text-${foreground} hover:text-${foreground} visted:text-${foreground}`}>
        <span className={`text-${foreground}`}>{text}</span>
      </a>
    )
  }
  else {
    return (
      <Link to={href} 
        title={title} 
        className={`w-full items-center justify-center border border-${border} text-oshBase font-semibold bg-${background} px-6 py-2 rounded shadow-sm text-${foreground} hover:text-${foreground} visted:text-${foreground}`}>
        <span className={`text-${foreground}`}>{text}</span>
      </Link>
    )
  }
}
