import { useContext, useState, useEffect, createContext } from "react";
import { useMsal, useAccount } from "@azure/msal-react";
import { InteractionRequiredAuthError, BrowserAuthError, InteractionStatus } from "@azure/msal-browser";
import { useLocation } from "react-router-dom";
import { protectedResources } from "../../../authConfig";
import { callApiWithToken } from "../../../fetch";
import dayjs from 'dayjs';

export const CareRecordContext = createContext(null);

function ProcessCareRecordSummaryData(props) {
  let careRecords = props.response;
  const yesterday = dayjs().subtract(1, 'day') 
  const sevenDaysAgo = dayjs().subtract(7, 'day');
  const threeMonthsAgo = dayjs().subtract(3, 'month');

  // allergy
  careRecords?.allergiesInformation && careRecords?.allergiesInformation.length && careRecords?.allergiesInformation.forEach (element => {
    element.isAttention = false;
  });

  // consultation
  careRecords?.consultationsInformation?.results && careRecords?.consultationsInformation?.results.length && careRecords?.consultationsInformation?.results.forEach (element => {
    element.isAttention = false;
  });

  // event
  careRecords?.eventsInformation?.results && careRecords?.eventsInformation?.results.length && careRecords?.eventsInformation?.results.forEach (element => {
    const eventOn = new Date(element.eventOn)
    if (dayjs(eventOn).isAfter(dayjs(yesterday))) {
      element.isAttention = true;
    }
    else {
      element.isAttention = false;
    }
  });

  // health
  careRecords?.healthStatusesInformation?.results && careRecords?.healthStatusesInformation?.results.length && careRecords?.healthStatusesInformation?.results.forEach (element => {
    element.isAttention = false;
  });

  // immunisation
  careRecords?.immunisationsInformation?.results && careRecords?.immunisationsInformation?.results.length && careRecords?.immunisationsInformation?.results.forEach (element => {
    element.isAttention = false;
  });

  // investigation
  careRecords?.investigationresultsInformation?.results && careRecords?.investigationresultsInformation?.results.length && careRecords?.investigationresultsInformation?.results.forEach (element => {
    const investigatedOn = new Date(element.investigatedOn)
    if (dayjs(investigatedOn).isAfter(dayjs(sevenDaysAgo))) {
      element.isAttention = true;
    }
    else {
      element.isAttention = false;
    }
  });

  // medication
  careRecords?.medicationsInformation?.results && careRecords?.medicationsInformation?.results.length && careRecords?.medicationsInformation?.results.forEach (element => {
    const prescribedOn = new Date(element.prescribedOn)
    if (dayjs(prescribedOn).isAfter(dayjs(sevenDaysAgo))) {
      element.isAttention = true;
    }
    else {
      element.isAttention = false;
    }
  });

  // patient
  careRecords?.patientInformation && careRecords?.patientInformation.length && careRecords?.patientInformation.forEach (element => {
    element.isAttention = false;
  });

  // problem
  careRecords?.problemsInformation?.results && careRecords?.problemsInformation?.results.length && careRecords?.problemsInformation?.results.forEach (element => {
    element.isAttention = false;
  });

  // referral
  careRecords?.referralsInformation?.results && careRecords?.referralsInformation?.results.length && careRecords?.referralsInformation?.results.forEach (element => {
    const referredOn = new Date(element.referredOn)
    if (element.status === "Active" && dayjs(referredOn).isAfter(dayjs(threeMonthsAgo))) {
      element.isAttention = true;
    }
    else {
      element.isAttention = false;
    }
  });

  return careRecords
}

export function CareRecordContextProvider({ children }) { 
  const { instance, accounts, inProgress } = useMsal();
  const account = useAccount(instance.getActiveAccount());
  const location = useLocation();
  const defaultPageSize = 5;
  
  //care record summary
  const [serviceError, setServiceError] = useState(false);
  const [careRecords, setCareRecords] = useState([]);
  useEffect(() => {
    if (account && inProgress === InteractionStatus.None && careRecords.length === 0 && location.pathname.startsWith("/dashboard/your-services/jersey-care-record/details")) {
      instance.acquireTokenSilent({
        scopes: protectedResources.apiDashboardCareRecordSummaryEndpoint.scopes,
        account: account,
        redirectUri: window.location.origin+"/auth.html",
        loginHint: instance.getActiveAccount()?.idTokenClaims.email
      }).then((response) => {
        if (instance.getActiveAccount()?.idTokenClaims?.authenticationSource === "L2") {
          callApiWithToken(response.accessToken, protectedResources.apiDashboardCareRecordSummaryEndpoint.endpoint + "?pageSize="+defaultPageSize)
          .then(response => { 
            if (response?.errordetails) {
              setServiceError(true);
              console.log("ERROR",response?.errordetails?.errorMessage);
            }
            else {
              console.log("JCR",response);
              const careRecordData = ProcessCareRecordSummaryData(response={response});
              console.log("CARE RECORD",careRecordData)
              setCareRecords(careRecordData);
            }
          });
        }
      }).catch((error) => { 
        // in case if silent token acquisition fails, fallback to an interactive method
        if (error instanceof InteractionRequiredAuthError || BrowserAuthError || BrowserAuthError) {
          if (account && inProgress === InteractionStatus.None) {
            instance.acquireTokenRedirect({
              scopes: protectedResources.apiDashboardCareRecordSummaryEndpoint.scopes,
              account: account
            }).then((response) => {
              if (instance.getActiveAccount()?.idTokenClaims?.authenticationSource === "L2") {
                callApiWithToken(response.accessToken, protectedResources.apiDashboardCareRecordSummaryEndpoint.endpoint + "?pageSize="+defaultPageSize)
                .then(response => {
                  if (response?.errordetails) {
                    setServiceError(true);
                    console.log("ERROR",response?.errordetails?.errorMessage);
                  }
                  else {
                    console.log("JCR",response);
                    const careRecordData = ProcessCareRecordSummaryData(response={response});
                    console.log("CARE RECORD",careRecordData)
                    setCareRecords(careRecordData);
                  }
                });
              }
            }).catch(error => { 
              console.log("Error: ", error);
            });
          }
        }
      });
    }
  }, [account, inProgress, instance, location]);

  return (
    <CareRecordContext.Provider
      value={{
        careRecords,
        serviceError
      }}
    >
      {children}
    </CareRecordContext.Provider>
  );
}

export function useCareRecord() {
  const context = useContext(CareRecordContext);
  if (context === undefined) {
    throw new Error("Context must be used within a Provider");
  }
  return context;
}

export default CareRecordContext;