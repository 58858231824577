import dayjs from 'dayjs';

export default function STIAInfo(props) {
  const allowanceInfo = props;
  const oneMonthAgo = dayjs().subtract(1, 'month');
  const oneMonthAhead = dayjs().add(1, 'month');
  let isForAttention = false;
  let cardAllowanceInfo = {display: false};
  // configure the tax rate data card
  if (allowanceInfo && allowanceInfo.paymentscount && allowanceInfo.latestpaymentdate) {
    // date is within one month
    if (dayjs(allowanceInfo.latestpaymentdate).isAfter(dayjs()) && dayjs(allowanceInfo.latestpaymentdate).isBefore(dayjs(oneMonthAhead))){
      isForAttention = true;
    }
    //if (dayjs(allowanceInfo.latestpaymentdate).isAfter(dayjs(oneMonthAgo))) {
      cardAllowanceInfo = {
        display: true,
        id: "stia-info",
        title: "Short Term Incapacity Allowance Benefit",
        description: "Short Term Incapacity Allowance (STIA) is a benefit which may be paid to you if you can't work due to illness or injury.",
        link: [
          {id: "stia-payments", text: `View recent payments (${allowanceInfo?.paymentscount})`, href:"/dashboard/your-services/short-term-incapacity-allowance-benefit/payments/"}//,
          //{id: "/services/short-term-incapacity-allowance-benefit/", text: "Audit history", href:"/dashboard/your-services/short-term-incapacity-allowance-benefit/"}
        ],
        date: allowanceInfo.latestpaymentdate,
        attention: isForAttention
      }
    //}
  }
  return cardAllowanceInfo;
}