import { ArrowRightIcon } from '@heroicons/react/24/outline';
import { Link } from 'react-router-dom';

export function DigitalCard({ card }) {
  const cardStyle = {
    backgroundColor: card.display.backgroundColor,
    color: card.display.textColor
  };

  console.log("card",card)

  return (
    <div className="card items-center border-0 rounded-lg border-goj-gray-light px-5 pt-1 pb-4 bg-goj-gray-light-super flex-col">
      {
        (card) ?
          <div>
            <div>
              <span className="inline-block relative w-64 rounded-lg mt-4 h-36 text-sm pl-2 pt-1" style={cardStyle}>
                <p className="absolute top-0 right-0 mt-2 mr-2">{card.display.title}</p>
                <img src={card.display.logo.uri} alt={card.display.logo.description} width="80" />
                <p className="absolute bottom-0 left-0 mb-2 ml-2">{card.display.issuedBy}</p>
              </span>
            </div>

            <div className="flex items-center">
              <h3 className="mt-3">{card.name}</h3>
            </div>
            <div>
              <p className="text-base">{card.description}</p>
            </div>
            <div>
              <div className="flex items-center mt-2 text-goj-secondary">
                <Link className="flex items-center hover:underline " to={card.name.toLowerCase().replace(/\s+/g, '-')} state={card}>
                  View {card.name.toLowerCase()}
                </Link>
                <ArrowRightIcon className="w-4 h-4 ml-1 flex-none" />
              </div>
            </div>
          </div> : null
      }
    </div>
  )
}