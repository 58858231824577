export default function ArrowForwardIcon ({ fillColor, classNames }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="22" viewBox="0 0 21 22" fill="none" className={classNames}>
    <path 
      d="M7.02178 19.4427L5.7749 18.1958L12.9718 10.9989L5.7749 3.80205L7.02178 2.55518L15.4655 10.9989L7.02178 19.4427Z"
      fill={fillColor ?? "#404040"}
    />
  </svg>
  )
}
