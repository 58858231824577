import { Link } from "react-router-dom";

export default function Confirmation(props) {
  let message = props.message;
  let message2 = props.message2;
  let messageHeading = props.messageHeading;
  let messageLink = props.messageLink;
  let messageLinkText = props.messageLinkText;
  return (
    <>
      <div className="px-4 py-6">
        <h1 className="mt-2">{messageHeading}</h1>
        <p className="mt-2">{message}</p>
        {message2 ?
          <p className="mt-2">{message2}</p>
          : null}
      </div>
      <div className="ml-4">
        <Link
          to={messageLink}
          className="w-full md:w-fit justify-center rounded border border-transparent bg-goj-secondary py-3 px-4 text-goj-white visited:text-goj-white shadow-sm focus:outline-none focus:ring-2 focus:border-goj-focus-dark focus:ring-goj-focus-light"
        >
          {messageLinkText}
        </Link>
      </div>
    </>
  )
}
