import {ReactComponent as BlueIcon} from '../../assets/icons/Search-Blue-27x27.svg'
import {ReactComponent as BlackIcon} from '../../assets/icons/Search-Black-21x21.svg'

export default function SearchIcon() {
  return (
    <BlueIcon className="w-auto sm:h-6" alt="Search icon" />
  )
}

export function SearchIconBlack() {
  return (
    <BlackIcon className="w-auto sm:h-6" alt="Search icon" />
  );
}
