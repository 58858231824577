export default function ConfirmationMessage(props) {
  let message = props.message;
  let message2 = props.message2;
  let messageHeading = props.messageHeading;
  let messageLink = props.messageLink;
  let messageLinkText = props.messageLinkText;
  return (
    <div className="container mx-auto min-h-[calc(100vh-428px)]">
      <div className="grid grid-cols-1 md:grid-cols-12 ">
        <div className='md:col-start-4 md:col-end-10'>
          <div className="px-4 py-6">
            <h1 className="mt-2">{messageHeading}</h1>
            <p className="mt-2">{message}</p>
            {message2 ?
              <p className="mt-2">{message2}</p>
              : null}
          </div>
          <div className="md:ml-4">
            <a href={messageLink}
              className="flex w-full md:w-fit items-center justify-center text-oshBase font-semibold border border-transparent rounded bg-goj-secondary px-4 py-2 text-goj-white shadow-sm hover:no-underline hover:text-goj-white visited:text-goj-white">
              {messageLinkText} &nbsp;<span aria-hidden="true"> &rarr;</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}
