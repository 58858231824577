import LogoWhite from "../../../components/LogoWhite";

export default function RegistrationCard({cardData}) {
  return (
    <div className="max-w-sm w-full bg-white border-2 border-goj-primary rounded-lg shadow-lg">
      <div className="bg-goj-primary text-white grid grid-cols-2 p-2 gap-1 items-center mb-1">
        <div className="mb-1">
          <LogoWhite classNames={'h-7 -ml-2'} />
        </div>
        <div className="text-oshFilter">
          Registration card
        </div>
      </div>
      <div className="grid px-4 grid-cols-2 gap-1 pb-1">
        <div className="text-oshLabel">Residential Status</div>
        <div className="text-base font-semibold">{cardData?.residentialStatus}</div>
        <div className="text-oshLabel">Name</div>
        <div className="text-base font-semibold">{cardData?.fullName}</div>
        <div className="text-oshLabel">Social Security number</div>
        <div className="text-base font-semibold">{cardData?.ssn}</div>
        <div className="text-oshLabel">Card status</div>
        <div className="text-base font-semibold">{cardData?.cardStatus}</div>
        <div className="text-oshLabel">Start date</div>
        <div className="text-base font-semibold">{cardData?.startDate}</div>
        {/* <div className="text-oshLabel">Expiry date</div>
        <div className="text-base font-semibold"><div className="bg-goj-tertiary rounded text-white w-fit px-2 mb-2">{cardData?.expiryDate}</div></div> */}
      </div>
    </div>
  )
}