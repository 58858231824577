import { useEffect, useState } from "react";
import { MsalAuthenticationTemplate, useMsal } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { loginRequest } from "../authConfig";
import { callUmbracoApi } from "../fetch";
import { useAppInsightsContext, useTrackMetric } from "@microsoft/applicationinsights-react-js";
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { Helmet } from "react-helmet";
import Breadcrumb from "../components/Breadcrumb";
import ProfileAside from "../components/ProfileAside";
import useBreadcrumbs from 'use-react-router-breadcrumbs';
import Loading from "../components/Loading";
import ParseHtml from "../utils/ParseHtml";
import FeedbackWidget from "../features/Feedback/Components/FeedbackWidget";
import ArrowForwardIcon from "../components/icons/ArrowForwardIcon";
import logger from "../utils/logger";

const changePasswordNavigation = process.env.REACT_APP_CHANGE_PASSWORD_REDIRECT

/**
 * The `MsalAuthenticationTemplate` component will render its children if a user is authenticated 
 * or attempt to sign a user in. Just provide it with the interaction type you would like to use 
 * (redirect or popup) and optionally a [request object](https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/request-response-object.md)
 * to be passed to the login API, a component to display while authentication is in progress or a component to display if an error occurs. For more, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
**/
export default function AccountSettings() {
  const { instance } = useMsal();
  const authRequest = {
    ...loginRequest
  };
  const changePassword = async () => {
    try {
      const changePasswordRequest = {
        scopes: loginRequest.scopes,
        authority: process.env.REACT_APP_CHANGEPASSWORD_AUTHORITY
      };

      await instance.loginRedirect(changePasswordRequest);
      // User is redirected to the change password page
    } catch (error) {
      console.error("Error initiating password change:", error);
    }
  };

  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    let url = "/content/url?url=/dashboard/account-settings";
    logger.log("URL: ", url);
    if (!data) {
      callUmbracoApi(url)
        .then(response => {
          setData(response);
          setLoading(false);
          setError(null);
          logger.log("RESPONSE: ", response);
        })
        .catch((error) => logger.log(error));
    }
  }, []);

  // appInsights
  const appInsights = useAppInsightsContext();
  const trackComponent = useTrackMetric(appInsights, "Settings");
  appInsights?.trackTrace({ message: 'PublishingWeb_Settings page has been clicked by the user', severityLevel: SeverityLevel.Information });

  const location = useLocation();
  const breadcrumbs = useBreadcrumbs();
  const pages = []
  breadcrumbs.map((elem) => {
    if (elem.breadcrumb.props.children != "Home") {
      pages.push({ name: elem.breadcrumb.props.children, href: elem.key + "/", current: false })
    }
  })

  function RenderFeedbackWidget() {
    return (<FeedbackWidget feedbackTitle={data?.feedbackTitle} feedbackDescription={data?.feedbackDescription} feedbackLink={data?.feedbackLink} />)
  }

  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authRequest}
    >
      <Helmet>
        <title>{!data ? '' : data.metaTitle}</title>
        <meta name="description" content={!data ? '' : data.metaDescription} />
        <meta name="keywords" content={!data ? '' : data.metaKeywords} />
      </Helmet>
      <>
        <Breadcrumb pages={pages} currentPage={location.pathname} />
        <div className="container mx-auto max-w-oshMaxWidth">
          <div className="grid grid-cols-1 lg:grid-cols-12 mt-4">
            <ProfileAside />
            <main className="p-4 lg:p-0 order-first lg:order-last lg:col-span-8 ">
              <RenderFeedbackWidget />
              <h1>{data?.mainHeading ?? `Account settings`}</h1>
              { loading ? <Loading /> : 
                <>{ParseHtml(data?.mainContent)}
                  <div className="grid gap-4 lg:grid-cols-2 mx-auto my-4 w-full">
                    {instance.getActiveAccount()?.idTokenClaims?.authenticationSource === "L2" ?
                      AccountSettingsCard({ isExternalLink: false, href: "/dashboard/account-settings/data-sharing/", title: "Data sharing settings", description: "View and change your data sharing settings" })
                      :
                      null
                    }
                    {instance.getActiveAccount()?.idTokenClaims?.authenticationSource === "L0" ?
                      AccountSettingsCard({ isExternalLink: true, onClick: changePassword, title: "Change password", description: "Reset or change your password" })
                      :
                      null
                    }
                  </div>
                </>
              }
            </main>
          </div>
        </div>
      </>
    </MsalAuthenticationTemplate>
  )
};

function AccountSettingsCard({ isExternalLink, href, title, description, onClick }) {
  const handleClick = (e) => {
    if (onClick) {
      e.preventDefault(); // Prevent default link behavior if an onClick handler is provided
      onClick(); // Execute the provided onClick function
    }
  };
<h3><a href="/dashboard/account-settings/data-sharing/">Data sharing settings</a></h3>
  return (<div className="items-center rounded border-goj-gray-light-super px-6 py-4 bg-goj-gray-light-super flex-col">
      <div className="flex justify-between">
        {isExternalLink ? (
          <h3 class="mt-1">
            <a
              href={href}
              className="text-goj-secondary visited:text-goj-secondary text-xl font-semibold"
              onClick={handleClick}
            >
              {title}
            </a>
          </h3>
        ) : (
          <h3 class="mt-1">
            <Link
              to={href}
              className="text-goj-secondary visited:text-goj-secondary text-xl font-semibold"
              onClick={handleClick}
            >
              {title}
            </Link>
          </h3>
        )}
        <ArrowForwardIcon fillColor={`#0057B6`} classNames={`mt-1`}  />
      </div>
      <div className="mt-4">
        <p className="text-oshBase">{description}</p>
      </div>
    </div>
  );
}

