import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);

export default function formatDate(inputDate, formatPattern) {
  const parsedDate = dayjs(inputDate);
  const formattedDate = parsedDate.format(formatPattern || 'D MMMM YYYY');
  
  return formattedDate
}
