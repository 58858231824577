import { useNavigate, useSearchParams } from 'react-router-dom';
import { useState } from 'react';
import SearchIcon from "./icons/SearchIcon";

export default function SearchBox() {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const searchTerm = searchParams.get('q') || '';
  const [query, setQuery] = useState(searchTerm);
  const handleChange = (event) => {
    setQuery(event.target.value);
  };
  const handleClick = () => {
    navigate("/search?q=" + query);
  };
  return (
    <div className="relative mb-9">
      <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
        <div className="my-auto mx-auto">
          <label htmlFor="search" className="sr-only">Search</label>
          <div className="flex h-14 justify-center">
            <div className="relative flex lg:flex-grow items-stretch">
              <input type="text" name="search" id="search"
                className="block lg:w-full border-goj-gray-light pl-5 pr-12 shadow-md focus:bg-goj-white focus:ring-goj-focus-light rounded-3xl"
                placeholder="Search"
                aria-label="Search"
                onChange={handleChange}
                onKeyDown={e => e.key === 'Enter' && handleClick()}
                value={query}
              />
            </div>
            <button type="button" aria-label="Search button"
              className="absolute right-0 top-0 mt-4 mr-3 pr-3 "
              onClick={handleClick}
            >
            <SearchIcon color="text-goj-white"/>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}