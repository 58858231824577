import { useEffect, useState } from 'react';
import { useMsal } from '@azure/msal-react';
import { useNavigate } from "react-router-dom";
import logger from './logger';

export const useInactivityLogout = (inactivityTimeout = 15 * 60 * 1000) => { // 15mins
  const { instance, accounts } = useMsal();
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const logoutRequest = {
    account: instance.getActiveAccount(),
    postLogoutRedirectUri: "/",
  };
  useEffect(() => {
    instance.enableAccountStorageEvents();
    instance.addEventCallback((message) => {
      switch (message.eventType) {
        case 'msal:accountAdded':
          // Update UI with new account
          logger.log('New account added:', message.account);
          break;
        case 'msal:loginSuccess':
          // Update UI with new account
          logger.log('New account added:', message.account);
          navigate("/dashboard");
          break;
        case 'msal:accountRemoved':
          // Update UI with account logged out
          logger.log('Account removed:', message.account);
          window.location.reload();
          break;
        case 'msal:activeAccountChanged':
          const accountInfo = instance.getActiveAccount();
          // Update UI with new active account info
          logger.log('Active account changed:', accountInfo);
          break;
        default:
      }
    });
  }, [instance]);

  useEffect(() => {
    const activeAccount = instance.getActiveAccount();
    if (!activeAccount) return;

    let timeoutId;
    let warningTimeoutId;

    const checkInactivity = () => {
      const lastActivityTime = localStorage.getItem('lastActivityTime');
      if (lastActivityTime && new Date().getTime() - lastActivityTime >= inactivityTimeout) {
        localStorage.setItem('userLoggedOut', 'true'); // Set the logout flag
        localStorage.removeItem('lastActivityTime');
        instance.logout();
      }
    };
    checkInactivity();
    const resetTimer = () => {
      clearTimeout(timeoutId);
      clearTimeout(warningTimeoutId);
      localStorage.setItem('lastActivityTime', new Date().getTime());
      timeoutId = setTimeout(checkInactivity, inactivityTimeout);
      const lastActivityTime = localStorage.getItem('lastActivityTime');
      const timeRemaining = inactivityTimeout - (new Date().getTime() - lastActivityTime);
      const warningTime = timeRemaining - 5 * 60 * 1000; // 5 min
      if (warningTime > 0) {
        warningTimeoutId = setTimeout(() => setShowModal(true), warningTime);
      }
    };
    resetTimer();
    const events = ['mousedown', 'keypress', 'touchstart'];
    events.forEach(event => window.addEventListener(event, resetTimer));
    window.addEventListener('storage', resetTimer);

    return () => {
      events.forEach(event => window.removeEventListener(event, resetTimer));
      window.removeEventListener('storage', resetTimer);
      clearTimeout(timeoutId);
      clearTimeout(warningTimeoutId);
    };
  }, [inactivityTimeout, instance, accounts]);

  const logout = () => {
    // Specify the target origin for security
    const targetOrigin = window.location.origin;
    // Send a message to all other tabs to trigger logout
    console.log('Sending logout message');
    window.postMessage({ type: 'LOGOUT', timestamp: new Date().getTime() }, targetOrigin);

    // Clear the logout flag and last activity time
    localStorage.removeItem('userLoggedOut');
    localStorage.removeItem('lastActivityTime');

    // Perform the logout action
    instance.logoutRedirect(logoutRequest);
  };

  useEffect(() => {
    const resetModalVisibility = () => {
      setShowModal(false);
    };

    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        const lastActivityTime = localStorage.getItem('lastActivityTime');
        if (new Date().getTime() - lastActivityTime >= inactivityTimeout) {
          setShowModal(false);
        }
      }
    };

    const handleLogoutFlag = () => {
      if (localStorage.getItem('userLoggedOut') === 'true') {
        setShowModal(false);
        // Only refresh the page if the user is not already logged in

      }
    };

    const events = ['mousedown', 'keypress', 'touchstart'];
    events.forEach(event => window.addEventListener(event, resetModalVisibility));
    document.addEventListener('visibilitychange', handleVisibilityChange);
    window.addEventListener('storage', handleLogoutFlag); // Listen for changes in localStorage

    return () => {
      events.forEach(event => window.removeEventListener(event, resetModalVisibility));
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      window.removeEventListener('storage', handleLogoutFlag); // Clean up the event listener
      localStorage.removeItem('userLoggedOut'); // Clear the logout flag on cleanup
      localStorage.removeItem('lastActivityTime');
    };
  }, [instance]); // Add instance as a dependency to re-run the effect when the instance changes

  return { showModal, setShowModal, logout };
};
