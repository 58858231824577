import { useContext } from "react";
import UmbracoContext from "../context/UmbracoContext";
import { Link } from "react-router-dom";
import { Popover, Transition } from '@headlessui/react';
function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function ReplaceUrl(taxonomyUrl) {
  let url = "";
  if (taxonomyUrl.startsWith("/taxonomies/category/")) {
    url = taxonomyUrl.replace("/taxonomies/category/", "/services/");
  }
  return url;
}

export function TaxonomyMenu() {
  const umbraco = useContext(UmbracoContext);
  const menu = umbraco.serviceCategories;
  //console.log("SERVICE CATS: ", menu);
  return (
    menu.map((item) => {
      return (
        <Link
          key={item.taxonomyTitle ?? item?.name}
          to={`${ReplaceUrl(item._url)}`}
          className={classNames(
            item.current ? 'text-goj-white' : 'text-goj-white hover:underline',
            'group flex items-center px-3 py-2 rounded active:text-goj-white active:underline hover:text-goj-white visited:text-goj-white'
          )}
          aria-current={item.current ? 'page' : undefined}
        >
          <img src={item.taxonomyIcon?.src}
            className={classNames(
              item.current ? 'text-goj-white' : 'text-goj-white group-hover:text-goj-white',
              'flex-shrink-0 -ml-1 mr-3 h-6 w-6 hidden lg:flex'
            )}
            aria-hidden="true"
          />
          <span className="truncate text-base">{item.taxonomyTitle ?? item?.name}</span>
        </Link>
      )
    })
  )
}
export function TaxonomyMenuPopover() {
  const umbraco = useContext(UmbracoContext);
  const menu = umbraco.serviceCategories;
  //console.log("SERVICE CATS: ", menu);
  return (
    menu.map((item) => {
      return (
        <Popover.Button as={Link} key={item.name}
        to={`${ReplaceUrl(item._url)}`} 
        className={classNames(
          item.current ? 'text-goj-white' : 'text-goj-white hover:underline',
          'group flex items-center px-3 py-2 rounded active:text-goj-white active:underline hover:text-goj-white visited:text-goj-white'
        )}
        aria-current={item.current ? 'page' : undefined}>
          <img src={item.taxonomyIcon?.src}
               className={classNames(
                   item.current ? 'text-goj-white' : 'text-goj-white group-hover:text-goj-white',
               'flex-shrink-0 -ml-1 mr-3 h-6 w-6 hidden lg:flex'
            )}
            aria-hidden="true"
          />
          <span className="truncate text-base">{item.name}</span>
        </Popover.Button>     
      )
    })
  )
}

