import { useContext, useEffect, useState } from "react";
import { useAccount, useMsal } from "@azure/msal-react";
import { protectedResources } from "../../../authConfig";
import { InteractionRequiredAuthError, BrowserAuthError, InteractionStatus} from "@azure/msal-browser";
import { callApiWithToken } from "../../../fetch";
import parse from 'html-react-parser';
import UserContext from "../../../context/UserContext";
import ErrorAlert from "../../../components/ErrorAlert";
import Loading from "../../../components/Loading";
import logger from "../../../utils/logger";
import ReplaceUmbracoUrl from "../../../utils/ReplaceUmbracoUrl";
import WebLinks from "../../../components/WebLinks";
import dayjs from "dayjs";
import RegistrationCard from "./RegistrationCard";
import formatDate from '../../../utils/FormatDate';
import ContentPanel from "../../../components/ContentPanel";

export default function DisplayRegistrationCard({cmsContent}) {
  // msal
  const { instance, inProgress } = useMsal();
  const account = useAccount(instance.getActiveAccount() || {});

  // context
  const user = useContext(UserContext);
  const fullProfile = user.fullProfile;

  // state
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);
  const [registrationCard, setRegistrationCard] = useState(null);
  const [hasRequestedCard, setHasRequestedCard] = useState(false);

  // props
  const mainHeading = cmsContent?.mainHeading;
  const mainContent = cmsContent?.mainContent;
  const infoHeading = cmsContent?.infoHeading;
  const infoContent = cmsContent?.infoContent;
  const hrefChildren = ReplaceUmbracoUrl(cmsContent?._links?.children?.href);

  // initiate a check for registration card 
  useEffect(() => {
    if (account && inProgress === InteractionStatus.None) {
      getRegistrationCard();
    }
  }, [account, inProgress, fullProfile]);

  const getRegistrationCard = () => {
    instance.acquireTokenSilent({
      scopes: protectedResources.apiRegistrationCard.scopes,
      account: account,
      loginHint: instance.getActiveAccount()?.idTokenClaims.email
    }).then((response) => {
      if (response?.statusCode !== 401) {
        apiFetchRegistrationCard(response);
      }
    }).catch((error) => {
      // in case if silent token acquisition fails, fallback to an interactive method
      if (error instanceof InteractionRequiredAuthError || BrowserAuthError) {
        instance.acquireTokenRedirect({
          scopes: protectedResources.apiRegistrationCard.scopes,
          account: account
        }).then((response) => {
          if (response?.statusCode !== 401) {
            apiFetchRegistrationCard(response);
          } 
        }).catch(error => {
          setLoading(false)
          setErrorMessage("The service is currently unavailable")
          logger.error(error)
        });
      }
    });
  };

  function apiFetchRegistrationCard(response) {
    callApiWithToken(response.accessToken, protectedResources.apiRegistrationCard.endpoint)
    .then(response => {
      logger.log("DEBUG apiFetchRegistrationCard", response);
      if (response?.meta?.totalResults > 0 && response?.registrationCard?.id && response?.registrationCard?.cardStatus) {
        setHasRequestedCard(true);
        setRegistrationCard({
          id: response.registrationCard.id,
          cardStatus: response.registrationCard.cardStatus,
          residentialStatus: response.registrationCard?.residentialStatus,
          conditions: response.registrationCard?.conditions,
          fullName: response.registrationCard?.fullName,
          ssn: response.registrationCard?.ssn,
          startDate: response.registrationCard?.startDate,
          expiryDate: response.registrationCard?.expiryDate
        });
      }
    })
    .catch(e => { 
      setErrorMessage("The service is curently unavailable")
      logger.error(e) 
    })
    .finally(setLoading(false));
  }

  function hasActiveStatus() {
    // check for a valid status
    const validStatus = registrationCard?.cardStatus.toLowerCase() === 'approved';

    // check for valid status with no expiry date
    const expiryDate = registrationCard?.expiryDate;
    if (validStatus && !expiryDate) {
      return true; 
    }

    // check for valid status and not expired
    if (dayjs(expiryDate).isAfter(dayjs())) { 
      return true; 
    }

    // no valid status or expired
    return false; 
  }

  if (loading) {
    return (
      <>
      <RenderHeading />
      <Loading />
      </>
    )
  }

  if (errorMessage) {
    return (
      <>
      <RenderHeading />
      <ErrorAlert messageHeading={'Service unavailable'} message={errorMessage} />
      </>
    )
  }

  function RenderHeading() {
    return (
      <h1>{mainHeading ?? 'Registration card'}</h1>
    )
  }

  function RenderMainContent() {
    return (
      <div>{mainContent ? parse(mainContent) : null}</div>
    )
  }

  function RenderApplyLink() {
    return (
      <div>{hrefChildren ? <WebLinks hrefChildren={hrefChildren} /> : null}</div>
    )
  }

  function RenderInProgress() {
    return (
      <ContentPanel heading={infoHeading} description={infoContent} />
    )
  }

  function RenderCard() {
    const cardData = {
      residentialStatus: registrationCard.residentialStatus + " " + registrationCard.conditions,
      fullName: registrationCard.fullName, 
      ssn: registrationCard.ssn, 
      cardStatus: registrationCard.cardStatus,
      startDate: registrationCard.startDate ? formatDate(registrationCard.startDate, 'D/M/YYYY') : '-',
      expiryDate: registrationCard.expiryDate ? formatDate(registrationCard.expiryDate, 'D/M/YYYY') : '-'
    }
    return (
      <>
      <h3>Current registration card details</h3>
      <RegistrationCard cardData={cardData} />
      </>
    )
  }

  return (
    <>
      <RenderHeading />
      <RenderMainContent />
      { hasRequestedCard ? hasActiveStatus() ? <RenderCard /> : <RenderInProgress /> : <RenderApplyLink /> }
    </>
  )
}