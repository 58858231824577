import { useEffect, useState } from "react";
import { callUmbracoApi } from "../fetch";
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/20/solid'

function FooterLeft(props) {
  const [isSectionVisible, setSectionVisible] = useState(true); // Footer section is initially visible
  const toggleSection = () => {
    setSectionVisible(!isSectionVisible);
  };
  const data = props.data;
  const colA = [];
  const colB = [];
  data && data.length && data.forEach((item, index) => {
    index < 4 ?
      colA.push({
        id: item._id,
        name: item.footerLeftLink.name,
        url: item.footerLeftLink.url,
        target: item.footerLeftLink.target,
        icon: item.footerLeftIcon.url
      })
      :
      colB.push({
        id: item._id,
        name: item.footerLeftLink.name,
        url: item.footerLeftLink.url,
        target: item.footerLeftLink.target,
        icon: item.footerLeftIcon.url
      })
  });
  return (
    <div className="mb-1 flex flex-wrap">
      <p className="text-goj-white px-7 cursor-pointer" onClick={toggleSection}>Government and administration
      {isSectionVisible ? (
        <ChevronUpIcon className="w-6 ml-1 inline-block lg:hidden" />
      ) : (
        <ChevronDownIcon className="w-6 ml-1 inline-block lg:hidden" />
      )}</p>
      {isSectionVisible && (
        <><div className="w-full px-4 lg:w-6/12">
          <ul className="list-unstyled">
            {colA?.map((item) => (
              <li key={item?.id}>
                <a href={item?.url} target={item?.target}
                  className="group flex items-center px-3 py-2">
                  <img src={item?.icon}
                    className="flex-shrink-0 -ml-1 mr-3 h-6 w-6" aria-hidden="true" alt="" />
                  <span className="truncate">{item?.name}</span>
                </a>
              </li>
            ))}
          </ul>
        </div><div className="w-full px-4 lg:w-6/12">
            <ul className="list-unstyled">
              {colB?.map((item) => (
                <li key={item?.id}>
                  <a href={item?.url} target={item?.target}
                    className="group flex items-center px-3 py-2">
                    <img src={item?.icon}
                      className="flex-shrink-0 -ml-1 mr-3 h-6 w-6" aria-hidden="true" alt="" />
                    <span className="truncate">{item?.name}</span>
                  </a>
                </li>
              ))}
            </ul>
          </div></>)}
    </div>
  );
}

function FooterRight(props) {
  const [isSectionVisible, setSectionVisible] = useState(true); // Footer section is initially visible
  const toggleSection = () => {
    setSectionVisible(!isSectionVisible);
  };
  const data = props.data;
  const colA = [];
  const colB = [];
  const colC = [];
  data && data.length && data.forEach((item, index) => {
    if (index < 3) {
      colA.push({
        id: item._id,
        name: item.footerRightLink.name,
        url: item.footerRightLink.url,
        target: item.footerRightLink.target,
        icon: item.footerRightIcon.url
      })
    }
    if (index > 2 && index < 5) {
      colB.push({
        id: item._id,
        name: item.footerRightLink.name,
        url: item.footerRightLink.url,
        target: item.footerRightLink.target,
        icon: item.footerRightIcon.url
      })
    }
    if (index > 4) {
      colC.push({
        id: item._id,
        name: item.footerRightLink.name,
        url: item.footerRightLink.url,
        target: item.footerRightLink.target,
        icon: item.footerRightIcon.url
      })
    }
  });
  return (
    <><p className="text-goj-white px-3 cursor-pointer" onClick={toggleSection}>Agencies and services
    {isSectionVisible ? (
      <ChevronUpIcon className="w-6 ml-1 inline-block lg:hidden" />
    ) : (
      <ChevronDownIcon className="w-6 ml-1 inline-block lg:hidden" />
    )}</p>
      {isSectionVisible && (
        <div className="mb-1 flex flex-wrap">
          <div className="w-full lg:w-4/12">
            <ul className="list-unstyled">
              {colA?.map((item) => (
                <li key={item?.id}>
                  <a href={item?.url} target={item?.target}
                    className="group flex items-center px-3 py-2">
                    <img src={item?.icon}
                      className="flex-shrink-0 -ml-1 mr-3 h-6 w-6" aria-hidden="true" alt="" />
                    <span className="truncate">{item?.name}</span>
                  </a>
                </li>
              ))}
            </ul>
          </div>
          <div className="w-full lg:w-4/12">
            <ul className="list-unstyled">
              {colB?.map((item) => (
                <li key={item?.id}>
                  <a href={item?.url} target={item?.target}
                    className="group flex items-center px-3 py-2">
                    <img src={item?.icon}
                      className="flex-shrink-0 -ml-1 mr-3 h-6 w-6" aria-hidden="true" alt="" />
                    <span className="truncate">{item?.name}</span>
                  </a>
                </li>
              ))}
            </ul>
          </div>
          <div className="w-full lg:w-4/12">
            <ul className="list-unstyled">
              {colC?.map((item) => (
                <li key={item?.id}>
                  <a href={item?.url} target={item?.target}
                    className="group flex items-center px-3 py-2">
                    <img src={item?.icon}
                      className="flex-shrink-0 -ml-1 mr-3 h-6 w-6" aria-hidden="true" alt="" />
                    <span className="truncate">{item?.name}</span>
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>)}</>
  );
}

function FooterBottom(props) {
  const data = props.data;
  return (
    <div className="flex flex-wrap">
      <div className="w-full px-4 lg:w-auto lg:hidden">
        <p className="text-goj-white font-semibold">About</p>
      </div>
      {data && data.length && data?.map((item) => (
        <div key={item?._id} className="w-full px-4 lg:w-auto">
          <a className="block pb-2 " href={item?.footerBottomLink?.url}>{item?.footerBottomLink?.name}</a>
        </div>
      ))}
    </div>
  );
}

export default function Footer() {
  const [footerLeftData, setFooterLeftData] = useState(null);
  const [footerRightData, setFooterRightData] = useState(null);
  const [footerBottomData, setFooterBottomData] = useState(null);

  useEffect(() => {
    if (!footerLeftData) {
      callUmbracoApi("/content/type?contentType=footerLeftItem&page=1&pageSize=8")
        .then(response => {
          setFooterLeftData(response?._embedded?.content);
        })
        .catch((error) => {
          console.log("Error: ", error);
        });
    }
    if (!footerRightData) {
      callUmbracoApi("/content/type?contentType=footerRightItem&page=1&pageSize=7")
        .then(response => {
          setFooterRightData(response?._embedded?.content);
        })
        .catch((error) => {
          console.log("Error: ", error);
        });
    }
    if (!footerBottomData) {
      callUmbracoApi("/content/type?contentType=footerBottomItem&page=1&pageSize=7")
        .then(response => {
          setFooterBottomData(response?._embedded?.content);
        })
        .catch((error) => {
          console.log("Error: ", error);
        });
    }
  }, []);

  return (
    <footer className="mt-12">
      <div className="bg-goj-gray-mid">
        <div className="container mx-auto py-8 max-w-oshMaxWidth">
          <div className="flex flex-wrap text-left lg:text-left">
            <div className="w-full border-0 lg:w-4/12 lg:border-r">
              <FooterLeft data={footerLeftData} />
            </div>
            <div className="w-full px-4 lg:w-8/12">
              <FooterRight data={footerRightData} />
            </div>
          </div>
        </div>
      </div>
      <div className="bg-goj-gray-dark">
        <div className="container mx-auto py-4 max-w-oshMaxWidth">
          <div className="flex flex-wrap text-left lg:text-left items-baseline">
            <div className="w-full lg:w-8/12">
              <FooterBottom data={footerBottomData} />
            </div>
            <div className="w-full lg:px-4 lg:w-4/12">
              <div className="flex flex-wrap">
                <div className="w-full px-4">
                  <span className="block text-goj-white text-sm pt-4 lg:pt-0 lg:text-right">
                    &copy; Copyright Government of Jersey
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}