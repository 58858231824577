import React from 'react'
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';

function paginationComponent({ nPages, currentPage, setCurrentPage }) {

  const pageNumbers = [...Array(nPages + 1).keys()].slice(1)

  const nextPage = () => {
          if(currentPage !== nPages) setCurrentPage(currentPage + 1)
  }
  const prevPage = () => {
      if(currentPage !== 1) setCurrentPage(currentPage - 1)
  }


  return (
    nPages > 1 ?
    <div className="mt-6">
      <nav className="flex items-center justify-between border-t border-gray-200 px-4 sm:px-0">
        <div className="-mt-px flex flex-1">
          <a
            href="#"
            className="inline-flex items-center border-t-2 border-transparent pt-4 pr-1 font-medium text-goj-secondary visited:text-goj-secondary hover:border-goj-gray-mid"
            onClick={prevPage}
          >
            <ChevronLeftIcon className="mr-3 h-5 w-5 text-goj-secondary visited:text-goj-secondary" aria-hidden="true" />
            Previous
          </a>
        </div>
        <div className="hidden md:-mt-px md:block">
          {pageNumbers.map(pgNumber => (
                      <li key={pgNumber} 
                        className= {`page-item ${currentPage === pgNumber ? 
                          'inline-flex items-center border-t-2 border-goj-primary px-4 pt-4 font-semibold text-goj-primary visited:text-goj-primary' 
                          : 
                          'inline-flex items-center border-t-2 border-transparent px-4 pt-4 font-semibold text-goj-gray-mid hover:border-goj-gray-mid hover:text-goj-gray-mid visited:text-goj-gray-mid'} `}
                          >
                          <a onClick={() => setCurrentPage(pgNumber)}  
                              className={`page-link ${currentPage === pgNumber ? 'text-goj-primary visited:text-goj-primary' : 'text-goj-secondary visited:text-goj-secondary' }`}
                              href='#'>
                              {pgNumber}
                          </a>
                      </li>
                  ))}

        </div>
        <div className="-mt-px flex flex-1 justify-end">
          <a
            href="#"
            className="inline-flex items-center border-t-2 border-transparent pt-4 pl-1 font-medium text-goj-secondary visited:text-goj-secondary hover:border-goj-gray-mid"
            onClick={nextPage}
          >
            Next
            <ChevronRightIcon className="ml-3 h-5 w-5 text-goj-secondary visited:text-goj-secondary" aria-hidden="true" />
          </a>
        </div>
      </nav>
    </div>
    : null
  )
}

export default paginationComponent