import { Link } from "react-router-dom";

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function FormDisplayComponent(props) {
  let displayAddress = props?.heading === 'Address';
  let fetchedObject = Object.entries(props).map((([key, value]) => {
    return value;
  }))
  return (
    <div className="mt-4">
      <div>
        <dl className="sm:grid sm:grid-cols-3 sm:gap-4 mt-4">
          <dt>
            <span className="text-goj-gray-dark text-base"><h2>{props.heading}</h2></span>
          </dt>
          {props.linkGen.includes(props.heading) ?
            (<dd className="sm:col-span-2">
              <Link to={`/dashboard/personal-details/${ props.heading === "Contact information" ? ("contact") : (props.heading.toLowerCase())}-information/`} 
                className="text-goj-secondary visited:text-goj-secondary flex flex-shrink: 0;">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"></path>
                  </svg>
                <span className="ml-1">Change { props.heading === "Contact information" ? ("contact") : (props.heading.toLowerCase())} {props.heading === "Preferences" ? "" : "details"}</span>
              </Link>
            </dd>)
            : (null)}
        </dl>
        {/* <p className="mt-6 text-goj-gray-dark text-base">
          <strong></strong>
        </p> */}
      </div>
      <div
        className={classNames(
          displayAddress ? 'pt-2' : '',
          'mt-2 sm:mt-0 border-t sm:border-t-0 border-b border-goj-gray-light mb-10'
        )}
      >
        <dl
          className={classNames(
            displayAddress ? 'pb-2' : 'divide-y divide-goj-gray-light'
          )}
        >
          {
            Object.entries(fetchedObject[0]).map((([key, value]) => {
              return (
                <div
                  key={key}
                  className={classNames(
                    displayAddress ? 'sm:py-1 py-0' : 'py-2 sm:py-3',
                    'sm:grid sm:grid-cols-3 sm:gap-4'
                  )}
                >
                  <dt
                    className={classNames(
                      displayAddress ? 'hidden md:block' : ''
                    )}>{key}</dt>
                  <dd
                    className={classNames(
                      displayAddress ? 'my-0 py-0' : 'mt-1',
                      'sm:col-span-2 sm:mt-0'
                    )}
                  >
                    <strong>{value}</strong>
                    {props.linkGen.includes(key) ?
                      (<div>
                        <Link to={`/change${key.toLowerCase().replace(/\s/g, '').replace(/[- )(]/g, '')}/`} className='text-goj-secondary visited:text-goj-secondary flex flex-shrink: 0; pt-2'>
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                          </svg>
                          <span className="ml-1">Change</span>
                        </Link>
                      </div>)
                      : (null)}
                  </dd>
                </div>
              )
            }))
          }
        </dl>
      </div>
    </div>
  )
}
