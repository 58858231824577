import { Link } from 'react-router-dom';
import ArrowForwardIcon from './icons/ArrowForwardIcon';

function Card({heading,description,linkData,attention}) {
  return (
    <div className="card items-center rounded-lg px-5 pt-1 pb-4 bg-goj-gray-light-super flex-col">
      {
        (heading)?
        <div>
          <div className="flex items-center">
            <h3 className="mt-3">{heading}</h3>
            {attention ? <span className="inline-flex items-center rounded text-oshFilter bg-transparent border border-goj-primary px-2 py-0.5 ml-3 text-goj-primary">Alert</span> : null}
          </div>
          <div>
            <p className="text-base">{description}</p>
          </div>
          <div className="space-y-4">
            {linkData.length?linkData.map((linkData)=>{
              return  ( 
                <div key={linkData.id} className={`flex items-center ${linkData.text === 'Audit history' ? 'border-t pt-4' : ''}`}>
                  <Link id={linkData.id} className={`flex items-center ${linkData.text === 'Audit history' ? 'text-goj-gray-mid hover:underline visited:text-goj-gray-mid' : ''}`} to={linkData.href}>
                    {linkData.text}
                  </Link>
                  <ArrowForwardIcon classNames={`ml-2`} />
                </div>
              )
            }):null}
          </div>
        </div>:null
      }
    </div>
  )
}

export default Card