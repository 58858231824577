import { useContext, useState, useEffect, createContext } from "react";
import { useMsal, useAccount } from "@azure/msal-react";
import { BrowserAuthError, InteractionRequiredAuthError, InteractionStatus } from "@azure/msal-browser";
import { protectedResources } from "../authConfig";
import { callApiWithToken, callApiWithTokenParams } from "../fetch";
import { useLocation } from "react-router-dom";
import dayjs from 'dayjs';
import ParseNotifications from "../features/Notifications/Components/ParseNotifications";

export const DashboardContext = createContext(null);

function ProcessCareRecordData(props) {
  let response = props.response;
  let arrCareRecords = [];
  // consultation
  if (response?.consultationsInformation?.results && response?.consultationsInformation?.results.length) {
    response.consultationsInformation?.results.sort(function (a, b) {
      return new Date(b.consultedOn) - new Date(a.consultedOn);
    });
    const objConsultation = response.consultationsInformation?.results[0];
    const objCareRecord = {
      type: 'consultation',
      status: '',
      title: 'Care record',
      description: 'Consultation on ' + dayjs(objConsultation.consultedOn).format('D MMMM YYYY'),
      link: {
        id: 'consultation-' + objConsultation.consultedOn,
        text: 'View care record',
        href: '/dashboard/your-services/jersey-care-record/details/'
      },
      date: objConsultation.consultedOn
    }
    arrCareRecords.push(objCareRecord);
  }
  // event
  if (response?.eventsInformation?.results && response?.eventsInformation?.results.length) {
    response.eventsInformation?.results.sort(function (a, b) {
      return new Date(b.eventOn) - new Date(a.eventOn);
    });
    const objEvent = response.eventsInformation?.results[0];
    const objCareRecord = {
      type: 'event',
      status: '',
      title: 'Jersey Care Record',
      description: 'Planned event: ' + objEvent.description + " on " + dayjs(objEvent.eventOn).format('D MMMM YYYY'),
      link: {
        id: 'event-' + objEvent.eventOn,
        text: 'View Jersey Care Record details',
        href: '/dashboard/your-services/jersey-care-record/details/'
      },
      date: objEvent.eventOn
    }
    arrCareRecords.push(objCareRecord);
  }
  // referral
  if (response?.referralsInformation?.results && response?.referralsInformation?.results.length) {
    response.referralsInformation?.results.sort(function (a, b) {
      return new Date(b.referredOn) - new Date(a.referredOn);
    });
    const objReferral = response.referralsInformation?.results[0];
    const objCareRecord = {
      type: 'referral',
      status: objReferral.status,
      title: 'Jersey Care Record',
      description: objReferral.term + " on " + dayjs(objReferral.referredOn).format('D MMMM YYYY'),
      link: {
        id: 'referral-' + objReferral.referredOn,
        text: 'View Jersey Care Record details',
        href: '/dashboard/your-services/jersey-care-record/details/'
      },
      date: objReferral.referredOn
    }
    arrCareRecords.push(objCareRecord);
  }
  // medication
  if (response?.medicationsInformation?.results && response?.medicationsInformation?.results.length) {
    response.medicationsInformation?.results.sort(function (a, b) {
      return new Date(b.prescribedOn) - new Date(a.prescribedOn);
    });
    const objMedication = response.medicationsInformation?.results[0];
    const objCareRecord = {
      type: 'medication',
      status: '',
      title: 'Jersey Care Record',
      description: objMedication.medication + " prescribed on " + dayjs(objMedication.prescribedOn).format('D MMMM YYYY'),
      link: {
        id: 'medication-' + objMedication.prescribedOn,
        text: 'View Jersey Care Record details',
        href: '/dashboard/your-services/jersey-care-record/details/'
      },
      date: objMedication.prescribedOn
    }
    arrCareRecords.push(objCareRecord);
  }
  // investigation
  if (response?.investigationresultsInformation?.results && response?.investigationresultsInformation?.results.length) {
    response.investigationresultsInformation?.results.sort(function (a, b) {
      return new Date(b.investigatedOn) - new Date(a.investigatedOn);
    });
    const objInvestigation = response.investigationresultsInformation?.results[0];
    const objCareRecord = {
      type: 'investigation',
      status: '',
      title: 'Jersey Care Record',
      description: objInvestigation.values[0].description + " investigated on " + dayjs(objInvestigation.investigatedOn).format('D MMMM YYYY'),
      link: {
        id: 'medication-' + objInvestigation.investigatedOn,
        text: 'View Jersey Care Record details',
        href: '/dashboard/your-services/care-record/'
      },
      date: objInvestigation.investigatedOn
    }
    arrCareRecords.push(objCareRecord);
  }
  arrCareRecords.sort(function (a, b) {
    return new Date(b.date) - new Date(a.date);
  })
  return arrCareRecords;
}

export function DashboardContextProvider({ children }) {
  const { instance, accounts, inProgress } = useMsal();
  const account = useAccount(instance.getActiveAccount());
  const location = useLocation();

  const notificationsBaseDate = "2000-01-01";
  const [notificationsLastRead, setNotificationsLastRead] = useState(notificationsBaseDate);
  const [notificationsRecent, setNotificationsRecent] = useState([]);
  const [notificationsContinuation, setNotificationsContinuation] = useState(null);

  const [careRecordInfo, setCareRecordInfo] = useState([]);
  const [taxRateInfo, setTaxRateInfo] = useState(null);
  const [ltiaInfo, setLtiaInfo] = useState(null);
  const [stiaInfo, setStiaInfo] = useState(null);
  const [yourServices, setYourServices] = useState([]);
  const [yourServicesLoaded, setYourServicesLoaded] = useState(false);

  function isLocationPath(pathName) {
    return (location.pathname === pathName || location.pathname === pathName + "/");
  }

  function getNotifications(response) {
    if ((isLocationPath("/dashboard") || isLocationPath("/dashboard/notifications") || isLocationPath("/dashboard/your-services"))) {
      callApiWithToken(response.accessToken, protectedResources.apiNotificationEndpoint.endpoint)
      .then(response => {
        if (response) {
          console.log("NOTIFICATIONS CONTEXT", response);
          setNotificationsRecent(response?.results ? ParseNotifications(response.results) : []);
          setNotificationsLastRead(response?.metadata?.lastRead ? response.metadata.lastRead : notificationsBaseDate);
          setNotificationsContinuation(response?.metadata?.continuationToken ? response.metadata.continuationToken : null);
        }
      });
    }
  }

  function getYourServices(response) {
    if (yourServices.length === 0 && (location.pathname === "/" || isLocationPath("/dashboard") || isLocationPath("/dashboard/your-services"))) {
      callApiWithToken(response.accessToken, protectedResources.apiInteractionSummaryEndpoint.endpoint)
        .then(response => {
          if (response?.services && response?.services.length) {
            response.services.sort(function (a, b) {
              return new Date(b.serviceEventdate) - new Date(a.serviceEventdate);
            });
            console.log("YOUR SERVICES SUMMARY", response);
            setYourServices(response?.services);
          }
        })
        .catch(console.log("An error occurred fetching your services"))
        .finally(() => setYourServicesLoaded(true))
    }
  }

  function getJCRHighlights(response) {
    if (careRecordInfo.length === 0) {
      callApiWithToken(response.accessToken, protectedResources.apiDashboardCareRecordHighlightsEndpoint.endpoint)
      .then(response => {
        if (response) {
          console.log("JCR HIGHLIGHTS", response);
          const careRecordData = ProcessCareRecordData(response = { response });
          //console.log("CARE RECORD",careRecordData)
          setCareRecordInfo(careRecordData);
        }
      });
    }
  }

  function getTaxRateSummary(response) {
    if (!taxRateInfo && (location.pathname === "/" || isLocationPath("/dashboard") || isLocationPath("/dashboard/your-services"))) {
      callApiWithToken(response.accessToken, protectedResources.apiDashboardTaxRateSummaryEndpoint.endpoint)
      .then(response => {
        if (response) {
          console.log("TAX RATE SUMMARY", response)
          setTaxRateInfo(response);
        }
      });
    }
  }

  function getLTIASummary(response) {
    if (!ltiaInfo && (location.pathname === "/" || isLocationPath("/dashboard") || isLocationPath("/dashboard/your-services"))) {
      callApiWithToken(response.accessToken, protectedResources.apiLtiaSummary.endpoint)
      .then(response => {
        console.log("LTIA SUMMARY", response);
        setLtiaInfo(response);
      });
    }
  }

  function getSTIASummary(response) {
    if (!stiaInfo && (location.pathname === "/" || isLocationPath("/dashboard") || isLocationPath("/dashboard/your-services"))) {
      callApiWithToken(response.accessToken, protectedResources.apiStiaSummary.endpoint)
      .then(response => {
        console.log("STIA SUMMARY", response);
        setStiaInfo(response);
      });
    }
  }

  useEffect(() => {
    if (account && inProgress === "none" && notificationsRecent.length === 0 && (location.pathname === ("/") || isLocationPath("/dashboard") || isLocationPath("/dashboard/notifications") || isLocationPath("/dashboard/your-services"))) {
      instance.acquireTokenSilent({
        scopes: protectedResources.apiProfile.scopes,
        account: account,
        redirectUri: window.location.origin+"/auth.html",
        loginHint: instance.getActiveAccount()?.idTokenClaims.email
      }).then((response) => {
        getNotifications(response);
        getYourServices(response);
        if (instance.getActiveAccount()?.idTokenClaims?.authenticationSource === "L2") {
          getJCRHighlights(response);
          getTaxRateSummary(response);
          getLTIASummary(response);
          getSTIASummary(response);
        }
      }).catch((error) => {
        console.log(error);
        // in case if silent token acquisition fails, fallback to an interactive method
        if (error instanceof InteractionRequiredAuthError || BrowserAuthError) {
          if (account && inProgress === InteractionStatus.None) {
            instance.acquireTokenRedirect({
              scopes: protectedResources.apiProfile.scopes,
              account: account,
              loginHint: instance.getActiveAccount()?.idTokenClaims.email
            }).then((response) => {
              getNotifications(response);
              getYourServices(response);
              if (instance.getActiveAccount()?.idTokenClaims?.authenticationSource === "L2") {
                getJCRHighlights(response);
                getTaxRateSummary(response);
                getLTIASummary(response);
                getSTIASummary(response);
              }
            }).catch(error => {
              console.log("Error: ", error);
            });
          }
        }
      });
    }
  }, [account, inProgress, instance, location]);

  return (
    <DashboardContext.Provider
      value={{
        yourServices,
        yourServicesLoaded,
        careRecordInfo,
        taxRateInfo,
        ltiaInfo,
        stiaInfo,
        notificationsLastRead,
        notificationsRecent,
        notificationsContinuation
      }}
    >
      {children}
    </DashboardContext.Provider>
  );
}

export function useDashboard() {
  const context = useContext(DashboardContext);
  if (context === undefined) {
    throw new Error("Context must be used within a Provider");
  }
  return context;
}

export default DashboardContext;