import { useEffect, useState } from "react";
import { MsalAuthenticationTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { loginRequest } from "../authConfig";
import { callUmbracoApi } from "../fetch";
import { useAppInsightsContext, useTrackMetric, useTrackEvent } from "@microsoft/applicationinsights-react-js";
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { useParams ,useLocation} from 'react-router-dom';
import Breadcrumb from "../components/Breadcrumb";
import ProfileAside from "../components/ProfileAside";
import useBreadcrumbs from 'use-react-router-breadcrumbs';
import GeneralContent from "../components/GeneralContent";
import Loading from "../components/Loading";

/**
 * The `MsalAuthenticationTemplate` component will render its children if a user is authenticated 
 * or attempt to sign a user in. Just provide it with the interaction type you would like to use 
 * (redirect or popup) and optionally a [request object](https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/request-response-object.md)
 * to be passed to the login API, a component to display while authentication is in progress or a component to display if an error occurs. For more, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
**/
export default function ProfileLayout() {
  const authRequest = {
    ...loginRequest
  };

  const [data, setData] = useState(null);
  const { name } = useParams();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {  
    let url = "/content/url?url=/dashboard/" + name;
    if (!data) {
      callUmbracoApi(url)
        .then(response => {
          setData(response);
          setLoading(false);
          setError(null);
        })
        .catch((e) => {
          setError(e?.message);
          console.log("Error: ", e?.message);
        });
    }
  }, [name]);

  // appInsights
  const appInsights = useAppInsightsContext();
  const trackComponent = useTrackMetric(appInsights, "Profile");
  appInsights?.trackTrace({ message: 'PublishingWeb_Profile page has been clicked by the user', severityLevel: SeverityLevel.Information });

  const location = useLocation();
  const breadcrumbs = useBreadcrumbs();
  const pages = []
  breadcrumbs.map((elem)=>{
    if(elem.breadcrumb.props.children !=="Home"){
      pages.push({ name: elem.breadcrumb.props.children, href: elem.key + "/", current: false })
    }
  })

  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authRequest}
    >
      {
        loading ? (
          <Loading />
        ) : (
          error ? (
          "An error occurred."
          ) : (
          <>
            <Breadcrumb pages={pages} currentPage={location.pathname}/>
            <div className="container mx-auto max-w-oshMaxWidth">
              <div className="grid grid-cols-1 lg:grid-cols-12 mt-4">
                <ProfileAside />
                <main className="p-4 lg:p-0 order-first lg:order-last lg:col-span-8 ">
                  {data ? <GeneralContent data={data} /> : null}
                </main>
              </div>
            </div>
          </>
          )
        )
      }
    </MsalAuthenticationTemplate>
  )
};
