import { useContext, useState, useEffect, createContext } from "react";
import { useMsal, useAccount } from "@azure/msal-react";
import { BrowserAuthError, InteractionRequiredAuthError, InteractionStatus } from "@azure/msal-browser";
import { protectedResources } from "../authConfig";
import { callApiWithToken } from "../fetch";
import { useLocation } from "react-router-dom";
import GetCountryCode from '../utils/GetCountryCode';
import logger from "../utils/logger";

export const UserContext = createContext(null);

export function UserContextProvider({ children }) {
  const { instance, accounts, inProgress } = useMsal();
  const account = useAccount(instance.getActiveAccount());
  const location = useLocation();

  const [serviceError, setServiceError] = useState(false);
  const [fullProfile, setFullProfile] = useState(null);
  const [codes, setCodes] = useState([]);

  function getCodes(response) {
    callApiWithToken(response.accessToken, protectedResources.apiCountryCodeEndpoint.endpoint)
    .then(response => {
      setCodes(response);
      logger.log("CODES TABLE CONTEXT", response);
    });
  }

  function getFullProfile(response) {
    callApiWithToken(response.accessToken, protectedResources.apiProfileFull.endpoint)
    .then(response => {
      if (response) {
        let profileData = response;
        
        
        if (profileData?.contact?.mobilePhoneCountryCode === "") { // ""
          var countryCode = GetCountryCode(profileData?.contact?.mobilePhone)
          var filteredJsonArrayValues = codes?.countries?.find(item => item.mobileCode === countryCode);
          if (filteredJsonArrayValues) {
            profileData.contact.mobilePhoneCountryCode = filteredJsonArrayValues.alpha2Code + "+" + filteredJsonArrayValues.mobileCode
          } else {
            profileData.contact.mobilePhoneCountryCode = "JE+44"
          }
        }

        if (profileData?.contact?.homePhoneCountryCode === "") {
          var countryCode = GetCountryCode(profileData?.contact?.homePhone)
          var filteredJsonArrayValues = codes?.countries?.find(item => item.mobileCode === countryCode);
          if (filteredJsonArrayValues) {
            profileData.contact.homePhoneCountryCode = filteredJsonArrayValues?.alpha2Code + "+" + filteredJsonArrayValues.mobileCode
          } else {
            profileData.contact.homePhoneCountryCode = "JE+44"
          }
        }

        if (profileData?.contact?.workPhoneCountryCode === "") {
          var countryCode = GetCountryCode(profileData.contact.workPhone)
          var filteredJsonArrayValues = codes?.countries?.find(item => item?.mobileCode === countryCode);
          if (filteredJsonArrayValues) {
            profileData.contact.workPhoneCountryCode = filteredJsonArrayValues?.alpha2Code + "+" + filteredJsonArrayValues?.mobileCode
          } else {
            profileData.contact.workPhoneCountryCode = "JE+44"
          }
        }
        setFullProfile(profileData);
        logger.log("FULL PROFILE CONTEXT", profileData);
      }
    });
  }

  useEffect(() => {
    if (account && inProgress === InteractionStatus.None && (location.pathname.startsWith("/dashboard") || location.pathname === "/")) {
      instance.acquireTokenSilent({
        scopes: protectedResources.apiProfile.scopes,
        account: account,
        redirectUri: window.location.origin+"/auth.html",
        loginHint: instance.getActiveAccount()?.idTokenClaims.email
      }).then((response) => {
        if (codes.length === 0) {
          getCodes(response);
        }
        if (instance.getActiveAccount()?.idTokenClaims?.authenticationSource === "L2" && 
          (location.pathname.startsWith("/dashboard/personal-details") ||
            location.pathname === "/dashboard/your-children" || location.pathname === "/dashboard/your-children/" ||
              location.pathname === "/dashboard/account-settings/data-sharing" || location.pathname === "/dashboard/account-settings/data-sharing/")) {
          getFullProfile(response);
        }
        else if (!fullProfile) {
          getFullProfile(response);
        }
      }).catch((error) => {
        logger.log(error);
        // in case if silent token acquisition fails, fallback to an interactive method
        if (error instanceof InteractionRequiredAuthError || BrowserAuthError) {
          if (account && inProgress === InteractionStatus.None) {
            instance.acquireTokenRedirect({
              scopes: [...protectedResources.apiProfile.scopes, ...protectedResources.apiInteractionEndpoint.scopes],
              account: account,
              loginHint: instance.getActiveAccount()?.idTokenClaims.email
            }).then((response) => {
              if (codes.length === 0) {
                getCodes(response);
              }
              if (instance.getActiveAccount()?.idTokenClaims?.authenticationSource === "L2" && 
                  (location.pathname.startsWith === "/dashboard/personal-details" ||
                    location.pathname === "/dashboard/your-children" || location.pathname === "/dashboard/your-children/" ||
                      location.pathname === "/dashboard/account-settings/data-sharing" || location.pathname === "/dashboard/account-settings/data-sharing/")) {
                getFullProfile(response);
              }
              else if (!fullProfile) {
                getFullProfile(response);
              }
            }).catch(error => {
              setServiceError(true);
              logger.log("Error: ", error);
            });
          }
        }
      });
    }
  }, [account, inProgress, instance, location, codes, codes.length, codes?.countries]);

  return (
    <UserContext.Provider
      value={{
        fullProfile: fullProfile,
        codes: codes,
        serviceError: serviceError
      }}
    >
      {children}
    </UserContext.Provider>
  );
}

export function useUser() {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error("Context must be used within a Provider");
  }
  return context;
}

export default UserContext;