import { useEffect, useState } from "react";
import { callUmbracoApi } from "../fetch";
import ServiceCard from "./ServiceCard";

export default function HomeContent(props) {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(40);
  const taxonomy = props?.taxonomy;
  const [data, setData] = useState([]);

  //error handle
  const [error, setError] = useState(null);

  useEffect(() => {
    callUmbracoApi("/content/type?contentType=" + taxonomy + `&page=${currentPage}&pageSize=${pageSize}`)
      .then(response => {
        if (response?._embedded?.content) {
          setData([...data, ...response?._embedded?.content]);
        }
      })
      .catch(error => {
        console.log(error);
        //jonny
        setError(error.message);
      });
  }, [currentPage]);

  //jj
  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <>
      <div className="mx-auto">
        <div className="grid grid-cols-2 mx-2 my-4 lg:w-full lg:grid-cols-4">
          {data && data.map((cat) => (
            <ServiceCard
              key={cat?.sortOrder}
              title={cat?.taxonomyTitle ?? cat?.name}
              url={cat?._url}
              icon={cat?.taxonomyIcon?.src} taxonomy={taxonomy}
              background={cat?.image?.media?.umbracoFile?.cropUrls?.tile}
            />
          ))}
        </div>
      </div>

    </>
  )
}
