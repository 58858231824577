import LogoWhite from "./LogoWhite";

export default function LoginMessage({title,message,errorDetails}) {
  return (
    <>
      <header className="items-center bg-goj-primary py-4">
        <div className="container mx-auto flex px-4 items-center">
          <div className="justify-start flex-1">
            <a href="/" title="Onlines services hub">
              <LogoWhite />
            </a>
          </div>  
          <div className="justify-end">
            <a href="/dashboard/" className="text-goj-white hover:text-goj-white visited:text-goj-white">Log in</a>
          </div>
        </div>
      </header> 
      <main className="container mx-auto max-w-screen-sm">
          <div className="rounded bg-goj-primary-very-light-tint p-4 m-10 border-l-4 border-goj-primary">
            <div className="text-xl flex items-center font-semibold">
              {title}
            </div>
            <div className="text-base pt-2">
              {message}
            </div>
            {errorDetails && (
              <div className="text-base pt-2">
                {errorDetails}
              </div>
            )}
          </div>
      </main>
    </>
  )
}