const Button = ({ children, onClick, styles, disabled, displayMobileAccordian }) => {
  if (displayMobileAccordian) {
    return (
      <button className={styles} onClick={onClick} disabled={disabled ? true : false} style={{display: "block"}}>
        {children}
      </button>
    );
  } else {
    return (
      <button className={styles} onClick={onClick} disabled={disabled ? true : false}>
        {children}
      </button>
    );
  }
};

export default Button;