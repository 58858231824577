import { Link } from 'react-router-dom';

export function ReplaceUrl(taxonomyUrl, serviceUrl) {
  let taxUrl = "";
  let url = "";
  if (taxonomyUrl.startsWith("/taxonomies/category/")) {
    taxUrl = taxonomyUrl.replace("/taxonomies/category/", "/services/");
  }
  if (taxonomyUrl.startsWith("/taxonomies/life-event/")) {
    taxUrl = taxonomyUrl.replace("/taxonomies/life-event/", "/life-events/");
  }
  if (taxonomyUrl.startsWith("/taxonomies/persona/")) {
    taxUrl = taxonomyUrl.replace("/taxonomies/persona/", "/personas/");
  }
  if (serviceUrl) {
    url = serviceUrl.replace("/services/", taxUrl);
  } else {
    url = taxUrl;
  }

  return url;
}

export default function ServiceCard(props) {
  //console.log("Service Card PROPS: ", props);
  const title = props?.title;
  const items = props?.items;
  const url = props?.url;
  const icon = props?.icon;
  const taxonomy = props?.taxonomy;
  const background = props?.background;
  return (
    <Link to={ReplaceUrl(url)} className="text-goj-charcoal visited:text-goj-charcoal font-semibold text-base mb-5" title={`Show all ${title} services`} >
      <div className='w-34 mx-2'>
        <div
          style={{
            backgroundImage: `url(${background})`,
            backgroundRepeat: "no-repeat",
          }}
          className="h-24 rounded bg-cover flex flex-col lg:h-[152px] overflow-hidden"
          data-testid="background-image"
        >
          <div className="w-full h-full bg-black bg-opacity-50">
            <div className="flex flex-col items-center lg:justify-center mt-7 lg:mt-3 h-32">
              <img src={icon} className="w-10 lg:w-14 lg:h-14 brightness-100" alt="" />
            </div>
          </div>

        </div>
        <div className="lg:px-5 mt-3 flex justify-center text-center leading-4">
          {title}
        </div>
      </div>
    </Link>
  )
}
