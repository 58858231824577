import dayjs from 'dayjs';

export default function LTIAInfo(props) { 
  const allowanceInfo = props;
  const oneMonthAgo = dayjs().subtract(1, 'month');
  const oneMonthAhead = dayjs().add(1, 'month');
  let isForAttention = false;
  let cardAllowanceInfo = {display: false};
  // configure the tax rate data card
  if (allowanceInfo && allowanceInfo.paymentscount && allowanceInfo.latestpaymentdate) {
    // date is within one month
    if (dayjs(allowanceInfo.latestpaymentdate).isAfter(dayjs()) && dayjs(allowanceInfo.latestpaymentdate).isBefore(dayjs(oneMonthAhead))){
      isForAttention = true;
    }
    //if (dayjs(allowanceInfo.latestpaymentdate).isAfter(dayjs(oneMonthAgo))) {
      cardAllowanceInfo = {
        display: true,
        id: "ltia-info",
        title: "Long Term Incapacity Allowance Benefit",
        description: "Long Term Incapacity Allowance (LTIA) is a Social Security contributory, in work benefit that you can apply for if you have a long term ill health or an injury that has been ongoing for 6 months before you apply.",
        link: [
          {id: "ltia-payments", text: `View recent payments (${allowanceInfo?.paymentscount})`, href:"/dashboard/your-services/long-term-incapacity-allowance-benefit/payments/"}//,
          //{id: "/services/long-term-incapacity-allowance-benefit/", text: "Audit history", href:"/dashboard/your-services/long-term-incapacity-allowance-benefit/"}
        ],
        date: allowanceInfo.latestpaymentdate,
        attention: isForAttention
      }
    //}
  }
  return cardAllowanceInfo;
}