export default function AppointmentType(name) {
    const stringName = typeof name === 'string' ? name : '';
    switch (true) {
        case stringName.toLowerCase().includes('person'):
            return "In-person";
        case stringName.toLowerCase().includes('phone'):
            return "Phone";
            case stringName.toLowerCase().includes('online'):
            return "Online";
        default:
            return null;
    }
};
