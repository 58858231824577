import { useContext, createContext } from "react";

export const TellUsOnceContext = createContext(null);

export function TellUsOnceContextProvider({ value, children }) {
  return (
    <TellUsOnceContext.Provider
      value={value}
    >
      {children}
    </TellUsOnceContext.Provider>
  );
}

export function useTellUsOnce() {
  const context = useContext(TellUsOnceContext);
  if (context === undefined) {
    throw new Error("Context must be used within a Provider");
  }
  return context;
}

export default TellUsOnceContext;