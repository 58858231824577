import { Fragment, useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { callUmbracoApiGraphQL } from "../fetch";
import { Popover, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/20/solid";
import Loading from "./Loading";
import PaginationComponent from './paginationComponent';
import FilterIcon from "./icons/FilterIcon";
import FilterResetIcon from "./icons/FilterResetIcon";
import OpenNew from "./icons/OpenNew";
import logger from "../utils/logger";

const cmsPreview = process.env.REACT_APP_CMS_PREVIEW;

function ReplaceUrl(href, cat) {
  let url = href.replace("/services/","/services" + cat);
  return url;
}

function DeleteSearchParamByKeyValue (searchParams, key, value) {
  const allKeys = []
  const entriesToKeep = []

  for (const [k, v] of searchParams.entries()) {
    if (k === undefined || v === undefined) continue
    allKeys.push(k)
    if (k === key && v === value) continue
    entriesToKeep.push([k, v])
  }

  for (const k of allKeys) {
    searchParams.delete(k)
  }

  for (const [k, v] of entriesToKeep) {
    searchParams.append(k, v)
  }
  
  return searchParams
}

function BuildSearchQuery(searchParams) {
  var isPreview = "false";
  if (cmsPreview && cmsPreview === "true") {
    isPreview = "true"
  }
  let qry = "query($term: String, $scope: String "
  if (searchParams.getAll("c").length) { 
    searchParams.getAll("c").map((param, index) => ( qry = qry + ", $cat" + index.toString() + ": ID" ))
  }
  if (searchParams.getAll("l").length) { 
    searchParams.getAll("l").map((param, index) => ( qry = qry + ", $life" + index.toString() + ": ID" ))
  }
  if (searchParams.getAll("p").length) { 
    searchParams.getAll("p").map((param, index) => ( qry = qry + ", $pers" + index.toString() + ": ID" ))
  }
  qry += "){ "
  qry += " allService(preview: " + isPreview + " where: { OR: [ { name_contains: $term } { serviceName_contains: $term } { shortDescription_contains: $term } { metaKeywords_some: [$term]} { metaDescription_contains: $term } ] "
  if (searchParams.getAll("c").length || searchParams.getAll("l").length || searchParams.getAll("p").length) qry += " AND: [ "
  if (searchParams.getAll("c").length) { 
    searchParams.getAll("c").map((param, index) => (qry = qry + " { categoryTaxonomy_some: { id: $cat" + index.toString() + " } } "))
  }
  if (searchParams.getAll("l").length) { 
    searchParams.getAll("l").map((param, index) => (qry = qry + " { lifeEventsTaxonomy_some: { id: $life" + index.toString() + " } } "))
  }
  if (searchParams.getAll("p").length) { 
    searchParams.getAll("p").map((param, index) => (qry = qry + " { personaTaxonomy_some: { id: $pers" + index.toString() + " } } "))
  }
  if (searchParams.getAll("c").length || searchParams.getAll("l").length || searchParams.getAll("p").length) qry += " ] "
  qry += " })"
  qry += " { edges { node { id name serviceName shortDescription metaKeywords metaDescription url categoryTaxonomy { id name url } lifeEventsTaxonomy { id name url } personaTaxonomy { id name url } } } } "
  qry += " allServiceInteraction(preview: " + isPreview + " where: { OR: [ { name_contains: $term } { interactionName_contains: $term } { shortDescription_contains: $term } { metaKeywords_some: [$term] } { metaDescription_contains: $term } ] } ) "
  qry += " { edges { node { id name interactionName shortDescription metaKeywords metaDescription url parent { id name url ... on Service { categoryTaxonomy { id name url } lifeEventsTaxonomy { id name url } personaTaxonomy { id name url } } } } } } "
  qry += " allGeneralContent(preview: " + isPreview + " where: {OR: [{name_contains: $term}, {mainHeading_contains: $term}, {shortDescription_contains: $term}, {metaTitle_contains: $term}, {metaDescription_contains: $term}, {metaKeywords_some: [$term]}] AND: [{url_starts_with: $scope }] }) "
  qry += " { edges { node { id name mainHeading shortDescription metaTitle metaDescription metaKeywords url } } } ";
  qry += " allWebLink(preview: " + isPreview + " where: {OR: [{title_contains: $term}, {aZTitle_some: [$term]}, {aZDescription_contains: $term}, {metaTitle_contains: $term}, {metaDescription_contains: $term}, {metaKeywords_some: [$term]}]}) "
  qry += " { edges { node { id title isServiceOnlineForm aZTitle aZDescription metaTitle metaDescription metaKeywords link { name url target } } } } ";
  qry += "}"

  let qryString = '{"query":"' + qry + '","variables": {'
  qryString += '"scope":' + '"/dashboard",'
  searchParams.getAll('c').map((param, index) => ( qryString = qryString + '"cat' + index.toString() + '": ' + '"' + param + '",' ))
  searchParams.getAll('l').map((param, index) => ( qryString = qryString + '"life' + index.toString() + '": ' + '"' + param + '",' ))
  searchParams.getAll('p').map((param, index) => ( qryString = qryString + '"pers' + index.toString() + '": ' + '"' + param + '",' ))
  qryString = qryString + '"term":' + '"'
  if (searchParams.get('q')) {
    qryString = qryString + searchParams.get('q').replace(/[^a-zA-Z0-9 ]/g, '')
  }
  qryString = qryString +  '"' 
  qryString += '}}'
  //logger.log("queryString",qryString)

  let objQry = JSON.parse(qryString);
  return objQry
}

export default function SearchResults() {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchTerm, setSearchTerm] = useState(searchParams.get('q') || '');
  const [searchInput, setSearchInput] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [results, setResults] = useState([]);
  const [topResults, setTopResults] = useState([]);
  const [categories, setCategories] = useState([]);
  const [lifeEvents, setLifeEvents] = useState([]); 
  const [personas, setPersonas] = useState([]);
  const [searchQuery, setSearchQuery] = useState(BuildSearchQuery(searchParams));
  const [toggleFilters, setToggleFilters] = useState(false);
  const [filterActive, setFilterActive] = useState(false);
  const [categoryFilterActive, setCategoryFilterActive] = useState(false);

  // event handlers
  const handleToggleFiltersClick = event =>  {
    event.preventDefault();
    setToggleFilters(current => !current);
  }

  const handleChange = (event) => {
    setSearchInput(event.target.value);
  };

  const handleClick = event => {
    event.preventDefault();
    if (searchInput) {
      setSearchTerm(searchInput);
      searchParams.delete("c");
      searchParams.delete("l");
      searchParams.delete("p");
      searchParams.delete("q");
      searchParams.append("q", searchInput);
      setSearchParams(searchParams);
      setSearchQuery(BuildSearchQuery(searchParams));
    }
  }

  const handleResetClick = event =>  {
    event.preventDefault();
    searchParams.delete("c");
    searchParams.delete("l");
    searchParams.delete("p");
    setSearchParams(searchParams);
    setSearchQuery(BuildSearchQuery(searchParams));
    setFilterActive(false);
  }

  const handleCategoryFilterChange = (event) => {
    setCategoryFilterActive(event.target.checked)
    setFilterActive(event.target.checked);
    if (event.target.checked) {
      searchParams.append('c',event.target.name);
      setSearchParams(searchParams);
      setSearchQuery(BuildSearchQuery(searchParams));
    }
    else {
      setSearchParams(DeleteSearchParamByKeyValue(searchParams, "c", event.target.name));
      setSearchQuery(BuildSearchQuery(searchParams));
    }
  }

  const handleLifeEventFilterChange = (event) => {
    setFilterActive(event.target.checked);
    if (event.target.checked) {
      searchParams.append('l',event.target.name);
      setSearchParams(searchParams);
      setSearchQuery(BuildSearchQuery(searchParams));
    }
    else {
      setSearchParams(DeleteSearchParamByKeyValue(searchParams, "l", event.target.name));
      setSearchQuery(BuildSearchQuery(searchParams));
    }
  }

  const handlePersonaFilterChange = (event) => {
    setFilterActive(event.target.checked);
    if (event.target.checked) {
      searchParams.append('p',event.target.name);
      setSearchParams(searchParams);
      setSearchQuery(BuildSearchQuery(searchParams));
    }
    else {
      setSearchParams(DeleteSearchParamByKeyValue(searchParams, "p", event.target.name));
      setSearchQuery(BuildSearchQuery(searchParams));
    }
  }

  // searchParams
  useEffect(() => {  
    logger.log("SEARCH QUERY: ", searchQuery)
    setResults([]);
    setTopResults([]);
    setCategories([]);
    setLifeEvents([]);
    setPersonas([]);
    setLoading(true);
    callUmbracoApiGraphQL(searchQuery)
    .then(response => {
      setSearchResults(response?.data);
      setLoading(false);
      setError(null);
      logger.log("SEARCH DATA: ", response?.data);
    })
    .catch((e) => {
      setError(e?.message);
      logger.log("ERROR: ", e?.message);
      setLoading(false);
    });
  }, [searchParams]);

  // searchResults
  useEffect(() => { 
    const services = searchResults?.allService?.edges;
    const serviceInteractions = searchResults?.allServiceInteraction?.edges;
    const generalContents = searchResults?.allGeneralContent?.edges;
    const webLinks = searchResults?.allWebLink?.edges;
    const serviceCategories = [];
    const serviceLifeEvents = [];
    const servicePersonas = [];
    const arrTopResults = [];
    const arrResults = [];
    if (services || serviceInteractions) {
      let count = 0;

      // services
      services && services.length && services.forEach(service => {
        // get the primary category url
        let catUrl = service.node.categoryTaxonomy[0].url.replace("/taxonomies/category", ""); 

        // promote first 4 to top results
        if (count < 4) {
          arrTopResults.push({id: service.node.id, name: service.node.name, url: ReplaceUrl(service.node.url,catUrl), shortDescription: service.node?.shortDescription, type: "service"}); 
        }

        // add service to results
        arrResults.push({id: service.node.id, name: service.node.name, url: ReplaceUrl(service.node.url,catUrl), shortDescription: service.node?.shortDescription, type: "service"});

        // categories
        if (service?.node?.categoryTaxonomy && service?.node?.categoryTaxonomy?.length > 0 ) {
          service.node.categoryTaxonomy.forEach(cat => {
            if (serviceCategories.length < 1) {
              serviceCategories.push({...cat, keyID: cat.id}); //empty array so always add the first one
            }
            if (serviceCategories.length > 0) {
              let catExists = false;
              serviceCategories.forEach(c => {
                if (c.id === cat.id) {
                  catExists = true; //check if the category is already in the array, later we need to count these i.e. Advice and Support (3)
                }
              })
              if (!catExists) {
                serviceCategories.push({...cat, keyID: cat.id}); //add new category to the array
              }
            } 
          })
        }

        // life-events
        if (service?.node?.lifeEventsTaxonomy && service?.node?.lifeEventsTaxonomy?.length > 0 ) {
          service.node.lifeEventsTaxonomy.forEach(le => {
            if (serviceLifeEvents.length < 1) {
              serviceLifeEvents.push({...le, keyID: le.id});
            }
            if (serviceLifeEvents.length > 0) {
              let catExists = false;
              serviceLifeEvents.forEach(c => {
                if (c.id === le.id) {
                  catExists = true;
                }
              })
              if (!catExists) {
                serviceLifeEvents.push({...le, keyID: le.id});
              }
            } 
          })
        }

        // personas
        if (service?.node?.personaTaxonomy && service?.node?.personaTaxonomy?.length > 0 ) {
          service.node.personaTaxonomy.forEach(pers => {
            if (servicePersonas.length < 1) {
              servicePersonas.push({...pers, keyID: pers.id});
            }
            if (servicePersonas.length > 0) {
              let catExists = false;
              servicePersonas.forEach(c => {
                if (c.id === pers.id) {
                  catExists = true;
                }
              })
              if (!catExists) {
                servicePersonas.push({...pers, keyID: pers.id});
              }
            } 
          })
        }

        count++;
      }); 

      // service interactions
      serviceInteractions && serviceInteractions.length && serviceInteractions.forEach(interaction => {
        let foundService = false;
        for (let i = 0; i < arrResults.length; i++) {
          if (arrResults[i].id === interaction.node.parent.id) {
            foundService = true;
            break;
          }
        }
        if (foundService) {

          // get the primary category url
          let catUrl = interaction.node.parent.categoryTaxonomy[0].url.replace("/taxonomies/category", ""); 

          // promote to top results if there is room
          if (arrTopResults.length < 4) {
            arrTopResults.push({id: interaction.node.id, name: interaction.node.name, url: ReplaceUrl(interaction.node.url,catUrl), shortDescription: interaction.node?.shortDescription, type: "service-interaction"}); 
          } 

          // add service interaction to results
          arrResults.push({id: interaction.node.id, name: interaction.node.name, url: ReplaceUrl(interaction.node.url,catUrl), shortDescription: interaction.node?.shortDescription, type: "service-interaction"});

          // // categories
          // if (interaction?.node?.parent?.categoryTaxonomy && interaction?.node?.parent?.categoryTaxonomy?.length > 0 ) {
          //   interaction.node.parent.categoryTaxonomy.forEach(cat => {
          //     if (serviceCategories.length < 1) {
          //       serviceCategories.push({...cat, keyID: cat.id}); //empty array so always add the first one
          //     }
          //     if (serviceCategories.length > 0) {
          //       let catExists = false;
          //       serviceCategories.forEach(c => {
          //         if (c.id === cat.id) {
          //           catExists = true; //check if the category is already in the array, later we need to count these i.e. Advice and Support (3)
          //         }
          //       })
          //       if (!catExists) {
          //         serviceCategories.push({...cat, keyID: cat.id}); //add new category to the array
          //       }
          //     } 
          //   })
          // }

          // // life-events
          // if (interaction?.node?.parent?.lifeEventsTaxonomy && interaction?.node?.parent?.lifeEventsTaxonomy?.length > 0 ) {
          //   interaction.node.parent.lifeEventsTaxonomy.forEach(le => {
          //     if (serviceLifeEvents.length < 1) {
          //       serviceLifeEvents.push({...le, keyID: le.id});
          //     }
          //     if (serviceLifeEvents.length > 0) {
          //       let catExists = false;
          //       serviceLifeEvents.forEach(c => {
          //         if (c.id === le.id) {
          //           catExists = true;
          //         }
          //       })
          //       if (!catExists) {
          //         serviceLifeEvents.push({...le, keyID: le.id});
          //       }
          //     } 
          //   })
          // }

          // // personas
          // if (interaction?.node?.personaTaxonomy && interaction?.node?.personaTaxonomy?.length > 0 ) {
          //   interaction.node.personaTaxonomy.forEach(pers => {
          //     if (servicePersonas.length < 1) {
          //       servicePersonas.push({...pers, keyID: pers.id});
          //     }
          //     if (servicePersonas.length > 0) {
          //       let catExists = false;
          //       servicePersonas.forEach(c => {
          //         if (c.id === pers.id) {
          //           catExists = true;
          //         }
          //       })
          //       if (!catExists) {
          //         servicePersonas.push({...pers, keyID: pers.id});
          //       }
          //     } 
          //   })
          // }

        }        

        count++;
      });

      // general content pages
      generalContents && generalContents.length && generalContents.forEach(generalContent => {
        // promote to top results if there is room
        if (arrTopResults.length < 4) {
          arrTopResults.push(
            {
              id: generalContent.node.id, 
              name: generalContent.node?.mainHeading ?? generalContent.node?.name, 
              url: generalContent.node?.url, 
              shortDescription: generalContent.node?.shortDescription ?? generalContent.node?.metaDescription, 
              type: "generalContent"
            }
          );
        }
        // add generalContents to results
        arrResults.push(
          {
            id: generalContent.node.id, 
            name: generalContent.node?.mainHeading ?? generalContent.node?.name, 
            url: generalContent.node?.url, 
            shortDescription: generalContent.node?.shortDescription ?? generalContent.node?.metaDescription, 
            type: "generalContent"
          }
        );
        
        count++;
      })
      
      // weblinks
      webLinks && webLinks.length && !categoryFilterActive && webLinks.forEach(webLink => {
        // promote to top results if there is room
        if (arrTopResults.length < 4) {
          arrTopResults.push(
            {
              id: webLink.node.id, 
              name: webLink.node?.aZTitle.length ? webLink.node?.aZTitle[0] : webLink.node?.title, 
              isServiceOnlineForm: webLink.node.isServiceOnlineForm,
              url: webLink.node?.link?.url, 
              target: webLink.node?.link.target,
              shortDescription: webLink.node?.aZDescription ?? webLink.node?.metaDescription, 
              type: "weblink"
            }
          );
        }
        // add weblinks to results
        arrResults.push(
          {
            id: webLink.node.id, 
            name: webLink.node?.aZTitle.length ? webLink.node?.aZTitle[0] : webLink.node?.title, 
            isServiceOnlineForm: webLink.node.isServiceOnlineForm,
            url: webLink.node?.link?.url, 
            target: webLink.node?.link?.target,
            shortDescription: webLink.node?.aZDescription ?? webLink.node?.metaDescription, 
            type: "weblink"
          }
        );
        
        count++;
      })

      setCategories(serviceCategories);
      setLifeEvents(serviceLifeEvents);
      setPersonas(servicePersonas);
      setTopResults(arrTopResults);
      setResults(arrResults);
    }
  }, [searchResults]);
  
  // paging
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(10);
  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = results.length > 0 ? results.slice(indexOfFirstRecord, indexOfLastRecord) : [];
  const nPages = results.length > 0 ? Math.ceil(results.length / recordsPerPage) : 0

  const placeholderMessage = "How can we help you today?";

  return (
    <>
      <div>
        {
          loading ? (
            <Loading />
          ) : (
            error ? (
              "An error occurred."
            ) : (
              <div>
                <div className="container mx-auto max-w-oshMaxWidth">
                  <Popover className="grid grid-cols-1 lg:grid-cols-12 p-4 lg:p-0 gap-4">
                    <main className="lg:col-start-3 lg:col-span-8">
                      {/* <h1 className="mt-4">Search results</h1> */}
                      <div className="mb-4 lg:mb-0">
                        <label htmlFor="search" className="sr-only">Search</label>
                        <div className="flex lg:mt-10 mt-8">
                          <div className="flex w-full">
                            <input type="input" name="search" id="search"
                              className="block p-2 w-full border-goj-gray-light text-goj-charcoal rounded border pl-5 focus:border-goj-focus-dark focus:bg-goj-white focus:ring-goj-focus-light"
                              placeholder={searchTerm ? searchTerm : placeholderMessage }
                              aria-label="Search"
                              onChange={handleChange}
                              onFocus={(e) => e.target.placeholder = ""}
                              onBlur={(e) => e.target.placeholder = searchTerm ? searchTerm : placeholderMessage}
                              onKeyDown={e => e.key === 'Enter' && handleClick(e)} 
                              value={searchInput}
                            />
                          </div>
                          <button type="button" aria-label="Search button"
                            className="relative -ml-px inline-flex items-center space-x-2 border-transparent bg-goj-secondary px-4 py-2 text-goj-charcoal hover:bg-goj-secondary-light focus:bg-goj-focus-dark focus:outline-none focus:ring-1 focus:ring-goj-focus-light"
                            onClick={handleClick}
                          >
                            <svg className="h-6 w-6 text-goj-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                              fill="currentColor" aria-hidden="true">
                              <path fillRule="evenodd"
                                d="M9 3.5a5.5 5.5 0 100 11 5.5 5.5 0 000-11zM2 9a7 7 0 1112.452 4.391l3.328 3.329a.75.75 0 11-1.06 1.06l-3.329-3.328A7 7 0 012 9z"
                                clipRule="evenodd" />
                            </svg>
                          </button>
                        </div>
                      </div>
                      {topResults && topResults.length > 0 && 
                      <div className="lg:mt-4 border-l-4 border-goj-secondary bg-goj-secondary-very-light-tint pt-6 pl-6 pr-6 pb-2 mb-6">
                        <h3><strong>Top results</strong></h3>
                        {topResults && topResults.map((item) => (
                        <p key={item?.id}>
                          {
                            item.type === "weblink" ?
                              <>
                                <a id={`top-${item?.id}`} href={item?.url} className="hover:text-goj-secondary visited:text-got-secondary" target="_blank" rel="noopener">
                                  <strong className="text-goj-secondary">{item?.name}</strong>
                                </a>
                                <span className="text-goj-primary text-base">
                                {
                                  item?.isServiceOnlineForm ?
                                    <span className="flex text-goj-primary"><OpenNew fillColor="#B60011" classNames="mr-1 mt-1" /> Online service</span>
                                  :
                                    <span className="flex text-goj-gray-mid"><OpenNew fillColor="#404040" classNames="mr-1 mt-1" /> External link</span>
                                }
                                </span>
                              </>
                              :
                              <Link id={`top-${item?.id}`} to={item?.url} className="hover:text-goj-secondary visited:text-got-secondary">
                                <strong className="text-goj-secondary">{item?.name}</strong>
                              </Link>
                          }
                        </p>
                        ))}
                      </div>
                      }
                      <div className="lg:hidden mb-4">
                      { 
                        categories.length > 0 || lifeEvents.length > 0 || personas.length > 0 ? 
                        <>
                          <div>
                            <Popover.Button 
                              className="flex mt-4 w-full lg:w-fit justify-center rounded border border-goj-secondary bg-goj-secondary py-2 px-4 text-goj-white hover:border-goj-secondary-light hover:text-goj-secondary-light shadow-sm focus:outline-none focus:ring-2 focus:border-goj-focus-dark focus:ring-goj-focus-light">
                              Search filters <FilterIcon />
                            </Popover.Button>
                          </div>
                          <div>
                            {
                              filterActive ?
                                <button
                                  type="button"
                                  className="flex mt-4 w-full lg:w-fit justify-center rounded border border-goj-secondary bg-goj-white py-2 px-4 text-goj-secondary hover:border-goj-secondary-light hover:text-goj-secondary-light shadow-sm focus:outline-none focus:ring-2 focus:border-goj-focus-dark focus:ring-goj-focus-light"
                                  onClick={handleResetClick}
                                >
                                  Clear filters <FilterResetIcon />
                                </button>
                              : null
                            }
                          </div>
                        </>
                        :
                        null
                      }
                      </div>
                      <div>
                      {currentRecords && currentRecords.length > 0 ? 
                        currentRecords.map((item) => (
                        <p key={item?.url}> {logger.log("item",item)}
                          {
                            item.type === "weblink" ?
                              <>
                                <a id={item?.id} href={item?.url} className="hover:text-goj-secondary visited:text-got-secondary text-xl" target="_blank" rel="noopener">
                                  <strong className="text-goj-secondary">{item?.name}</strong>
                                </a>
                                <span className="line-clamp-2 text-goj-charcoal text-oshBase">{item?.shortDescription}</span>
                                <span className="text-goj-primary text-base">
                                {
                                  item?.isServiceOnlineForm ?
                                    <span className="flex text-goj-primary"><OpenNew fillColor="#B60011" classNames="mr-1 mt-1" /> Online service</span>
                                  :
                                    <span className="flex text-goj-gray-mid"><OpenNew fillColor="#404040" classNames="mr-1 mt-1" /> External link</span>
                                }
                                </span>
                              </>
                            :
                            <>
                              <Link id={item?.id} to={item?.url} className="hover:text-goj-secondary visited:text-got-secondary text-xl">
                                <strong className="text-goj-secondary">{item?.name}</strong>
                              </Link>
                              <br /><span className="line-clamp-2">{item?.shortDescription}</span>
                            </>
                          }
                        </p>
                        ))
                        :
                        <div> 
                          <p>There are no matches for your search.</p>
                          <p>Suggestions:</p>
                          <ul><li>make sure all words are spelt correctly</li><li>try different keywords</li><li>try more general keywords</li><li>try fewer keywords</li></ul>
                        </div>}
                      </div>
                      <PaginationComponent
                        nPages={nPages}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                      />
                    </main>
                    <aside className="hidden lg:flex lg:sticky lg:top-24 lg:min-h-[calc(100vh-428px)] px-2 lg:col-span-2 lg:my-4 my-2"> 
                      <fieldset className="lg:space-y-5">
                        <legend className="sr-only">Filters</legend>
                        <div className="flex lg:hidden mb-4">
                        { 
                          categories.length > 0 || lifeEvents.length > 0 || personas.length > 0 ? 
                          <button
                              type="button"
                              className="flex mt-4 w-full lg:w-fit justify-center rounded border border-goj-secondary bg-goj-white py-2 px-4 text-goj-secondary hover:border-goj-secondary-light hover:text-goj-secondary-light shadow-sm focus:outline-none focus:ring-2 focus:border-goj-focus-dark focus:ring-goj-focus-light"
                              onClick={handleToggleFiltersClick}
                            >
                              {toggleFilters ? "Hide filters" : "Show filters"}
                          </button>
                          :
                          null
                        }
                        </div>
                        <div className={toggleFilters ? "space-y-5 lg:block" : "lg:space-y-5 hidden lg:block"}>
                        {categories && categories.length > 0 && 
                          <strong className="text-goj-primary text-oshFilter">Filter by category</strong>}
                        {categories && categories.length > 0 && categories.map((item) => (
                          <div className="relative flex items-start" key={item.keyID}>
                            <div className="flex h-5 items-center">
                              <input
                                id={item.id}
                                aria-describedby={item.id}
                                name={item.id}
                                type="checkbox"
                                className="h-4 w-4 rounded border-goj-gray-light focus:ring-goj-focus-dark"
                                onChange={handleCategoryFilterChange}
                                checked={searchParams.getAll("c") && searchParams.getAll("c").includes(item.id) ? true : false}
                              />
                            </div>
                            <div className="ml-3">
                              <label htmlFor={item.id} className="text-oshFilter">
                                {item.name}
                              </label>
                            </div>
                          </div>
                        ))}

                        {lifeEvents && lifeEvents.length > 0 &&
                          <p><strong className="text-goj-primary text-oshFilter">Filter by life event</strong></p>}
                        {lifeEvents && lifeEvents.length > 0 && lifeEvents.map((item) => (
                          <div className="relative flex items-start" key={item.keyID}>
                            <div className="flex h-5 items-center">
                              <input
                                id={item.id}
                                aria-describedby={item.id}
                                name={item.id}
                                type="checkbox"
                                className="h-4 w-4 rounded border-goj-gray-light focus:ring-goj-focus-dark"
                                onChange={handleLifeEventFilterChange}
                                checked={searchParams.getAll("l") && searchParams.getAll("l").includes(item.id) ? true : false}
                              />
                            </div>
                            <div className="ml-3">
                              <label htmlFor={item.id} className="text-oshFilter">
                                {item.name}
                              </label>
                            </div>
                          </div>
                        ))}

                        {personas && personas.length > 0 &&
                          <p><strong className="text-goj-primary text-oshFilter">Filter by persona</strong></p>}
                        {personas && personas.length > 0 && personas.map((item) => (
                          <div className="relative flex items-start" key={item.keyID}>
                            <div className="flex h-5 items-center">
                              <input
                                id={item.id}
                                aria-describedby={item.id}
                                name={item.id}
                                type="checkbox"
                                className="h-4 w-4 rounded border-goj-gray-light focus:ring-goj-focus-dark"
                                onChange={handlePersonaFilterChange}
                                checked={searchParams.getAll("p") && searchParams.getAll("p").includes(item.id) ? true : false}
                              />
                            </div>
                            <div className="ml-3">
                              <label htmlFor={item.id} className="text-oshFilter">
                                {item.name}
                              </label>
                            </div>
                          </div>
                        ))}

                        { 
                          filterActive && (categories.length > 0 || lifeEvents.length > 0 || personas.length > 0) ? 
                          <button
                              type="button"
                              className="flex mt-4 w-full lg:w-fit justify-center rounded border border-goj-secondary bg-goj-white py-2 px-4 text-goj-secondary hover:border-goj-secondary-light hover:text-goj-secondary-light shadow-sm focus:outline-none focus:ring-2 focus:border-goj-focus-dark focus:ring-goj-focus-light"
                              onClick={handleResetClick}
                            >
                              Clear filters <FilterResetIcon />
                          </button>
                          :
                          null
                        }
                        </div>
                      </fieldset>
                    </aside>

                    {/* responsive filters */}
                    <Transition
                      as={Fragment}
                      enter="duration-500 ease-out"
                      enterFrom="-left-96"
                      enterTo="left-0"
                      leave="duration-200 ease-in"
                      leaveFrom="left-0"
                      leaveTo="-left-96"
                    >
                      <Popover.Panel focus className="absolute w-full inset-x-0 top-0 origin-left transform lg:hidden z-50 h-screen overflow-auto overflow-x-hidden">
                        <div className="divide-y-2 divide-goj-gray-mid bg-goj-gray-mid/95 shadow-lg ring-1 ring-goj-charcoal ring-opacity-5 min-h-[calc(100vh)]">
                          <div className="pt-5 pb-6">
                            <div className="flex items-center justify-start px-4">
                              <div className="-ml-1">
                                <Popover.Button className="text-goj-white flex">
                                  <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                  <span className="ml-1 mt-px">Close</span>
                                </Popover.Button>
                              </div>
                            </div>
                            <div className="mt-6">
                              <nav className="grid gap-y-8">
                                <fieldset className="">
                                  <legend className="sr-only">Filters</legend>
                                  <div className="w-full items-center text-goj-white visited:text-goj-white p-4 space-y-2">
                                    <div className="mb-1">
                                      {categories && categories.length > 0 && 
                                        <strong className="text-goj-white text-oshFilter block">Filter by category</strong>}
                                      {categories && categories.length > 0 && categories.map((item) => (
                                        <div className="relative flex items-start" key={item.keyID}>
                                          <div className="flex h-5 items-center">
                                            <input
                                              id={item.id}
                                              aria-describedby={item.id}
                                              name={item.id}
                                              type="checkbox"
                                              className="h-4 w-4 rounded-sm border-2 border-goj-gray-light focus:ring-goj-focus-dark bg-transparent"
                                              onChange={handleCategoryFilterChange}
                                              checked={searchParams.getAll("c") && searchParams.getAll("c").includes(item.id) ? true : false}
                                            />
                                          </div>
                                          <div className="ml-3">
                                            <label htmlFor={item.id} className="text-oshFilter">
                                              {item.name}
                                            </label>
                                          </div>
                                        </div>
                                      ))}
                                    </div>
                                    <div className="mb-1">
                                      {lifeEvents && lifeEvents.length > 0 &&
                                        <strong className="text-goj-white text-oshFilter block">Filter by life event</strong>}
                                      {lifeEvents && lifeEvents.length > 0 && lifeEvents.map((item) => (
                                        <div className="relative flex items-start" key={item.keyID}>
                                          <div className="flex h-5 items-center">
                                            <input
                                              id={item.id}
                                              aria-describedby={item.id}
                                              name={item.id}
                                              type="checkbox"
                                              className="h-4 w-4 rounded-sm border-2 border-goj-gray-light focus:ring-goj-focus-dark bg-transparent"
                                              onChange={handleLifeEventFilterChange}
                                              checked={searchParams.getAll("l") && searchParams.getAll("l").includes(item.id) ? true : false}
                                            />
                                          </div>
                                          <div className="ml-3">
                                            <label htmlFor={item.id} className="text-oshFilter">
                                              {item.name}
                                            </label>
                                          </div>
                                        </div>
                                      ))}
                                    </div>
                                    <div className="mb-1">
                                      {personas && personas.length > 0 &&
                                        <strong className="text-goj-white text-oshFilter block">Filter by persona</strong>}
                                      {personas && personas.length > 0 && personas.map((item) => (
                                        <div className="relative flex items-start" key={item.keyID}>
                                          <div className="flex h-5 items-center">
                                            <input
                                              id={item.id}
                                              aria-describedby={item.id}
                                              name={item.id}
                                              type="checkbox"
                                              className="h-4 w-4 rounded-sm border-2 border-goj-gray-light focus:ring-goj-focus-dark bg-transparent"
                                              onChange={handlePersonaFilterChange}
                                              checked={searchParams.getAll("p") && searchParams.getAll("p").includes(item.id) ? true : false}
                                            />
                                          </div>
                                          <div className="ml-3">
                                            <label htmlFor={item.id} className="text-oshFilter">
                                              {item.name}
                                            </label>
                                          </div>
                                        </div>
                                      ))}
                                    </div>
                                  </div>
                                </fieldset>
                              </nav>
                            </div>
                          </div>
                        </div>
                      </Popover.Panel>
                    </Transition>
                  </Popover>
                </div>
              </div>
            )
          )
        }
      </div>
    </>
  )
}