// src/hooks/useApiCall.js
import { useMsal } from '@azure/msal-react';
import { InteractionRequiredAuthError } from '@azure/msal-browser';
import { protectedResources, loginRequest } from '../authConfig';
 
const useApiCall = () => {
  const { instance } = useMsal();
 
  const callProtectedApi = async (endpoint, options = {}, scopes = []) => {
    try {
      // Determine the scopes to use: default scopes if none are provided, otherwise use the provided scopes
      const scopesToUse = scopes.length >  0 ? scopes : [...protectedResources.apiProfileFull.scopes, ...protectedResources.apiDashboardTaxRateEndpoint.scopes];
 
      // Get the currently active account
      let activeAccount = instance.getActiveAccount();
 
      const request = {
        scopes: scopesToUse,
        account: activeAccount, // Use the active account for the token request
      };
 
      let response;
      try {
        // Attempt to acquire token silently
        response = await instance.acquireTokenSilent(request);
      } catch (error) {
        if (error instanceof InteractionRequiredAuthError) {
          // If silent acquisition fails, fall back to interactive acquisition
          response = await instance.acquireTokenRedirect(request);
        } else {
          // If the error is not due to interaction being required, rethrow the error
          throw error;
        }
      }
 
      // Update the active account if it was not set or if it has changed
      if (!activeAccount || activeAccount.accountIdentifier !== response.account.accountIdentifier) {
        instance.setActiveAccount(response.account);
      }
 
      const apiResponse = await fetch(endpoint, {
        ...options,
        headers: {
          ...options.headers,
          Authorization: `Bearer ${response.accessToken}`,
        },
      });
 
      if (!apiResponse.ok) {
        throw new Error('API call failed');
      }
 
      return await apiResponse.json();
    } catch (err) {
      throw err; // Rethrow the error to be caught by the caller
    }
  };
 
  return callProtectedApi;
};
 
export default useApiCall;