import { useLocation, useNavigate } from 'react-router-dom';
import { useState, useEffect, useContext } from 'react';
import { useMsal, useAccount } from "@azure/msal-react";
import { deleteApiParamWithToken } from '../../../fetch';
import { BrowserAuthError, InteractionRequiredAuthError } from '@azure/msal-browser';
import { protectedResources } from '../../../authConfig';
import Toggle from "../../../components/Toggle";
import RemoveIcon from "../../../components/icons/RemoveIcon";
import Button from "../../../components/Button";

function scrollToTop() {
  window.scrollTo({top: 0, left: 0, behavior: 'instant'});
}

function randomNumberInRange(min, max) {
  // get number between min (inclusive) and max (inclusive)
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export default function SharingConsent(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const { instance, accounts, inProgress } = useMsal();
  const account = useAccount(instance.getActiveAccount() || {});
  const item = props?.item ? props.item : ''; 
  const tabular = props?.tabular ? true : false;
  const readOnly = props?.readOnly ? true : false;
  const childId = props?.child?.id ? props.child.id : '';
  const consentId = item?.consentId ? item.consentId : '';
  const appliesTo = item?.appliesTo? item.appliesTo : ''; //adult,child,all
  const consentField = item?.typeName ? item.typeName.toLowerCase().replace(/\s+/g, '') + "consent" : ''; //schoolconsent,activecardconsent,librarycardconsent
  const typeId = item?.typeId ? item.typeId : '';
  const typeName = item?.typeName ? item.typeName : ''; //school,activecard,librarycard
  const trimmedTypeName = typeName.toLowerCase().replace(/\s+/g, ''); //removes spaces
  const [organisationId, setOrganisationId] = useState(item?.organisationId ? item.organisationId : item?.instances.length === 1 ? item.instances[0].id : ''); // id of selected organisation
  const [enabled, setEnabled] = useState(item?.isGrantedOn ? true : false)
  const num = randomNumberInRange(1, 99);
  const [vehicles, setVehicles] = useState(props?.vehicles ?? []);
  const [displayDialog, setDisplayDialog] = useState(false);
  const [selectedVehicle, setSelectedVehicle] = useState(null);
  const [showSuccess, setShowSuccess] = useState(false)
  const [serviceError, setServiceError] = useState(false)
  const [displayError, setDisplayError] = useState(false)
  const [errorMessage, setErrorMessage] = useState({
    messageHeading: '',
    message: '',
  });

  const handleToggleSwitchChange = (value) => {
    setEnabled(value);
    let consent = {typeId:typeId,consentId:consentId,organisationId:organisationId,isGrantedOn:value,consentFor:appliesTo}
    props.onEnabledChange(consent,childId);
  };

  const handleChange = (e) => {
    setOrganisationId(e.target.value);
    let consent = {typeId:typeId,consentId:consentId,organisationId:e.target.value,isGrantedOn:enabled,consentFor:appliesTo}
    props.onEnabledChange(consent,childId);
  }

  function showDialog({e,display,item}) {
    e.preventDefault();
    if (display) {
      setDisplayDialog(display);
      setSelectedVehicle(item);
    }
    else {
      setDisplayDialog(false);
      setSelectedVehicle(null);
    }
  }

  function removeVehicle(response, param) {
    deleteApiParamWithToken(response.accessToken, protectedResources.apiProfileVehicles.endpoint, param)
    .then(response => {
      console.log("DELETE RESPONSE", response)
      if (response?.statusCode === 200) {
        setDisplayError(false);
        setVehicles((current) =>
          current.filter((item) => item.vehicleNumber !== param)
        );
        scrollToTop();
        //window.location.reload();
      }
      else {
        console.log("DELETE ERROR", response?.errordetails?.message);
        setShowSuccess(false);
        setServiceError(true);
        setErrorMessage((prevState) => ({
          ...prevState,
          messageHeading: "An error occurred",
          message: "We are unable to process your request at the moment, please try again later."
        }));
      }
    });
  }

  function handleRemoveVehicle(param) {
    instance.acquireTokenSilent({
      scopes: protectedResources.apiProfile.scopes,
      account: account,
      loginHint: instance.getActiveAccount()?.idTokenClaims.email
    }).then((response) => {
      console.log("REMOVE PARAM",param)
      removeVehicle(response, param);
      setDisplayDialog(false);
      setSelectedVehicle(null);
    }).catch((error) => {
      // in case if silent token acquisition fails, fallback to an interactive method
      if (error instanceof InteractionRequiredAuthError || BrowserAuthError) {
        if (account && inProgress === "none") {
          instance.acquireTokenRedirect({
            scopes: protectedResources.apiProfile.scopes,
            account: account
          }).then((response) => {
            removeVehicle(response, param);
          }).catch(error => {
            console.log(error);
            setServiceError(true);
              setErrorMessage((prevState) => ({
                ...prevState,
                messageHeading: "Service unavailable",
                message: "We are unable to service your request at the moment, please try again later."
              }));
          });
        }
      }
    });
  };

  function handleClickAdd(e) {
    e.preventDefault();
    let linkTextString = "Return to personal details";
    if (location.pathname.includes("account-settings")) { linkTextString = "Return to account settings" };
    if (location.pathname.includes("address-information")) { linkTextString = "Return to address details" };
    if (location.pathname.includes("contact-information")) { linkTextString = "Return to contact details" };
    navigate("/dashboard/your-vehicles/add-vehicle/", { state: { linkUrl:location.pathname, linkText:linkTextString } });
  };

  function DisplayVehicles({vehicles,enabled}) {
    console.log("vehicles ", vehicles)
    return (<div>
      <span className="block">
        {vehicles.map((vehicle) => {
          let vehicleNumber = vehicle.vehicleNumber
          let vehicleMake = vehicle.vehicleMake
          let vehicleModel = vehicle.vehicleModel
          return (
            <span key={vehicleNumber} className="flex space-x-4 pt-4">
              <span className="flex">{vehicleNumber} {vehicleMake} {vehicleModel}</span>
              <span className="flex">
                  <Button
                    onClick={(e) => showDialog({e, display:true, item:vehicle})} 
                    styles="flex text-goj-secondary hover:text-goj-secondary-light hover:underline visited:text-goj-secondary">
                    <RemoveIcon classNames="flex mr-1" /> <span className="flex">Remove vehicle</span>
                  </Button>
                </span>
            </span> 
          )
        })}
        <Button 
          onClick={(e) => handleClickAdd(e)} 
          styles={`${enabled ? 'flex': 'hidden'} w-full md:w-fit justify-center rounded text-oshBase font-semibold border border-transparent bg-goj-secondary mt-4 py-2 px-4 text-goj-white hover:text-goj-white hover:border-goj-secondary-light shadow-sm focus:outline-none focus:ring-2 focus:border-goj-focus-dark focus:ring-goj-focus-light`}>
          Add vehicle
        </Button>
      </span>
      <div className={displayDialog ? '' : 'hidden'}>
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <div className="relative transform overflow-hidden rounded-lg bg-goj-gray-light-super border border-goj-gray-light px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
              <p>Are you sure you wish to remove {selectedVehicle?.vehicleNumber}?</p>
              <div className="space-x-4">
                <button
                  type="submit"
                  className="w-full md:w-fit justify-center rounded text-oshBase font-semibold border border-goj-secondary bg-goj-secondary py-2 px-4 text-goj-white hover:text-goj-white hover:border-goj-secondary shadow-sm focus:outline-none focus:ring-2 focus:border-goj-focus-dark focus:ring-goj-focus-light"
                  onClick={() => handleRemoveVehicle(selectedVehicle?.vehicleNumber)}
                >
                  Yes
                </button>
                <button
                  type="submit"
                  className="w-full md:w-fit justify-center rounded text-oshBase font-semibold border border-goj-secondary bg-goj-white py-2 px-4 text-goj-secondary hover:text-goj-secondary-light hover:border-goj-secondary-light shadow-sm focus:outline-none focus:ring-2 focus:border-goj-focus-dark focus:ring-goj-focus-light"
                  onClick={(e) => showDialog({e, display:false})}
                >
                  No
                </button>
              </div>
            </div>
          </div>
        </div>
      </div></div>
    )
  }

  if (tabular) {
    return (
      <div className="mt-2 sm:mt-0 sm:border-t-0 border-b border-goj-gray-light">
        <dl className="sm:grid sm:grid-cols-2 pb-4">
          <dt className="items-center">
            <label htmlFor={consentField}>{item.introText}</label>
            {/* {consentField === "dvsconsent" ? 
              <span className="block">
                {vehicles.map((vehicle,index) => {
                    return (
                      <span key={index} className="block pt-4">Registration number</span>
                    )
                  })}
              </span>
              : null
            } */}
          </dt>
          <dd className="mt-1 sm:mt-0 inline-block items-center space-y-2">
            <span className="block lg:inline-block ml-0 lg:pl-4 py-2 lg:py-0">
              {consentField === "dvsconsent" ? 
                <>
                  <Toggle id={consentField} enabled={enabled} onEnabledChange={handleToggleSwitchChange} readOnly={vehicles.length ? true : false} />
                  <DisplayVehicles vehicles={vehicles} enabled={enabled} />
                </>
                : <Toggle id={consentField} enabled={enabled} onEnabledChange={handleToggleSwitchChange} readOnly={readOnly} />
              }
            </span>
            { item && item?.instances && item.instances.length > 1 ?
            <>
              <label htmlFor={trimmedTypeName+num} className="sr-only">
              <strong>{typeName}</strong>
              </label>
              <select
                id={trimmedTypeName+num}
                name={trimmedTypeName+num}
                autoComplete={trimmedTypeName+num}
                onChange={handleChange}
                value={organisationId}
                required={enabled}
                disabled={readOnly}
                className={`${enabled ? readOnly ? 'border-0 hover:cursor-not-allowed' : 'border' : 'hidden'} pl-2 lg:pl-2 lg:ml-4 rounded border-goj-gray-light focus:border-goj-focus-dark focus:ring-goj-focus-light w-fit text-lg`}
              >
                <option value="">Choose one</option>
                {item.instances.map((option) => {
                  return (
                    <option key={option.id} value={option.id}>{option.name}</option>
                  )
                })}
              </select>
            </>
            :
            <></>
            }
          </dd>
        </dl>
      </div>
    )
  }

  return (
    <>
      <div className="border-b border-goj-gray-light pb-4">
        <label htmlFor={consentField}>{item.introText}</label>
        { item && item?.instances && item.instances.length > 1 ?
        <>
          <label htmlFor={trimmedTypeName+num} className="sr-only">
            <strong>{typeName}</strong>
          </label>
          <select
            id={trimmedTypeName+num}
            name={trimmedTypeName+num}
            autoComplete={trimmedTypeName+num}
            onChange={handleChange}
            value={organisationId}
            required={enabled}
            disabled={readOnly}
            className={`${enabled ? readOnly ? 'border-0 hover:cursor-not-allowed' : 'border' : 'hidden'} pl-2 lg:ml-4 rounded border border-goj-gray-light focus:border-goj-focus-dark focus:ring-goj-focus-light w-fit text-lg`}
            // className="pl-2 lg:ml-4 rounded border border-goj-gray-light focus:border-goj-focus-dark focus:ring-goj-focus-light w-fit text-lg"
          >
            <option value="">Choose one</option>
            {item.instances.map((option) => {
              return (
                <option key={option.id} value={option.id}>{option.name}</option>
              )
            })}
          </select>
        </>
        :
        <></>
        }
        <span className="block lg:inline-block ml-0 lg:pl-4 py-2 lg:py-0 ">
          <Toggle 
            id={consentField+num}
            enabled={enabled}
            onEnabledChange={handleToggleSwitchChange}
            readOnly={readOnly} ></Toggle>
        </span>
      </div>
    </>
  )
}