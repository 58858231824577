import { useContext, useEffect, useState } from 'react';
import { callUmbracoApiGraphQL } from '../fetch';
import { Link } from 'react-router-dom';
import UmbracoContext from '../context/UmbracoContext';
import ServiceInteractionIcon from './icons/ServiceInteractionIcon';

const cmsPreview = process.env.REACT_APP_CMS_PREVIEW;

function ReplaceUrl(taxonomy, category, serviceUrl) {
  let url = "";
  if (taxonomy === "taxonomyCategoryItem") {
    url = serviceUrl.replace("/services", "/services/" + category);
  }
  if (taxonomy === "taxonomyLifeEventItem") {
    url = serviceUrl.replace("/services", "/life-events/" + category);
  }
  if (taxonomy === "taxonomyPersonaItem") {
    url = serviceUrl.replace("/services", "/personas/" + category);
  }

  return url;
}

function ServiceCards(props) {
  const services = props?.data;
  const taxonomy = props?.taxonomy;
  const category = props?.category;
  const cards = [];
  services?.forEach(item => {
    cards.push({
      id: item.node.id,
      url: ReplaceUrl(taxonomy, category, item.node.url),
      name: item.node.name,
      description: item.node?.shortDescription
    })
  });
  const cardsAscending = [...cards].sort((a, b) =>
    a.name > b.name ? 1 : -1,
  );
  //console.log("CARDS ARR: ", cardsAscending);

  return (
    <div className="p-4 lg:p-0">
      <div className="flex">
        <h2 className="text-goj-charcoal my-0 pb-6 flex-none">All services</h2>
      </div>
      <div className="grid gap-4 lg:grid-cols-2">
        {cardsAscending && cardsAscending.map((card) => {
          return (
            <div key={card?.id} className="items-center rounded border-goj-gray-light-super px-6 py-4 bg-goj-gray-light-super flex-col">
              <div className="flex justify-between">
                <Link to={card?.url} className="text-goj-secondary visited:text-goj-secondary text-xl font-semibold">{card?.name}</Link>
                <svg className={`w-8 h-8 md:ml-1 text-goj-secondary`} fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
                </svg>
              </div>
              <div className="mt-4">
                <p className="text-oshBase">{card?.description}</p>
              </div>
            </div>
            )
          }
        )}
      </div>
    </div>
  )
}

export default function TaxonomyListingContent(props) {
  const [numServicesDisplay, setNumServicesDisplay] = useState(0);
  const umbraco = useContext(UmbracoContext);
  const taxonomy = props?.data?.contentTypeAlias;
  const name = props?.data?.taxonomyTitle ?? props?.data?.name;
  const id = props?.data?._id; //console.log("ID",id);
  const category = props?.category; //console.log("Taxonomy Listing Props", props);
  const [data, setData] = useState(null);
  let filterField = "";
  if (taxonomy === "taxonomyCategoryItem") {
    filterField = "categoryTaxonomy_some";
    if (numServicesDisplay === 0
      && umbraco
      && umbraco?.umbracoConfig
      && umbraco.umbracoConfig[0]
      && umbraco.umbracoConfig[0]?.maxNumberOfServicesCategory > 0) {
      setNumServicesDisplay(umbraco.umbracoConfig[0]?.maxNumberOfServicesCategory);
    }
  }
  if (taxonomy === "taxonomyLifeEventItem") {
    filterField = "lifeEventsTaxonomy_some";
    if (numServicesDisplay === 0
      && umbraco
      && umbraco?.umbracoConfig
      && umbraco.umbracoConfig[0]
      && umbraco.umbracoConfig[0]?.maxNumberOfServicesLifeEvents > 0) {
      setNumServicesDisplay(umbraco.umbracoConfig[0]?.maxNumberOfServicesLifeEvents);
    }
  }
  if (taxonomy === "taxonomyPersonaItem") {
    filterField = "personaTaxonomy_some";
    if (numServicesDisplay === 0
      && umbraco
      && umbraco?.umbracoConfig
      && umbraco.umbracoConfig[0]
      && umbraco.umbracoConfig[0]?.maxNumberOfServicesCategory > 0) {
      setNumServicesDisplay(umbraco.umbracoConfig[0]?.maxNumberOfServicesCategory);
    }
  }
  const items = props?.data?.services?.length > numServicesDisplay ? props?.data?.services?.slice(0, numServicesDisplay) : props?.data?.services; //selected popular services
  var isPreview = "false";
  if (cmsPreview && cmsPreview === "true") {
    isPreview = "true"
  }
  var filterQuery = {
    query: "query ($id: ID) { allService(preview: " + isPreview + " where: {" + filterField + ": {id: $id}}) { edges { node { id name url shortDescription } } }}",
    variables: { id: id }
  }; //console.log("FQ: ", filterQuery);
  useEffect(() => {
    callUmbracoApiGraphQL(filterQuery)
      .then(response => {
        setData(response?.data?.allService?.edges); //console.log("FILTER QUERY RESULT: ", response?.data?.allService?.edges);
      })
      .catch((e) => {
        console.log("Error: ", e?.message);
      });
  }, [filterQuery]);

  return (
    <>
      <div className="h-40 text-goj-white bg-no-repeat bg-cover bg-center" style={{ backgroundImage: `url("${props?.data?.image?.media?.umbracoFile?.cropUrls?.wideTile}")` }}>
        <div className="w-full h-full bg-black bg-opacity-50">
          <div className="container mx-auto max-w-oshMaxWidth">
            <div className="lg:grid lg:grid-cols-12">
              <div className="lg:col-start-3 lg:col-end-11 flex pt-6 px-4 lg:px-0">
                <img src={props?.data?.taxonomyIcon?.src} className="w-10 h-10 mr-3" alt="" />
                <h1 className="text-goj-white">{name}</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container mx-auto max-w-oshMaxWidth">
        <div className="lg:grid lg:grid-cols-12">
          <div className="lg:col-start-3 lg:col-end-11">
            {items?.length > 0 &&
              <>
                <div className="flex pt-6 pl-4 lg:pl-0">
                  <h2 className="text-goj-charcoal my-0 flex-none">Popular services</h2>
                </div>
                <div className="grid-cols-4 mx-4 space-y-4 mb-8">
                  <div className="grid gap-2 grid-cols-2 lg:grid-cols-4">
                    {items.map((item) => {
                      return (
                        <Link key={item?._id} to={ReplaceUrl(taxonomy, category, item?._url)} className="text-goj-charcoal visited:text-goj-charcoal hover:text-goj-charcoal flex flex-col lg:h-full lg:p-4 p-2" 
                          target={item?._url.startsWith("http") ? "_blank" : ""} rel={item?._url.startsWith("http") ? "noopener" : ""}>
                          <span className="bg-goj-secondary rounded-full w-12 h-12 flex justify-center items-center mx-auto">
                          <ServiceInteractionIcon />
                          </span>
                          <span className="text-center pt-2 font-semibold pb-px text-goj-charcoal visited:text-goj-charcoal hover:text-goj-charcoal mx-auto leading-4">{item?.serviceName}</span>
                        </Link>
                      )
                    })}
                  </div>
                </div>
              </>
            }

            <ServiceCards data={data} taxonomy={taxonomy} name={name} id={id} category={category} />
          </div>
        </div>
      </div>
    </>

  )
}