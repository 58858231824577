import FeedbackWidget from '../features/Feedback/Components/FeedbackWidget';
import ReplaceUmbracoUrl from '../utils/ReplaceUmbracoUrl';
import WebLinks from './WebLinks';

export default function GeneralContent(props) {
  const parse = require('html-react-parser')
  const data = props.data;
  const mainHeading = data?.mainHeading;
  const mainContent = data?.mainContent;
  const hrefChildren = ReplaceUmbracoUrl(data?._links?.children?.href);

  return (
    <>
      <FeedbackWidget feedbackTitle={data?.feedbackTitle} feedbackDescription={data?.feedbackDescription} feedbackLink={data?.feedbackLink} />
      <h1>{mainHeading}</h1>
      <article>{mainContent ? parse(mainContent) : null}</article>
      {hrefChildren ? <WebLinks hrefChildren={hrefChildren} /> : null}
    </>
  )
}
