import { useState, useEffect } from "react";
import { useParams, useLocation } from 'react-router-dom';
import { Helmet } from "react-helmet";
import { callUmbracoApi } from "../fetch";
import useBreadcrumbs from 'use-react-router-breadcrumbs'
import Breadcrumb from "../components/Breadcrumb";
import GeneralContent from "../components/GeneralContent";
import Loading from "../components/Loading";

export default function General() {
  let { name } = useParams();
  const location = useLocation();
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  //CMS API: Get content by URL
  useEffect(() => {  
    if (!data) {
      let url = "";
      if (name) {
        url = "/content/url?url=/" + name;
      }
      else {
        url = "/content/url?url=" + location.pathname;
      }
      callUmbracoApi(url)
        .then(response => {
          setData(response);
          console.log("PAGE RESPONSE: ", response);
          setLoading(false);
          setError(null);
        })
        .catch((error) => {
          setError(error?.message);
          console.log("Error: ", error);
        });
    }
  }, []);

  //Breadcrumb
  const breadcrumbs = useBreadcrumbs();
  const pages = [];
  breadcrumbs.map((elem) => {
    if (elem.breadcrumb.props.children !== "Home") {
      pages.push({ name: elem.breadcrumb.props.children, href: elem.key + "/", current: false })
    }
  })

  return (
    <>
      {
        loading ? (
          <Loading />
        ) : (
          error ? (
            "An error occurred."
          ) : (
            <>
              <Helmet>
                <title>{!data ? '' : data.metaTitle}</title>
                <meta name="description" content={!data ? '' : data.metaDescription} />
                <meta name="keywords" content={!data ? '' : data.metaKeywords} />
              </Helmet>
              <Breadcrumb pages={pages} currentPage={location.pathname} />
              <div className="container mx-auto max-w-oshMaxWidth">
                <div className="grid grid-cols-1 lg:grid-cols-12 mt-4">
                  <aside className="lg:mr-2 lg:py-4 sticky top-16 h-0 lg:h-96 order-last lg:order-first lg:col-span-2 lg:-mt-3">
                    <div className="hidden lg:block">
                      <nav className="space-y-2" aria-label="Sidebar">
                      </nav>
                    </div>
                  </aside>
                  <main className="p-4 lg:p-0 order-first lg:order-last lg:col-span-8 ">
                    <GeneralContent data={data} />
                  </main>
                </div>
              </div>
            </>
          )
        )
      }
    </>
  )
}
