import { MsalAuthenticationTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { loginRequest } from "../authConfig";
import { useLocation } from "react-router-dom";
import Breadcrumb from "../components/Breadcrumb";
import useBreadcrumbs from 'use-react-router-breadcrumbs'
import ConfirmationMessage from "../components/ConfirmationMessage";
import { useAppInsightsContext, useTrackMetric } from "@microsoft/applicationinsights-react-js";
import { SeverityLevel } from '@microsoft/applicationinsights-web';

export default function Confirmation(props) {
  const authRequest = {
    ...loginRequest
  };
  const result = props?.result;
  console.log("Confirmation result:", result);
  
  // breadcrumb
  const location = useLocation();
  const breadcrumbs = useBreadcrumbs();
  const pages = []
  breadcrumbs.map((elem)=>{
    if(elem.breadcrumb.props.children !="Home"){
      pages.push({ name: elem.breadcrumb.props.children, href: elem.key + "/", current: false })
    }
  })

  // appInsights
  const appInsights = useAppInsightsContext();
  const trackComponent = useTrackMetric(appInsights, "Profile confirmation");
  appInsights?.trackTrace({ message: 'PublishingWeb_Confirmation page has been loaded for the user', severityLevel: SeverityLevel.Information });
  
  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authRequest}
    >
      <Breadcrumb pages={pages} currentPage={location.pathname} />
      {result === "success" ?
        <ConfirmationMessage
          message="Your change of email request has now been completed successfully."
          messageHeading="Email verification complete"
          messageLink="/dashboard/personal-details/"
          messageLinkText="Go back to personal details" />
        :
        <ConfirmationMessage
          message="Your change of email request was not successfull."
          messageHeading="Email verification invalid"
          messageLink="/dashboard/personal-details/"
          messageLinkText="Go back to personal details" />
      }
    </MsalAuthenticationTemplate>
  )
}