import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useMsal, useAccount } from "@azure/msal-react";
import { InteractionRequiredAuthError, BrowserAuthError, InteractionStatus} from "@azure/msal-browser";
import { protectedResources } from '../../../authConfig';
import parse from 'html-react-parser';
import Loading from '../../../components/Loading';
import { DigitalCard } from './DigitalCard';
import { callApiParamWithToken, callApiWithToken, deleteApiParamWithToken, updateApiWithToken } from '../../../fetch';
import FeedbackWidget from '../../Feedback/Components/FeedbackWidget';

export default function DisplayDigitalCardTypes(props) {
  // mock data
  //const mockData = JSON.parse('[{"id":"123","name":"Registration card","typeName":"SampleRegistrationCard","description":"This is a sample card using mock data for display purposes only","display":{"backgroundColor":"#B60011","description":"This is your Verifiable Credential","issuedBy":"Government of Jersey","textColor":"#FFFFFF","title":"Jersey resident","logo":{"description":"GOJ crest","uri":"https://media.umbraco.io/dev-government-of-jersey-heartcore-professional/sw3dnnyt/goj-crest-white.png"}}},{"id":"234","name":"Another card","typeName":"SampleAnotherCard","description":"Another sample card using mock data for further illustration","display":{"backgroundColor":"#FFFFFF","description":"This is your Verifiable Credential","issuedBy":"Government of Jersey","textColor":"#B60011","title":"Another card","logo":{"description":"GOJ crest","uri":"https://media.umbraco.io/dev-government-of-jersey-heartcore-professional/mrtg4glq/goj-crest-red.png"}}}]');
  //console.log("MOCK", mockData);

  // msal
  const { instance, accounts, inProgress } = useMsal();
  const account = useAccount(instance.getActiveAccount() || {});

  // context  //TODO: Consider if we need context for digital cards
  // const user = useContext(UserContext);
  // const codes = user.codes;
  // const fullProfile = user.fullProfile;

  // state
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [serviceError, setServiceError] = useState(false)
  const [displayError, setDisplayError] = useState(false)
  const [errorMessage, setErrorMessage] = useState({
    messageHeading: '',
    message: '',
  })

  // content
  const mainHeading = props?.content?.mainHeading ? props.content.mainHeading : "Digital cards";
  const mainContent = props?.content?.mainContent ? parse(props.content.mainContent) : "";

  //TODO: Call api and get digital card types
  function getDigitalCards(response) {
    //setData(mockData);
    callApiWithToken(response.accessToken, protectedResources.apiDigitalCardTypes.endpoint)
    .then(response => {
      console.log("DIGITAL CARD TYPES",response);
      if (response?.length > 0) {
        setData(response);
      }
      else {
        console.log(errorMessage);
        setData([]);
        setErrorMessage((prevState) => ({
          ...prevState,
          messageHeading: "Unable to retrieve cards",
          message: "Digital cards are currently unavailable."
        }));
      }
    })
  }

  // load existing data
  useEffect(() => {
    if (!data) {
      setLoading(true);
      if (account && inProgress === InteractionStatus.None) {
        instance.acquireTokenSilent({
          scopes: protectedResources.apiProfile.scopes,
          account: account,
          redirectUri: window.location.origin+"/auth.html",
          loginHint: instance.getActiveAccount()?.idTokenClaims.email
        }).then((response) => {
          getDigitalCards(response);
        }).catch((error) => {
          // in case if silent token acquisition fails, fallback to an interactive method
          if (error instanceof InteractionRequiredAuthError || BrowserAuthError) {
            if (account && inProgress === InteractionStatus.None) {
              instance.acquireTokenRedirect({
                scopes: protectedResources.apiProfile.scopes,
                account: account
              }).then((response) => {
                getDigitalCards(response);
              }).catch(error => {
                console.log("Error: ", error);
                setLoading(false);
                setData([]);
                setServiceError(true);
                setErrorMessage((prevState) => ({
                  ...prevState,
                  messageHeading: "Service unavailable",
                  message: "We are unable to service your request at the moment, please try again later."
                }));
              });
            }
          }
        });
      }
    }
    else {
      setLoading(false);
    }
  }, [data]);

  function RenderFeedbackWidget() {
    return (<FeedbackWidget feedbackTitle={props?.content?.feedbackTitle} feedbackDescription={props?.content?.feedbackDescription} feedbackLink={props?.content?.feedbackLink} />)
  }

  return (
    <>
      {loading ?
        <Loading /> :
        <>
          <RenderFeedbackWidget />
          <h1>{mainHeading ? mainHeading : ""}</h1>
          {mainContent ? mainContent : <></>}
          <div className="grid gap-4 lg:grid-cols-2 mx-auto my-4 w-full">
            {/* {mockData ? mockData.map((card) => {
              return (
                <DigitalCard key={card.id} card={card} />
              )
            }) : null} */}
            {data ? data.map((card) => {
              return (
                <DigitalCard key={card.id} card={card} />
              )
            }) : "There are no digital card types available to display."}
          </div>

        </>
      }
    </>
  )
}