import { useState, useEffect, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMsal, useAccount } from "@azure/msal-react";
import { protectedResources } from '../../../authConfig';
import { postApiWithToken } from '../../../fetch';
import { BrowserAuthError, InteractionRequiredAuthError } from '@azure/msal-browser';
import parse from 'html-react-parser';
import ErrorAlert from '../../../components/ErrorAlert';
import Loading from '../../../components/Loading';
import Confirmation from './Confirmation';
import UmbracoContext from '../../../context/UmbracoContext';
import Button from '../../../components/Button';
import LoadingButton from '../../../components/LoadingButton';
import UserContext from '../../../context/UserContext';
import FeedbackWidget from '../../Feedback/Components/FeedbackWidget';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function scrollToTop() {
  window.scrollTo({top: 0, left: 0, behavior: 'instant'});
}

export default function VehicleInformation(props) {
  // msal
  const { instance, accounts, inProgress } = useMsal();
  const account = useAccount(instance.getActiveAccount() || {});
  
  //context
  const user = useContext(UserContext);
  const umbraco = useContext(UmbracoContext);

  //state
  const navigate = useNavigate();
  const location = useLocation();
  const stateData = location.state; //console.log("STATE DATA",stateData)
  const [disableButton, setDisableButton] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [formSettings, setFormSettings] = useState(null);
  const [loading, setLoading] = useState(true);
  const [inputField, setInputField] = useState({
    registrationNumber: '',
    make: '',
    model: ''
  })
  const [showSuccess, setShowSuccess] = useState(false)
  const [serviceError, setServiceError] = useState(false)
  const [displayError, setDisplayError] = useState(false)
  const [displayErrorRegNumber, setDisplayErrorRegNumber] = useState(false)
  const [errorMessage, setErrorMessage] = useState({
    messageHeading: '',
    message: '',
  })
  const [errorMessageRegNumber, setErrorMessageRegNumber] = useState({
    messageHeading: '',
    message: ''
  });
  const [registrationNumberError, setRegistrationNumberError] = useState(false);
  const registrationNumberRegEx = new RegExp(/^J\d{1,6}$|^JSY\d{1,3}$/); //J123456,JSY123

  // load user
  useEffect(() => { 
    if (user?.fullProfile) {
      setLoading(false);
    }
    else {
      setLoading(true);
    }
  }, [user])
  
  // content
  const mainHeading = props?.content?.mainHeading ? props.content.mainHeading : "Add vehicle";
  const mainContent = props?.content?.mainContent ? parse(props.content.mainContent) : "";

  // load form settings
  useEffect(() => { 
    if (!umbraco) {
      setLoading(true);
    }
    else {
      setFormSettings(umbraco?.formSettings);
      setLoading(false);
    }
  }, [umbraco])

  useEffect(() => { 
    if (displayError || serviceError) {
      setLoadingButton(false);
      setDisableButton(false);
    }
  }, [displayError, serviceError])

  // add vehicle
  function addVehicle(response, payload) {
    console.log("ADD PAYLOAD", payload)
    postApiWithToken(response.accessToken, protectedResources.apiProfileVehicles.endpoint, payload)
      .then(response => {
        console.log("ADD RESPONSE", response)
        if (response?.statusCode === 200 || response?.statusCode === 201) {
          setShowSuccess(true);
          setDisplayError(false);
        }
        else if (response?.errordetails?.statusCode === 400 || response?.errordetails?.statusCode === 409) {
          setShowSuccess(false);
          setDisplayError(true);
          setLoadingButton(false);
          setDisableButton(false);
          setErrorMessage((prevState) => ({
            ...prevState,
            messageHeading: "Verification issue",
            message: response?.errordetails?.message
          }));
        }
        else {
          setShowSuccess(false);
          setDisplayError(true);
          setLoadingButton(false);
          setDisableButton(false);
          setErrorMessage((prevState) => ({
            ...prevState,
            messageHeading: "An error occurred",
            message: "We are unable to process your request at the moment, please try again later."
          }));
        }
      })
  }

  // events
  function handleFormSubmit(event) {
    event.preventDefault();
    scrollToTop();

    const { registrationNumber, make, model, vehicleDeclaration } = inputField
    if (!(registrationNumber)) {
      setDisplayError(true)
      setErrorMessage((prevState) => ({
        ...prevState,
        messageHeading: "Form verification error, please check and try again",
        message: "Ensure all mandatory details have been completed."
      }));
      return;
    }
    if (!registrationNumber.match(registrationNumberRegEx)) {
      setDisplayError(true)
      setErrorMessage((prevState) => ({
        ...prevState,
        messageHeading: "Form verification error, please check and try again",
        message: "The registration number is not valid."
      }));
      return;
    }
    if (!(vehicleDeclaration)) {
      setDisplayError(true)
      setErrorMessage((prevState) => ({
        ...prevState,
        messageHeading: "Form verification error, please check and try again",
        message: "You must complete the declaration."
      }));
      return;
    }

    setLoadingButton(true);
    setDisableButton(true);

    let payload = {
      vehicleNumber: registrationNumber,
      vehicleMake: make,
      vehicleModel: model,
      vehicleDeclaration: vehicleDeclaration ? true : false,
    };

    if (account && inProgress === "none") {
      instance.acquireTokenSilent({
        scopes: protectedResources.apiProfileVehicles.scopes,
        account: account,
        loginHint: instance.getActiveAccount()?.idTokenClaims.email
      }).then((response) => {
        addVehicle(response, payload);
      }).catch((error) => {
        // in case if silent token acquisition fails, fallback to an interactive method
        if (error instanceof InteractionRequiredAuthError || BrowserAuthError) {
          if (account && inProgress === "none") {
            instance.acquireTokenRedirect({
              scopes: protectedResources.apiProfileVehicles.scopes,
              account: account
            }).then((response) => {
              addVehicle(response, payload);
            }).catch(error => {
              console.log(error);
              setServiceError(true);
              setErrorMessage((prevState) => ({
                ...prevState,
                messageHeading: "Service unavailable",
                message: "We are unable to service your request at the moment, please try again later."
              }));
            });
          }
        }
      });
    }
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputField((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    if (name === "registrationNumber") {
      if (!value.match(registrationNumberRegEx)) {
        setRegistrationNumberError(true);
      }
      else {
        setRegistrationNumberError(false);
        setDisplayError(false);
      }
    }
  }

  
  const handleBlur = (e) => {
    const { name, value } = e.target;
    if (name === "registrationNumber") {
      if (!value.match(registrationNumberRegEx)) {
        setRegistrationNumberError(true);
        setDisplayErrorRegNumber(true)
        console.log("invalid reg number")
        setErrorMessageRegNumber((prevState) => ({
          ...prevState,
          messageHeading: "Form verification error, please check and try again",
          message: "The registration number is not valid."
        }));
      }
      else {
        setRegistrationNumberError(false);
        setDisplayErrorRegNumber(false)
        setDisplayError(false);
        console.log("valid reg number")
        setErrorMessageRegNumber({
          messageHeading: "",
          message: ""
        });
      }
    }
  }
  
  function prevPage() {
    navigate(-1);
  }

  function RenderFeedbackWidget() {
    return (<FeedbackWidget feedbackTitle={props?.content?.feedbackTitle} feedbackDescription={props?.content?.feedbackDescription} feedbackLink={props?.content?.feedbackLink} />)
  }

  if (loading) return <Loading />

  if (user?.fullProfile?.authenticationSource !== "L2") {
    return (
      <><h1>Your vehicles</h1><p>If you wish to provide details of your vehicles you will need to login with a verified account, for example with JerseyMe or Yoti.</p></>
    )
  }
  
  if (showSuccess) return (
    <>
      <RenderFeedbackWidget />
      <Confirmation
        message="We are now in the process of updating the Government departments and other organisations that you have selected in your data sharing preferences."
        message2=""
        messageHeading="Your new details have been received"
        messageLink={stateData?.linkUrl ?? "/dashboard/personal-details/"}
        messageLinkText={stateData?.linkText ?? "Return to personal details"}/>
    </>
  )

  return (
    <>
      <RenderFeedbackWidget />
      <h1>{mainHeading ? mainHeading : "Vehicle information"}</h1>
      {mainContent ? mainContent : <></>}
      <p>Fields marked <span className="text-goj-primary">*</span> are required</p>
      <form onSubmit={handleFormSubmit}>
        <div className="mt-4">
          <div>
            <label htmlFor="registrationNumber" className="text-oshLabel">
              <strong>Registration number</strong> <span className="text-goj-primary">*</span>
            </label>
            <div className="mt-1">
              <input
                id="registrationNumber"
                name="registrationNumber"
                type="text"
                placeholder="Enter the registration plate number"
                className={classNames(
                  registrationNumberError ? 'border-goj-primary border-2' : 'border-goj-gray-light border shadow-sm',
                  'block w-full rounded focus:border-goj-focus-dark focus:ring-goj-focus-light focus:ring-2'
                )}
                onChange={handleChange}
                onBlur={handleBlur}
                value={inputField.registrationNumber.toUpperCase()}
                required
              />
            </div>
            {(displayErrorRegNumber) ? (<div className="text-goj-primary flex text-oshAssistive mt-1 items-center">{errorMessageRegNumber.message}</div>) : null}
          </div>
        </div>
        <div className="mt-4">
          <div>
            <label htmlFor="make" className="text-oshLabel">
              <strong>Make</strong>
            </label>
            <div className="mt-1">
              <input
                id="make"
                name="make"
                type="text"
                placeholder="Enter the vehicle make"
                className="block w-full rounded border-goj-gray-light shadow-sm focus:border-goj-focus-dark focus:ring-goj-focus-light"
                onChange={handleChange}
                value={inputField.make}
              />
            </div>
          </div>
        </div>
        <div className="mt-4">
          <div>
            <label htmlFor="model" className="text-oshLabel">
              <strong>Model</strong>
            </label>
            <div className="mt-1">
              <input
                id="model"
                name="model"
                type="text"
                placeholder="Enter the vehicle model"
                className="block w-full rounded border-goj-gray-light shadow-sm focus:border-goj-focus-dark focus:ring-goj-focus-light"
                onChange={handleChange}
                value={inputField.model}
              />
            </div>
          </div>
        </div>

        <div className="mt-6">
          <h2>Data sharing settings</h2>
          <div className="border-t border-b border-goj-gray-light py-4">
            <span>Your address details will be shared with Driver and Vehicle Standards</span>
          </div>
        </div>

        <div className="mt-6">
          <div className="mt-6 border-l-4 text-left text-base p-4 border-goj-secondary bg-goj-secondary/5 space-y-3 rounded">
            <div className="relative flex items-start">
              <div className="flex h-5 items-center mt-1 py-3">
                <input
                  type="checkbox"
                  id="vehicleDeclaration"
                  aria-describedby="vehicleDeclaration"
                  name="vehicleDeclaration"
                  className="h-6 w-6 rounded border-goj-gray-light focus:ring-goj-focus-dark filter-input"
                  onClick={handleChange}
                  required
                />
              </div>
              <div className="ml-3">
                <label htmlFor="vehicleDeclaration" className="text-oshBase">
                  <span className="block mt-1">{formSettings && formSettings.length && formSettings[0]?.vehicleDeclaration ? formSettings[0].vehicleDeclaration : "I declare that the information provided is accurate"}</span>
                </label>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-4">
          {(displayError || serviceError) ? (<ErrorAlert messageHeading={errorMessage.messageHeading} message={errorMessage.message} />) : null}
        </div>

        <div className="mt-6">
          <div className="">
            <div className="flex justify-end flex-col md:flex-row">
              <button
                type="button"
                className="order-last mb-4 md:order-first md:mr-4 w-full md:w-fit justify-center rounded text-oshBase font-semibold border border-goj-secondary bg-goj-white py-2 px-4 text-goj-secondary hover:text-goj-secondary-light hover:border-goj-secondary-light shadow-sm focus:outline-none focus:ring-2 focus:border-goj-focus-dark focus:ring-goj-focus-light"
                onClick={prevPage}
              >
                Cancel
              </button>
              <Button disabled={disableButton} styles="order-first mb-4 md:order-last w-full md:w-fit justify-center rounded text-oshBase font-semibold border border-transparent bg-goj-secondary py-2 px-4 text-goj-white hover:bg-goj-secondary-light shadow-sm focus:outline-none focus:ring-2 focus:border-goj-focus-dark focus:ring-goj-focus-light">
                {loadingButton ? 
                  <>
                    <LoadingButton />
                  </> 
                  : 
                  <>Add vehicle</>
                }
              </Button>

            </div>
          </div>
        </div>
      </form>
    </>
  )
}