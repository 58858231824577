import ParseHtml from "../../../utils/ParseHtml";

export default function ParseNotifications(notifications) {
  notifications && notifications.length && notifications.forEach(item => {
    if (item.channelType.toString().toUpperCase() === "EMAIL") {
      item.contentDetails = ParseHtml(item.contentDetails)
    }
  });

  console.log("NOTIFICATIONS DATA", notifications)
  return notifications
}