import { useContext } from "react";
import Card from '../components/Card';
import DashboardContext from '../context/DashboardContext';
import TaxRateInfo from '../features/PersonalTaxRate/Components/TaxRateInfo';
import CareRecordInfo from '../features/CareRecord/Components/CareRecordInfo';
import LTIAInfo from "../features/IncapacityAllowance/Components/LTIAInfo";
import STIAInfo from "../features/IncapacityAllowance/Components/STIAInfo";
import { useEffect } from "react";
import { useState } from "react";

function ReplaceUrl(href) {
  let url = href;
  url = url.replace("/services/", "/dashboard/your-services/")
  return url;
}

export default function YourServicesCards() {
  const dashboard = useContext(DashboardContext); //console.log("DASHBOARD",dashboard)
  const services = dashboard.yourServices;
  const ltiaInfo = dashboard.ltiaInfo;
  const stiaInfo = dashboard.stiaInfo;
  const taxRateInfo = dashboard.taxRateInfo;
  const cardCareRecord = CareRecordInfo();
  let cardCareRecordAdded = false;
  const cardTaxRateInfo = TaxRateInfo(taxRateInfo);
  let cardTaxRateInfoAdded = false;
  const cardLtia = LTIAInfo(ltiaInfo);
  let cardLtiaAdded = false;
  const cardStia = STIAInfo(stiaInfo);
  let cardStiaAdded = false;
  let cardsYourServices = [];

  // for each service add a card
  services && services.length && services.forEach(service => {
    if (service?.serviceId && service?.serviceUrl && service?.serviceName) {
      let linkObj = []
      let obj = {
        id: service.serviceUrl,
        text: "Audit history",
        href: `${ReplaceUrl(service.serviceUrl)}`
      }
      linkObj.push(obj);
      let card = {
        id: service.serviceId,
        title: service.serviceName,
        description: service?.serviceShortdescription,
        link: linkObj,
        date: service?.serviceEventdate,
        attention: false
      };
      if (service.serviceName.toLowerCase() === 'long term incapacity allowance benefit' || service.serviceId.toUpperCase() === 'CLS-002') {
        if (cardLtia.display) {
          card.link = [...cardLtia.link,...card.link];
          card.date = cardLtia.date;
          card.attention = cardLtia.attention;
        }
        cardLtiaAdded = true;
      }
      if (service.serviceName.toLowerCase() === 'short term incapacity allowance benefit' || service.serviceId.toUpperCase() === 'CLS-003') {
        if (cardStia.display) {
          card.link = [...cardStia.link,...card.link];
          card.date = cardStia.date;
          card.attention = cardStia.attention;
        }
        cardStiaAdded = true;
      }
      if (service.serviceName.toLowerCase() === 'personal tax' || service.serviceId.toUpperCase() === 'SRV-07') {
        if (cardTaxRateInfo.display){
          card.link = [...cardTaxRateInfo.link,...card.link];
          card.description = cardTaxRateInfo.description;
          card.date = cardTaxRateInfo.date;
          card.attention = cardTaxRateInfo.attention;
        }
        cardTaxRateInfoAdded = true;
      }
      if (service.serviceName.toLowerCase() === 'jersey care record' || service.serviceId.toUpperCase() === 'SRV-36') { 
        if (cardCareRecord){
          card.date = cardCareRecord.date;
          card.attention = cardCareRecord.attention;
        }
        card.link = [
          {id: "jersey-care-record-details", text: "View Jersey Care Record details", href:"/dashboard/your-services/jersey-care-record/details/"},
          {id: "jersey-care-record-history", text: "Audit history", href:"/dashboard/your-services/jersey-care-record/"}
        ]
        cardCareRecordAdded = true;
      }
      cardsYourServices.push(card);
    }
  });

  // add tax info if we have it and it was not yet added
  if (!cardTaxRateInfoAdded && cardTaxRateInfo.display) {
    console.log("Tax card added");
    cardsYourServices.push(cardTaxRateInfo);
  }

  // add LTIA if we have it and was not yet added 
  if (!cardLtiaAdded && cardLtia.display) {
    console.log("LTIA card added");
    cardsYourServices.push(cardLtia);
  }

  // add STIA if we have it and was not yet added 
  if (!cardStiaAdded && cardStia.display) {
    console.log("STIA card added");
    cardsYourServices.push(cardStia);
  }

  // add care record if we have it and it was not yet added
  if (!cardCareRecordAdded && cardCareRecord) {
    cardsYourServices.push(cardCareRecord);
  }

  // sort the cards by date
  cardsYourServices.sort(function (a, b) {
    return new Date(b.date) - new Date(a.date);
  });

  // all cards on your services
  const dashboardYourservices = cardsYourServices.filter(function (el) { return el != null });

  return (
    <> 
      { 
        dashboardYourservices && dashboardYourservices.length ?
        <>
          <div className="grid gap-4 lg:grid-cols-2 mx-auto my-4 w-full">
          {
            dashboardYourservices.map((card) => {
              return (
                <Card key={card.id} heading={card.title} description={card.description} linkData={card.link} attention={card.attention} />
              )
            })
          }
          </div>
        </>
        : null
      }
    </>
  )
}