import { useState, useEffect, useContext } from 'react';
import { Accordion, AccordionBody, AccordionHeader, AccordionItem } from "react-headless-accordion";
import AZList from './AZList';
import UmbracoContext from '../../../context/UmbracoContext';

function ProcessData(letter, edges) { 
  let data = [];
  if (letter && edges && edges.length) {
    for (const edge of edges) {
      if(edge.node.aZTitle.length && edge.node.aZIndex.includes(letter)) {
        let obj = {
          id: edge.node.id,
          title: edge.node.aZTitle.find((str) => str.startsWith(letter)) || edge.node.aZTitle[0],
          description: edge.node.aZDescription,
          url: edge.node.link.url,
          target: edge.node.link.target
        };
        data.push(obj);
      }
    }
    data.sort((a, b) => a.title.localeCompare(b.title));
  }
  return (
    <AccordionItem key={letter}>
      {({ open }) => (
        <>
          <AccordionHeader className={`w-full flex justify-between items-center text-goj-charcoal border-b py-4 border-goj-gray-light text-left bg-goj-white ${open ? '' : ''}`}>
            <h2 className="mb-0">{letter}</h2>
            <svg className={`w-6 h-6 text-goj-charcoal ${!open ? '' : 'rotate-90'}`} fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
            </svg>
          </AccordionHeader>
          <AccordionBody className={`${open ? 'my-4' : ''}`}>
            <AZList data={data} /> 
          </AccordionBody>
        </>
      )}
    </AccordionItem>
  )
}

export default function AZAccordion() {
  const umbraco = useContext(UmbracoContext);
  let azWebLinks = umbraco?.azWebLinks ?? [];
  const [alphabet, setAlphabet] = useState([]);

  useEffect(() => {
    if (azWebLinks.length) {
      let letters = [];
      for (let i = 0; i < 26; i++) {
        const letter = String.fromCharCode(65 + i); // ASCII value for 'A' is 65
        const isValueExists = azWebLinks.some(subArray => subArray.node.aZIndex.includes(letter));
        if (isValueExists) {
          letters.push(letter); 
        }
      }
      setAlphabet(letters);
    }
  }, [azWebLinks]);
  
  return (
    <Accordion transition={{ duration: "300ms", timingFunction: "cubic-bezier(0, 0, 0.2, 1)" }} alwaysOpen="true">
      {alphabet.map((letter) => {
        return(ProcessData(letter, azWebLinks))
      })}
    </Accordion>
  )
}