export default function DataRow({name, value, plain, hideTerm, dtWidth = "w-1/6", ddWidth = "w-auto"}) {
  if (!name || !value) { return null }
  return (
    <div className="mt-2 lg:mt-0 lg:border-t-0 lg:border-b lg:border-goj-gray-light">
      <dl className="pb-2 lg:flex lg:w-full lg:py-4">
        <dt className={`flex items-top ${dtWidth}`}><span  className={hideTerm ? "hidden lg:flex" : "flex"}>{name}:</span></dt>
        <dd className={`mt-1 lg:mt-0 inline-block items-center space-y-2 break-words lg:px-4 ${ddWidth}`}>{plain ? value : <strong>{value}</strong>}</dd>
      </dl>
    </div>
  )
}