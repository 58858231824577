import { useState, useEffect, useContext } from 'react';
import UmbracoContext from '../../../context/UmbracoContext';

export default function AZMenu({ letter, onLetterClick }) {
  const umbraco = useContext(UmbracoContext);
  const [webLinks, setWebLinks] = useState(umbraco?.azWebLinks ?? [])
  const [alphabet, setAlphabet] = useState([]);
  const [selectedLetter, setSelectedLetter] = useState(letter);

  function handleClick(letter) {
    setSelectedLetter(letter);
    onLetterClick(letter);
  }

  useEffect(() => { 
    if (webLinks.length) {
      let letters = [];
      for (let i = 0; i < 26; i++) {
        const letter = String.fromCharCode(65 + i); // ASCII value for 'A' is 65
        const isLetterExists = webLinks.some(subArray => subArray.node.aZIndex.includes(letter));
        if (isLetterExists) {
          letters.push(letter); 
        }
      }
      setAlphabet(letters);
    }
  }, [webLinks]);

  return (
    <nav data-testid="az-menu" className="space-x-2 my-6">
      {alphabet.length && alphabet.map((letter) =>
        <button
          key={letter}
          className={`${letter === selectedLetter ? "bg-goj-primary text-goj-white w-12 h-12" : "text-goj-charcoal bg-goj-gray-very-light hover:bg-goj-gray-mid"} w-8 py-1 text-center align-middle font-semibold text-oshH2 rounded visited:text-goj-charcoal hover:text-goj-white hover:no-underline`}
          onClick={() => handleClick(letter)}
        >
          {letter}
        </button>
      )}
    </nav>
  );
}
