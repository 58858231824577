import { useEffect, useState } from "react"
import { callUmbracoApi } from "../fetch";
import PromotionServiceLink from "./PromotionServiceLink";
import ServiceInteractionIcon from "./icons/ServiceInteractionIcon";
import logger from "../utils/logger";

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function renderSwitch(param) {
  switch (param) {
    case 1: return "lg:grid-cols-1";
    case 2: return "lg:grid-cols-2";
    case 3: return "lg:grid-cols-3";
    case 4: return "lg:grid-cols-4";
    case 5: return "lg:grid-cols-5";
    case 6: return "lg:grid-cols-6";
    default: return "invisible";
  }
}

export default function PromotedServices(props) {
  let maxItems = props?.maxItems;
  if (maxItems > 6) { maxItems = 6 }; //hard limit for design
  const lgGridSize = renderSwitch(maxItems);
  const [promotedServices, setPromotedServices] = useState([]);
  useEffect(() => {
    if (promotedServices.length === 0 && maxItems) {
      const url = "/content/type?contentType=promotedServices&pageSize=" + maxItems; 
      callUmbracoApi(url)
        .then(response => { 
          logger.log("response: ", response);
          response?._embedded?.content?.sort(function (a, b) {
            return a.sortOrder - b.sortOrder;
          });
          setPromotedServices(response?._embedded?.content);
        })
        .catch((e) => {
          logger.log("ERROR: ", e);
        });
    }
  }, [promotedServices.length, maxItems]);

  return (
    <>
      {promotedServices && promotedServices.length > 0 ? 
        <div className="mx-auto grid lg:grid-cols-12">
          <div className={classNames('mx-auto grid grid-cols-2 gap-1 lg:gap-8 my-4 w-full lg:col-start-1 lg:col-end-13 text-base',lgGridSize)}>
            {promotedServices.map((item) => 
              <div key={item?._id} className="">
                {item?.promotedService?.url.startsWith("http") ?
                  <a href={item?.promotedService?.url} className="text-goj-charcoal visited:text-goj-charcoal hover:text-goj-charcoal flex flex-col lg:h-full lg:p-4 p-2" target="_blank" rel="noopener">
                   <span className="bg-goj-secondary rounded-full w-12 h-12 flex justify-center items-center mx-auto">
                    <ServiceInteractionIcon/>

                    </span>
                    <span className="text-center pt-2 font-semibold pb-px text-goj-charcoal visited:text-goj-charcoal hover:text-goj-charcoal mx-auto leading-4">
                    {item?.promotionTitle ? item?.promotionTitle : item?.name}
                    </span>
                  </a>
                  : 
                  <PromotionServiceLink url={item?.promotedService?.url} text={item?.promotionTitle ? item?.promotionTitle : item?.name} />
                }
              </div>
            )}
          </div>
        </div>
        : null
      }
    </>
  )
}