import { useEffect, useState } from "react";
import { MsalAuthenticationTemplate, useMsal, useAccount } from "@azure/msal-react";
import { InteractionRequiredAuthError, InteractionType, BrowserAuthError, InteractionStatus } from "@azure/msal-browser";
import { loginRequest, protectedResources } from "../authConfig";
import { callApiWithToken, callUmbracoApi } from "../fetch";
import { useAppInsightsContext, useTrackMetric, useTrackEvent } from "@microsoft/applicationinsights-react-js";
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { Helmet } from "react-helmet";
import { useLocation } from 'react-router-dom';
import Breadcrumb from "../components/Breadcrumb";
import ProfileData from "../components/ProfileData";
import ProfileAside from "../components/ProfileAside";
import useBreadcrumbs from 'use-react-router-breadcrumbs'
import Loading from "../components/Loading";
import ParseHtml from "../utils/ParseHtml";
import FeedbackWidget from "../features/Feedback/Components/FeedbackWidget";

function ProfileContent() {
  /**
 * useMsal is hook that returns the PublicClientApplication instance, 
 * an array of all accounts currently signed in and an inProgress value 
 * that tells you what msal is currently doing. For more, visit: 
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/hooks.md
 */
  const { instance, accounts, inProgress } = useMsal();
  const account = useAccount(instance.getActiveAccount() || {});
  const [profile, setProfile] = useState(null);
  const [loading, setLoading] = useState(true);

  function getCoreProfile(response) {
    callApiWithToken(response.accessToken, protectedResources.apiProfileCore.endpoint)
    .then(response => {
      setLoading(false)
      setProfile(response)
      console.log("Core Profile",response)
    });
  }

  useEffect(() => {
    if (account && inProgress === InteractionStatus.None && !profile) {
      instance.acquireTokenSilent({
        scopes: protectedResources.apiProfileCore.scopes,
        account: account,
        loginHint: instance.getActiveAccount()?.idTokenClaims.email
      }).then((response) => {
        getCoreProfile(response);
      }).catch((error) => {
        // in case if silent token acquisition fails, fallback to an interactive method
        if (error instanceof InteractionRequiredAuthError || BrowserAuthError) {
          if (account && inProgress === InteractionStatus.None) {
            instance.acquireTokenRedirect({
              scopes: protectedResources.apiProfileCore.scopes,
              account: account
            }).then((response) => {
              getCoreProfile(response);
            }).catch(error => {
              setLoading(false)
              console.log(error)
            });
          }
        }
      });
    }
  }, [account, inProgress, instance]);

  return (
    <>
      {(loading) ? <Loading /> : null}
      {profile ? <ProfileData profile={profile} /> : null}
    </>
  );
};

/**
 * The `MsalAuthenticationTemplate` component will render its children if a user is authenticated 
 * or attempt to sign a user in. Just provide it with the interaction type you would like to use 
 * (redirect or popup) and optionally a [request object](https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/request-response-object.md)
 * to be passed to the login API, a component to display while authentication is in progress or a component to display if an error occurs. For more, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
**/
function Profile() {
  const authRequest = {
    ...loginRequest
  };

  // appInsights
  const appInsights = useAppInsightsContext();
  const trackComponent = useTrackMetric(appInsights, "Profile");
  appInsights?.trackTrace({ message: 'PublishingWeb_Profile page has been clicked by the user', severityLevel: SeverityLevel.Information });

  const location = useLocation();
  const breadcrumbs = useBreadcrumbs();
  const pages = []
  breadcrumbs.map((elem) => {
    if (elem.breadcrumb.props.children !== "Home") {
      pages.push({ name: elem.breadcrumb.props.children, href: elem.key + "/", current: false })
    }
  })

  // fetch page content from cms
  const [data, setData] = useState(null);
  useEffect(() => {
    const fetchData = async () => {
      try {
        let url = "/content/url?url=/dashboard/personal-details/";
        const response = await callUmbracoApi(url);
        setData(response);
      } catch (e) {
        console.log("Error: ", e?.message);
      }
    };
    if (!data) {
      fetchData();
    }
  }, [data]);

  function RenderFeedbackWidget() {
    return (<FeedbackWidget feedbackTitle={data?.feedbackTitle} feedbackDescription={data?.feedbackDescription} feedbackLink={data?.feedbackLink} />)
  }
  

  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authRequest}
    >
      <Helmet>
        <title>{!data ? '' : data.metaTitle}</title>
        <meta name="description" content={!data ? '' : data.metaDescription} />
        <meta name="keywords" content={!data ? '' : data.metaKeywords} />
      </Helmet>
      <Breadcrumb pages={pages} currentPage={location.pathname} />
      <div className="container mx-auto max-w-oshMaxWidth">
        <div className="grid grid-cols-1 lg:grid-cols-12 mt-4">
          <ProfileAside />
          <main className="p-4 lg:p-0 order-first lg:order-last lg:col-span-8 ">
            <RenderFeedbackWidget />
            <h1>{data?.mainHeading ?? `Personal details`}</h1>
            {ParseHtml(data?.mainContent)}
            <ProfileContent />
          </main>
        </div>
      </div>
    </MsalAuthenticationTemplate>
  )
};

export default Profile;