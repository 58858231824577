import { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import Card from '../components/Card';
import DashboardContext from "../context/DashboardContext";
import TaxRateInfo from "../features/PersonalTaxRate/Components/TaxRateInfo";
import CareRecordInfo from "../features/CareRecord/Components/CareRecordInfo";
import NotificationsCards from "../features/Notifications/Components/NotificationsCards";
import LTIAInfo from "../features/IncapacityAllowance/Components/LTIAInfo";
import STIAInfo from "../features/IncapacityAllowance/Components/STIAInfo";

function ReplaceUrl(href) {
  let url = href;
  url = url.replace("/services/", "/dashboard/your-services/")
  return url;
}

export default function DashboardCards() {
  const dashboard = useContext(DashboardContext);
  const maxAttention = 3;
  const maxYourServices = 2;
  const services = dashboard.yourServices;
  const ltiaInfo = dashboard.ltiaInfo;
  const stiaInfo = dashboard.stiaInfo;
  const taxRateInfo = dashboard.taxRateInfo;
  const cardCareRecord = CareRecordInfo();
  let cardCareRecordAdded = false;
  const cardTaxRateInfo = TaxRateInfo(taxRateInfo);
  let cardTaxRateInfoAdded = false;
  const cardLtia = LTIAInfo(ltiaInfo);
  let cardLtiaAdded = false;
  const cardStia = STIAInfo(stiaInfo);
  let cardStiaAdded = false;
  let cardsYourServices = [];
  let cardsAttention = [];

  const navigate = useNavigate();
  function navigateToServices() {
    navigate("/dashboard/your-services/");
  }

  // for each service add a card
  services && services.length && services.forEach(service => {
    if (service?.serviceId && service?.serviceUrl && service?.serviceName) {
      let cardAttention = false;
      let linkObj = []
      let obj = {
        id: service.serviceUrl,
        text: "Audit history",
        href: `${ReplaceUrl(service.serviceUrl)}`
      }
      linkObj.push(obj);
      let card = {
        id: service.serviceId,
        title: service.serviceName,
        description: service?.serviceShortdescription,
        link: linkObj,
        date: service?.serviceEventdate,
        attention: false
      };
      if (service.serviceName.toLowerCase() === 'long term incapacity allowance benefit' || service.serviceId.toUpperCase() === 'CLS-002') {
        if (cardLtia.display) {
          card.link = [...cardLtia.link,...card.link];
          card.date = cardLtia.date;
          if (cardLtia.attention) {
            cardsAttention.push(card);
            cardAttention = true;
          }
        }
        cardLtiaAdded = true;
      }
      if (service.serviceName.toLowerCase() === 'short term incapacity allowance benefit' || service.serviceId.toUpperCase() === 'CLS-003') {
        if (cardStia.display) {
          card.link = [...cardStia.link,...card.link];
          card.date = cardStia.date;
          if (cardStia.attention) {
            cardsAttention.push(card);
            cardAttention = true;
          }
        }
        cardStiaAdded = true;
      }
      if (service.serviceName.toLowerCase() === 'personal tax' || service.serviceId.toUpperCase() === 'SRV-07') {
        if (cardTaxRateInfo.display){
          card.description = cardTaxRateInfo.description;
          card.link = [...cardTaxRateInfo.link,...card.link];
          card.date = cardTaxRateInfo.date;
          if (cardTaxRateInfo.attention) {
            cardsAttention.push(card);
            cardAttention = true;
          }
        }
        cardTaxRateInfoAdded = true;
      }
      if (service.serviceName.toLowerCase() === 'jersey care record' || service.serviceId.toUpperCase() === 'SRV-36') { 
        if (cardCareRecord){
          card.date = cardCareRecord.date;
          if (cardCareRecord.attention) {
            cardsAttention.push(card);
            cardAttention = true;
          }
        }
        card.link = [
          {id: "jersey-care-record-details", text: "View Jersey Care Record details", href:"/dashboard/your-services/jersey-care-record/details/"},
          {id: "jersey-care-record-history", text: "Audit history", href:"/dashboard/your-services/jersey-care-record/"}
        ]
        cardCareRecordAdded = true;
      }
      // push to your services if not already pushed for attention
      if (!cardAttention) cardsYourServices.push(card)
    }
    
  });

  // add tax info if we have it and it was not yet added
  if (!cardTaxRateInfoAdded && cardTaxRateInfo.display) {
    console.log("Tax card added");
    cardTaxRateInfo.attention ? cardsAttention.push(cardTaxRateInfo) : cardsYourServices.push(cardTaxRateInfo);
  }

  // add LTIA if we have it and was not yet added 
  if (!cardLtiaAdded && cardLtia.display) {
    console.log("LTIA card added");
    cardLtia.attention ? cardsAttention.push(cardLtia) : cardsYourServices.push(cardLtia);
  }

  // add STIA if we have it and was not yet added 
  if (!cardStiaAdded && cardStia.display) {
    console.log("STIA card added");
    cardStia.attention ? cardsAttention.push(cardStia) : cardsYourServices.push(cardStia);
  }

  // add care record if we have it and it was not yet added
  if (!cardCareRecordAdded && cardCareRecord) {
    cardCareRecord.attention ? cardsAttention.push(cardCareRecord) : cardsYourServices.push(cardCareRecord);
  }
 
  // sort the cards by date
  cardsAttention.sort(function (a, b) {
    return new Date(b.date) - new Date(a.date);
  });
  cardsYourServices.sort(function (a, b) {
    return new Date(b.date) - new Date(a.date);
  });

  //console.log("Your services: ",cardsYourServices)

  // limit amount to show on dashboard
  cardsAttention = cardsAttention.filter(function (el) { return el != null }); //console.log("ATTENTION CARDS",cardsAttention)
  const dashboardPriority = cardsAttention.slice(0,1); //console.log("PRIORITY",dashboardPriority)
  const dashboardAttention = cardsAttention.slice(1,maxAttention +  1);
  const dashboardYourservices = cardsAttention.slice(maxAttention + 1, maxYourServices + 1);
  if (dashboardYourservices.length < maxYourServices) {
    dashboardYourservices.push(...cardsYourServices.filter(function (el) { return el != null }).slice(0, maxYourServices - dashboardYourservices.length));
  }

  return (
    <>
      {
        dashboardPriority && dashboardPriority.length ? 
        <>
          <div className="rounded bg-goj-secondary-very-light-tint p-5 my-4 border-l-4 border-goj-secondary">
            <div className="flex">
              <div className="">
                <div className="flex items-center">
                  <h3>{dashboardPriority[0].title}</h3>
                </div>
                {dashboardPriority[0].description ?
                <div className="text-base">
                  {dashboardPriority[0].description}
                </div>
                : null
                }
                <div className="my-2">
                  <div className="mt-4 flex">
                    <Link to={dashboardPriority[0].link[0].href} 
                      className="w-fit justify-center rounded-lg border border-transparent bg-goj-secondary py-2 px-4 hover:bg-goj-secondary-light text-goj-white shadow-sm hover:text-goj-white focus:outline-none focus:ring-2 focus:border-goj-focus-dark focus:ring-goj-focus-light">
                      <span className="text-goj-white">{dashboardPriority[0].link[0].text}</span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
        : null
      }
      {
        dashboardAttention && dashboardAttention.length ? 
        <>
          <div className="grid gap-4 lg:grid-cols-2 mx-auto my-4 w-full">
          {
            dashboardAttention.map((card) => {
              return (
                <Card key={card.id} heading={card.title} description={card.description} linkData={card.link} attention={card.attention} />
              )
            })
          }
          </div>
        </>
        : null
      }
      {
        dashboard.yourServices.length && cardsAttention.length ?               
          <div className="container mx-0 min-w-full flex flex-col items-end mb-4">
            <button
              type="button"
              className="w-full md:w-fit justify-center rounded text-oshBase font-semibold border border-goj-secondary bg-goj-white py-2 px-4 text-goj-secondary hover:text-goj-secondary-light hover:border-goj-secondary-light shadow-sm focus:outline-none focus:ring-2 focus:border-goj-focus-dark focus:ring-goj-focus-light"
              onClick={navigateToServices}
            >
              View all services
            </button>
          </div> 
        : null
      }
      <div>
        <NotificationsCards />
      </div>
      {
        dashboardYourservices && dashboardYourservices.length ?
        <div className="mt-8">
          <h3>Your services</h3>
          <div className="grid gap-4 lg:grid-cols-2 mx-auto my-4 w-full">
          {
            dashboardYourservices.map((card) => {
              return (
                <Card key={card.id} heading={card.title} description={card.description} linkData={card.link} attention={card.attention} />
              )
            })
          }
          </div>
        </div>
        : null
      }
      {
        dashboard.yourServices.length ?               
          <div className="container mx-0 min-w-full flex flex-col items-end">
            <button
              type="button"
              className="w-full md:w-fit justify-center rounded text-oshBase font-semibold border border-goj-secondary bg-goj-white py-2 px-4 text-goj-secondary hover:text-goj-secondary-light hover:border-goj-secondary-light shadow-sm focus:outline-none focus:ring-2 focus:border-goj-focus-dark focus:ring-goj-focus-light"
              onClick={navigateToServices}
            >
              View all services
            </button>
          </div> 
        : null
      }
      {
        dashboardPriority.length || dashboardAttention.length || dashboardYourservices.length || dashboard.yourServices.length || services.length || cardCareRecordAdded || cardTaxRateInfoAdded ? 
          null
        : <p>You do not have any service interactions with the Government of Jersey to display on this page.</p>
      }
    </>
  )
}