import PhoneIcon from "./icons/PhoneIcon";
import MailIcon from "./icons/MailIcon";
import InteractionLink from "../utils/InteractionLink";

export default function ContactCallToAction({name,phone,email}) {
  return (
    <>
    {name ? <div className="flex"><p><strong className="text-xl">{name}</strong></p></div> : null}
    {phone ? 
      <div className="flex gap-1 items-center text-goj-secondary">
        <PhoneIcon />
        <InteractionLink interaction={"tel"} label={phone} contact={phone} />
      </div>
      : null}
    {email ? 
      <div className="flex gap-1 items-center text-goj-secondary">
        <MailIcon />
        <InteractionLink interaction={"mailto"} label={email} contact={email} />
      </div>
      : null}
    </>
  )
}