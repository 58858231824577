import { useSwipeable } from 'react-swipeable';
import { ArrowLeftIcon, ArrowRightIcon } from '@heroicons/react/24/outline';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import EllipseIcon from '../../../components/icons/EllipseIcon';
import PlayIcon from '../../../components/icons/PlayIcon';
import PauseIcon from '../../../components/icons/PauseIcon';

export default function Carousel({ carouselData, userData }) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [intervalId, setIntervalId] = useState(null);
  const [isPaused, setIsPaused] = useState(false);
  const isMobile = useMediaQuery({ maxWidth: 768 });

  const handlers = useSwipeable({
    onSwipedLeft: () => {
      goToNextSlide();
      setIsPaused(true);
      stopCarousel();
    },
    onSwipedRight: () => {
      goToPreviousSlide();
      setIsPaused(true);
      stopCarousel();
    }
  });

  useEffect(() => {
    if (isPaused) {
      stopCarousel();
    } else {
      startCarousel();
    }
  }, [isPaused]);

  const startCarousel = () => {
    const id = setInterval(() => {
      goToNextSlide();
    }, 7000);

    setIntervalId(id);
  };

  const stopCarousel = () => {
    clearInterval(intervalId);
  };

  const togglePause = () => {
    setIsPaused((prevIsPaused) => !prevIsPaused);
  };

  const goToSlide = (index) => {
    setCurrentIndex(index);
    setIsPaused(true);
    stopCarousel();
  };

  const goToNextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % carouselData.length);
  };

  const goToPreviousSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? carouselData.length - 1 : prevIndex - 1
    );
  };

  function parseContentTokens(content) {
    if (content.toLowerCase().includes("{{username}}")) {
      content = content.replace("{{username}}", userData?.givenName)
    }
    if (content.toLowerCase().includes("{{notifications}}")) {
      content = content.replace("{{notifications}}", userData?.unreadNotifications)
    }
    return content;
  }

  function renderLink({ callToAction, foreground }) {
    return (
      <Link
        to={callToAction?.url}
        className={`w-full items-center justify-center border border-${foreground} text-oshBase font-semibold bg-transparent px-6 py-2 rounded shadow-sm text-${foreground} hover:text-${foreground} visted:text-${foreground}`}>
        <span className={`text-${foreground}`}>{callToAction?.name}</span>
      </Link>
    )
  }

  function renderAnchor({ callToAction, foreground }) {
    return (
      <a
        href={callToAction?.url}
        className={`w-full items-center justify-center border border-${foreground} text-oshBase font-semibold bg-transparent px-6 py-2 rounded shadow-sm text-${foreground} hover:text-${foreground} visted:text-${foreground}`}
        target='_blank'
        rel="noopener noreferrer">
        <span className={`text-${foreground}`}>{callToAction?.name}</span>
      </a>
    )
  }

  function CarouselControls() {
    return (
       <div className="absolute flex mb-2 mt-auto pt-4 space-x-2 left-1/2 bottom-8 -translate-x-1/2">
         {carouselData.map((item, index) => (
           <button
             key={item._id}
             onClick={() => goToSlide(index)}
             className={index === currentIndex ? 'active' : ''}
           >
             <span className="sr-only">Slide {index}</span>
             <EllipseIcon opacity={index === currentIndex ? "1.0" : "0.5"} />
           </button>
         ))}
         <button onClick={goToPreviousSlide}><span className="sr-only">Previous</span><ArrowLeftIcon className='h-4 hidden' /></button>
         <button onClick={togglePause}><span className="sr-only">{isPaused ? "Play" : "Pause"}</span>{isPaused ? <PlayIcon className='h-4' /> : <PauseIcon className='h-4' />}</button>
         <button onClick={goToNextSlide}><span className="sr-only">Next</span><ArrowRightIcon className='h-4 hidden' /></button>
       </div>
    )
   }

  return (
    <div className="hero relative h-80 overflow-hidden" {...handlers}>
      {carouselData.map((item, index) => (
        <div
          key={index}
          className={`absolute transition-transform duration-500 h-full w-full bg-goj-primary bg-no-repeat bg-cover bg-center ease-in-out ${index === currentIndex ? "translate-x-0" : "translate-x-full"}`}
          style={{
            backgroundImage: `url(${isMobile ? item?.carouselImage?.media?.umbracoFile?.cropUrls?.mobileHeader : item?.carouselImage?.media?.umbracoFile?.cropUrls?.header ?? item?.carouselImage?.src})`,
            transform: `translateX(${(index - currentIndex) * 100}%)`
          }}
        >
          <div className={`container flex flex-col h-full max-w-oshMaxWidth mx-auto px-4 py-8 text-${item?.carouselForeground?.label} hover:text-${item?.carouselForeground?.label}`}>
            <h1 className={`truncate text-${item?.carouselForeground?.label}`}>{parseContentTokens(item?.carouselHeading)}</h1>
            <div>
              {item?.carouselMessage && item?.carouselMessage.length ?
                item?.carouselMessage.map((message, index) => {
                  return (<div className={`truncate text-${item?.carouselForeground?.label}`} key={index}>{parseContentTokens(message)}</div>)
                })
                : null
              }
            </div>
            {item?.carouselCallToAction !== null && item?.carouselCallToAction?.type !== null ?
              <p className='mt-8 space-x-4'>
                {/*console.info("DEBUG item",item)*/}
                {item?.carouselCallToAction?.type === "EXTERNAL" && item?.carouselCallToAction?.url?.startsWith('http') ? renderAnchor({ callToAction: item?.carouselCallToAction, foreground: item?.carouselForeground?.label }) : renderLink({ callToAction: item?.carouselCallToAction, foreground: item?.carouselForeground?.label })}
              </p>
              : null
            }
          </div>
        </div>
      ))}
      <CarouselControls />
    </div>
  );
};
