export default function AZList({ data }) {
  return (
    <div data-testid="az-list" className="space-y-4">
    {
    data && data.length ?  
    data.map(({id, title, description, url, target}) => {
      return (
        <div key={id}>
          <a href={url} target={target} className="text-xl">{title}</a><br/>
          {description}
        </div>
      )
    })
    : 
    <div>There are no results for this selection</div> 
    }
    </div>
  )
}