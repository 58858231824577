import { useEffect } from 'react';
const govMetric = process.env.REACT_APP_GOVMETRIC;
export default function GovMetric() {
  useEffect(() => {
    const script1 = document.createElement('script');
    script1.src = 'https://websurveys2.govmetric.com/js/client/gm_sidebar.js';
    script1.async = true;
    document.body.appendChild(script1);
    const script2 = document.createElement('script');
    script2.src = 'https://hitcounter.govmetric.com/1575';
    script2.async = true;
    document.body.appendChild(script2);

    return () => {
      // Clean up the script when the component unmounts
      document.body.removeChild(script1);
      document.body.removeChild(script2);
    };
  }, []);

  return (
    <>
      <div className="gm_sidebar gm_sidebar_cnr">
        <a
          href={`https://websurveys2.govmetric.com/theme/gm/${govMetric}`}
          target="_blank"
          className="gm_sidebar_anchor"
          title="feedback"
          rel="nofollow noreferrer">
          <img src="https://websurveys2.govmetric.com/imgs/triggers/cnr.png" alt="rating button" />
        </a>
      </div>
      <link rel="stylesheet" type="text/css" href="https://websurveys2.govmetric.com/css/client/gm_sidebar_cnr.css" />
    </>
  );
};
