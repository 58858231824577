import { useState, useEffect } from "react";
import { MsalAuthenticationTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { loginRequest } from "../authConfig";
import { useLocation } from 'react-router-dom';
import { Helmet } from "react-helmet";
import { callUmbracoApi } from "../fetch";
import useBreadcrumbs from 'use-react-router-breadcrumbs'
import Breadcrumb from "../components/Breadcrumb";
import ProfileAside from "../components/ProfileAside";
import Loading from "../components/Loading";
import DisplayDigitalCardTypes from "../features/DigitalCards/Components/DisplayDigitalCardTypes";

export default function DigitalCardTypes() {
  const authRequest = {
    ...loginRequest
  };
  const location = useLocation();
  const [content, setContent] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {  
    if (!content) {
      let url = "/content/url?url=/dashboard/digital-cards";
      callUmbracoApi(url)
        .then(response => {
          setContent(response);
          setLoading(false);
          setError(null);
        })
        .catch((error) => {
          setError(error?.message);
          console.log("Error: ", error);
        });
    }
  }, []);

  const breadcrumbs = useBreadcrumbs();
  const pages = [];
  breadcrumbs.map((elem) => {
    if (elem.breadcrumb.props.children !== "Home") {
      pages.push({ name: elem.breadcrumb.props.children, href: elem.key + "/", current: false })
    }
  })

  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authRequest}
    >
    <>
      {
        error ? (
          "An error occurred."
        ) : (
          <>
            <Helmet>
              <title>{!content ? '' : content.metaTitle}</title>
              <meta name="description" content={!content ? '' : content.metaDescription} />
              <meta name="keywords" content={!content ? '' : content.metaKeywords} />
            </Helmet>
            <Breadcrumb pages={pages} currentPage={location.pathname} />
            <div className="container mx-auto max-w-oshMaxWidth">
              <div className="grid grid-cols-1 lg:grid-cols-12 mt-4">
                <ProfileAside />
                <main className="p-4 lg:p-0 order-first lg:order-last lg:col-span-8 ">
                  {loading ? <Loading /> : <DisplayDigitalCardTypes content={content} /> }
                </main>
              </div>
            </div>
          </>
        )
      }
    </>
    </MsalAuthenticationTemplate>
  )
}
