import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { callUmbracoApi } from "../fetch";
import useBreadcrumbs from "use-react-router-breadcrumbs";
import Breadcrumb from "../components/Breadcrumb";
import TaxonomyListingContent from "../components/TaxonomyListingContent";
import Loading from "../components/Loading";

export default function TaxonomyListing(props) {
  const taxonomy = props?.taxonomy;
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const { category } = useParams();
  const location = useLocation();
  const breadcrumbs = useBreadcrumbs();
  const pages = [];
  breadcrumbs.map((elem) => {
    if (elem.breadcrumb.props.children !== "Home" && elem.breadcrumb.props.children !== "Taxonomies" && 
          elem.breadcrumb.props.children !== "Category" && elem.breadcrumb.props.children !== "Life event") {
      pages.push({ name: elem.breadcrumb.props.children, href: elem.key + "/", current: false })
    }
  })
  //CMS API: Get content by URL, in CMS this should be Taxonomies > Taxononomy > Name
  useEffect(() => {
      let url = "/content/url?url=/taxonomies/" + taxonomy + "/" + category; 
      callUmbracoApi(url)
        .then(response => {
          setData(response); //console.log("TAXONOMY LISTING RESPONSE: ", response);
          setLoading(false);
          setError(null);
        })
        .catch((e) => {
          setError(e?.message);
          console.log("Error: ", e?.message);
        });
  }, [category]);
  return (
    <>
      {
        loading ? (
          <Loading />
        ) : (
          error ? (
            "An error occurred."
          ) : (
            <>
              <Breadcrumb pages={pages} currentPage={location.pathname} />
              <TaxonomyListingContent data={data} category={category} />
            </>
          )
        )
      }
    </>
  )
}