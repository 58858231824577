import { useState, useEffect, useContext } from "react";
import { useAppInsightsContext, useTrackMetric, useTrackEvent } from "@microsoft/applicationinsights-react-js";
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { Helmet } from "react-helmet";
import { callApiWithToken, callUmbracoApi } from "../fetch";
import { useMsal } from "@azure/msal-react";
import { useLocation, useNavigate } from "react-router-dom";
import { protectedResources } from "../authConfig";
import HomeContent from "../components/HomeContent";
import UmbracoContext from "../context/UmbracoContext";
import PromotedServices from "../components/PromotedServices";
import Loading from "../components/Loading";
import AZContent from "../features/AZ/Components/AZContent";
import Carousel from "../features/Carousel/Components/Carousel";
import SearchBox from "../components/SearchBox";
import logger from "../utils/logger";

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}


export default function Home() {
  // user data
  const { instance, accounts } = useMsal();
  const [userData, setUserData] = useState({givenName: null, unreadNotifications: 0});

  useEffect(() => {
    let undreadNotifications = 0;
    getCarouselData();
    if (instance.getActiveAccount()) {
      const accessTokenRequest = {
        scopes: protectedResources.apiNotificationUnreadEndpoint.scopes,
        account: instance.getActiveAccount(),
        loginHint: instance.getActiveAccount()?.idTokenClaims.email
      }
      instance.acquireTokenSilent(accessTokenRequest).then(function (accessTokenResponse) {
        let accessToken = accessTokenResponse.accessToken
        callApiWithToken(accessToken, protectedResources.apiNotificationUnreadEndpoint.endpoint)
          .then(response => {
            if (response.unreadNotificationsCount > 0) {
              undreadNotifications = response.unreadNotificationsCount
            }
          })
          .catch((e) => { logger.log("Error: ", e?.message) })
      }).catch((e) => { logger.log("Error: ", e?.message) })

      setUserData({givenName: accounts.length > 0 ? instance.getActiveAccount()?.idTokenClaims?.given_name : null, unreadNotifications: undreadNotifications})
      getCarouselData();
    }
  }, [accounts]);

  // cms settings
  const [numPromotedServices, setNumPromotedServices] = useState(0);
  const umbraco = useContext(UmbracoContext);
  const [redirectHandled, setRedirectHandled] = useState(false);

  if (
    numPromotedServices === 0 &&
    umbraco &&
    umbraco?.umbracoConfig &&
    umbraco.umbracoConfig[0] &&
    umbraco.umbracoConfig[0]?.maxNumberOfPromotedServicesMainPage > 0
  ) {
    setNumPromotedServices(umbraco.umbracoConfig[0]?.maxNumberOfPromotedServicesMainPage);
  }

  // taxonomy filter
  const location = useLocation();
  const [taxCategory, setTaxCategory] = useState(location?.state?.taxonomy !== "life-event");
  const [taxLifeEvent, setTaxLifeEvent] = useState(location?.state?.taxonomy === "life-event");
  const [taxPersona, setTaxPersona] = useState(false);
  const [taxAZ, setTaxAZ] = useState(false);

  function handleTaxCategory(e) {
    e.preventDefault();
    setTaxCategory(true);
    setTaxLifeEvent(false);
    setTaxPersona(false);
    setTaxAZ(false);
  }

  function handleTaxLifeEvent(e) {
    e.preventDefault();
    setTaxCategory(false);
    setTaxLifeEvent(true);
    setTaxPersona(false);
    setTaxAZ(false);
  }

  function handleTaxAZ(e) {
    e.preventDefault();
    setTaxCategory(false);
    setTaxLifeEvent(false);
    setTaxPersona(false);
    setTaxAZ(true);
  }

  // appInsights
  const appInsights = useAppInsightsContext();
  const trackComponent = useTrackMetric(appInsights, "Home");
  appInsights?.trackTrace({ message: 'PublishingWeb_Home page has been clicked by the user', severityLevel: SeverityLevel.Information });

  // fetch page content from cms
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        let url = "/content/url?url=/home/";
        const response = await callUmbracoApi(url);
        setData(response);
        setLoading(false);
        setError(null);
      } catch (e) {
        setError(e?.message);
        logger.log("Error: ", e?.message);
      }
    };

    if (!data) {
      fetchData();
    }
  }, [data]);

  const [carouselData, setCarouselData] = useState(null);

  function getCarouselData() {
    const fetchCarouselData = async () => {
      try {
        let url = "/content/type?contentType=carouselItem&pageSize=6";
        const response = await callUmbracoApi(url);
        logger.log("Carousel: ", response)
        processCarouselData(response)
        setLoading(false);
        setError(null);
      } catch (e) {
        setError(e?.message);
        logger.log("Error: ", e?.message);
      }
    };

    fetchCarouselData();
  }
  // carousel content

  
  function processCarouselData(response) {
    logger.log("processCarouselData account")
    if (response?._embedded?.content && response?._embedded?.content.length) {
      const desiredProperties = ['sortOrder', 'carouselImage', 'carouselForeground', 'carouselHeading', 'carouselMessage', 'carouselCallToAction', 'carouselTargetAudience'];
      const filteredData = response._embedded.content.map(({ _id, ...rest }) => {
        const filteredProps = Object.keys(rest)
          .filter(key => desiredProperties.includes(key))
          .reduce((obj, key) => {
            obj[key] = rest[key];
            return obj;
          }, {});
        return { _id, ...filteredProps };
      });
      logger.log("accounts3 ", accounts)
      logger.log("accounts.length ", accounts.length)
      logger.log("filteredData ", filteredData)
      const filteredAudience = instance.getActiveAccount() ? filteredData.filter(item => item.carouselTargetAudience.toLowerCase() !== "anonymous") : filteredData.filter(item => item.carouselTargetAudience.toLowerCase() !== "authenticated");
      setCarouselData(filteredAudience.sort((a, b) => a.sortOrder - b.sortOrder));
    }
    else {
      return [];
    }
  }

  return (
    <div>
      {loading ? (
        <Loading />
      ) : (
        error ? (
          "An error occurred."
        ) : (
          <>
            <Helmet>
              <title>{!data ? "" : data.metaTitle}</title>
              <meta name="description" content={!data ? "" : data.metaDescription} />
              <meta name="keywords" content={!data ? "" : data.metaKeywords} />
            </Helmet>
            {!data || !carouselData ? (
              <Loading /> // Show loading component if data is still being fetched
            ) : (
              <>
                <Carousel carouselData={carouselData} userData={userData} />
                <SearchBox />
                <div className="container mx-auto my-2 max-w-oshMaxWidth">
                  {numPromotedServices ? (
                    <PromotedServices maxItems={numPromotedServices} />
                  ) : null}
                  <div className="my-2 mx-4 lg:mr-0">
                    <div className="mx-auto">
                      <div className="border-b border-goj-gray-light">
                        <div>
                          <nav className="-mb-px flex" aria-label="Tabs">
                            <a
                              href="/"
                              className={classNames(
                                taxCategory
                                  ? "border-goj-primary text-goj-charcoal visited:text-goj-charcoal font-semibold"
                                  : "border-transparent text-goj-charcoal visited:text-goj-charcoal",
                                "whitespace-nowrap p-4 border-b-2 hover:no-underline hover:text-goj-primary"
                              )}
                              aria-current="page"
                              onClick={handleTaxCategory}
                            >
                              All services
                            </a>
                            <a
                              href="/"
                              className={classNames(
                                taxLifeEvent
                                  ? "border-goj-primary text-goj-primary visited:text-goj-charcoal font-semibold"
                                  : "border-transparent text-goj-charcoal visited:text-goj-charcoal",
                                "whitespace-nowrap p-4 border-b-2 hover:no-underline hover:text-goj-primary"
                              )}
                              onClick={handleTaxLifeEvent}
                            >
                              Life events
                            </a>
                            <a
                              href="/"
                              className={classNames(
                                taxAZ
                                  ? "border-goj-primary text-goj-primary visited:text-goj-charcoal font-semibold"
                                  : "border-transparent text-goj-charcoal visited:text-goj-charcoal",
                                "whitespace-nowrap p-4 border-b-2 hover:no-underline hover:text-goj-primary"
                              )}
                              onClick={handleTaxAZ}
                            >
                              A-Z
                            </a>
                          </nav>
                        </div>
                      </div>
                    </div>
                  </div>
                  {taxCategory && <HomeContent taxonomy="taxonomyCategoryItem" />}
                  {taxLifeEvent && <HomeContent taxonomy="taxonomyLifeEventItem" />}
                  {taxPersona && <HomeContent taxonomy="taxonomyPersonaItem" />}
                  {taxAZ && <AZContent />}
                </div>
              </>
            )}
          </>
        )
      )}
    </div>
  );

}
