import { useContext, memo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import UmbracoContext from '../context/UmbracoContext';
import { Popover } from '@headlessui/react';
function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const MemoizedProfileMenuItem = memo(({ item, location }) => {
  if (
    location.pathname.startsWith('/dashboard/your-services') &&
    item.profileMenuLink.url.startsWith('/dashboard/your-services')
  ) {
    item.current = true;
  } else if (
    location.pathname.startsWith('/dashboard/personal-details') &&
    item.profileMenuLink.url.startsWith('/dashboard/personal-details')
  ) {
    item.current = true;
  } else if (
    location.pathname.startsWith('/dashboard/your-children') &&
    item.profileMenuLink.url.startsWith('/dashboard/your-children')
  ) {
    item.current = true;
  } else if (
    location.pathname.startsWith('/dashboard/your-vehicles') &&
    item.profileMenuLink.url.startsWith('/dashboard/your-vehicles')
  ) {
    item.current = true;
  } else if (
    location.pathname.startsWith('/dashboard/digital-cards') &&
    item.profileMenuLink.url.startsWith('/dashboard/digital-cards')
  ) {
    item.current = true;
  } else if (
    location.pathname.startsWith('/dashboard/registration-cards') &&
    item.profileMenuLink.url.startsWith('/dashboard/registration-cards')
  ) {
    item.current = true;
  } else if (
    location.pathname.startsWith('/dashboard/account-settings') &&
    item.profileMenuLink.url.startsWith('/dashboard/account-settings')
  ) {
    item.current = true;
  } else {
    item.current = (location.pathname === item.profileMenuLink.url || location.pathname + "/" === item.profileMenuLink.url);
  }

  return (
    <Link
      key={item.name}
      to={item.profileMenuLink?.url}
      className={classNames(
        item.current
          ? 'bg-goj-primary-very-light-tint text-goj-primary visited:text-goj-primary'
          : 'text-goj-charcoal visited:text-goj-charcoal hover:text-goj-charcoal',
        'group flex items-center p-2 rounded hover:bg-goj-gray-light-super hover:no-underline mr-4 text-oshFilter'
      )}
      aria-current={item.current ? 'page' : undefined}
    >
      <img
        src={
          item.current && item.profileMenuHighlightedIcon != null
            ? item.profileMenuHighlightedIcon?.src
            : item.profileMenuIcon?.src
        }
        className={'flex-shrink-0 -ml-1 mr-2 h-4 w-4'}
        aria-hidden="true"
        alt=""
      />
      <span className="truncate">{item.name}</span>
    </Link>
  );
});
export default function ProfileMenu() {
  const umbraco = useContext(UmbracoContext);
  const menu = umbraco?.menuProfile;
  let location = useLocation();

  return (
    menu &&
    menu.length &&
    menu.map((item) => (
      <MemoizedProfileMenuItem key={item.name} item={item} location={location} />
    ))
  );
}

export function MobileProfileMenu() {
  const umbraco = useContext(UmbracoContext);
  const menu = umbraco?.menuProfile;
  let location = useLocation();


  return (
    menu && menu.length && menu.map((item) => {
      if (location.pathname.startsWith("/dashboard/your-services") && item.profileMenuLink.url.startsWith("/dashboard/your-services")) {
        item.current = true;
      }
      else if (location.pathname.startsWith("/dashboard/personal-details") && item.profileMenuLink.url.startsWith("/dashboard/personal-details")) {
        item.current = true;
      }
      else if (location.pathname.startsWith("/dashboard/your-children") && item.profileMenuLink.url.startsWith("/dashboard/your-children")) {
        item.current = true;
      }
      else if (location.pathname.startsWith("/dashboard/your-vehicles") && item.profileMenuLink.url.startsWith("/dashboard/your-vehicles")) {
        item.current = true;
      }
      else if (location.pathname.startsWith("/dashboard/digital-cards") && item.profileMenuLink.url.startsWith("/dashboard/digital-cards")) {
        item.current = true;
      }
      else if (location.pathname.startsWith("/dashboard/registration-cards") && item.profileMenuLink.url.startsWith("/dashboard/registration-cards")) {
        item.current = true;
      }
      else if (location.pathname.startsWith("/dashboard/account-settings") && item.profileMenuLink.url.startsWith("/dashboard/account-settings")) {
        item.current = true;
      }
      else {
        item.current = location.pathname === item.profileMenuLink.url;
      }

      return (
        <Popover.Button as={Link} key={item.name}
          to={item.profileMenuLink?.url}
          className={classNames(
            item.current ? '' : '',
            'group flex items-center p-2 text-2xl rounded text-goj-white visited:text-goj-white hover:text-goj-white hover:underline mr-4'
          )}
          aria-current={item.current ? 'page' : undefined}>
          <span className="truncate"><strong>{item.name}</strong></span>
        </Popover.Button>
      )
    })
  )
}

