import ProfileMenu from "../components/ProfileMenu";

export default function ProfileAside() {
    return (
        <aside className="lg:mr-2 lg:py-4 sticky top-16 h-0 lg:h-fit order-last lg:order-first lg:col-span-2 lg:-mt-3">
            <div className="hidden lg:block">
                <nav className="space-y-2" aria-label="Sidebar">
                    <ProfileMenu />
                </nav>
            </div>
        </aside>
    )
}