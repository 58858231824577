import Footer from "./Footer";
import GovMetric from "./GovMetric";
import Navigation from "./Navigation";

export const PageLayout = (props) => {
  /**
   * Most applications will need to conditionally render certain components based on whether a user is signed in or not. 
   * msal-react provides 2 easy ways to do this. AuthenticatedTemplate and UnauthenticatedTemplate components will 
   * only render their children if a user is authenticated or unauthenticated, respectively.
   */
  return (
    <>
      <header className="sticky z-50 top-0 bg-goj-white items-center border border-b-goj-gray-light lg:py-0 px-0">
        <Navigation />
      </header>
      {props.children}
      <Footer />
      <GovMetric />
    </>
  );
};