import ButtonLink from "./ButtonLink";

export default function ButtonCallToAction(props) {
  // header
  const icon = props?.icon;
  const caption = props?.caption ?? "";

  // link
  const href = props?.href ?? "#";
  const title = props?.title ?? "Apply online";
  const text = props?.text ?? "Apply online";
  const internal = props?.internal;
  
  // body
  const description = props?.description ?? "";

  // style
  const foreground = props?.foreground ?? "goj-white";
  const background = props?.background ?? "goj-secondary";
  const border = props?.border ?? "goj-secondary";
  const tint = props?.tint ?? "goj-secondary-very-light-tint";

  return (
    <div className={`border-l-4 border-${border} rounded p-4 my-4 bg-${tint} w-full`}>
      <div className="flex">{icon && <div><img src={icon} alt="" className="mt-px mr-2" /></div>}<div><p><strong className="text-xl">{caption}</strong></p></div></div>
      {description && <div className="flex"><p>{description}</p></div>}
      <div className="flex w-fit"><ButtonLink href={href} title={title} text={text} internal={internal} /></div>
    </div>
  )
}
