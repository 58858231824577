import { useEffect } from "react";
import { useState } from "react";
import { callUmbracoApi } from "../fetch";
import ServiceInteractionIcon from "./icons/ServiceInteractionIcon";

function ReplaceUrl(href, cat) {
  let url = href.replace("/services/","/services" + cat);
  return url;
}

export default function PromotionServiceLink(props) {
  let url = props?.url;
  let text = props?.text;
  const [serviceUrl, setServiceUrl] = useState(null);
  useEffect(() => {
    if (!serviceUrl) {
      let catUrl = "";
      callUmbracoApi("/content/url?url=" + url)
        .then(response => {
          if (response?.categoryTaxonomy && response.categoryTaxonomy.length) {
            catUrl = response.categoryTaxonomy[0]?._url.replace("/taxonomies/category", ""); //get the primary category url
            if (catUrl) {
              setServiceUrl(ReplaceUrl(url, catUrl));
            }
          }
        })
        .catch((e) => {
          console.log("ERROR: ", e);
        });
    }
  }, [url, serviceUrl]);

  return (
    <a href={serviceUrl} className="text-goj-charcoal visited:text-goj-charcoal hover:text-goj-charcoal flex flex-col lg:h-full lg:p-4 p-2">
      <span className="bg-goj-secondary rounded-full w-12 h-12 flex justify-center items-center mx-auto">
      <ServiceInteractionIcon/>
      </span>
      <span className="text-center pt-2 font-semibold pb-px text-goj-charcoal visited:text-goj-charcoal hover:text-goj-charcoal mx-auto leading-4">
        {text}
      </span>
    </a>
  );
}
