import ExternalLink from "../../../components/ExternalLink";
import LocationIcon from "../../../components/icons/LocationIcon";

export default function AppointmentInstructions(type, address, meetingLinkUrl, instructions) {
    switch (type) {
        case "In-person":
            return (    
                <>
                <p><strong>In-person appointment at:</strong></p>
                    <p>
                    {address.name ? address.name : ''} {address.name ? <br></br> : ''}
                    {address.addressLine1 ? address.addressLine1 : ''} {address.addressLine1 ? <br></br> : ''}
                    {address.addressLine2 ? address.addressLine2 : ''} {address.addressLine2 ? <br></br> : ''}
                    {address.addressCity ? address.addressCity : ''} {address.addressCity ? <br></br> : ''}
                    {address.addressCountry ? address.addressCountry : 'Jersey'} <br></br>
                    {address.addressZip ? address.addressZip : ''} {address.addressZip ? <br></br> : ''}
                    </p>
                    <p><ExternalLink url={address.mapLink} text="Get directions" icon={<LocationIcon />} /></p>
                    <p><strong>{instructions}</strong></p>
                </>
            )
        case "Online":
            return (
                <>
                    <p><strong>Microsoft Teams meeting:</strong></p>
                    <p>Join on your computer, mobile app or room device</p>
                    <p><ExternalLink url={meetingLinkUrl} text="Click here to join the meeting" /></p>
                    <p><strong>{instructions}</strong></p>
                </>
            )
        default:
            return (
                <>
                    <p><strong>{type}</strong></p>
                    <p><strong>{instructions}</strong></p>
                </>
            )
        }
};
