import { useLocation } from 'react-router-dom';
import ButtonLink from '../../../components/ButtonLink';

export default function AppointmentCallToAction(props) {
  const location = useLocation();
  let pathnameWithoutLastTrailingSlash = location.pathname.replace(/\/$/, '');
  let newURL = `${pathnameWithoutLastTrailingSlash}/appointment/`;
  const href = props?.href || newURL;
  const title = props?.title || "Book now";
  const text = props?.text || "Book now";
  const description = props.description ?? "Book an appointment";

  return (
    <div className={`rounded w-full`}>
      <div className="flex"><p><strong className="text-xl">{description}</strong></p></div>
      <div className="flex w-fit"><ButtonLink href={href} title={title} text={text} /></div>
    </div>
  );
}
