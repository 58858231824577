import { useEffect, useState } from "react";
import { callUmbracoApi } from "../fetch";
import Loading from "./Loading";
import logger from "../utils/logger";
import ButtonCallToAction from "./ButtonCallToAction";
import parse from 'html-react-parser';

export default function WebLinks({hrefChildren}) {
  const [items, setItems] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  
  useEffect(() => { 
    if (!hrefChildren) { return }
    setLoading(true)
    callUmbracoApi(hrefChildren)
    .then(response => {
      if (response?._embedded?.content && response._embedded.content.length)
      {
        const webLink = [];
        response._embedded.content.forEach(item => {
          let x = item?.contentTypeAlias;
          if (x === "webLink") {
            webLink.push({
              id: item?.sortOrder, 
              icon: item?.icon?.src,
              caption: item?.title ? parse(item?.title) : null,
              description: item?.description ? parse(item?.description) : null,
              href: item?.link?.url, 
              title: item?.link?.name, 
              target: item?.link?.target
            })
          }
        });
        setItems(webLink);
      }
      setError(null)
    })
    .catch((e) => {
      setError(e?.message)
      logger.error(e)
    })
    .finally(() => {
      setLoading(false)
    })
  }, [])

  if (loading) { return <Loading /> }
  
  return (
    <>
    {items?.length > 0 ? 
      <div className="mb-4">
      {items.map((item) =>
        <div key={item?.id}>
          <ButtonCallToAction href={item?.href} title={item?.title} text={item?.title} target={item?.target} caption={item.caption} description={item.description} icon={item.icon} internal={true} />
        </div>
      )} 
      </div>
      : null
    }
    </>
  )
}