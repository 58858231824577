import { useState, useEffect, useRef } from "react";
import { useMsal, useAccount } from "@azure/msal-react";
import { InteractionRequiredAuthError, BrowserAuthError, InteractionStatus } from "@azure/msal-browser";
import { protectedResources } from "../../../authConfig";
import { callApiWithTokenParams } from "../../../fetch";
import { Accordion, AccordionBody, AccordionHeader, AccordionItem } from "react-headless-accordion";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { formatTime } from "../../../utils/FormatTime";
import dayjs from "dayjs";
import Loading from "../../../components/Loading";
import FeedbackWidget from "../../Feedback/Components/FeedbackWidget";
import DataRow from "../../../components/DataRow";
import CalendarIcon from "../../../components/icons/CalendarIcon";
import Button from "../../../components/Button";
import Badge from "../../../components/Badge";
import formatDate from "../../../utils/FormatDate";
import FormatPhoneNumber from "../../../utils/FormatPhoneNumber";
import logger from "../../../utils/logger";
import AppointmentType from "../utils/AppointmentType";
import AppointmentInstructions from "../utils/AppointmentInstructions";

function scrollToTop() {
  window.scrollTo({top: 0, left: 0, behavior: 'instant'});
}

function formatAppointment(appointmentDetails) {
  const publicId = appointmentDetails.publicId;

  const title = appointmentDetails?.title ?? '';
  const name = appointmentDetails?.customers[0].name ?? '';
  const email = appointmentDetails?.customers[0].email ?? '';
  const phone = FormatPhoneNumber(appointmentDetails?.customers[0].phone)
  const type = AppointmentType(appointmentDetails?.type)
  const address = appointmentDetails.branch
  const instructions = appointmentDetails?.instructions
  const meetingLinkUrl =  appointmentDetails?.meetingLinkUrl ?? ''

  const inputDate = appointmentDetails?.date ?? '';
  const parsedDate = dayjs(inputDate, 'YYYY-MM-DD');
  const formattedDate = formatDate(parsedDate,'dddd D MMMM YYYY');
  const formattedDateDesktopHeading = formatDate(appointmentDetails.date, 'dddd D MMMM YYYY')
  const formattedDateDesktopTodayHeading = formatDate(appointmentDetails.date, 'D MMMM YYYY')
  const formattedDateMobileHeading = formatDate(appointmentDetails.date, 'DD/MM/YYYY')
  const startTime = formatTime(dayjs((dayjs(appointmentDetails.start, 'HH:mm a'))).format('HH:mm'));
  const endTime = formatTime(dayjs((dayjs(appointmentDetails.end, 'HH:mm a'))).format('HH:mm'));

  const formattedTime = startTime && endTime ? startTime + ' to ' + endTime: '';

  const notes = appointmentDetails?.notes ?? '';

  const formattedType = AppointmentInstructions(type, address, meetingLinkUrl, instructions)

  const formattedData = {
    title: title,
    publicId: publicId,
    name: name,
    email: email,
    phone: phone,
    date: formattedDate,
    dateDesktopHeading: formattedDateDesktopHeading,
    dateDesktopTodayHeading: formattedDateDesktopTodayHeading,
    dateMobileHeading: formattedDateMobileHeading,
    time: formattedTime,
    typeDetails: formattedType,
    type: type,
    notes: notes
  }
  return formattedData
}

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function DisplayAppointments(props) {
  const { instance, accounts, inProgress } = useMsal();
  const navigate = useNavigate();
  const location = useLocation();
  const account = useAccount(instance.getActiveAccount());
  const data = props?.data;
  const [appointmentsToday, setAppointmentsToday] = useState([]);
  const [appointmentsFuture, setAppointmentsFuture] = useState([]);
  const [appointmentsPast, setAppointmentsPast] = useState([]);
  const [loadingPastAppointments, setLoadingPastAppointments] = useState(true);
  const [noAppointmentsPast, setNoAppointmentsPast] = useState(false);
  const [toggleExpanded, setToggleExpanded] = useState(null);
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [serviceError, setServiceError] = useState(false);
  const [page, setPage] = useState("all");

  useEffect(() => {
    getAppointmentsApi();
  }, [])

  const refToday = useRef(null);
  const refFuture = useRef(null);
  const refPast = useRef(null);
  const refHeading = useRef(null);
  const refTodayHeading = useRef(null);
  const refFutureHeading = useRef(null);
  const refPastHeading = useRef(null);
  const refFutureButton = useRef(null);
  const refPastButton = useRef(null);
  const handleClickHide = () => {
    refHeading && refHeading?.current?.classList && refHeading.current.classList.add("hidden");
    refTodayHeading && refTodayHeading?.current?.classList && refTodayHeading.current.classList.add("hidden");
    refPastHeading && refPastHeading?.current?.classList && refPastHeading.current.classList.add("hidden");
    refFutureButton && refFutureButton?.current?.classList && refFutureButton.current.classList.add("hidden");
    refFutureHeading && refFutureHeading?.current?.classList && refFutureHeading.current.classList.add("hidden");
    refPastButton && refPastButton?.current?.classList && refPastButton.current.classList.add("hidden");
    refToday && refToday?.current?.children && refToday.current.children[0].childNodes.forEach(item => {
      if (item.nodeName === "BUTTON" && item.classList.length) {
        item.classList.remove("show")
      }
    });
    refFuture && refFuture?.current?.children && refFuture.current.children[0].childNodes.forEach(item => {
      if (item.nodeName === "BUTTON" && item.classList.length) {
        item.classList.remove("show")
      }
    });
    refPast && refPast?.current?.children && refPast.current.children[0].childNodes.forEach(item => {
      if (item.nodeName === "BUTTON" && item.classList.length) {
        item.classList.remove("show")
      }
    });
    if (window.innerWidth < 1024) {
      document.documentElement.scrollTo({
        top: 0,
        left: 0,
        behavior: "instant",
      });
    }
  };

  function showHeaders() {
    setToggleExpanded(null)
    refHeading && refHeading?.current?.classList && refHeading.current.classList.remove("hidden");
    refTodayHeading && refTodayHeading?.current?.classList && refTodayHeading.current.classList.remove("hidden");
    refPastHeading && refPastHeading?.current?.classList && refPastHeading.current.classList.remove("hidden");
    refFutureButton && refFutureButton?.current?.classList && refFutureButton.current.classList.remove("hidden");
    refFutureHeading && refFutureHeading?.current?.classList && refFutureHeading.current.classList.remove("hidden");
    refPastButton && refPastButton?.current?.classList && refPastButton.current.classList.remove("hidden");
    refToday && refToday?.current?.children && refToday.current.children[0].childNodes.forEach(item => {
      if (item.nodeName === "BUTTON" && item.classList.length) {
        item.classList.add("show")
      }
    });
    refFuture && refFuture?.current?.children &&refFuture.current.children[0].childNodes.forEach(item => {
      if (item.nodeName === "BUTTON" && item.classList.length) {
        item.classList.add("show")
      }
    });
    refPast && refPast?.current?.children && refPast.current.children[0].childNodes.forEach(item => {
      if (item.nodeName === "BUTTON" && item.classList.length) {
        item.classList.add("show")
      }
    });

  }

  function handleClickShow () {
    showHeaders()
  };

  const handleTogglePastButton = (e) => {
    e.preventDefault();
    getPastAppointmentsApi();

    setPage("past")
    scrollToTop()
  };
  const handleTogglePastLink = (e) => {
    e.preventDefault();
    handleClickShow()
    getPastAppointmentsApi();

    if (page ==="past") {
      setPage("pastBack")

    } else if (page === "pastBack") {
      setPage("past")
    }
  };
  
  const handleToggleFutureButton = (e) => {
    e.preventDefault();
    setPage("future")
  };

  const handleToggleFutureLink = (e) => {
    e.preventDefault();
    showHeaders()
    
    setPage("futureBack")

    if (page ==="future") {
      setPage("futureBack")

    } else if (page === "futureBack") {
      setPage("future")
    }
  };
  const handleToggleTodayLink = (e) => {
    e.preventDefault();
    showHeaders()

    if (page === "all") {
      setPage("todayBack")
    } else if (page === "today"){
      setPage("all")
    } else {
      setPage("today")
    }
  }
  const handleClickChange = (e, appointmentId) => {
    e.preventDefault();
    navigate('/change-appointment/' + appointmentId);
  }
  const handleClickCancel = (e, appointmentId) => {
    e.preventDefault();
    navigate('/cancel-appointment/' + appointmentId);
  }

  // get current appointments
  function getAppointments(response) {
    const params = { startDate: dayjs().format('YYYY-MM-DD') } // startDate and no endDate is all future appointments including today
    callApiWithTokenParams(response.accessToken, protectedResources.apiAppointment.endpoint, params)
    .then(response => {
      if (response) {
        console.log("DEBUG getAppointments response", response);
        if (response?.appointmentList) {
          setAppointmentsToday(response.appointmentList.filter((item) => item.date === dayjs().format('YYYY-MM-DD')));
          setAppointmentsFuture(response.appointmentList.filter((item) => dayjs(item.date).toDate() > dayjs().toDate() && item.date !== dayjs().format('YYYY-MM-DD')));
        }
      }
    })
    .catch((e) => {
      logger.error(e);
    } )
    .finally(() => {
      setLoading(false);
      logger.log("DEBUG getAppointments completed");
    });
  }
  function getAppointmentsApi() {
    if (account && inProgress === InteractionStatus.None) {
      instance.acquireTokenSilent({
        scopes: protectedResources.apiAppointment.scopes,
        account: account,
        redirectUri: window.location.origin+"/auth.html",
        loginHint: instance.getActiveAccount()?.idTokenClaims.email
      }).then((response) => {
        if (response) {
          getAppointments(response);
        }
      }).catch((error) => {
        logger.error(error);
        // in case if silent token acquisition fails, fallback to an interactive method
        if (error instanceof InteractionRequiredAuthError || BrowserAuthError) {
          if (account && inProgress === InteractionStatus.None) {
            instance.acquireTokenRedirect({
              scopes: [...protectedResources.apiProfile.scopes, ...protectedResources.apiInteractionEndpoint.scopes],
              account: account,
              loginHint: instance.getActiveAccount()?.idTokenClaims.email
            }).then((response) => {
              if (response) {
                getAppointments(response);
              }
            }).catch(error => {
              setServiceError(true);
              logger.error(error);
            });
          }
        }
      })
      .finally(() => {
        logger.log("DEBUG getAppointmentsAPI completed")
      });
    }
  }

  // get past appointments on demand
  function getPastAppointments(response) {
    logger.log("DEBUG getAppointments");
    const params = { endDate: dayjs().format('YYYY-MM-DD') } // includes today
    callApiWithTokenParams(response.accessToken, protectedResources.apiAppointment.endpoint, params)
    .then(response => {
      if (response) {
        logger.log("DEBUG past appointments response", response);
        if (response?.appointmentList && response?.appointmentList.length) {
          setAppointmentsPast(response.appointmentList.filter((item) => dayjs(item.date).toDate() < dayjs().toDate() && item.date !== dayjs().format('YYYY-MM-DD')));
          setLoadingPastAppointments(false)
          setNoAppointmentsPast(false)
        } else {
          setLoadingPastAppointments(false)
          setNoAppointmentsPast(true)
        }
      }
    })
    .catch((error) => {
      setErrorMessage("An error occurred fetching your appointments");
      logger.error(error);
    } )
    .finally();
  }
  function getPastAppointmentsApi() {
    if (account && inProgress === InteractionStatus.None) {
      instance.acquireTokenSilent({
        scopes: protectedResources.apiAppointment.scopes,
        account: account,
        redirectUri: window.location.origin+"/auth.html",
        loginHint: instance.getActiveAccount()?.idTokenClaims.email
      }).then((response) => {
        if (response) {
          getPastAppointments(response);
        }
        else {
          setNoAppointmentsPast(true);
          setLoadingPastAppointments(false)
        }
      }).catch((error) => {
        logger.error(error);
        // in case if silent token acquisition fails, fallback to an interactive method
        if (error instanceof InteractionRequiredAuthError || BrowserAuthError) {
          if (account && inProgress === InteractionStatus.None) {
            instance.acquireTokenRedirect({
              scopes: [...protectedResources.apiProfile.scopes, ...protectedResources.apiInteractionEndpoint.scopes],
              account: account,
              loginHint: instance.getActiveAccount()?.idTokenClaims.email
            }).then((response) => {
              getPastAppointments(response);
            }).catch(error => {
              setServiceError(true);
              logger.error(error);
            });
          }
        }
      });
    }
  }

  function RenderFeedbackWidget() {
    return (<FeedbackWidget feedbackTitle={data?.feedbackTitle} feedbackDescription={data?.feedbackDescription} feedbackLink={data?.feedbackLink} />)
  }

  function RenderHeading() {
    return (
      <div className="flex justify-between lg:flex" ref={refHeading}>
        <div><h1 className="px-4 lg:px-0">Appointments</h1></div>
      </div>
    )
  }

  function RenderAccordionItems({items, past, today, future}) {
    return (
      <>
        {items.map((item, index) => {
          const thisAppointment = formatAppointment(item)
          var indexCorrected = index + 1

          if (indexCorrected === items.length) {
            var hideDivider = true
            var lastRow = true
          } else {
            var hideDivider = null
            var lastRow = null
          }

          if (indexCorrected === 1) {
            var firstRow = true
          } else {
            var firstRow = null
          }

          return (
            <AccordionItem key={thisAppointment.publicId}>
              {({ open }) => (
                <>
                    <div className={open && today ? "flex w-full mb-4 lg:hidden" : "hidden"}>
                      <Link
                        onClick={(e) => handleToggleTodayLink(e)}
                        styles={`justify-center rounded text-oshBase font-semibold border border-goj-secondary bg-goj-white py-2 px-4 text-goj-secondary hover:text-goj-secondary-light hover:border-goj-secondary-light shadow-sm focus:outline-none focus:ring-2 focus:borfder-goj-focus-dark focus:ring-goj-focus-light`}><strong>
                          <span className="inline-block"><svg className="w-10 h-10 lg:ml-1 rotate-180" fill="currentColor" viewBox="0 5 15 20" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
                          </svg></span>
                          Back to appointments today</strong>
                      </Link>
                    </div>
                    <div className={open && future ? "flex w-full mb-4 lg:hidden" : "hidden"}>
                      <Link
                        onClick={(e) => handleToggleFutureLink(e)}
                        styles={`justify-center rounded text-oshBase font-semibold border border-goj-secondary bg-goj-white py-2 px-4 text-goj-secondary hover:text-goj-secondary-light hover:border-goj-secondary-light shadow-sm focus:outline-none focus:ring-2 focus:borfder-goj-focus-dark focus:ring-goj-focus-light`}><strong>
                          <span className="inline-block"><svg className="w-10 h-10 lg:ml-1 rotate-180" fill="currentColor" viewBox="0 5 15 20" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
                          </svg></span>
                          Back to future appointments</strong>
                      </Link>
                    </div>
                    <div className={open && past ? "flex w-full mb-4 lg:hidden" : "hidden"} ref={refPast}>
                      <Link
                        onClick={(e) => handleTogglePastLink(e)}
                        styles={`justify-center rounded text-oshBase font-semibold border border-goj-secondary bg-goj-white py-2 px-4 text-goj-secondary hover:text-goj-secondary-light hover:border-goj-secondary-light shadow-sm focus:outline-none focus:ring-2 focus:border-goj-focus-dark focus:ring-goj-focus-light`}><strong>
                          <span className="inline-block"><svg className="w-10 h-10 lg:ml-1 rotate-180" fill="currentColor" viewBox="0 5 15 20" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
                          </svg></span>
                          Back to appointments past</strong>
                      </Link>
                    </div>
                    {open ?
                    <>
                      <AccordionHeader className={past && firstRow ? "show w-full flex my-0 border-b lg:border-b-0 items-start text-left px-4 pt-4 lg:px-2 lg:pt-4 rounded-t" : past && !firstRow ? "show w-full flex my-0 border-b lg:border-b-0 items-start text-left px-4 pt-4 lg:px-2 lg:pt-4" : !past && firstRow ? "show w-full flex my-0 border-b-goj-gray-light-super border-b lg:border-b-0 items-start border-l-4 text-left px-4 pt-4 lg:px-2 lg:pt-4 border-l-goj-charcoal bg-goj-gray-light-super rounded-t" : "show w-full flex my-0 border-b-goj-gray-light-super border-b lg:border-b-0 items-start border-l-4 text-left px-4 pt-4 lg:px-2 lg:pt-4 border-l-goj-charcoal bg-goj-gray-light-super"}
                        onClick={handleClickShow}>
                        <div className={`flex flex-shrink-0 text-goj-charcoal`}>
                          <CalendarIcon />
                        </div>
                        <div className="w-full pl-2 pr-8">
                          <div className="hidden truncate lg:block">{today ? "Today " + thisAppointment.dateDesktopTodayHeading + " from " + thisAppointment.time : thisAppointment.dateDesktopHeading + " from " + thisAppointment.time}</div>
                          <div className="block truncate lg:hidden">{thisAppointment.dateMobileHeading + " from " + thisAppointment.time}</div>
                          <div className="flex justify-between">
                            <div className="flex items-start justify-center">

                              <div className="block lg:hidden truncate"><h2 style={{textWrap: "wrap"}}>{thisAppointment?.title}</h2></div>
                              <div className="hidden lg:block truncate"><h2>{thisAppointment?.title}</h2></div>

                              <div className="hidden lg:flex"><Badge text={thisAppointment.type} type="info" style="ml-5" /></div>
                            </div>
                            <div className="flex-none justify-end text-base block">
                              <svg className={`w-6 h-6 lg:ml-1 text-goj-charcoal ${!open ? '' : 'hidden lg:block rotate-90'}`} fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
                              </svg>
                            </div>
                          </div>
                          <hr className={`hidden lg:flex lg:mt-4 lg:border-0 `} />
                        </div>
                      </AccordionHeader>
                      <AccordionBody className={ past && lastRow ? "px-4 lg:px-0 rounded-b": past && !lastRow ? "px-4 lg:px-0" : !past && lastRow ? "px-4 lg:px-0 border-l-4 bg-goj-gray-light-super border-goj-charcoal rounded-b" : "px-4 lg:px-0 border-l-4 bg-goj-gray-light-super border-goj-charcoal" } >
                        <div className="pl-8 lg:pl-11 lg:py-0 lg:pr-4 lg:mb-4">
                          <div className="hidden lg:block">
                            <DataRow name="Name" value={thisAppointment?.name} ddWidth="w-5/6" />
                            <DataRow name="Email" value={thisAppointment?.email} ddWidth="w-5/6" />
                            <DataRow name="Phone" value={thisAppointment?.phone} ddWidth="w-5/6" />
                            <DataRow name="Date" value={thisAppointment?.date} ddWidth="w-5/6" />
                            <DataRow name="Time" value={thisAppointment?.time} ddWidth="w-5/6" />
                            <DataRow name="Type" value={thisAppointment?.typeDetails} plain="true" hideTerm="yes" ddWidth="w-5/6" />
                            <DataRow name="Notes" value={thisAppointment?.notes} ddWidth="w-5/6" />
                            <span className={past ? "hidden": ""}><RenderInteractionButtons appointmentId={thisAppointment?.publicId} /></span>
                          </div>
                          <div className="block lg:hidden">
                            <DataRow name="Name" value={thisAppointment?.name} ddWidth="w-5/6"  />
                            <DataRow name="Email" value={thisAppointment?.email} ddWidth="w-5/6" />
                            <DataRow name="Phone" value={thisAppointment?.phone} ddWidth="w-5/6" />
                            <DataRow name="Date" value={thisAppointment?.date} ddWidth="w-5/6" />
                            <DataRow name="Time" value={thisAppointment?.time} ddWidth="w-5/6" />
                            <DataRow name="Notes" value={thisAppointment?.notes} ddWidth="w-5/6" />
                            <hr className="border-goj-gray-light" />
                            <DataRow name="Type" value={thisAppointment?.typeDetails} plain="true" hideTerm="yes" ddWidth="w-5/6" />
                            <span className={past ? "hidden": ""}><RenderInteractionButtons appointmentId={thisAppointment?.publicId} /></span>
                          </div>
                          <hr className={past || hideDivider ? "hidden": "mt-4 mb-4 border"} />
                        </div>
                      </AccordionBody>
                    </>
                    :
                    <>
                      <AccordionHeader className={ past && firstRow ? "show w-full flex items-start text-left px-2 pt- rounded-t" : past && !firstRow ? "show w-full flex items-start text-left px-2 pt-" : !past && firstRow ? "show w-full flex items-start border-l-4 text-left px-2 pt-4 border-goj-charcoal bg-goj-gray-light-super rounded-t" : "show w-full flex items-start border-l-4 text-left px-2 pt-4 border-goj-charcoal bg-goj-gray-light-super" }
                        onClick={handleClickHide}>
                        <div className="hidden flex-shrink-0 lg:hidden">
                          <svg className="w-10 h-10 lg:ml-1 text-goj-charcoal rotate-180" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
                          </svg>
                        </div>
                        <div className={`lg:flex lg:flex-shrink-0 text-goj-charcoal`}>
                          <CalendarIcon />
                        </div>
                        <div className="w-full pl-2 pr-8">
                          <div className="hidden truncate lg:block">{today ? "Today " + thisAppointment.dateDesktopTodayHeading + " from " + thisAppointment.time : thisAppointment.dateDesktopHeading + " from " + thisAppointment.time}</div>
                          <div className="block truncate lg:hidden">{thisAppointment.dateMobileHeading + " from " + thisAppointment.time}</div>
                          <div className="flex justify-between mb-4">
                            <div className="flex items-start justify-center">

                              <div className="block lg:hidden truncate"><h2 style={{marginBottom: "2px",textWrap: "wrap"}}>{thisAppointment?.title}</h2></div>
                              <div className="hidden lg:block truncate"><h2 style={{marginBottom: "2px"}}>{thisAppointment?.title}</h2></div>

                              <div className="hidden lg:flex"><Badge text={thisAppointment.type} type="info" style="ml-5" /></div>
                            </div>
                            <div className="flex-none justify-end text-base">
                              <svg className={`w-6 h-6 lg:ml-1 text-goj-charcoal ${!open ? '' : 'rotate-90'}`} fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
                              </svg>
                            </div>
                          </div>
                          <div className="flex mb-4 lg:hidden"><Badge text={thisAppointment.type} type="info" style="ml-5" /></div>
                          <hr className={hideDivider ? "hidden" : "mb-4"} />
                        </div>
                      </AccordionHeader>
                      <AccordionBody className={ lastRow ? "rounded-b" : "" }>
                        <div className="pl-11 py-1">
                        </div>
                        </AccordionBody>
                    </>
                  }
                </>
              )}
            </AccordionItem>
          );
        })}
      </>
    );
  }
  
  function RenderInteractionButtons({appointmentId}) {
    return (
      <>
      <div className="flex justify-between mt-4 mb-4 lg:hidden">
        <div className="flex w-full">
          <Button
            onClick={(e) => handleClickChange(e, appointmentId)}
            styles={`w-full w-fit justify-center rounded text-oshBase font-semibold border border-goj-white bg-goj-secondary py-2 px-4 text-goj-white hover:text-goj-white hover:border-goj-secondary-light shadow-sm focus:outline-none focus:ring-2 focus:border-goj-focus-dark focus:ring-goj-focus-light flex block`}
            displayMobileAccordian={true}>
            Change appointment
          </Button>
        </div>
      </div>
      <div className="flex justify-between mt-4 mb-4 lg:hidden">
        <div className="flex w-full">
          <Button
            onClick={(e) => handleClickCancel(e, appointmentId)}
            styles={`w-full w-fit justify-center rounded text-oshBase font-semibold border border-goj-secondary bg-goj-white py-2 px-4 text-goj-secondary hover:text-goj-secondary-light hover:border-goj-secondary-light shadow-sm focus:outline-none focus:ring-2 focus:border-goj-focus-dark focus:ring-goj-focus-light block`}
            displayMobileAccordian={true}>
            Cancel appointment
          </Button>
        </div>
      </div>
      <div className="hidden lg:flex mt-4 mb-4">
        <div className="pr-5">
          <Button
            onClick={(e) => handleClickChange(e, appointmentId)}
            styles={`w-full w-fit justify-center rounded text-oshBase font-semibold border border-goj-white bg-goj-secondary py-2 px-4 text-goj-white hover:text-goj-white hover:border-goj-secondary-light shadow-sm focus:outline-none focus:ring-2 focus:border-goj-focus-dark focus:ring-goj-focus-light`}
            displayMobileAccordian={true}>
            Change appointment
          </Button>
        </div>
        <div className="pr-5">
          <Button
            onClick={(e) => handleClickCancel(e, appointmentId)}
            styles={`w-full w-fit justify-center rounded text-oshBase font-semibold border border-goj-secondary bg-goj-white py-2 px-4 text-goj-secondary hover:text-goj-secondary-light hover:border-goj-secondary-light shadow-sm focus:outline-none focus:ring-2 focus:border-goj-focus-dark focus:ring-goj-focus-light`}
            displayMobileAccordian={true}>
            Cancel appointment
          </Button>
        </div>
      </div>
      </>
    )
  }
  function RenderTodayAppointments() {
    return (
        <>
          <div className="accordion mb-0 pb-2" ref={refToday}>
            <Accordion transition={{ duration: "300ms", timingFunction: "cubic-bezier(0, 0, 0.2, 1)" }} alwaysOpen="true">
              <div className="px-4 mt-2 lg:px-0 text-base text-goj-charcoal mb-2 lg:block" ref={refTodayHeading}>
                <h2>Appointments today</h2>
              </div>
              <RenderAccordionItems items={appointmentsToday} today="true" />
            </Accordion>
          </div>
        </>
    )
  }

  function RenderPastAppointments() {
    return (
        <>
          <div className="accordion mb-4" ref={refPast}>
            <Accordion transition={{ duration: "300ms", timingFunction: "cubic-bezier(0, 0, 0.2, 1)" }} alwaysOpen="true">
              <RenderAccordionItems items={appointmentsPast} past="true" />
            </Accordion>
          </div>
        </>
    )
  }

  function RenderFutureAppointments() {
    return (
        <>
          <div className="accordion mb-4" ref={refFuture}>
            <Accordion transition={{ duration: "300ms", timingFunction: "cubic-bezier(0, 0, 0.2, 1)" }} alwaysOpen="true">
              <RenderAccordionItems items={appointmentsFuture} future="true" />
            </Accordion>
          </div>
        </>
    )
  }

  return (
    <>
      <main className="p-4 lg:p-0 order-2 lg:col-span-8 ">
        <RenderFeedbackWidget />
        <RenderHeading />
        {(appointmentsToday && appointmentsToday.length) 
          || (appointmentsFuture && appointmentsFuture.length) 
          || (appointmentsPast && appointmentsPast.length) ?
          <>
          {(appointmentsToday && appointmentsToday.length && (page === "all" || page === "future" || page === "futureBack"))  ?
            <>
              <RenderTodayAppointments />
            </>
            :
            <>
            </>
          }
          {(appointmentsToday && appointmentsToday.length && page === "todayBack")  ?
            <>
              <RenderTodayAppointments />
            </>
            :
            <>
            </>
          }
          {(appointmentsToday && appointmentsToday.length && page === "today")  ?
            <>
              <RenderTodayAppointments />
            </>
            :
            <>
            </>
          }
          {(!appointmentsPast || appointmentsPast.length == 0 && loadingPastAppointments && page === "past")  ?
            <>
              <Loading />
            </>
            :
            <>
            </>
          }
          {(!appointmentsPast || appointmentsPast.length == 0 && noAppointmentsPast && !loadingPastAppointments && page === "past")  ?
            <>
              <div className="px-4 mt-2 lg:px-0 text-base text-goj-charcoal mb-2 lg:block" ref={refPastHeading}>
                <h2>Past Appointments</h2>
              </div>
              <div className="flex justify-between pb-4">
                <div className="hidden">
                  {/* Book new appointment */}
                </div>
                <div className="hidden lg:flex">
                  <Button
                    onClick={(e) => handleToggleFutureButton(e)}
                    styles={`w-full md:w-fit justify-center rounded text-oshBase font-semibold border border-goj-secondary bg-goj-white py-2 px-4 text-goj-secondary hover:text-goj-secondary-light hover:border-goj-secondary-light shadow-sm focus:outline-none focus:ring-2 focus:border-goj-focus-dark focus:ring-goj-focus-light`}>
                    View future appointments
                  </Button>
                </div>
                <div className="flex w-full mb-4 lg:hidden" ref={refFutureButton}>
                  <Button
                    onClick={(e) => handleToggleFutureButton(e)}
                    styles={`w-full md:w-fit justify-center rounded text-oshBase font-semibold border border-goj-secondary bg-goj-white py-2 px-4 text-goj-secondary hover:text-goj-secondary-light hover:border-goj-secondary-light shadow-sm focus:outline-none focus:ring-2 focus:border-goj-focus-dark focus:ring-goj-focus-light`}>
                    View future appointments
                  </Button>
                </div>
              </div>
              <div className="block">
                <p className={noAppointmentsPast && !loadingPastAppointments ? "py-4" : "hidden"}>No appointments found</p>
              </div>
            </>
            :
            <>
            </>
          }
          {(appointmentsPast && appointmentsPast.length && page === "past")  ?
            <>
              <div className="px-4 mt-2 lg:px-0 text-base text-goj-charcoal mb-2 lg:block" ref={refPastHeading}>
                <h2>Past Appointments</h2>
              </div>
              <div className="flex justify-between pb-4">
                <div className="hidden">
                  {/* Book new appointment */}
                </div>
                <div className="hidden lg:flex">
                  <Button
                    onClick={(e) => handleToggleFutureButton(e)}
                    styles={`w-full md:w-fit justify-center rounded text-oshBase font-semibold border border-goj-secondary bg-goj-white py-2 px-4 text-goj-secondary hover:text-goj-secondary-light hover:border-goj-secondary-light shadow-sm focus:outline-none focus:ring-2 focus:border-goj-focus-dark focus:ring-goj-focus-light`}>
                    View future appointments
                  </Button>
                </div>
                <div className="flex w-full mb-4 lg:hidden" ref={refFutureButton}>
                  <Button
                    onClick={(e) => handleToggleFutureButton(e)}
                    styles={`w-full md:w-fit justify-center rounded text-oshBase font-semibold border border-goj-secondary bg-goj-white py-2 px-4 text-goj-secondary hover:text-goj-secondary-light hover:border-goj-secondary-light shadow-sm focus:outline-none focus:ring-2 focus:border-goj-focus-dark focus:ring-goj-focus-light`}>
                    View future appointments
                  </Button>
                </div>
              </div>
              <RenderPastAppointments />
            </>
            :
            <>
            </>
          }
          {(appointmentsPast && appointmentsPast.length && (page === "pastBack"))  ?
            <>
              <div className="px-4 mt-2 lg:px-0 text-base text-goj-charcoal mb-2 lg:block">
                <h2>Past Appointments</h2>
              </div>
              <div className="flex justify-between pb-4">
                <div className="hidden">
                  {/* Book new appointment */}
                </div>
                <div className="hidden lg:flex">
                  <Button
                    onClick={(e) => handleToggleFutureButton(e)}
                    styles={`w-full md:w-fit justify-center rounded text-oshBase font-semibold border border-goj-secondary bg-goj-white py-2 px-4 text-goj-secondary hover:text-goj-secondary-light hover:border-goj-secondary-light shadow-sm focus:outline-none focus:ring-2 focus:border-goj-focus-dark focus:ring-goj-focus-light`}>
                    View future appointments
                  </Button>
                </div>
                <div className="flex w-full mb-4 lg:hidden">
                  <Button
                    onClick={(e) => handleToggleFutureButton(e)}
                    styles={`w-full md:w-fit justify-center rounded text-oshBase font-semibold border border-goj-secondary bg-goj-white py-2 px-4 text-goj-secondary hover:text-goj-secondary-light hover:border-goj-secondary-light shadow-sm focus:outline-none focus:ring-2 focus:border-goj-focus-dark focus:ring-goj-focus-light`}>
                    View future appointments
                  </Button>
                </div>
              </div>
              <RenderPastAppointments />
            </>
            :
            <>
            </>
          }
          {(appointmentsFuture && appointmentsFuture.length && (page === "all" || page === "future" || page === "futureBack" || page === "todayBack" || page === "today"))  ?
            <>
            
            <div className="px-4 mt-2 lg:px-0 text-base text-goj-charcoal mb-2 lg:block" ref={refFutureHeading}>
                <h2>Future Appointments</h2>
              </div>
              <div className="flex justify-between pb-4">
                <div className="hidden">
                  {/* Book new appointment */}
                </div>
                <div className="hidden lg:flex">
                  <Button 
                    onClick={(e) => handleTogglePastButton(e)} 
                    styles={`w-full md:w-fit justify-center rounded text-oshBase font-semibold border border-goj-secondary bg-goj-white py-2 px-4 text-goj-secondary hover:text-goj-secondary-light hover:border-goj-secondary-light shadow-sm focus:outline-none focus:ring-2 focus:border-goj-focus-dark focus:ring-goj-focus-light`}>View past appointments</Button>
                </div>
                <div className="flex w-full mb-4 lg:hidden" ref={refPastButton}>
                  <Button 
                    onClick={(e) => handleTogglePastButton(e)} 
                    styles={`w-full md:w-fit justify-center rounded text-oshBase font-semibold border border-goj-secondary bg-goj-white py-2 px-4 text-goj-secondary hover:text-goj-secondary-light hover:border-goj-secondary-light shadow-sm focus:outline-none focus:ring-2 focus:border-goj-focus-dark focus:ring-goj-focus-light`}>View past appointments</Button>
                </div>
              </div>
              <RenderFutureAppointments />
            </>
            :
            <>
            </>
          }
          </>
          :
          <>
            {!appointmentsToday.length && !appointmentsFuture.length && !appointmentsPast.length && !loading ? <p className="py-4">No appointments found</p> : <Loading />}
          </>
        }
      </main>
    </>
  )
}
