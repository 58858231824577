export default function FormatPhoneNumber(phoneNumber) {
  //match one - formats +441234567890 to +44 (0) 1234 567890, otherwise returns back the same number
  //match two - formats +5091234567890 to +509 (0) 1234 567890, otherwise returns back the same number
  //match three - formats +11234567890 to +1 (0) 1234 567890, otherwise returns back the same number
  const cleaned = ('' + phoneNumber).replace(/\D/g, '');
  const matchOne = cleaned.match(/^(\d{2})(\d{4})(\d{6})$/);
  const matchTwo = cleaned.match(/^(\d{3})(\d{4})(\d{6})$/);
  const matchThree = cleaned.match(/^(\d{1})(\d{4})(\d{6})$/);
  if (matchOne) {
    return `+${matchOne[1]} (0) ${matchOne[2]} ${matchOne[3]}`;
  } else {
    if (matchTwo) {
      return `+${matchTwo[1]} (0) ${matchTwo[2]} ${matchTwo[3]}`;
    } else {
      if (matchThree) {
        return `+${matchThree[1]} (0) ${matchThree[2]} ${matchThree[3]}`;
      } else {
        return phoneNumber;
      }   
    }
  }
}