import { useState, useEffect } from "react";
import { useParams, useLocation } from 'react-router-dom';
import { Helmet } from "react-helmet";
import { callUmbracoApi } from "../fetch";
import useBreadcrumbs from 'use-react-router-breadcrumbs'
import Breadcrumb from "../components/Breadcrumb";
import ServiceInteractionContent from "../components/ServiceInteractionContent";
import Loading from "../components/Loading";

export default function ServiceInteraction() {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const { service } = useParams();
  const { interaction } = useParams();
  const location = useLocation();
  
  //if this is a life-event or persona route then set the canonical url
  let canonicalUrl = "";
  canonicalUrl = window.location.href.replace("/life-events/","/services/") + "/";
  canonicalUrl = window.location.href.replace("/personas/","/services/") + "/";

  //CMS API: Get content by URL, in CMS this should be Service > Service Interaction
  useEffect(() => {  
    if (!data) {
      let url = "/content/url?url=/services/" + service + "/" + interaction; 
      callUmbracoApi(url)
        .then(response => {
          setData(response); //console.log("SERVICE INTERACTION RESPONSE: ", response);
          setLoading(false);
          setError(null);
        })
        .catch((error) => {
          setError(error?.message);
          console.log("Error: ", error);
        });
    }
  }, []);

  const breadcrumbs = useBreadcrumbs();
  const pages = [];
  breadcrumbs.map((elem) => {
    if (elem.breadcrumb.props.children !== "Home") {
      pages.push({ name: elem.breadcrumb.props.children, href: elem.key + "/", current: false })
    }
  })
  return (
    <>
      {
        loading ? (
          <Loading />
        ) : (
          error ? (
            "An error occurred."
          ) : (
            <>
              <Helmet>
                <title>{!data ? '' : data.metaTitle}</title>
                <meta name="description" content={!data ? '' : data.metaDescription} />
                <meta name="keywords" content={!data ? '' : data.metaKeywords} />
                <link rel="canonical" href={canonicalUrl} />
              </Helmet>
              <Breadcrumb pages={pages} currentPage={location.pathname} />
              <ServiceInteractionContent data={data} />
            </>
          )
        )
      }
    </>
  )
}