import { Link, useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useMsal, useAccount } from "@azure/msal-react";
import { PencilSquareIcon } from '@heroicons/react/24/outline';
import { useContext } from 'react';
import { callApiWithToken, deleteApiParamWithToken } from '../../../fetch';
import { BrowserAuthError, InteractionRequiredAuthError } from '@azure/msal-browser';
import { protectedResources } from '../../../authConfig';
import parse from 'html-react-parser';
import dayjs from 'dayjs';
import ErrorAlert from '../../../components/ErrorAlert';
import Loading from '../../../components/Loading';
import Confirmation from './Confirmation';
import UserContext from '../../../context/UserContext';
import FeedbackWidget from '../../Feedback/Components/FeedbackWidget';
import SharingConsent from './SharingConsent';

function scrollToTop() {
  window.scrollTo({top: 0, left: 0, behavior: 'instant'});
}

export default function FamilyDataSharing(props) {

  // msal
  const { instance, accounts, inProgress } = useMsal();
  const account = useAccount(instance.getActiveAccount() || {});
  // context
  const user = useContext(UserContext);
  const codes = user.codes;
  // state
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [profile, setProfile] = useState(null);
  const [childSharingConsentList, setChildSharingConsentList] = useState([]);
  const [displayDialog, setDisplayDialog] = useState(false);
  const [familyMember, setFamilyMember] = useState(null);
  // content
  const mainHeading = props?.content?.mainHeading ? props.content.mainHeading : "Change your data sharing settings";
  const mainContent = props?.content?.mainContent ? parse(props.content.mainContent) : "";
  // status
  const [showSuccess, setShowSuccess] = useState(false)
  const [serviceError, setServiceError] = useState(false)
  const [displayError, setDisplayError] = useState(false)
  const [errorMessage, setErrorMessage] = useState({
    messageHeading: '',
    message: '',
  });


  // get profile data
  useEffect(() => {
    GetUserProfile();
  }, [account, inProgress, instance]);
  function GetUserProfile() {
    if (account && inProgress === "none") {
      // profile
      instance.acquireTokenSilent({
        scopes: protectedResources.apiProfileFull.scopes,
        account: account,
        loginHint: instance.getActiveAccount()?.idTokenClaims.email
      }).then((response) => {
        getProfile(response);
        setLoading(false);
      }).catch((error) => {
        // in case if silent token acquisition fails, fallback to an interactive method
        if (error instanceof InteractionRequiredAuthError || BrowserAuthError) {
          setLoading(true);
          if (account && inProgress === "none") {
            instance.acquireTokenSilent({
              scopes: protectedResources.apiProfileFull.scopes,
              account: account,
              loginHint: instance.getActiveAccount()?.idTokenClaims.email
            }).then((response) => {
              getProfile(response);
              setLoading(false);
            }).catch(error => {
              setLoading(false);
              console.log(error)
              setServiceError(true);
              setErrorMessage((prevState) => ({
                ...prevState,
                messageHeading: "Service unavailable",
                message: "We are unable to service your request at the moment, please try again later."
              }));
            });
          }
        }
      });
    }
    else {
      setLoading(true);
    }
  }
  function getProfile(response) {
    callApiWithToken(response.accessToken, protectedResources.apiProfileFull.endpoint)
    .then(response => {
      setLoading(false)
      setProfile(response)
    }).catch((error) => {
      setLoading(false);
      setServiceError(true);
      console.log("Error",error)
      setErrorMessage((prevState) => ({
        ...prevState,
        messageHeading: "Service unavailable",
        message: "We are unable to service your request at the moment, please try again later."
      }));
    });
  }


  // codes table data
  function getCodeTableData() {
    if (codes && codes?.organisations && codes.organisations.length) {
      const children = profile?.children?.map((child) => {
        const sharingOptions = codes?.organisations?.filter(item => (item.appliesTo.toLowerCase() === 'child' || item.appliesTo.toLowerCase() === 'all')).map((item) => {
          const found = child?.consents?.find(obj => {
            return obj.typeId === item.typeId;
          });
          return {
            appliesTo:item.appliesTo.toLowerCase() === 'all' ? 'Child' : item.appliesTo,
            instances:item.instances,
            introText:item.introText,
            typeId:item.typeId,
            typeName:item.typeName,
            consentId:found?.consentId ? found.consentId : '',
            organisationId:found?.organisationId ? found.organisationId : '',
            isGrantedOn:found?.isGrantedOn ? true : false,
          };
        });
        return {
          ...child, consents:sharingOptions
        };
      })
      setChildSharingConsentList(children);
    }
    setLoading(false);
  }

  // load existing data
  useEffect(() => { 
    if (!codes?.organisations?.length || !profile) { 
      setLoading(true)
    }
    else {
      if (codes.serviceError) {
        setServiceError(true);
        setErrorMessage((prevState) => ({
          ...prevState,
          messageHeading: "Service unavailable",
          message: "We are unable to service your request at the moment, please try again later."
        }));
      }
      else {
        getCodeTableData();
      }
    }
  }, [user,profile])

  // previous
  function prevPage() {
    navigate(-1);
  }

  // add child
  function addChild() {
    navigate("/dashboard/your-children/add-child/");
  }
  
  // form submit
  function handleFormSubmit(event) {
    event.preventDefault();
  }

  function removeChild(response, param) {
    deleteApiParamWithToken(response.accessToken, protectedResources.apiProfileChildren.endpoint, param)
    .then(response => {
      console.log("DELETE RESPONSE", response)
      if (response?.statusCode === 200) {
        setDisplayError(false);
        window.location.reload();
      }
      else {
        console.log("DELETE ERROR", response?.errordetails?.message);
        setShowSuccess(false);
        setServiceError(true);
        setErrorMessage((prevState) => ({
          ...prevState,
          messageHeading: "An error occurred",
          message: "We are unable to process your request at the moment, please try again later."
        }));
      }
    });
  }

  function showDialog({display,child}) {
    if (display) {
      setDisplayDialog(display);
      setFamilyMember(child);
    }
    else {
      setDisplayDialog(false);
      setFamilyMember(null);
    }
  }

  function handleRemoveChild(param) {
    instance.acquireTokenSilent({
      scopes: protectedResources.apiProfile.scopes,
      account: account,
      loginHint: instance.getActiveAccount()?.idTokenClaims.email
    }).then((response) => {
      console.log("REMOVE CHILD PARAM",param)
      removeChild(response, param);
      setDisplayDialog(false);
      setFamilyMember(null);
    }).catch((error) => {
      // in case if silent token acquisition fails, fallback to an interactive method
      if (error instanceof InteractionRequiredAuthError || BrowserAuthError) {
        if (account && inProgress === "none") {
          instance.acquireTokenRedirect({
            scopes: protectedResources.apiProfile.scopes,
            account: account
          }).then((response) => {
            removeChild(response, param);
          }).catch(error => {
            console.log(error);
            setServiceError(true);
              setErrorMessage((prevState) => ({
                ...prevState,
                messageHeading: "Service unavailable",
                message: "We are unable to service your request at the moment, please try again later."
              }));
          });
        }
      }
    });
  };

  function RenderFeedbackWidget() {
    return (<FeedbackWidget feedbackTitle={props?.content?.feedbackTitle} feedbackDescription={props?.content?.feedbackDescription} feedbackLink={props?.content?.feedbackLink} />)
  }

  return (
    <>
      <RenderFeedbackWidget />
      { instance.getActiveAccount()?.idTokenClaims?.authenticationSource !== "L2" ? 
        <><h1>Your children</h1><p>If you wish to provide details of your children you will need to login with a verified account, for example with JerseyMe or Yoti.</p></> : 
        loading ? <Loading /> :
        showSuccess ? 
        <Confirmation
          message="We are now in the process of updating the Government departments and other organisations that you have selected in your data sharing preferences."
          message2=""
          messageHeading="Your new details have been received"
          messageLink="/dashboard/personal-details/"
          messageLinkText="Return to personal details" />
        :
        <>
        <h1>{mainHeading ? mainHeading : ""}</h1>
        <form onSubmit={handleFormSubmit}>
          {(displayError) ? (<div className="mt-4"><ErrorAlert messageHeading={errorMessage.messageHeading} message={errorMessage.message} /></div>) : null}
          {(serviceError) ? (<div className="mt-4"><ErrorAlert messageHeading={errorMessage.messageHeading} message={errorMessage.message} /></div>) : null}
          <div className="mt-4">
            {childSharingConsentList && childSharingConsentList.length ?
            <>
            <div className="mt-3 space-y-4">
              {
                childSharingConsentList.map((child) => { 
                  let name = child.firstName + ' ' + child.lastName
                  let consent = child?.consents?.map((item,index) => { 
                    return(
                      item.appliesTo.toLowerCase().includes('child') || item.appliesTo.toLowerCase().includes('all') 
                      ? <SharingConsent key={index} item={item} child={child} tabular={true} readOnly={true} /> 
                      : null
                    )})
                  return (
                    <div key={child.id} className="my-4">
                      <div className="sm:mt-0">
                          <dl className="sm:grid sm:grid-cols-2">
                            <dt className="sm:py-0">
                              <h2>{name}</h2>
                            </dt>
                            <dd className="sm:py-0 sm:mt-3 sm:ml-4">
                              <span className="lg:inline block ml-0">
                                <Link to="/dashboard/your-children/update-child" state={{ data: child }}>
                                  <PencilSquareIcon className="w-5 h-5 inline-block mr-1 mb-1" />
                                  <span className="lg:inline-block hover:underline">Change {child?.firstName ? child.firstName.endsWith('s') ? child.firstName+"'" : child.firstName+"'s" : ""} details</span>
                                </Link>
                              </span>
                            </dd>
                          </dl>
                        </div>
                      <div className="my-4 sm:mt-0 sm:border-t-0 border-b border-goj-gray-light">
                        <dl className="sm:grid sm:grid-cols-2 pb-4">
                          <dt className="sm:py-1">
                            Relationship
                          </dt>
                          <dd className="mb-3 sm:mb-0 sm:py-1 sm:mt-0">
                            <span className="lg:inline block ml-0 lg:pl-4">
                              <strong>Child</strong>
                            </span>
                          </dd>
                          <dt className="sm:py-1">
                            Full name:
                          </dt>
                          <dd className="mb-3 sm:mb-0 sm:py-1 sm:mt-0">
                            <span className="lg:inline block ml-0 lg:pl-4">
                              <strong>{name}</strong>
                            </span>
                          </dd>
                          <dt className="sm:py-1">
                            Date of birth:
                          </dt>
                          <dd className="mb-3 sm:mb-0 sm:py-1 sm:mt-0">
                            <span className="lg:inline block ml-0 lg:pl-4">
                              <strong>{dayjs(child.dateOfBirth).format('D MMMM YYYY')}</strong>
                            </span>
                          </dd>
                          <dt className="sm:py-1">
                            Social Security Number:
                          </dt>
                          <dd className="mb-1 sm:mb-0 sm:py-1 sm:mt-0">
                            <span className="lg:inline block ml-0 lg:pl-4">
                              <strong>{child.ssn}</strong>
                            </span>
                          </dd>
                        </dl>
                      </div>
                      <div className="mb-8 space-y-4">
                        {consent}
                        <div className="sm:mt-0 sm:border-t-0 border-b border-goj-gray-light">
                          <dl className="sm:grid sm:grid-cols-2 pb-4">
                            <dt className="sm:py-0">
                            </dt>
                            <dd className="sm:py-0 sm:mt-0 sm:pl-4">
                              <button
                                type="submit"
                                // className="w-full md:w-fit justify-center rounded border border-transparent bg-goj-secondary py-2 px-4 text-goj-white shadow-sm focus:outline-none focus:ring-2 focus:border-goj-focus-dark focus:ring-goj-focus-light"
                                className="w-full md:w-fit justify-center rounded text-oshBase font-semibold border border-goj-secondary bg-goj-white py-2 px-4 text-goj-secondary hover:text-goj-secondary-light hover:border-goj-secondary-light shadow-sm focus:outline-none focus:ring-2 focus:border-goj-focus-dark focus:ring-goj-focus-light"
                                onClick={() => showDialog({display:true, child:child})}
                              >
                                Remove {child.firstName}
                              </button>
                            </dd>
                          </dl>
                        </div>
                      </div>
                    </div>
                  ) 
                })
              }
            </div>
            </>
            :
            <>
            <p>You have not added any children to your profile.</p>
            </>
            }
            <div className={displayDialog ? '' : 'hidden'}>
              <div className="fixed inset-0 z-10 overflow-y-auto">
                <div className="relative lg:flex lg:unset min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                  <div className="absolute max-w-full top-2/4 lg:max-w-unset lg:max-top-unset lg:relative transform overflow-hidden rounded-lg bg-goj-gray-light-super border border-goj-gray-light px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                    <p>Are you sure you wish to remove {familyMember?.firstName}?</p>
                    <div className="space-y-4 space-x-0 lg:space-y-0 lg:space-x-4">
                      <button
                        type="submit"
                        className="w-full md:w-fit justify-center rounded text-oshBase font-semibold border border-goj-secondary bg-goj-secondary py-2 px-4 text-goj-white hover:text-goj-white hover:border-goj-secondary shadow-sm focus:outline-none focus:ring-2 focus:border-goj-focus-dark focus:ring-goj-focus-light"
                        onClick={() => handleRemoveChild(familyMember?.ssn)}
                      >
                        Yes
                      </button>
                      <button
                        type="submit"
                        className="w-full md:w-fit justify-center rounded text-oshBase font-semibold border border-goj-secondary bg-goj-white py-2 px-4 text-goj-secondary hover:text-goj-secondary-light hover:border-goj-secondary-light shadow-sm focus:outline-none focus:ring-2 focus:border-goj-focus-dark focus:ring-goj-focus-light"
                        onClick={() => showDialog({display:false})}
                      >
                        No
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-6">
            <div className="">
              <div className="flex justify-end space-x-4">
                <button
                  type="button"
                  className="w-full md:w-fit justify-center rounded text-oshBase font-semibold border border-transparent bg-goj-secondary py-2 px-4 text-goj-white hover:text-goj-white hover:border-goj-secondary-light shadow-sm focus:outline-none focus:ring-2 focus:border-goj-focus-dark focus:ring-goj-focus-light"
                  onClick={addChild}
                >
                  Add child
                </button>
              </div>
            </div>
          </div>
        </form>
        </>
      }
    </>
  )
}