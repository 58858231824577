import { useEffect, useState } from "react";
import { Accordion, AccordionBody, AccordionHeader, AccordionItem } from "react-headless-accordion";
import { useLocation } from "react-router-dom";
import { callUmbracoApi } from "../fetch";
import LocationContactsContent from "./LocationContactsContent";
import LocationContactsContentWebLinks from "./LocationContactsContentWebLinks";
import { useMediaQuery } from "react-responsive";
import FeedbackWidget from "../features/Feedback/Components/FeedbackWidget";
import DownloadIcon from "./icons/DownloadIcon";

function ReplaceUmbracoUrl(href) {
  let url = href;
  if (url.startsWith("https://preview.umbraco.io")) {
    url = href.replace("https://preview.umbraco.io","");
  }
  if (url.startsWith("https://cdn.umbraco.io")) {
    url = href.replace("https://cdn.umbraco.io","");
  }
  return url;
}

function LocationContacts(props) {
  const [contacts, setContacts] = useState(null);
  const [error, setError] = useState(null);
  const url = props?.href;
  useEffect(() => {  
    callUmbracoApi(url)
      .then(response => {
        setContacts(response?._embedded?.content); //console.log("CONTACTS: ", response?._embedded?.content);
        setError(null);
      })
      .catch((e) => {
        setError(e?.message);
        console.log("Error: ", error);
      });
  }, []);
  return (
    <LocationContactsContent contacts={contacts} appointmentButtonText={props?.appointmentButtonText} qmaticReference={props?.qmaticReference} />
  )
}


function SupportingDocuments(props) {
  return (
    <ul className="w-full list-none ml-0">
      {props?.attachments?.map((file) => (
        <li key={file?.media?._id} className="border-l-4 border-goj-secondary rounded p-4 my-4 bg-goj-secondary-very-light-tint">
          <a href={file?.src} className="flex justify-between text-goj-charcoal visited:text-goj-charcoal" title={file?.media?.name + "." + file?.media?.umbracoExtension} target="_blank" rel="noopener noreferrer">
            <div className="flex">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 flex-none">
                <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m2.25 0H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
              </svg>
              <span className="ml-2 text-lg">
                {file?.media?.name + "." + file?.media?.umbracoExtension}
              </span>
            </div>
            <div className="flex">
              <DownloadIcon />
            </div>
          </a>
        </li>
      ))}
    </ul>
  )
}

export default function ServiceInteractionContent(props) {
  const parse = require('html-react-parser');
  const data = props?.data; //console.log("Service Interaction DATA: ", props?.data);
  let count = 0;
  const sections = [];
  const location = useLocation();
  const isMobile = useMediaQuery({ maxWidth: 768 });

  if (data?.longDescription) {
    count++
    sections.push({id:count,title:"Description",content: parse(data?.longDescription),parsed:true})
  }
  if (data?.price) {
    count++
    sections.push({id:count,title:"Cost",content: parse(data?.price),parsed:true})
  }
  if (data?.targetSLA || data?.targetSLA === 0) {
    count++
    let strTargetSLA = "We expect to respond to your request after " + data?.targetSLA?.toString() + " working days.";
    if (data?.targetSLA === 0) {
      strTargetSLA = "We expect to be able to respond to your request immediately either via online channels or in-person."
    }
    sections.push({id:count,title:"Expected response time",content: strTargetSLA,parsed:true})
  }
  if (data?.attachments?.length > 0) {
    count++
    sections.push({id:count,title:"Supporting documents",content: <SupportingDocuments attachments={data?.attachments} />,parsed:false})
  }

  if (!isMobile) {
    return (
      <div className="hidden lg:block container mx-auto my-0 max-w-oshMaxWidth">
        <div className="grid grid-cols-1 lg:grid-cols-12">
          <aside className="sticky top-24 h-fit pr-4 order-last lg:order-first lg:col-span-2 my-6">
            <ol className="text-oshFilter">
              {sections.map((section) => {
                return (
                  <li key={section.id} className="cursor-pointer border-l-2 border-goj-gray-light hover:border-goj-primary pl-2 hover:underline py-1" 
                    onClick={() => window.location.replace(`${location.pathname}#${section.title}`)}>
                    <span>{section.title}</span>
                  </li>
                )})
              }
            </ol>
          </aside>
          <main className="order-first lg:order-last lg:col-span-8 my-4">
            <FeedbackWidget feedbackTitle={data?.feedbackTitle} feedbackDescription={data?.feedbackDescription} feedbackLink={data?.feedbackLink} />
            <h1>{data?.interactionName}</h1>
            <div className="mb-10">
              <LocationContacts href={ReplaceUmbracoUrl(data?._links?.children?.href)} qmaticReference={data?.qmaticReference} appointmentButtonText={data?.appointmentButtonText} />
            </div>
            <h2>Introduction</h2>
            <div className="mb-10">
              {data?.shortDescription}
            </div>
            {sections.map((section) => {
              return (
                <section key={section.id} id={section.title} className={`mb-10 ${section.parsed ? "content" : null}`}>
                  <h2 className="mt-10">{section.title}</h2>
                  {section.content}
                </section>
              )})
            }
          </main>
        </div>
      </div>
    )
  }
  else {
    return (
      <main className="lg:hidden">
        <div className="mx-4 my-2">
          <FeedbackWidget feedbackTitle={data?.feedbackTitle} feedbackDescription={data?.feedbackDescription} feedbackLink={data?.feedbackLink} />
          <h1>{data?.interactionName}</h1>
          <div className="mb-10">
            <LocationContacts href={ReplaceUmbracoUrl(data?._links?.children?.href)} qmaticReference={data?.qmaticReference} appointmentButtonText={data?.appointmentButtonText} />
          </div>
          <h2>Introduction</h2>
          <div className="mb-10">
            {data?.shortDescription}
          </div>
        </div>
        <Accordion transition={{ duration: "300ms", timingFunction: "cubic-bezier(0, 0, 0.2, 1)" }}>
          {sections.map((section) =>
            <AccordionItem key={section.id}>
              {({ open }) => (
                <>
                  <AccordionHeader className={`w-full flex justify-between items-center text-goj-charcoal border-b p-4 border border-goj-gray-light text-left ${open ? 'bg-goj-secondary-very-light-tint' : 'bg-goj-white'}`}>
                    <h2 className="mb-0">{section.title}</h2>
                    <svg className={`w-6 h-6 text-goj-charcoal ${!open ? '' : 'rotate-90'}`} fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                      <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
                    </svg>
                  </AccordionHeader>
                  <AccordionBody>
                    <div className={`p-5 font-light ${section.parsed ? "content" : null}`}>
                      {section.content}
                    </div>
                  </AccordionBody>
                </>
              )}
            </AccordionItem>
          )}
        </Accordion>
      </main>
    )
  }
};
