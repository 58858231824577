import { ArrowDownTrayIcon, ArrowRightIcon, ChevronRightIcon, EnvelopeIcon, PhoneArrowUpRightIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import { Accordion, AccordionBody, AccordionHeader, AccordionItem } from "react-headless-accordion";
import { Link, useLocation } from "react-router-dom";
import { callUmbracoApi, callUmbracoApiGraphQL } from "../fetch";
import ContactCallToAction from "./ContactCallToAction";
import ServiceInteractionIcon from "./icons/ServiceInteractionIcon";
import DownloadIcon from "./icons/DownloadIcon";
import LocationContactsContent from "./LocationContactsContent";
import FeedbackWidget from "../features/Feedback/Components/FeedbackWidget";
import { useMediaQuery } from "react-responsive";
import AppointmentCallToAction from "../features/Appointments/Components/AppointmentCallToAction";

function ReplaceUrl(path,href) {
  const last = href.split("/")
  const lastSegment = last[last.length-2];
  const url = path + lastSegment + "/";
  return url;
}

function ReplaceUmbracoUrl(href) {
  let url = href;
  if (url.startsWith("https://preview.umbraco.io")) {
    url = href.replace("https://preview.umbraco.io","");
  }
  if (url.startsWith("https://cdn.umbraco.io")) {
    url = href.replace("https://cdn.umbraco.io","");
  }
  return url;
}

function ServiceInteractions(props) {
  const location = useLocation();
  const path = location.pathname;
  const [interactions, setInteractions] = useState(null);
  const [simpleService, setSimpleService] = useState(false);
  const [error, setError] = useState(null);
  const url = props?.href; 
  useEffect(() => {  
    if (!interactions) {
      callUmbracoApi(url)
        .then(response => {
          setInteractions(response?._embedded?.content); 
          if (response?._embedded?.content && response?._embedded.content.length)
          {
            response?._embedded?.content.forEach(element => {
              if (element.contentTypeAlias === "webLink" 
                  || element.contentTypeAlias === "emailContact" 
                  || element.contentTypeAlias === "phoneContact" 
                  || element.contentTypeAlias === "inPersonContact") {
                setSimpleService(true);
              }
            });
          }
          setError(null);
        })
        .catch((error) => {
          setError(error?.message);
          console.log("Error: ", error);
        });
    }
  }, []);
  //console.log("SERVICE INTERACTIONS: ", interactions);

  return (
    <>
      {interactions ? 
        simpleService ?
          <div className="mb-10">
            <LocationContactsContent contacts={interactions} />
          </div>
          : 
          <div className="grid grid-cols-2 lg:grid-cols-4 mb-6">
          {
            interactions.map((item) => (
              <Link key={item.sortOrder} to={ReplaceUrl(path,item._url)} title={item.interactionName} 
                className="flex flex-col items-center p-4 text-goj-charcoal hover:text-goj-charcoal visited:text-goj-charcoal leading-4">
                <span className="h-12 w-12 rounded-full bg-goj-secondary flex items-center justify-center">
                  <ServiceInteractionIcon />
                </span>
                {console.log("item",item)}
                <span className="text-center py-1">{item.interactionName}</span>
              </Link>
            ))
          }
          </div>
        : null
      }
    </>
  )
}

function SupportingDocuments(props) {
  return (
    <ul className="w-full list-none ml-0">
      {props?.attachments?.map((file) => (
        <li key={file?.media?._id} className="border-l-4 border-goj-secondary rounded p-4 my-4 bg-goj-secondary-very-light-tint">
          <a href={file?.src} className="flex justify-between text-goj-charcoal visited:text-goj-charcoal" title={file?.media?.name + "." + file?.media?.umbracoExtension} target="_blank" rel="noopener noreferrer">
            <div className="flex">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 flex-none">
                <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m2.25 0H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
              </svg>
              <span className="ml-2 text-lg">
                {file?.media?.name + "." + file?.media?.umbracoExtension}
              </span>
            </div>
            <div className="flex">
              <DownloadIcon />
            </div>
          </a>
        </li>
      ))}
    </ul>
  )
}

function Contact(props) {
  const parse = require('html-react-parser');
  let teamContactInformation = props?.team?.contactInformation;
  if (teamContactInformation) {
    teamContactInformation = parse(teamContactInformation);
  }
  return (
    <div className="flex flex-wrap">
      {props?.team?.contactTeamName ?
        <div className="w-full lg:w-1/2">
          <div className="space-y-2 bg-goj-gray-light-super rounded p-4 mb-4 lg:mr-4">
            <ContactCallToAction name={props?.team?.contactTeamName} phone={props?.team?.contactPhone} email={props?.team?.contactEmail} />
            {props?.team?.contactInformation ? 
              <div className="flex gap-1 items-center">
                {teamContactInformation}
              </div> : null
            }          
          </div>        
        </div> : null
      }
      <div className="w-full lg:w-1/2">
        <div className="space-y-2 bg-goj-gray-light-super rounded p-4 mb-4 lg:mr-4">
          <AppointmentCallToAction text={props?.appointmentButtonText} />
        </div>
      </div>
    </div>
  )
}

function ReplaceUrlRelatedService(path,href) {
  const last = href.split("/");
  const lastSegment = last[last.length-2]; 
  const segments = path.split("/");
  const url = "/" + segments[1] + "/" + segments[2] + "/" + lastSegment + "/";
  return url;
}

function RelatedServices(props) {
  const location = useLocation();
  const path = location.pathname;
  return (
    <ul className="w-full list-none ml-0">
    {props?.relatedServices?.map((item) => (
      <li key={item?.url} className="text-goj-secondary hover:text-goj-secondary">
        <a href={ReplaceUrlRelatedService(path,item?.url)} className="flex text-goj-secondary hover:text-goj-secondary" title={item?.name}>
          <span className="text-goj-secondary hover:text-goj-secondary">
            {item?.name}
          </span>
        </a>
      </li>
    ))}
  </ul>
  )
}

function AdditionalInformation(props) {
  return (
    <ul className="w-full list-none ml-0">
    {props?.additionalInformation?.map((info) => (
      <li key={info?.url} className="text-goj-secondary hover:text-goj-secondary">
        <a href={info?.url} className="flex text-goj-secondary hover:text-goj-secondary" title={info?.name}>
          <span className="text-goj-secondary hover:text-goj-secondary">
            {info?.name}
          </span>
        </a>
      </li>
    ))}
  </ul>
  )
}

export default function ServiceContent(props) {
  const parse = require('html-react-parser');
  const data = props?.data;
  let count = 0;
  const sections = [];
  const location = useLocation();
  const isMobile = useMediaQuery({ maxWidth: 768 });

  if (data?.longDescription) {
    count++
    sections.push({id:count,title:"Description",content: parse(data?.longDescription),parsed:true})
  }
  if (data?.serviceSteps) {
    count++
    sections.push({id:count,title:"Service steps",content: parse(data?.serviceSteps),parsed:true})
  }
  if (data?.preRequisites) {
    count++
    sections.push({id:count,title:"Pre-requisites",content: parse(data?.preRequisites),parsed:true})
  }
  if (data?.constraints) {
    count++
    sections.push({id:count,title:"Constraints",content: parse(data?.constraints),parsed:true})
  }
  if (data?.attachments?.length > 0) {
    count++
    sections.push({id:count,title:"Supporting documents",content: <SupportingDocuments attachments={data?.attachments} />,parsed:false})
  }
  if (data?.employee || data?.team || data?.qmaticReference) {
    count++
    sections.push({id:count,title:"Contact",content: <Contact department={data?.department} team={data?.team} employee={data?.employee} appointmentButtonText={data?.appointmentButtonText} />,parsed:false})
  }
  if (data?.relatedServices?.length > 0) {
    count++
    sections.push({id:count,title:"Related services",content: <RelatedServices relatedServices={data?.relatedServices} />,parsed:false})
  }
  if (data?.additionalInformation?.length > 0) {
    count++
    sections.push({id:count,title:"Related links",content: <AdditionalInformation additionalInformation={data?.additionalInformation} />,parsed:false})
  }

  if (!isMobile) {
    return (
      <div className="hidden lg:block container mx-auto my-0 max-w-oshMaxWidth">
        <div className="grid grid-cols-1 lg:grid-cols-12">
          <aside className="sticky top-24 h-fit pr-4 order-last lg:order-first lg:col-span-2 my-6">
            <ol className="text-oshFilter">
              {sections.map((section) => {
                return (
                  <li key={section.id} className="cursor-pointer border-l-2 border-goj-gray-light hover:border-goj-primary pl-2 hover:underline py-1" 
                    onClick={() => window.location.replace(`${location.pathname}#${section.title}`)}>
                    <span>{section.title}</span>
                  </li>
                )
              })}
            </ol>
          </aside>
          <main className="pagecontent order-first lg:order-last lg:col-span-8 my-4">
            <FeedbackWidget feedbackTitle={data?.feedbackTitle} feedbackDescription={data?.feedbackDescription} feedbackLink={data?.feedbackLink} />
            <h1>{data?.serviceName}</h1>
            {
              data?.shortDescription ? <div><h2>Introduction</h2><p>{data?.shortDescription}</p></div> : null
            }
            {
              data?._links?.children?.href ? <ServiceInteractions href={ReplaceUmbracoUrl(data?._links?.children?.href)} /> : null
            }
            {sections.map((section) => { 
              return (
                <section key={section.id} id={section.title} className={`mb-10 ${section.parsed ? "content" : null}`}>
                  <h2>{section.title}</h2>
                  {section.content}
                </section>
              )})
            }
          </main>
        </div>
      </div>
    )
  }
  else {
    return (
      <main className="pagecontent lg:hidden">
        <div className="mx-4 my-2">
          <FeedbackWidget feedbackTitle={data?.feedbackTitle} feedbackDescription={data?.feedbackDescription} feedbackLink={data?.feedbackLink} />
          <h1>{data?.serviceName}</h1>
          {
            data?.shortDescription ? <div><h2>Introduction</h2><p>{data?.shortDescription}</p></div> : null
          }
          {
            data?._links?.children?.href ? <ServiceInteractions href={ReplaceUmbracoUrl(data?._links?.children?.href)} /> : null
          }
        </div>
        <Accordion transition={{ duration: "300ms", timingFunction: "cubic-bezier(0, 0, 0.2, 1)" }} alwaysOpen="true">
          {sections.map((section) =>
            <AccordionItem key={section.id}>
              {({ open }) => (
                <>
                  <AccordionHeader className={`w-full flex justify-between items-center text-goj-charcoal border-b p-4 border border-goj-gray-light text-left ${open ? 'bg-goj-secondary-very-light-tint' : 'bg-goj-white'}`}>
                    <h2 className="mb-0">{section.title}</h2>
                    <svg className={`w-6 h-6 text-goj-charcoal ${!open ? '' : 'rotate-90'}`} fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                      <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
                    </svg>
                  </AccordionHeader>
                  <AccordionBody>
                    <div className={`p-5 font-light ${section.parsed ? "content" : null}`}>
                      {section.content}
                    </div>
                  </AccordionBody>
                </>
              )}
            </AccordionItem>
          )}
        </Accordion>
      </main>
    )
  }
};
