import dayjs from 'dayjs';

export default function TaxRateInfo(props) {
  const taxRateInfo = props;
  const oneMonthAgo = dayjs().subtract(1, 'month');
  const oneMonthAhead = dayjs().add(1, 'month');
  let cardTaxRateInfo = {display: false};
  // configure the tax rate data card
  if (taxRateInfo && taxRateInfo.commenceDate && taxRateInfo.ceasedDate) {
    // start date is recent 
    //if (dayjs(taxRateInfo.commenceDate).isAfter(dayjs(oneMonthAgo))) {
      cardTaxRateInfo = {
        display: true,
        id: "tax-rate-info",
        title: "Income tax",
        description: "Your income tax effective rate from " + dayjs(taxRateInfo.commenceDate).format('D MMMM YYYY') + " to " + dayjs(taxRateInfo.ceasedDate).format('D MMMM YYYY') + " is: " + taxRateInfo?.ratePercentage + "%",
        link: [
          {id: "tax-rate-link", text: "View your tax rate", href:"/dashboard/your-services/income-tax-rate/"}//,
          //{id: "SRV-07", text: "Audit history", href:"/dashboard/your-services/personal-tax/"}
        ],
        date: taxRateInfo.commenceDate,
        attention: false
      }
      // attention start date is within one month
      if (dayjs(taxRateInfo.commenceDate).isAfter(dayjs()) && dayjs(taxRateInfo.commenceDate).isBefore(dayjs(oneMonthAhead))){
        cardTaxRateInfo.attention = true;
      }
    //}
  }
  return cardTaxRateInfo;
}