import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin, withAITracking } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from "history";

var reactPlugin = new ReactPlugin();

const browserHistory = createBrowserHistory({ basename: '' });
const instrumentKey = process.env.REACT_APP_APPINSIGHTS_INSTRUMENTATION_KEY;
const IngestionEndpoint=process.env.REACT_APP_APPINSIGHTS_INGESTATION_KEY;
const liveEndpoint=process.env.REACT_APP_APPINSIGHTS_LIVE_ENDPOINT;

var appInsights = new ApplicationInsights({
    config: {
        connectionString:`InstrumentationKey=${instrumentKey};IngestionEndpoint=${IngestionEndpoint};liveEndpoint=${liveEndpoint}`,
        enableAutoRouteTracking: true,
        extensions: [reactPlugin],
        extensionConfig: {
          [reactPlugin.identifier]: { history: browserHistory }
        }
    }
});

appInsights.loadAppInsights();
export { reactPlugin, appInsights };