import { useContext, useEffect, useState } from "react";
import { MsalAuthenticationTemplate, useMsal } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { loginRequest } from "../authConfig";
import { useAppInsightsContext, useTrackMetric, useTrackEvent } from "@microsoft/applicationinsights-react-js";
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { useLocation, useNavigate } from 'react-router-dom';
import Breadcrumb from "../components/Breadcrumb";
import ProfileAside from "../components/ProfileAside";
import useBreadcrumbs from 'use-react-router-breadcrumbs'
import Loading from "../components/Loading";
import DashboardContext from "../context/DashboardContext";
import DashboardCards from "../components/DashboardCards";
import { callUmbracoApi } from "../fetch";
import { Helmet } from "react-helmet";
import ParseHtml from "../utils/ParseHtml";
import FeedbackWidget from "../features/Feedback/Components/FeedbackWidget";

export default function Dashboard() {
  const { instance } = useMsal();
  const authRequest = { ...loginRequest };
  const dashboard = useContext(DashboardContext);
  const lastRead = dashboard?.notificationsLastRead;
  const navigate = useNavigate();
  const location = useLocation();

  function navigateToServices() {
    navigate("/dashboard/your-services/");
  }

  // appInsights
  const appInsights = useAppInsightsContext();
  const trackComponent = useTrackMetric(appInsights, "Dashboard");
  appInsights?.trackTrace({ message: 'PublishingWeb_Dashboard page has been clicked by the user', severityLevel: SeverityLevel.Information });

  // breadcrumbs
  const breadcrumbs = useBreadcrumbs();
  const pages = []
  breadcrumbs.map((elem) => {
    if (elem.breadcrumb.props.children != "Home") {
      pages.push({ name: elem.breadcrumb.props.children, href: elem.key + "/", current: false })
    }
  })

  const [loading, setLoading] = useState(true);
  useEffect(() => {
    lastRead ? setLoading(false) : setLoading(true);
  }, [lastRead]);

  // fetch page content from cms
  const [data, setData] = useState(null);
  useEffect(() => {
    const fetchData = async () => {
      try {
        let url = "/content/url?url=/dashboard/your-dashboard/";
        const response = await callUmbracoApi(url);
        setData(response);
      } catch (e) {
        console.log("Error: ", e?.message);
      }
    };
    if (!data) {
      fetchData();
    }
  }, [data]);

  function RenderFeedbackWidget() {
    return (<FeedbackWidget feedbackTitle={data?.feedbackTitle} feedbackDescription={data?.feedbackDescription} feedbackLink={data?.feedbackLink} />)
  }
  
  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authRequest}
    >
      <Helmet>
        <title>{!data ? '' : data.metaTitle}</title>
        <meta name="description" content={!data ? '' : data.metaDescription} />
        <meta name="keywords" content={!data ? '' : data.metaKeywords} />
      </Helmet>
      <>
        <Breadcrumb pages={pages} currentPage={location.pathname} />
        <div className="container mx-auto max-w-oshMaxWidth">
          <div className="grid grid-cols-1 lg:grid-cols-12 mt-4">
            <ProfileAside />
            <main className="p-4 lg:p-0 order-first lg:order-last lg:col-span-8 ">
              <RenderFeedbackWidget />
              <h1>{data?.mainHeading ?? `Your dashboard`}</h1>
              {ParseHtml(data?.mainContent)}
              {/* <h2 className="text-right text-goj-charcoal"><span className="font-medium">Welcome</span> {instance.getActiveAccount()?.idTokenClaims?.given_name} {instance.getActiveAccount()?.idTokenClaims?.family_name}</h2> */}
              {loading ? <Loading /> : <DashboardCards />}
            </main>
          </div>
        </div>
      </>
    </MsalAuthenticationTemplate>
  )
}
