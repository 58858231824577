import { useContext } from "react";
import { Accordion, AccordionBody, AccordionHeader, AccordionItem } from "react-headless-accordion";
import { DevicePhoneMobileIcon, EnvelopeIcon } from "@heroicons/react/24/outline";
import dayjs from "dayjs";
import DashboardContext from "../../../context/DashboardContext";
import { useNavigate } from "react-router-dom";

export default function NotificationsCards() {
  const dashboard = useContext(DashboardContext);
  const lastRead = dashboard?.notificationsLastRead; 
  const notifications = dashboard?.notificationsRecent;
  const listLength = 3

  const navigate = useNavigate();
  function navigateToNotifications() {
    navigate("/dashboard/notifications/");
  }

  let content = [];
  if (notifications && notifications?.length) {
    content = notifications.map((item,index) => {
      if (index < listLength) {
        if (item?.id && item?.channelType && item?.notificationDate) {
          return (
            <AccordionItem key={item.id}>
              {({ open }) => (
                <>
                  <AccordionHeader 
                    className={`show w-full flex items-start border-l-4 text-left px-2 pt-4 ${dayjs(item.notificationDate).isAfter(dayjs(lastRead)) ? 'border-goj-secondary bg-goj-secondary/5' : 'border-goj-white bg-goj-white'} ${index === 0 ? " rounded-t" : ""}`}>
                    <div className={`lg:flex lg:flex-shrink-0 ${dayjs(item.notificationDate).isAfter(dayjs(lastRead)) ? 'text-goj-secondary' : 'text-goj-charcoal'}`}>
                      {item.channelType.toString().toUpperCase() === "SMS" ?
                        <DevicePhoneMobileIcon className="w-6 h-6" />
                        :
                        <EnvelopeIcon className="w-6 h-6" />
                      }
                    </div>
                    <div className="w-full pl-2 pr-8">
                    <div className="hidden lg:block truncate">{dayjs(item.notificationDate).format('dddd D MMMM YYYY')}</div>
                    <div className="block lg:hidden truncate">{dayjs(item.notificationDate).format('DD/MM/YYYY')}</div>
                    <div className="flex justify-between">
                      <h2 style={{marginBottom: "0px"}}>{item?.senderName ? item?.senderName : "Online Services Hub"}</h2>
                      <div className="flex-none justify-end text-base">
                        <svg className={`w-6 h-6 lg:ml-1 text-goj-charcoal ${!open ? '' : 'hidden lg:block rotate-90'}`} fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                          <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
                        </svg>
                      </div>
                    </div>
                    <div className="truncate mb-4">
                      {item.channelType.toString().toUpperCase() === "SMS" ? item.contentDetails : <span style={{textWrap: "wrap"}}>{item.subjectLine}</span>}
                    </div>
                      {
                        dayjs(item.notificationDate).isBefore(dayjs(lastRead)) ? <hr className={`mt-4 border border-goj-gray-light`} /> : !open ? notifications?.length === index + 1 || index === 2 ? <hr className={`mt-4 border-0`} /> : <hr className={`mt-4 border border-goj-gray-light`} /> : <hr className={`mt-4 border-0`} />
                      }
                    </div>
                  </AccordionHeader>
                  <AccordionBody className={index === (listLength - 1) ? "rounded-b" : ""} >
                    <div className="pl-11 py-4">
                      <p>{item.contentDetails}</p>
                    </div>
                  </AccordionBody>
                </>
              )}
            </AccordionItem>
          )
        }
      }
    })
  }

  return (
    <>
    { 
      content && content.length ? 
        <div id="NotificationCards">
          <h3>Notifications</h3>
          <Accordion transition={{ duration: "300ms", timingFunction: "cubic-bezier(0, 0, 0.2, 1)" }} alwaysOpen="true">
            {content}
          </Accordion>
          <div className="container mx-0 min-w-full flex flex-col items-end mt-4">
            <button
              type="button"
              className="w-full md:w-fit justify-center rounded text-oshBase font-semibold border border-goj-secondary bg-goj-white py-2 px-4 text-goj-secondary hover:text-goj-secondary-light hover:border-goj-secondary-light shadow-sm focus:outline-none focus:ring-2 focus:border-goj-focus-dark focus:ring-goj-focus-light"
              onClick={navigateToNotifications}
            >
              View all notifications
            </button>
          </div> 
        </div>
      : null 
    }
    </>
  )
}