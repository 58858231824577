import { useEffect } from 'react';
import { useMsal } from '@azure/msal-react';
import { useNavigate } from "react-router-dom";

export const HandleRedirectPromiseComponent = () => {
  const { instance, accounts } = useMsal();
  const navigate = useNavigate();

  useEffect(() => {
    const handleRedirect = async () => {
      try {
        const response = await instance.handleRedirectPromise();
        if (response !== null) {
          // Check if the authentication was successful
          if (response.account) {
            // Set the active account
            localStorage.removeItem('userLoggedOut');
            instance.setActiveAccount(response.account);
          }
        }
      } catch (error) {
        console.error(error);
        // Handle errors as needed, e.g., redirect to an error page or display a message
      }
    };

    handleRedirect();
  }, [instance]);
  return null; // This component does not render anything, it just handles the redirect promise
};