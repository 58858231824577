import { protectedResources } from '../authConfig';
import React from 'react';
import { useMsal } from "@azure/msal-react";
import Button from './Button';

export default function LoginButton({ btnStyles, text, textStyles }) {
  const { instance } = useMsal();
  const scopes = [...protectedResources.apiProfileFull.scopes];
  const request = {
    prompt: 'select_account',
    scopes: scopes
  };

  btnStyles = btnStyles ?? "flex items-center justify-center border border-transparent bg-goj-primary lg:px-4 lg:py-2 py-0 px-2 rounded text-base font-medium text-goj-white shadow-sm focus:bg-goj-focus-dark focus:outline-none focus:ring-1 focus:ring-goj-focus-light";
  text = text ?? "Log in";
  textStyles = textStyles ?? "text-oshFilter lg:text-oshBase";

  const handleLoginClick = () => {
    instance.loginRedirect(request)
      .then(response => {
        if (response !== null) {
          localStorage.removeItem('userLoggedOut');
          instance.setActiveAccount(response.account);
        }
      })
      .catch(error => {
        console.error("Login failed:", error);
      });
  };

  return (
    <Button styles={btnStyles} onClick={handleLoginClick}><span className={textStyles}>{text}</span></Button>
  )
};
