import FormDisplayComponent from "./FormDisplayComponent";
import { useState, useContext } from "react";
import { useMsal, useAccount } from "@azure/msal-react";
import { BrowserAuthError, InteractionRequiredAuthError, InteractionStatus } from "@azure/msal-browser";
import { protectedResources } from "../authConfig";
import { callApiWithToken } from "../fetch";
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import UserContext from "../context/UserContext";
import FormatPhoneNumber from "../utils/FormatPhoneNumber";

const formatBirthdate = (birthdate) => {
  const date = new Date(birthdate);
  const options = {
    day: 'numeric',
    month: 'long',
    year: 'numeric'
  };
  return date.toLocaleDateString('en-GB', options);
}

const formatResidencyDate = (residencyDate) => {
  const date = new Date(residencyDate);
  const options = {
    day: 'numeric',
    month: 'numeric',
    year: 'numeric'
  };
  return date.toLocaleDateString('en-CA', options);
}

function FormatWords(word) {
  return word.replace(/([A-Z])/g, ' $1').trim()
}

export default function ProfileData(props) {
  const { instance, accounts, inProgress } = useMsal();
  const account = useAccount(instance.getActiveAccount() || {});
  const profile = props.profile;
  const user = useContext(UserContext);
  const fullProfile = user.fullProfile;
  let [showMore, setShowMore] = useState(false);
  const [fullProfileOther, setProfileOther] = useState(null);
  const [fullProfileImmigration, setProfileImmigration] = useState(null);
  const [loadingButton, setLoadingButton] = useState(false);

  //console.log("PROFILE PROPS",props.profile)
  var LinkFields = []
  if (profile.authenticationSource === 'L2') {
    LinkFields = ['Contact information', 'Address', 'Preferences']
  }
  if (profile.authenticationSource === 'L0') {
    LinkFields = ['First name', 'Surname', 'Date of birth', 'Contact information', 'Address', 'Preferences']
  }

  let personalDetails = {
    'Full name': !profile || profile?.firstName === undefined || profile?.lastName === undefined ? '--' : profile?.firstName + ' ' + profile?.lastName,
    'First name': !profile?.firstName ? '--' : profile.firstName,
    'Surname': !profile?.lastName ? '--' : profile.lastName,
    'Date of birth': !profile?.dateOfBirth ? '--' : formatBirthdate(profile.dateOfBirth)
  }
  let contactInformation = {
    'Email': !profile?.contact?.emailAddress ? '--' : profile.contact.emailAddress,
    'Mobile': !profile?.contact?.mobilePhone ? '--' : FormatPhoneNumber(profile.contact.mobilePhone),
    'Home phone': !profile?.contact?.homePhone ? '--' : FormatPhoneNumber(profile.contact.homePhone),
    'Work phone': !profile?.contact?.workPhone ? '--' : FormatPhoneNumber(profile.contact.workPhone)
  }
  let notificationChannel = {
    'Preferred notification': !profile?.notificationChannel ? '--' : profile.notificationChannel === "EMAIL" ? "Email" : profile.notificationChannel,
    // 'Persona': !profile.goj_profileType ? '--' : profile.goj_profileType
  }
  let addressInformation = {};
  if (profile?.address?.country === "Jersey" || profile?.address?.country === "Guernsey" || profile?.address?.country === null || profile?.address?.country === "") {
    addressInformation = {
      'Address line 1': !profile?.address?.addressLine1 ? '--' : profile.address.addressLine1,
      'Address line 2': !profile?.address?.addressLine2 ? '--' : profile.address.addressLine2,
      'Address line 3': !profile?.address?.addressLine3 ? '--' : profile.address.addressLine3,
      'Postcode': !profile?.address?.postalCode ? '--' : profile.address.postalCode,
      'Parish': !profile?.address?.stateProvince ? '--' : profile.address.stateProvince,
      'Country': !profile?.address?.country ? '--' : profile.address.country
    }
  }
  else {
    addressInformation = {
      'Address line 1': !profile?.address?.addressLine1 ? '--' : profile.address.addressLine1,
      'Address line 2': !profile?.address?.addressLine2 ? '--' : profile.address.addressLine2,
      'Address line 3': !profile?.address?.addressLine3 ? '--' : profile.address.addressLine3,
      'Postcode': !profile?.address?.postalCode ? '--' : profile.address.postalCode,
      'City': !profile?.address?.stateProvince ? '--' : profile.address.stateProvince,
      'Country': !profile?.address?.country ? '--' : profile.address.country
    }
  }

  function fullProfileData(fullProfile) {
    setProfileOther({
      'Place of birth': !fullProfile?.address?.cityBirthPlace ? '--' : fullProfile.address.cityBirthPlace,
      'Social Security Number': !fullProfile?.identifiers?.ssn ? '--' : fullProfile.identifiers.ssn,
      'Tax Identification Number': !fullProfile?.identifiers?.tin ? '--' : fullProfile.identifiers.tin.substring(0, 3) + "-" + fullProfile.identifiers.tin.substring(3, 6) + "-" + fullProfile.identifiers.tin.substring(6)
    })
    setProfileImmigration({
      'Residential status': !fullProfile?.residential?.status ? '--' : FormatWords(fullProfile.residential.status),
      'Residential condition': !fullProfile?.residential?.condition ? '--' : FormatWords(fullProfile.residential.condition),
      'Residential status expiry': !fullProfile?.residential?.statusExpiry ? '--' : formatResidencyDate(fullProfile.residential.statusExpiry)
    })
    setShowMore(true);
  }

  function showMoreOptions() {
    setLoadingButton(true);
    console.log("loadingbutton:", loadingButton);
    if (fullProfile === 2) {
      fullProfileData(fullProfile);
    } else if (account && inProgress === InteractionStatus.None) {
      instance.acquireTokenSilent({
        scopes: protectedResources.apiProfileFull.scopes,
        account: account,
        redirectUri: window.location.origin+"/auth.html",
        loginHint: instance.getActiveAccount()?.idTokenClaims.email
      }).then((response) => {
        callApiWithToken(response.accessToken, protectedResources.apiProfileFull.endpoint).then(response => {
          const fullProfile = response;
          fullProfileData(fullProfile);
        }).finally(() => {
          setLoadingButton(false);
        });
      }).catch((error) => { // in case if silent token acquisition fails, fallback to an interactive method
        if (error instanceof InteractionRequiredAuthError || BrowserAuthError) {
          if (account && inProgress === InteractionStatus.None) {
            instance.acquireTokenRedirect({
              scopes: protectedResources.apiProfileFull.scopes,
              account: account,
              loginHint: account
            }).then((response) => {
              callApiWithToken(response.accessToken, protectedResources.apiProfileFull.endpoint).then(response => {
                const profile = response;
                fullProfileData(profile);
              }).finally(() => {
                setLoadingButton(false);
              });
            }).catch(error => {
              console.log(error);
              setLoadingButton(false);
            });
          }
        } else {
          setLoadingButton(false);
        }
      });
    }
  }

  return (
    <div>
      <FormDisplayComponent personalDetails={personalDetails} linkGen={LinkFields} heading='User profile' />
      <FormDisplayComponent personalDetails={contactInformation} linkGen={LinkFields} heading='Contact information' />
      <FormDisplayComponent personalDetails={notificationChannel} linkGen={LinkFields} heading='Preferences' />
      <FormDisplayComponent personalDetails={addressInformation} linkGen={LinkFields} heading='Address' />
      {profile.authenticationSource === 'L2' ? <div>
        {(showMore) ?
          <div>
            <FormDisplayComponent personalDetails={fullProfileOther} linkGen={LinkFields} heading='Other details' />
            <FormDisplayComponent personalDetails={fullProfileImmigration} linkGen={LinkFields} heading='Residential and employment information' />
          </div> :
          <div className="container py-10 px-10 mx-0 min-w-full flex flex-col items-center">
            <button
              type="button"
              className="flex items-center justify-center rounded border border-goj-secondary bg-white py-2 px-4 text-oshBase font-semibold text-goj-secondary shadow-sm focus:outline-none focus:ring-2 focus:ring-goj-secondary-dark focus:ring-offset-2" onClick={showMoreOptions} disabled={loadingButton}>
              {console.log("loadingbutton:", loadingButton)}
              {loadingButton ? (
                <svg className="inline w-5 h-5 text-gray-200 animate-spin dark:text-gray-600 fill-goj-secondary" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                  <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                </svg>
              ) : (
                <> View more <ChevronDownIcon className="w-4 h-4 ml-2" fill="white" />
                </>
              )}
            </button>
          </div>}
      </div> : null}
    </div>
  )
}
