export default function ReplaceUmbracoUrl(href) {
  let url = href;
  if (!url) { return null };
  
  if (url.startsWith("https://preview.umbraco.io")) {
    url = href.replace("https://preview.umbraco.io","");
  }
  if (url.startsWith("https://cdn.umbraco.io")) {
    url = href.replace("https://cdn.umbraco.io","");
  }
  return url;
}